<template lang="pug">
  .comments-panel-container
    .comments-panel.elevation-2
      .no-one-comment.px-3( v-if='selectedDrawingPage.attachments == 0' ) {{ $t('module.drawing.addComment.noOneComment') }}
      div( v-else )
        .comments-panel-header
          .font-weight-bold {{ $t('module.task.comments') }}
          v-btn.ml-auto(icon x-small @click='importAllComments')
            img(src='../../../public/img/project/tools/arrow-left-top-double.svg')
        .comments-panel-body
          v-hover(v-slot:default='{ hover }' v-for='comment in currentComments' :key='comment.uuid')
            .comment-item(@click.stop='scrollPageToComment(comment)')
              .d-flex.align-center
                .user-avatar
                  span.ma-auto {{ comment.user.name[0].toUpperCase() }}
                .ml-2
                  .comment-user-name {{ comment.user.name }}
                  .comment-create-date {{ dateFormat(comment.createDate) }}
                .ml-auto
                  transition( name='button' )
                    v-btn.ml-1(icon small v-if='hover' @click.stop='importOneComment(comment)')
                      img(src='../../../public/img/project/tools/arrow-left-top.svg')
                  transition( name='button' )
                    v-btn.ml-1(icon small v-if='hover' @click.stop='showCommentChangeDialog(comment)')
                      v-icon(color='#757575') mdi-pencil
                  transition( name='button' )
                    v-btn.ml-1(icon small v-if='hover' @click.stop='removeComment(comment)')
                      v-icon(color='#757575') mdi-delete-outline
              .comment-text {{ comment.text }}
    
    //- Редактирование комментария
    app-dialog(v-model='changeCommentDialog.show' :header='$t("module.drawing.addComment.placeholder")')
      app-section
        app-textarea( v-model="changeCommentDialog.comment.text" :label='$t("module.drawing.addComment.placeholder")' outlined hide-details)
        app-select( v-if='changeCommentDialog.comment.task' v-model='changeCommentDialog.comment.task' :items='tasks' item-text='title' :label='$t("module.drawing.task.chooseTask")' )
        .text-color-grey(v-else)
          .font-weight-regular {{$t("module.drawing.task.addToTask")}}
          app-select( v-model='changeCommentDialog.comment.task' :items='tasks' item-text='title' :label='$t("module.drawing.task.chooseTask")' )
      template(v-slot.footer)
        .d-flex.align-center.mt-5
          v-btn( icon small @click='removeCommentAndHidePanel(changeCommentDialog.comment)')
            v-icon(color='#e5e5e5') mdi-delete-outline
          v-btn.ml-auto( color='accent' @click='changeComment(changeCommentDialog.comment)') {{ $t("button.save") }}
</template>

<script>
import { DrawingComment } from '@/assets/model/drawing/DrawingComment'
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  name: "DrawingsCommentsPanel",
  data() {
    return {
      changeCommentDialog: {
        show: false,
        comment: {},
      }
    }
  },
  computed: {
    ...mapState('drawings', ['selectedDrawingPage', 'commentImportMode', 'commentsToImport', 'drawingsPanel']),
    ...mapState('task', ['tasks']),
    currentComments() {
      if(this.commentImportMode) {
        return this.commentsToImport
      }
      return this.selectedDrawingPage.attachments
    }
  },
  methods: {
    ...mapActions('drawings', ['deleteComment', 'editDrawingComment']),
    ...mapMutations('drawings', ['setScaleDrawing', 'setSelectedDrawingPage', 'setCommentImportMode', 'setCommentsToImport', 'addCommentToImportComments', 'setFlagComment']),

    showCommentChangeDialog(comment) {
      this.changeCommentDialog.show = true
      this.changeCommentDialog.comment = new DrawingComment(null, comment)
    },

    removeComment(comment) {
      this.deleteComment(comment)
    },

    changeComment(comment) {
      this.editDrawingComment(comment).then(() => {
        this.changeCommentDialog.show = false
      })
    },

    scrollPageToComment(comment) {
      this.$emit('scrollto', comment)
    },

    importAllComments() {
      console.log("DrawingCommentsPanel: ", this.selectedDrawingPage)
      this.setCommentImportMode(true)
      this.setFlagComment(true)
      this.setCommentsToImport(this.selectedDrawingPage.attachments)
    },

    importOneComment(comment) {
      console.log("2")
      this.setCommentImportMode(true)
      this.setFlagComment(false)
      this.addCommentToImportComments(comment)
    },
    
    dateFormat(date) {
      return new Date(date).toISOString().split("T")[0].replace(/-/g, "/")
    },

    removeCommentAndHidePanel(comment){
      this.removeComment(comment)
      this.changeCommentDialog.show = false
    }
  },
}
</script>

<style lang="scss" scoped>
  .comments-panel{
    color: #505254;
    widows: 340px;
    background: white;
    border-radius: 3px;
  }
  .comments-panel-header{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #bdbdbd;
    padding: 6px 12px 6px 12px;
  }
  .comments-panel-body{
    display: flex;
    flex-direction: column;
    padding: 8px;
    gap: 4px;
  }
  .comment-item{
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    padding: 4px;
    transition-duration: .4s;
  }
  .comment-item:hover{
    // background: #f2f2f2;
    // background: #c4c4c4;
    background: #e5e5e5;
    cursor: pointer;
  }
  .comment-item:active{
    background: #c7c7c7;
    transition-duration: .1s;
  }
  .user-avatar{
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background: #505254;
    display: flex;
    align-items: center;
    justify-items: center;
    color: white;
    font-size: 18px;
  }
  .comment-user-name{
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
  }
  .comment-create-date{
    font-size: 12px;
    font-weight: thin;
    line-height: 16px;
  }
  .comment-text{
    padding: 0px 0px 0px 40px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .button-enter-active, .button-leave-active {
    transition: opacity .4s;
  }
  .button-enter, .button-leave-to{
    opacity: 0;
  }
  .text-color-grey{
    color: #666666;
  }
</style>