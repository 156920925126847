<template lang="pug">
  app-panel( padding="0px")
    fix-panels-section( style="height: calc(100% - 26px) !important; overflow: auto; overflow-x: hidden" )
      fix-panel(:title="$t('section.journal.chart')" style="height: calc(100% - 10px) !important;")
        template(v-slot:headerButton)
          app-toolbar-button.mr-2(v-if="hasMaintainceSettingChartTO" header size="small" icon="$setting-icon" :title="$t('module.task.settings')" @click.stop="openSettings" )
          
        div.wrapper
          v-simple-table.scroll-changer(dense fixed-header height="100%" style="height: 100%")
            template(v-slot:default)
              thead
                tr
                  th.sticky-col 
                    v-tooltip(v-if="daysInMonth" top) {{ $t('module.task.back') }}
                      template(v-slot:activator="{on, attrs}" )
                        v-btn(@click="returnFromMonthView()" small icon v-bind="attrs" v-on="on")
                          v-icon mdi-arrow-left 
                    span(v-if="daysInMonth").ml-2 {{prettyDate}}    
                  th.px-1.app-cursor--pointer(v-if="!daysInMonth" v-for="(month,index) in months" @click="goToMonthView(month,index)") {{monthTitle(month)}}
                  th.px-1.app-cursor--pointer(v-if="daysInMonth" v-for="day in daysInMonth")  {{day}}

              tbody
                tr(v-for="item in treeFlatList" :key="item.uuid")
                  td.sticky-col(:style="item.isGroup? '' : 'overflow: auto;'") 
                    .node__level( v-for="index in item.level" :key="index" )
                    span(v-if="item.isGroup") {{ sourceTitle(item) }}
                    span.app-cursor--pointer.font-weight-bold(v-if="!item.isGroup" @click="goToEquipment(item)") {{ sourceTitle(item) }}

                  td.td-item.px-1(v-if="!daysInMonth" v-for="taskList in mTasks" )
                    span(v-if="!item.isGroup") 
                      template(v-for="task in taskList")
                        span.app-cursor--pointer.px-1(v-if="task.equipmentId == item.uuid" :class="'task-status--' + task.status.name" @click="openCheck(task)" @contextmenu="openMenu($event, task)") {{task.title}}

                  td.td-item.px-1(v-if="daysInMonth" v-for="taskList in monthTask" )
                    span(v-if="!item.isGroup") 
                      template(v-for="task in taskList")
                        //- v-tooltip(top) Смена: {{getDayWorkShift(task.maintenanceDate)}}
                        //-   template(v-slot:activator="{on, attrs}")
                        .app-cursor--pointer.px-1(v-if="task.equipmentId == item.uuid" style="padding:0" :class="'task-status--' + task.status.name" @contextmenu="openMenu($event, task)" @click="openCheck(task)")
                          div(style="font-size:9px") {{ getDutyNum(task) }}
                          div(style="font-size:11px") {{task.title}}


    maintenance-check(v-model="dialog.saveCheck" :maintenanceTask="currentTask" @updateMDate="updateData")
    maintenance-settings(v-model="dialog.settings" v-if="dialog.settings" @close="closeSettings()" @confirm="closeSettings()")
    maintenance-date-dialog(v-model="dialog.dateEdit" :maintenanceTask="currentTask" @updateMDate="updateData")
    maintenance-duty-dialog(ref="dutyDialog" @updateDuty="updateData")
    
    app-menu( ref="menu" :menu="taskMenu" )

</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import { api } from '@/api'
import { AlertService } from '@app/AlertService'
import date_utils from '@/plugins/gantt/date_utils'

import MaintenanceCheck from './components/MaintenanceCheck.vue'
import MaintenanceSettings from './components/MaintenanceSettings.vue'
import MaintenanceDateDialog from './components/MaintenanceDateDialog.vue'
import MaintenanceDutyDialog from './components/MaintenanceDutyDialog.vue'
import FixPanel from '@/components/project/panel/left/maintenance/components/FixPanel.vue'
import FixPanelsSection from '@/components/project/panel/left/maintenance/components/FixPanelsSection.vue'

export default {
  components: {
    MaintenanceCheck,
    MaintenanceSettings,
    MaintenanceDateDialog,
    MaintenanceDutyDialog, 
    FixPanel,
    FixPanelsSection
  },

  data() {
    return {
      currentTask: null,
      months: [],
      mTasks: [],
      dialog: {
        saveCheck: false,
        settings: false,
        dateEdit: false,
      },
      monthTask: [],
      daysInMonth: 0,
      prettyDate: null,

      viewSettings: {
        month: null,
        index: null,
      },
    }
  },

  watch: {
    months() {
      this.goToMonthView(this.viewSettings.month, this.viewSettings.index)
    }
  },

  computed: {
    ...mapGetters('maintenance', ['treeFlatList']),
    ...mapState('maintenance', ['selectedEquipment']),

    ...mapGetters('project', ['projectUuid']),
    ...mapState('project', ['projectSettings', 'project', 'maintenanceTaskSettings']),

    ...mapState('task', ['tasks', 'taskDisplay']),
    ...mapGetters('layoutControler', ['panelWidth']),

    ...mapGetters('projectPermissions', ['hasMaintainceDateTO', 'hasMaintainceSettingChartTO']),

    taskMenu() {
      let menu = [
        { title: this.$t('module.task.openTask'), action: this.goToTask }
      ]

      if (this.hasMaintainceDateTO) {
        menu.push({ title: this.$t('section.equipment.maintenance.maintenanceDateChange'), action: () => this.dialog.dateEdit = true })
      }

      if (this.daysInMonth)
        menu.push({ title: this.$t('section.equipment.maintenance.changeDutyNum'), action: () => this.changeDutyDialog() })
        
      return menu
    },
  },

  methods: {
    ...mapActions('task', ['loadTasks']),
    ...mapMutations('task', ['setTaskDisplay', 'setSelectedTask', 'setPrevTaskPanel']),
    ...mapMutations('project', ['setTopUnit']),
    ...mapMutations('maintenance', ['setEquipmentTasks']),

    ...mapMutations('layoutControler', ['setFromCollision', 'setWidthData']),

    loadData() {
      return api.maintenance.getSchedule(this.projectUuid).then((data) => {
        this.months = Object.keys(data)
        this.mTasks = Object.values(data)
      })
    },

    openEditDateDialog(task) {
      this.currentTask = task
      this.dialog.dateEdit = true
    },

    monthTitle(m) {
      const months = [
        this.$t('section.schedule.month.jan'),
        this.$t('section.schedule.month.feb'),
        this.$t('section.schedule.month.mar'),
        this.$t('section.schedule.month.apr'),
        this.$t('section.schedule.month.may'),
        this.$t('section.schedule.month.jun'),
        this.$t('section.schedule.month.jul'),
        this.$t('section.schedule.month.aug'),
        this.$t('section.schedule.month.sep'),
        this.$t('section.schedule.month.oct'),
        this.$t('section.schedule.month.nov'),
        this.$t('section.schedule.month.dec'),
      ]
      let num = m.split(' ')
      return months[num[0]] + ' ' + num[1].substr(2)
    },

    sourceTitle(source) {
      return source.isGroup ? source.title : source.name
    },

    openCheck(task) {
      this.currentTask = task
      this.dialog.saveCheck = true
    },

    goToEquipment(item) {
      this.$emit('goToEquipment', item)
    },

    openSettings() {
      this.dialog.settings = true
    },

    closeSettings() {
      this.dialog.settings = false
    },

    goToMonthView(month, index) {
      if (month) {
        this.viewSettings = { month, index }
        let [m, y] = month.split(' ')
        const date = new Date(y, Number(m) + 1, 0)
        this.daysInMonth = date.getDate()
        this.prettyDate = date_utils.format(date, 'MMMM YYYY', localStorage.getItem('locale'))
        let monthTask = []
        for (let i = 0; i < this.daysInMonth; i++) monthTask.push([])

        for (let i in this.mTasks[index]) {
          let date = this.mTasks[index][i]?.maintenanceDate
          if (date) {
            let day = new Date(date).getDate() - 1
            monthTask[day].push(this.mTasks[index][i])
          }
        }

        this.monthTask = monthTask
      }
    },

    changeDutyDialog() {
      this.$refs.dutyDialog.showDialog(this.currentTask, this.getDayWorkShift(this.currentTask.maintenanceDate))
    },

    getCurrentDuty(task) {
      if (task && !task.dutyNum && task.dutyNum <= 0) return this.getDayWorkShift(task.maintenanceDate)
      return null
    },

    getDutyNum(task) {
      if (task.dutyNum === -1) return "error"
      else if (task.dutyNum) return "c." + task.dutyNum
      else return "c." + this.getDayWorkShift(task.maintenanceDate)
    },

    getDayWorkShift(date) {
      const ms = this.maintenanceTaskSettings
      const oneDay = 1000 * 60 * 60 * 24

      const start = new Date(ms.schedulerStartDate || this.project.createDate)
      start.setHours(0, 0, 0, 0)
      const now = new Date(date)
      now.setHours(0, 0, 0, 0)

      if (now.getTime() < start.getTime()) return 'Error'
      const totalDays = Math.round((now.getTime() - start.getTime()) / oneDay)
      const currentDutu = (totalDays % ms.numDuty) + 1

      return currentDutu
    },

    returnFromMonthView() {
      this.daysInMonth = 0
      this.prettyDate = null
      this.monthTask = []
    },

    updateData() {
      this.loadData().then(() => {
        if (this.daysInMonth) {
          this.goToMonthView(this.viewSettings.month, this.viewSettings.index)
        }

        if(this.currentTask && this.currentTask.equipmentId && (this.selectedEquipment?.uuid === this.currentTask?.equipmentId)) {
          api.maintenance.loadTasks(this.currentTask.equipmentId).then(data => {
            this.setEquipmentTasks(data)
          })
        }
      })
    },

    openMenu(event, item) {
      this.currentTask = item
      this.$refs.menu.show(event)
    },

    goToTask() {
      if (this.tasks && this.tasks.length > 0) {
        this.openTask()
      } 
      else {
        this.loadTasks().then(() => { 
          this.openTask()
        })
      }
    },

    openTask() {
      let task
      if (this.currentTask?.taskUuid)
        task = this.tasks.find((t) => t.uuid === this.currentTask?.taskUuid)

      if (!task)
        task = this.tasks.find((t) => t.maintenanceTaskUuid === this.currentTask?.uuid)

      if (task) {
        this.setTopUnit('task')
        this.setTaskDisplay('dashboard')
        this.setSelectedTask(task)
      }
      else {
        AlertService.info({ info: 'На данное ТО нет задачи' })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  overflow: auto;
  white-space: nowrap;
}

th.app-cursor--pointer:hover {
  background-color: #eee !important;
}

th.app-cursor--pointer,
td.td-item {
  z-index: 0 !important;
  text-align: center !important;
}

::v-deep .v-data-table__wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--v-scroll-base);
}

::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: var(--v-accent-base);
}
/* mozilla firefox */
.scroll-changer .v-data-table__wrapper {
  scrollbar-width: thin;
  scrollbar-color: var(--v-scroll-darken1) var(--v-scroll-base);
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  left: 0px;
  padding-left: 5px !important;
  scrollbar-color: var(--v-primary-lighten4) var(--v-surface-lighten5);
}

.sticky-col span {
  font-size: 12px;
}

.t-scroll-body {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  height: 100%;
  scrollbar-color: var(--v-primary-lighten4) var(--v-surface-lighten5);
}

.sticky-col::-webkit-scrollbar {
  height: 5px;
  background-color: var(--v-surface-lighten5);
}

.t-scroll-body::-webkit-scrollbar {
  width: 5px;
  background-color: var(--v-surface-lighten5);
}

.t-scroll-body::-webkit-scrollbar-thumb,
.sticky-col::-webkit-scrollbar-thumb {
  background-color: var(--v-primary-lighten4);
}

.node__level {
  display: inline-block;
  width: 8px;
}

.task-status--DONE {
  padding: 5px;
  background-color: green;
}
.task-status--SKIP {
  padding: 5px;
  background-color: red;
}
.task-status--PROGRESS {
  padding: 5px;
  background-color: #cddd16;
}
</style>
