<template lang="pug">
  div
    app-dialog(v-model="$_show" :header="$t('registration.title')" :width="noDialog?400:270" @close="handleClose" :closeButtonHidden="noDialog?true:false" :hideOverlay="noDialog?true:false" complex)
        social-button(icon-name="add" @click.native="register" ) {{ $t('registration.button') }}

        div
          .text-center.body-2.my-4 {{ $t('registration.titleSocial') }}
          //-Facebook.mt-2(@login="socialLogin")
          Vk.mt-2(@login="socialLogin")
          Google.mt-2(@login="socialLogin")
          Yandex.mt-2(@login="socialLogin")
          MailRu.mt-2(@login="socialLogin")
          //-Microsoft.mt-2(@login="socialLogin")

    AccessRegistation(v-model="dialog.register" @close="handleClose" :noDialog="noDialog")
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable.vue'
import social from '@/components/social'
import { authUserService } from '@/_services'
import { App } from '@app/App'
import Registation from '@/components/access/Registation.vue'
import SocialButton from '@/components/social/SocialButton.vue'


export default {
  name: 'BeforeRegistration',
  components: {
    Facebook: social.Facebook,
    Vk: social.VK,
    Google: social.Google,
    Yandex: social.Yandex,
    MailRu: social.MailRu,
    Microsoft: social.Microsoft,
    [Registation.name]: Registation,
    SocialButton
  },
  props: {
    noDialog: {
      default: false,
      type: Boolean
    },
  },
  mixins: [DialogToggable],
  data () {
    return {
      dialog: {
        register: false
      }
    }
  },
  methods: {
    register () {
      this.dialog.register = true
      this.$_show = false
    },

    socialLogin(req, social_type) {
      console.log('social', req, social_type)
      authUserService.social(req, social_type).then(status => {
        if (status) {
          if (App.version.isCloud || location.href.indexOf('/cloud/') != -1) {
            this.$_show = false
            return true
          }
          this.$store.dispatch('authUser/LOAD_CURRENT_USER').then(data => {
            this._setTheme(data?.name || 'dark')
          })
          
          localStorage.setItem("loginName", 'social ' + social_type)
          this.$metrika.reachGoal('REGISTRATION_SOCIAL', {type: social_type})

          if (App.auth.isAdmin) 
            return this.$router.push('/admin/users')
          return this.$router.push('/dashboard')
        } else {
          this.error = this.$t('error.loginPassword')
        }
      })
    },

    handleClose() {
      this.$emit('close')
    }
  },
  
}
</script>