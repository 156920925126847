<template lang="pug">
v-list
  v-list-item( v-for="key in filterKeys" )
    v-switch.pa-0.ma-0( v-model="filters[key]" dense color="#3b93af" hide-details @change="$emit('updateFilter', { key, value: $event })")
      template( v-slot:label ) 
        .smeta-tree-filter-list__text {{ keyName(key) }}
</template>

<script>
export default {
  props: {
    filters: null
  },

  data() {
    return {
    }
  },

  computed: {
    filterKeys() {
      if (this.filters) return Object.keys(this.filters)
      return []
    }
  },

  methods: {
    keyName(key) { 
      if (key == "onlyLinks") return "Только связи"
      if (key == "withClassificator") return "Со сторонним классификатором"
      if (key == "withWorm") return "С ВОРМ"
      if (key == "withTask") return "С задачей"
      return key
    }
  }
}
</script>

<style scoped>
.smeta-tree-filter-list__text {
  color:#3b93af;
  font-size: 10px;
  text-transform: uppercase;
}
</style>