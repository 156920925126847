<template lang="pug">
  div
    .d-flex.justify-space-between 
      v-btn.dict-button.px-1( color="accent" dense text x-small test-id="rule-founder-collapse-expand" @click="toggleMassFolding") 
        v-icon.mr-1( color="accent" size="16" left ) unfold_more
        | {{ areAllTreeFolderOpens ? $t('section.elementTree.axis.collapseTree') : $t('section.elementTree.axis.expandTree') }}

      v-btn.dict-button.px-1( color="accent" dense text x-small test-id="rule-founder-select-all" @click="toggleMassSelect") 
        | {{ areAllTreeRulesSelected ? $t('section.elementTree.axis.clearAll') : $t('section.elementTree.axis.selectedAll') }}
        v-icon.ml-1( v-if="areAllTreeRulesSelected" color="accent" size="16" ) remove_done
        v-icon.ml-1( v-else color="accent" size="16" ) done_all

    v-treeview.__project_view_tree(
      v-if="renderComponent"
      v-model="selected"
      :items="preparedRuleTree"
      :active="active"
      item-key="uuid" item-text="title" item-children="nodes" color="accent" 
      disable-per-node
      light dense activatable selectable open-on-click return-object
      v-bind="$attrs" v-on="$listeners"
      test-id="rules-founder-treeview"
      :open.sync="rulesOpen"
      )
      //- template( #label="{ item }" )
      //-   draggable( :list="preparedRuleTree" group="node" :id="item.uuid" :data-parent="item.parentUuid" @start="checkStart" @end="checkEnd" )
      //-     v-hover( v-slot:default="{ hover }" close-delay="150" v-if="item.isFolder")
      //-       .node-folder.d-flex.align-center( v-line-clamp="1" test-id="collision-rule-folder" @contextmenu.prevent="onFolderMenu(item, $event)" )
      //-         span.text-truncate {{ item.title }}
      //-         v-icon.ml-auto( v-if='hover' color="#000" @click.stop="onFolderMenu(item, $event)" ) more_vert
      //-         div.mr-4( v-else )
      //-   draggable( :list="preparedRuleTree" group="node" :id="item.uuid" :data-parent="item.parentUuid" @end="checkEnd" )
      //-     v-hover( v-slot:default="{ hover }" close-delay="150" v-if="item.isRule")
      //-       .node-rule.d-flex.align-center( v-line-clamp="1" @contextmenu.prevent="onRuleMenu(item, $event)" @click="onRuleClick(item)" test-id="collision-rule" )
      //-         span.text-truncate {{ item.title }}
      //-         v-icon.ml-auto( v-if="hover" color="#000" @click.stop="onRuleMenu(item, $event)" ) more_vert
      //-         div.mr-4( v-else )

      //- template( #append="{ item }" ) 
      //-   .node-visibility.px-1 {{ item.visibility }}

      template(v-slot:label='{ item }')
        v-hover(v-slot:default='{ hover }' open-delay='100' close-delay='100')
          div.d-flex.align-center( style='height: 24px; position: relative;' draggable @dragstart='() => (checkStart({ from: { id: item.uuid }}), moving = item)' @dragend='moveto && dragend(item)' @dragenter='moveto = item' @dragover.prevent @contextmenu.prevent='item.isFolder ? onFolderMenu(item, $event) : onRuleMenu(item, $event)' @click='!item.isFolder ? onRuleClick(item) : null')
            div.accent(v-if='(moveto && moveto.uuid === item.uuid) && (moving.isFolder ? true : item.parentUuid)' style='height: 2px; position: absolute; top: 0px; left: 0px; right: 0px;')
            
            div.text-truncate(:class='{ "font-weight-bold": item.isFolder }') {{ item.title }}
            v-fade-transition
              v-icon.ml-auto(v-if='hover' color='surface' @click.stop='() => item.isFolder ? onFolderMenu(item, $event) : onRuleMenu(item, $event)') more_vert
              
      template(v-slot:append='{ item }')
        .node-visibility.px-1 {{ item.visibility }}
    
    app-menu( ref="treeMenu" :menu="treeMenu" )

    rules-founder-folder-dialog( v-if="dialog.folder.edit" v-model="dialog.folder.edit" :folder="wip.folder" :parent="wip.parent" :header="$t('section.collision.rulesFounder.editingGroup')" )
    app-dialog-confirm( v-if="wip.folder" v-model="dialog.folder.delete" delete @confirm="onDeletingFolder") 
      app-text {{ $t('section.collision.rulesFounder.deleteFolder') }} "{{ wip.folder.title }}"?

    rules-founder-rule-dialog( v-if="dialog.rule.edit" v-model="dialog.rule.edit" :rule="wip.rule" :folder="wip.folder" :header="$t('section.collision.rulesFounder.editingRule')" )
    app-dialog-confirm( v-if="wip.rule" v-model="dialog.rule.delete" delete @confirm="onDeletingRule") 
      app-text {{ $t('section.collision.rulesFounder.deleteRule') }} "{{ wip.rule.title }}"?
</template>

<script>
import RulesFounderFolderDialog from "./RulesFounderFolderDialog.vue"
import RulesFounderRuleDialog from "./RulesFounderRuleDialog.vue"

import draggable from 'vuedraggable'

import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'

export default {
  components: {
    RulesFounderFolderDialog, 
    RulesFounderRuleDialog,
    draggable
  },

  data: () => ({
    moving: null,
    moveto: null,

    renderComponent: true,
    dialog: {
      folder: {
        edit: false,
        delete: false,
      },
      rule: {
        edit: false,
        delete: false,
      }
    },
    wip: {
      folder: null,
      parent: null,
      rule: null
    },
    selectionControl: [],    
  }),

  computed: {
    ...mapGetters('rule', ['preparedRuleTree', 'folderFlatList', 'markedRules', 'rulesFlatList', 'countFolder']),
    ...mapState('rule', ['openedFolder', 'ruleTree']),
    ...mapGetters('rule/details', ['rule']),
    ...mapGetters('projectPermissions', ['hasCollisionCreateRule', 'hasCollisionView']),

    selected: {
      get () { return this.selectionControl },
      set (rules) { this.markRules(rules) }
    },

    active: {
      get () { return this.rule ? [this.rule] : [] },
    },

    rulesOpen:{
      get() { return this.openedFolder.founder },
      set(rules) {this.SET_OPEN_FOLDER_FOUNDER(rules)}
    },

    areAllTreeFolderOpens() {
      return this.rulesOpen?.length === this.countFolder
    },

    areAllTreeRulesSelected() {
      return this.selected?.length === this.checkAllSelected
    },

    checkAllSelected() {
      let count = 0
      this.preparedRuleTree.forEach(node => {
        if (!node.disabled) {
          count++
        } 
      })

      return count
    },

    folderMenu () {
      let list = []

      if(this.hasCollisionCreateRule) list.push(
          { title: this.$t("section.collision.rulesFounder.newRule"), action: this.showRuleAddDialog },
          { title: this.$t("section.collision.rulesFounder.createSubgroup"), action: this.showSubfolderCreationDialog },
          { title: this.$t("section.collision.rulesFounder.editGroup"), action: this.showFolderEditDialog },
          { title: this.$t("section.collision.rulesFounder.deleteGroup"), action: this.showFolderDeleteDialog },
        )

      return list
    },

    ruleMenu () {
      let list = []
      if(this.hasCollisionCreateRule) list.push(
          { title: this.$t("section.collision.rulesFounder.copyRule"), action: this.startCopyingRule },
          { title: this.$t("section.collision.rulesFounder.editRule"), action: this.showRuleEditDialog },
          { title: this.$t("section.collision.rulesFounder.deleteRule"), action: this.showRuleDeleteDialog },
        )
      return list
    },

    treeMenu () {
      return this.wip.folder ? this.folderMenu : this.ruleMenu
    },
  },

  watch: {
    preparedRuleTree (val) {
      this.selectionControl = this.сheckDisabled(val, []).selectedItems
    }
  },

  mounted () {
    this.selectionControl = this.сheckDisabled(this.preparedRuleTree, []).selectedItems
  },

  methods: {
    ...mapActions('rule', ['saveFolder', 'deleteFolder', 'deleteRule', 'markRules', 'copyRule', 'selectRules', 'moveFolder', 'moveRule', 'reloadTree']),
    ...mapMutations('rule',['SET_OPEN_FOLDER_FOUNDER', 'SET_RULE_TREE']),
    ...mapActions('rule/details', ['selectRule', 'getRule']),

// Menu
    сheckDisabled(nodes, selectedItems) { 
      let selectedNode = 0
      nodes.forEach(node => {
        let lenBefore = selectedItems.length 
        if (node.selectedToWork) {
          selectedItems.push(node)
          selectedNode++
          return
        } 

        if (node.disabled) {
          selectedNode++
          return
        } 

        if (node.nodes) {
          let result = this.сheckDisabled(node.nodes, selectedItems)
          selectedItems = result.selectedItems
          if (result.selectedNode) {
            let lenAfter = selectedItems.length
            selectedItems.splice(lenBefore-lenAfter)
            selectedItems.push(node)
            selectedNode++
          }      
        }
      })
      
      return {selectedNode: nodes.length == selectedNode? 1 : 0, selectedItems: selectedItems}
    },

    onFolderMenu (folder, event) {
      this.wip.folder = folder
      this.wip.rule = null
      // this.$refs.folderMenu.show(event)
      if(this.hasCollisionCreateRule) this.$refs.treeMenu.show(event)
    },

    onRuleMenu (rule, event) {
      this.wip.rule = rule
      this.wip.folder = null
      // this.$refs.ruleMenu.show(event)
      if(this.hasCollisionCreateRule) this.$refs.treeMenu.show(event)
    },

// Folder
    flatlistFoldersAndRules(tree) {
      let array = []
      if(tree) {
        if (tree && tree?.length > 0) {
          tree.forEach(element => {
            let newElement = { ...element }
            array.push(newElement, ...this.flatlistFoldersAndRules(element.nodes))
          })
        }
      }
      return array
    },

    showSubfolderCreationDialog () {
      this.wip.parent = this.wip.folder
      this.wip.folder = null
      this.dialog.folder.edit = true
    },

    showFolderEditDialog () {
      this.wip.parent = this.folderFlatList.find(one => one.children.map(i => i.uuid).includes(this.wip.folder.uuid))
      this.dialog.folder.edit = true
    },

    showFolderDeleteDialog () {
      this.dialog.folder.delete = true
    },

    onDeletingFolder () {
      if(this.rule && this.checkIncludeRuleInFolder(this.wip.folder)) {
        this.selectRule()
      }

      this.deleteFolder(this.wip.folder.uuid)
      this.$emit('clearElements')
    },

    checkIncludeRuleInFolder(folder) {
      let needClear = false
      if (folder.children.length > 0) {
        folder.children.forEach(folder => {
          needClear = this.checkIncludeRuleInFolder(folder)
        })
      }
      let rules = folder.rules.find(rule => rule.uuid === this.rule.uuid) || needClear
      return !!rules
    },

    movingFolders(source, target) {
      let data = { source: source.uuid, target: target.uuid, parent: target.parentUuid }
      this.moveFolder(data)

      let parent = this.flatlistFoldersAndRules(this.preparedRuleTree).find(el => el.uuid == target.parentUuid)
      let oldParent = this.flatlistFoldersAndRules(this.preparedRuleTree).find(el => el.uuid == source.parentUuid)
      let oldIndex = source.parentUuid ? oldParent.children.findIndex(el => el.uuid == source.uuid) : this.preparedRuleTree.findIndex(el => el.uuid == source.uuid)
      let newIndex = target.parentUuid ? parent.children.findIndex(el => el.uuid == target.uuid) : this.preparedRuleTree.findIndex(el => el.uuid == target.uuid)

      if(oldParent){
        if(oldIndex > -1){
          oldParent.children.splice(oldIndex,1)
        }
      } else {
        if(oldIndex > -1){
          this.preparedRuleTree.splice(oldIndex,1)
        }
      }
      this.SET_RULE_TREE(this.preparedRuleTree)


      source.parentUuid = parent ? parent.uuid : null
      if( !parent ) {
        this.preparedRuleTree.splice(newIndex,0,source)
        this.SET_RULE_TREE(this.preparedRuleTree)
      } else {
        let parentChildren = parent.children
        if( parentChildren.length > 0 ){
          if(target.isFolder){
            parentChildren.splice(newIndex,0,source)
          } else {
            parentChildren.unshift(source)
          }
        } else {
          parentChildren.push(source)
        }
        this.SET_RULE_TREE(this.preparedRuleTree)
      }
    },

// Rule

    showRuleAddDialog () {
      this.wip.rule = null
      this.dialog.rule.edit = true
    },

    startCopyingRule () {
      let { uuid } = this.wip.rule
      this.copyRule(uuid)
    },

    showRuleEditDialog () {
      this.getRule(this.wip.rule).then(data => {
        this.wip.rule = data
        this.dialog.rule.edit = true
        this.$emit('clearElements')
      })
    },

    showRuleDeleteDialog () {
      this.dialog.rule.delete = true
    },

    onDeletingRule () {
      this.deleteRule(this.wip.rule.uuid)
      this.$emit('clearElements')
    },

    toggleMassFolding() {
      if (!this.areAllTreeFolderOpens) {
        this.rulesOpen = []
        this.expandFolders(this.preparedRuleTree)
      } else this.rulesOpen = []
    },

    expandFolders(tree) {
      tree.forEach(folder => {
        this.rulesOpen.push(folder)
        if (folder.children && folder.children.length > 0)
          this.expandFolders(folder.children)
      })
    },

    toggleMassSelect() {
      if (this.areAllTreeRulesSelected) {
        this.selected = []
        this.selectRules([])
      } 
      else this.selected = this.rulesFlatList
    },
    
    movingRules(source, target){
      let parent = this.flatlistFoldersAndRules(this.preparedRuleTree).find(el => el.uuid == target.parentUuid)
      let oldParent = this.flatlistFoldersAndRules(this.preparedRuleTree).find(el => el.uuid == source.parentUuid)
      let oldIndex = oldParent.rules.findIndex(el => el.uuid == source.uuid)
      let newIndex =  parent ? parent.rules.findIndex(el => el.uuid == target.uuid) : null

      if(parent) {
        let data = { source: source.uuid, target: target.uuid, folder: target.parentUuid }
        this.moveRule(data)
      }
      
      if (oldParent && parent) {
        oldParent.rules.splice(oldIndex, 1)
        this.SET_RULE_TREE(this.preparedRuleTree)
      }

      if (parent) {
        source.parentUuid = target.parentUuid
        if(target.isRule){
          parent.rules.splice(newIndex,0,source)
        } else {
          parent.rules.push(source)
        }
        this.SET_RULE_TREE(this.preparedRuleTree)
      }
    },  

// Choose rule for work with details

    onRuleClick (rule) {
      this.selectRule(rule).then(() => {
        this.$emit('selectrule', rule)
        this.$emit('clearElements')
      })
    },

//draggable 

    checkEnd(event) {
      let source = this.flatlistFoldersAndRules(this.preparedRuleTree).find(el => el.uuid == event.from.id)
      let target = this.flatlistFoldersAndRules(this.preparedRuleTree).find(el => el.uuid == event.to.id)
      if(source.isFolder){
        this.movingFolders(source, target)
        this.renderComponent = false
        this.$nextTick(() => {
          this.renderComponent = true
        })
      } else {
        this.movingRules(source, target)
      }
    },

    checkStart(event) {
      this.rulesOpen = this.rulesOpen.filter(rule => rule.uuid != event.from.id)
    },

    async dragend(item) {
      const data = { source: item.uuid, target: this.moveto.uuid, parent: this.moveto.parentUuid, folder: this.moveto.parentUuid }
      
      this.moveto = null
      this.moving = null

      await (item.isFolder ? this.moveFolder(data) : data.folder ? this.moveRule(data) : null)
      this.reloadTree()
    }
  }
}
</script>

<style lang="scss" scoped>
.node-folder {
  color: var(--v-primary-base);
  font: bold 12px/16px $roboto;
  cursor: context-menu;
  position: relative;
  height: 25px;
}
.node-rule {
  color: var(--v-primary-base);
  font: normal 12px/16px $roboto;
  cursor: context-menu;
  position: relative;
  height: 25px;
}
.node-visibility {
  background-color: var(--v-primary-lighten5);
  color: var(--v-primary-base);
  font: normal 10px/14px $roboto;
  border-radius: 4px;
}
.dict-button {
  display: block;
  font: normal 10px/16px $roboto;
  text-transform: none;
}
</style>

<style lang="sass">
.__project_view_tree.v-treeview
  .v-treeview-node__level
    width: 8px
  .v-icon
    font-size: 16px
  .v-treeview-node__content
    padding-right: 20px
  .v-treeview-node__checkbox 
    position: absolute
    right: 2px
</style>