<template lang='pug'>
div.matching-attributes-form
  div.base-scroll-body(id="scrollTo" style="overflow-x: hidden")
    draggable( :value='matchingAttributes' handle='.drag-handler' @input='updateAttributesPriorities($event)')
      div.matching-attribute( v-for='(attribute, index) in matchingAttributes' )
        div(v-if="editingIndex !== index")
          v-tooltip(bottom)
            template(v-slot:activator="{ on, attrs}")
              div.d-flex
                v-icon.drag-handler.pointer( color='var(--v-primary-base)' :disabled='!hasClassificatorControlUniversalAttr' small ) mdi-drag-vertical
                div.attribute( v-bind="attrs" v-on="on" v-html="makeLogicGroupText(attribute.logicGroup, '', '', null)")
                div.d-flex.ml-auto
                  v-btn(x-small icon)
                    v-icon.pointer(color='var(--v-primary-base)' small @click="updateAttribute(index)") mdi-pencil
                  v-btn(x-small icon)
                    v-icon.pointer(color='var(--v-primary-base)' small @click="deleteAttribute(index)") mdi-delete
            div( v-html="makeLogicGroupText(attribute.logicGroup, '', '', null)")

        div.editAttributeForm(v-else)
          div
            div.base-scroll-body
              div.logic-group
                logic-group-calc(:logicGroup="attribute.logicGroup || {}" ref="logicGroups" :axisFilter="['ATTRIBUTE','USERPROP','CONST', 'MATERIALS', 'SYSTEMS', 'GEOMETRY']" calcType="TITLECALC" :default-class="false" @changed="changed")
          
          div.d-flex.px-2.justify-end.btn-container
            div.mt-auto
              v-btn.text-none.dialog-confirm-action.ml-auto.mr-2(fab @click="closeEditAttribute" small depressed light ) 
                v-icon mdi-close
              v-btn.text-none.dialog-confirm-action(fab color="accent" @click="saveUpdateAttribute(attribute, index)" :disabled="disabled" small depressed light )
                v-icon mdi-check
   
    template(v-if="editModelAttribute")
      div.editAttributeForm
        div
          div.base-scroll-body
            div.logic-group
              logic-group-calc(:logicGroup="attributeForm.logicGroup || {}" ref="logicGroup" :axisFilter="['ATTRIBUTE','USERPROP','CONST', 'MATERIALS', 'SYSTEMS', 'GEOMETRY']" calcType="TITLECALC" :default-class="false" @changed="changed")

        div.d-flex.px-2.justify-end.btn-container
          div.mt-auto
            v-btn.text-none.dialog-confirm-action.ml-auto.mr-2(fab  @click="closeEditAttribute" small depressed light ) 
              v-icon mdi-close
            v-btn.text-none.dialog-confirm-action(fab @click="createAttributeModel" :disabled="disabled" color="accent" small depressed light )
              v-icon mdi-check

</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import draggable from 'vuedraggable'
import { $_validate_rules } from '@/common/ValidateRules'
import { ConditionTreeCache } from '@/assets/model/smeta/ConditionTreeCache'
import { projectService } from '@/_services'
import LogicGroupCalc from '../../../components/logicGroup/LogicGroupCalc'

const defaultForm = Object.freeze({
  name: '',
  priority: 0,
  logicGroup: new Object()
})

export default {
  mixins: [$_validate_rules],

  components: {
    draggable,
    LogicGroupCalc
  },

  props: {
    value: Array,
  },

  data() {
    return {
      attributeForm: Object.assign({}, defaultForm),
      shownMenuAttributeIdx: null,
      conditionTreeCache: new ConditionTreeCache(),
      editingIndex: -1,
      attributeFormValid: false,
      attributeModelName: '',
      valid: true,
      openTree: false,
      isLoading: true,
      disabled: true
    }
  },

  watch: {
    countCopiedElementAttribute() {
      if(!this.copiedElementAttribute) return

      if(!this.copiedElementAttribute.topLevel){
        if(!this.editModelAttribute){
          this.$refs.logicGroups[0]?.afterCreateCondition({
            "uuid": null,
            "operator": {
              "name": "EX",
              "value": 8,
              "title": "Имеет значение"
            },
            "operand": {
              "value": 5,
              "title": "Атрибут",
              "group": 1,
              "cond": 1,
              "name": "ATTRIBUTE"
            },
            "operandUuid": this.copiedElementAttribute.uuid,
            "value": null,
            "title": this.copiedElementAttribute.sourceType.title + '/' + this.copyManualOperand.split('/').slice(1).join('/'),
            "manualOperand": this.copiedElementAttribute.sourceType.title + '/' + this.copyManualOperand.split('/').slice(1).join('/'),
            "elements": [],
            "gridCondition": null
          })
        }
        else {
            this.$refs.logicGroup?.afterCreateCondition({
            "uuid": null,
            "operator": {
              "name": "EX",
              "value": 8,
              "title": "Имеет значение"
            },
            "operand": {
              "value": 5,
              "title": "Атрибут",
              "group": 1,
              "cond": 1,
              "name": "ATTRIBUTE"
            },
            "operandUuid": this.copiedElementAttribute.uuid,
            "value": null,
            "title": this.copiedElementAttribute.sourceType.title + '/' + this.copyManualOperand.split('/').slice(1).join('/'),
            "manualOperand": this.copiedElementAttribute.sourceType.title + '/' + this.copyManualOperand.split('/').slice(1).join('/'),
            "elements": [],
            "gridCondition": null
          })
        }
      }
    },

    editModelAttribute(){
      if(this.editModelAttribute){
        this.disabled = true
      }
    //   let elmnt = document.getElementById('scrollTo')
    //   this.$nextTick().then(() => {
    //     elmnt.scrollTo(0, elmnt.scrollHeight)
    //   })
    }
  },

  computed: {
    ...mapGetters("projectPermissions", ['hasClassificatorControlUniversalAttr']),
    ...mapState('projectDict', ['editModelAttribute', 'copyMatchingAttributes', 'count']),
    ...mapGetters('project', ['projectUuid']),
    ...mapState('copyutil', ['copiedElementAttribute', 'countCopiedElementAttribute']),
    ...mapState('condition', ['copyManualOperand']),

    matchingAttributes: {
      get() {
        return this.value.slice(0, this.value.length)
      },

      set(val) {
        this.$emit('input', val)
      }
    },
  },

  methods: {
    ...mapMutations('projectDict', ['setEditModelAttribute', 'setEditingMode']),
    ...mapActions('projectDict', ['editAttribute']),

    deleteAttribute(attributeIdx) {
      this.matchingAttributes.splice(attributeIdx, 1)
      this.fireInput(this.matchingAttributes)
    },

    makeLogicGroupText(logicGroup, start, end, prevOperatorType) {
      let text = ''
      let rows = []
      logicGroup.condition.forEach(cond => rows.push(cond))
      if (logicGroup.children) {
        logicGroup.children.forEach(lg => rows.push(lg))
      }
      rows.sort((a, b) => a.sortOrder - b.sortOrder)
      
      rows.forEach((row, index) => {
        if(row.calcOperatorType !== undefined) {
          if(rows.length==1) text += this.makeLogicGroupText(row, start+"(", end+")", prevOperatorType)
          else if(index==0) text += this.makeLogicGroupText(row, start+"(", ")", logicGroup.calcOperatorType)
          else if(index<rows.length-1) text += this.makeLogicGroupText(row, "(", ")", logicGroup.calcOperatorType)
          else text += this.makeLogicGroupText(row, "(", end+")", prevOperatorType)
        } else {
          
          if(index==0){
            text += ' '+start
            start=''
          }

          let cClass = ''

          let validy = null
          if (this.checkLogicMode == 0) {
            validy = this.ruleConditionValid?.conditionsValid.find(e => e.conditionUuid == row.uuid);
          } else if (this.checkLogicMode == 1 && this.pickedElement) {
            validy = this.elementRuleConditionValid?.conditionsValid.find(e => e.conditionUuid == row.uuid);
          }

          if (validy && validy.valid) cClass = 'condition-validy-valid'
          else if (validy && !validy.valid) cClass = 'condition-validy-invalid'

          text += '<span class="'+cClass+'">'

          text += row.title

          text += '</span>'

          if(index==rows.length-1) {
              text += ' '+end
              end=''
          }

          if((index==0 || index<rows.length-1) && logicGroup.calcOperatorType!=null) text += ' '+logicGroup.calcOperatorType.title + ' '
          else if(prevOperatorType!=null && prevOperatorType.title) text += ' ' + prevOperatorType.title + ' '
        }
      })

      return text
    },

    createAttributeModel(){
      this.attributeForm.logicGroup = this.$refs.logicGroup.makeLogicGroup()
      if(this.attributeForm.logicGroup?.condition?.length || this.attributeForm.logicGroup?.children?.length){
        this.$emit('createAttributeModel', this.attributeForm.logicGroup)
        this.attributeForm.logicGroup = {}
      }
    },

    changed(logicGroup, conditionRows){
      if(conditionRows.length){
        this.disabled = false
      } else {
        this.disabled = true
      }
    },

    updateAttribute(attributeIdx) {
      this.setEditModelAttribute(false)
      this.setEditingMode(true)
      this.disabled = true
      this.editingIndex = attributeIdx
    },

    saveUpdateAttribute(attribute, attributeIdx){
      const logicGroup = this.$refs.logicGroups[0].makeLogicGroup()
      if(!logicGroup) return
      attribute.logicGroup = logicGroup
      this.matchingAttributes.splice(attributeIdx, 1, attribute)
      this.fireInput(this.matchingAttributes)
      this.editingIndex = -1
      this.setEditingMode(false)
    },

    updateAttributesPriorities(attributes) {
      this.matchingAttributes = attributes.map((attribute, idx) => ({
        ...attribute,
        priority: idx
      }))
    },

    showAttributeTree(){
      this.openTree = !this.openTree
      if(this.openTree){
        this.isLoading = true
        projectService.loadParamForCondition(this.projectUuid, 'ATTRIBUTE', '').then((data)=> {
          this.conditionTreeCache.setItems(data)
        }).finally(()=>{
          this.isLoading = false
        })
      }
    },

    selectedAttribute(attribute){
      if(attribute.children.length == 0){
        this.attributeModelName = attribute.parent.name + '/' + attribute.name
        this.openTree = false
      }
    },

    fireInput() {
      this.$emit('input', this.matchingAttributes)
    },

    closeEditAttribute() {
      this.editingIndex = -1
      this.attributeModelName = ''
      this.setEditModelAttribute(false)
      this.setEditingMode(false)
      this.openTree = false
    },

  },
}
</script>

<style lang="scss" scoped>
.matching-attributes-form {
  height: calc(26px * 8);
  border: 1px solid #9e9e9e;
  border-radius: 3px;
  position: relative;
  flex-wrap: nowrap;
}

.matching-attributes-form:hover {
   border: 1px solid var(--v-primary-base);
}

.attribute {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.matching-attribute {
  color: var(--v-primary-base);
  font-size: 12px;
  line-height: 26px;
  align-items: center;
  transition: 0.1s;
}

.matching-attribute:hover {
  background: var(--v-primary-lighten5);
}

.editAttributeForm{
  position: absolute;
  width: 100%;
  background: white;
  top: 0;
  max-height: calc(26px * 8);
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 11;
}

.indent{
  padding: 0px 8px 0px 0px;
}

.note {
  font: normal 11px/12px $roboto;
  color: grey;
}

.edge{
  border: solid 1px #9e9e9e;
  border-radius: 2px;
  margin: 4px 8px 4px 4px;
}

.logic-group {
  height: calc(26px * 5);
}

.btn-container {
  height: calc(34px * 2);
}
</style>