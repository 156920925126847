<template lang='pug'>
v-row.h100p(no-gutters style='flex-wrap: nowrap')
  v-col(v-if='!isMnemoschemaListHidden' style='max-width: 300px; min-width: 300px;')
    app-panel(padding='0px')
      app-floating-panels-section.floating-panels-section
        app-floating-panel(:menu="mnemoschemaListMenu" ref='mnemoschemaListPanel' :title="$t('module.mnemoschemas.listOfSchemes')" bodyMinHeight="150" overflowBody="auto" :settings="floatingPanels('MNEMOSCHEMAS_LIST')")
          //- template(v-slot:headerButton)
            v-btn.mr-n1(small icon @click.stop='openMnemoschemaListMenu($event)')
              v-icon mdi-playlist-play

          div.overflow-auto.base-scroll-body
            div.mnemoschema-list-item(v-for='(schema, index) in list' :class='mnemoschemaListItemClassList(schema, index)' @click='selectMnemoschema(schema)' @contextmenu='openMnemoschemaListItemMenu($event, schema)')
              span.flex-1 {{ schema.title }}
              v-btn.ml-auto(v-if="mnemoschemaListItemMenu" small icon @click.stop='openMnemoschemaListItemMenu($event, schema)')
                v-icon mdi-dots-vertical

        app-floating-panel( v-if="element" :title="$t('section.element', {name: element.name})" bodyMinHeight="150" overflowBody="hidden" 
          :settings="floatingPanels('MAINTENANCE_ELEMENT_DETAILS')")
          element-details.py-0( :element="element")

  v-col(style='width: calc(100% - 300px)')
    app-panel(padding='0px')
      app-floating-panels-section.floating-panels-section(:class='isMnemoschemaListHidden ? "" : "pl-2"')
        app-floating-panel(ref='mnemoschemaViewerPanel' :closeable="false" :title="selectedMnemoschema ? selectedMnemoschema.title : $t('module.mnemoschemas.schemes')" bodyMinHeight="150" overflowBody="hidden" :settings="floatingPanels('MNEMOSCHEMA_VIEWER')" @open='handleMnemoschemaViewerPanelOpen()')
          template(v-slot:headerButton)
            div.d-flex.mr-n1
              div(v-for='[title, icon, action] in mnemoschemaViewerHeaderActions')
                v-tooltip(top)
                  template(v-slot:activator='{ on, attrs }')
                    v-btn(small icon )
                      v-icon(v-bind='attrs' v-on='on' color='var(--v-primary-darken2)' :key='icon' rel='button' @click.stop='action') {{ icon }}
                  span {{ $t(title) }}
          
          div.h100p(style='position: relative')
            graph-viewer(v-show='selectedMnemoschema' ref='graph-viewer')
            v-progress-linear(v-if='mnemoschemaIsLoading' color='accent' indeterminate absolute top right)
            div.h100p.d-flex.black--text.text-center.pa-3(v-if='!selectedMnemoschema')
              span.ma-auto {{ $t('module.mnemoschemas.viewer.noMnemoschemaMessage') }}
  
  app-menu(ref="mnemoschemaListItemMenu" :menu='mnemoschemaListItemMenu')
  app-menu(ref="mnemoschemaListMenu" :menu='mnemoschemaListMenu')
  
  delete-schema-dialog(v-model='showDeleteMnemoschemaDialog' :schema='actionSchema' @confirm='deleteMnemo(actionSchema.uuid)')
  create-schema-dialog(v-model='showEditMnemoschemaDialog' :schema='actionSchema')

  app-dialog(v-model='showImportDialog' :header='$t("module.mnemoschemas.import")' :confirmText='$t("button.confirm")' :confirmDisabled='!importSchemaTitle' @confirm='importDialogConfirm(true)' @close='importDialogConfirm(false)')
    app-section
      app-text-field(v-model='importSchemaTitle' :placeholder='$t("module.mnemoschemas.editor.mnemoschemaTitle")')
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import CreateSchemaDialog from '../mnemoschemas/components/CreateSchemaDialog'
import DeleteSchemaDialog from '../mnemoschemas/components/DeleteSchemaDialog'
import GraphViewer from '../mnemoschemas/components/graphviewer/GraphViewer'
import TempMnemoschemasList from '../mnemoschemas/components/TempMnemoschemasList'
import ElementDetails from '../components/element/Details'
import { fileInput, readFileAsText } from '@/utils'
import { AlertService } from '@/assets/app/AlertService'

export default {
  name: "Mnemoschemas",

  components: {
    TempMnemoschemasList,
    GraphViewer,
    DeleteSchemaDialog,
    CreateSchemaDialog,
    ElementDetails,
  },

  data: () => ({
    isMnemoschemaListHidden: false,

    showDeleteMnemoschemaDialog: false,
    showEditMnemoschemaDialog: false,
    actionSchema: null,

    importDialogConfirm: null,
    importSchemaTitle: null,
    showImportDialog: false,

    mnemoschemaIsLoading: false,
  }),

  beforeMount() {
    this.updateList()
  },

  beforeDestroy() {
    this.editors?.forEach(editor => {
      editor?.close()
    })
  },

  async mounted() {
    // выбранная ранее схема убирается при входе в другой проект 
    this.setSelectedMnemoschema(null)  

    await this.openPanels()

    if (this.selectedMnemoschema) {
      this.$refs['graph-viewer'].setMnemoschema(this.selectedMnemoschema)
    }
  },

  computed: {
    ...mapGetters('floatingPanels', ['floatingPanels']),
    ...mapState('mnemoschema', ['list', 'selectedMnemoschema']),
    ...mapGetters('axis/tree', ['element']),
    ...mapGetters('project', ['projectUuid']),

    ...mapGetters('projectPermissions', ['hasMaintainceMnemoschemes', 'hasMaintainceImportExportMnemoschemes']),

    mnemoschemaListItemMenu() {
      let menu = []
      if (this.hasMaintainceImportExportMnemoschemes) {
        menu.push(
          { title: this.$t("button.export") + ' (JSON)', action: () => this.exportMnemoschemaJSON(this.actionSchema) },
          { title: this.$t("button.export") + ' (XML)', action: () => this.exportMnemoschema(this.actionSchema) }
        )
      }

      if (this.hasMaintainceMnemoschemes) {
        if (menu.length > 0) menu.push({ divider: true })

        menu.push(
          { title: this.$t("button.edit"), action: () => this.openEditMnemoschemaDialog() },
          { title: this.$t("module.mnemoschemas.openInEditor"), action: () => this.goToEditor(this.actionSchema) },
          { title: this.$t("module.mnemoschemas.copy"), action: () => this.copyMnemoschema(this.actionSchema) },
          { title: this.$t("button.delete"), action: () => this.openDeleteMnemoschemaDialog() }
        )
      }

      if (menu.length == 0) return null

      return menu
    },

    mnemoschemaListMenu() {
      let menu = []

      if (this.hasMaintainceMnemoschemes) {
        menu.push({ title: this.$t('module.mnemoschemas.create'), action: () => this.openCreateMnemoschemaDialog() })
      }

      if (this.hasMaintainceImportExportMnemoschemes) {
        menu.push(
          { title: this.$t('button.import') + ' (XML)', action: () => this.importMnemoschema() },
          { title: this.$t('button.import') + ' (JSON)', action: () => this.importMnemoschemaJSON() }
        )
      }

      if (menu.length == 0) return null

      return menu
    },

    mnemoschemaViewerHeaderActions() {
      let menu = []
      if (this.hasMaintainceMnemoschemes) {
        menu.push(
          ['module.mnemoschemas.openInEditor', 'mdi-view-dashboard', () => this.selectedMnemoschema && this.goToEditor(this.selectedMnemoschema)]
        )
      }
      return [
        ['module.mnemoschemas.viewerHeaderAction.refresh', 'mdi-refresh', () => this.refreshMnemoschema()],
        ...menu,
        ['module.mnemoschemas.viewerHeaderAction.fullscreen', 'mdi-fullscreen', () => this.setMnemoschemaListVisible(!this.isMnemoschemaListHidden)],
        ['module.mnemoschemas.viewerHeaderAction.clearSelect', 'mdi-close', () => this.selectMnemoschema(null)],
      ]
    }
  },

  methods: {
    ...mapActions('mnemoschema', ['updateList', 'deleteMnemoschema', 'saveMnemoschema', 'setSelectedMnemoschema']),

    openMnemoschemaListItemMenu(event, mnemoschema) {
      this.$refs['mnemoschemaListItemMenu'].show(event)
      this.actionSchema = mnemoschema
    },

    openMnemoschemaListMenu(event) {
      this.$refs['mnemoschemaListMenu'].show(event)
    },

    deleteMnemo(mnemo){
      this.deleteMnemoschema(mnemo)
      if(this.selectedMnemoschema?.uuid === mnemo){
        this.selectMnemoschema(null)
      }

    },

    mnemoschemaListItemClassList(mnemoschema, index) {
      let classList = (((!(index % 2) || '') && 'pressed') + ((mnemoschema.uuid === this.selectedMnemoschema?.uuid || '') && ' selected')).trim()
      return classList
    },

    async setMnemoschemaListVisible(visible) {
      this.isMnemoschemaListHidden = visible
      
      await this.$nextTick()
      this.openPanels()
    },
    
    openDeleteMnemoschemaDialog() {
      this.showDeleteMnemoschemaDialog = true
    },

    openEditMnemoschemaDialog() {
      this.showEditMnemoschemaDialog = true
    },

    openCreateMnemoschemaDialog() {
      this.actionSchema = null
      this.showEditMnemoschemaDialog = true
    },

    goToEditor(mnemoschema) {
      const editorWindow = window.open(`/mnemo/project/${mnemoschema.project}/schema/${mnemoschema.uuid}`)
      
      editorWindow.addEventListener('message', (event) => {
        if (event.data.type === 'saveMnemoschema') {
          this.updateList().then(() => {
            const selectedMnemoschema = this.list.find(mnemoschema => mnemoschema.uuid === this.selectedMnemoschema?.uuid)
            selectedMnemoschema.schema = event.data.txtSchema
            this.selectMnemoschema(selectedMnemoschema || null)
          }) 
        }
      })

      this.editors ||= []
      this.editors.push(editorWindow)
    },

    async selectMnemoschema(mnemoschema) {
      this.mnemoschemaIsLoading = true

      this.setSelectedMnemoschema(mnemoschema).then(async (freshMnemoschema) => {
        this.mnemoschemaIsLoading = false

        if (!freshMnemoschema) return
        
        await this.openPanels()
        this.$refs['graph-viewer'].setMnemoschema(freshMnemoschema)
      })
    },

    openPanels() {
      const mnemoschemaViewerPanel = this.$refs['mnemoschemaViewerPanel']
      const mnemoschemaListPanel = this.$refs['mnemoschemaListPanel']
      
      !mnemoschemaListPanel?.openedPanel && mnemoschemaListPanel?.openBody()
      !mnemoschemaViewerPanel?.openedPanel && mnemoschemaViewerPanel?.openBody()

      return this.$nextTick()
    },

    async handleMnemoschemaViewerPanelOpen() {
      await this.$nextTick()

      if (this.selectedMnemoschema) {
        this.$refs['graph-viewer'].setMnemoschema(this.selectedMnemoschema)
      }
    },

    exportMnemoschemaJSON(mnemoschema) {
      const jsonSchema = JSON.stringify(mnemoschema, (key, value) => {
        return key === 'binding' || key === 'uuid' || key === 'title' ? undefined : value
      })

      const mnemoschemaBlob = new Blob([jsonSchema], {type: 'application/json'})
      
      const link = document.createElement('a')
      link.download = mnemoschema.title + '.json'
      link.href = URL.createObjectURL(mnemoschemaBlob)

      link.click()
    },

    async importMnemoschemaJSON() {
      const JSONMimeType = 'application/json'

      const mnemoschemaJSONFile = await fileInput({accept: JSONMimeType})
      if (!mnemoschemaJSONFile || mnemoschemaJSONFile.type !== JSONMimeType) {
        return AlertService.error({
          data: {error_description: this.$t('module.mnemoschemas.invalidFormat')}
        }) 
      }

      const jsonText = await readFileAsText(mnemoschemaJSONFile)
      const importedMnemoschema = JSON.parse(jsonText)

      const domParser = new DOMParser()
      const importedMnemoschemaXmlDoc = domParser.parseFromString(importedMnemoschema.schema, 'text/xml')
      const mxGraphModel = importedMnemoschemaXmlDoc.querySelector('mxGraphModel')

      if (!mxGraphModel) {
        return AlertService.error({
          data: {error_description: this.$t('module.mnemoschemas.xml')}
        })
      }

      importedMnemoschema.schema = mxGraphModel.outerHTML

      const waitForExportDialog = new Promise((resolve) => this.importDialogConfirm = resolve)
      this.importSchemaTitle = importedMnemoschema.title || importedMnemoschema.name
      this.showImportDialog = true

      const exportConfirm = await waitForExportDialog
      if (!exportConfirm) return

      importedMnemoschema.title = this.importSchemaTitle
      importedMnemoschema.project = this.projectUuid
      delete importedMnemoschema.uuid
      delete importedMnemoschema.binding

      this.saveMnemoschema(importedMnemoschema)
    },

    exportMnemoschema(mnemoschema) {
      const schemaBlob = new Blob([mnemoschema.schema || ''], {type: 'text/xml'})

      const link = document.createElement('a')
      link.download = mnemoschema.title + '.xml'
      link.href = URL.createObjectURL(schemaBlob)
      
      link.click()
    },

    async importMnemoschema() {
      const xmlMimeType = 'text/xml'
      const schemaXmlFile = await fileInput({accept: xmlMimeType})
      if (!schemaXmlFile || schemaXmlFile.type !== xmlMimeType) {
        return AlertService.error({
          data: {error_description: this.$t('module.mnemoschemas.invalidFormat')}
        })
      }
      const xmlText = await readFileAsText(schemaXmlFile)

      const domParser = new DOMParser()
      const importedXMLDoc = domParser.parseFromString(xmlText, 'text/xml')
      const mxGraphModel = importedXMLDoc.querySelector('mxGraphModel')

      if (!mxGraphModel) {
        return AlertService.error({
          data: {error_description: this.$t('module.mnemoschemas.xml')}
        })
      }
      const waitForExportDialog = new Promise((resolve) => this.importDialogConfirm = resolve)
      this.importSchemaTitle = null
      this.showImportDialog = true
      const exportConfirm = await waitForExportDialog
      if (!exportConfirm) return
      const exportedSchema = {
        title: this.importSchemaTitle,
        schema: xmlText,
        project: this.projectUuid
      }
      this.saveMnemoschema(exportedSchema)
    },

    copyMnemoschema(mnemoschema) {
      const copiedMnemoschema = {...mnemoschema}
      delete copiedMnemoschema.uuid
      delete copiedMnemoschema.binding

      this.saveMnemoschema(copiedMnemoschema)
    },

    refreshMnemoschema() {
      if (!this.selectedMnemoschema) return
      this.selectMnemoschema(this.selectedMnemoschema)
    }
  }
}
</script>

<style lang='scss' scoped>
  .h100p {
    height: 100%;
  }

  .floating-panels-section {
    height: calc(100% - 26px) !important; 
    overflow: auto; 
    overflow-x: hidden
  }

  .mnemoschema-list-item {
    cursor: pointer;
    
    display: flex;
    align-items: center;
    padding: 4px 8px;

    color: black;
    font-weight: bold;
    
    background: white;

    transition: .1s;

    & span {
      flex: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &.pressed {
      background: #F8F8F8;
    }

    &:hover {
      background: #e5e5e5;
    }

    &.selected {
      background: #e5e5e5;
    }
  }
</style>