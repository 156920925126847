<template lang="pug">
app-panel.project-panel.px-1.py-2.wid( width="256" shadowPanel flex ident="process")
  app-panel-header( v-if="checkProcessProject" :title="$t('module.process.loadFiles')" hideClose)
  app-section.mb-2( v-if="checkProcessProject" )
    .p-text {{ $t('module.process.activeLoad') }}
    .mt-4( v-for="u in uploads" :key="u.idx" )
      .p-text {{ u.name }}
      v-progress-linear.mt-1.progress-unclick( v-model="u.progress" color="light-blue" height="10" value="10" striped )

  app-panel-header( :title="$t('module.process.title')" hideClose)
  app-section.t-scroll-body
    div(:class="{'block-processing' : (!processList.length && !onlyProjectProcessList.length)}")
      div( v-if="!processList.length && !onlyProjectProcessList.length" ) 
        .primary--text.text-center {{ $t('module.process.empty') }}
      
      div(v-else)
        
        .mb-4( style="width:100%" v-for="el in returnProcessList.withThisProject" :key="el.uuid" )
          .p-text(test-id="active-processes") {{ formatAlias(el) }}
          v-progress-linear.mt-1.progress-unclick( v-model="el.progress" color="light-blue" height="10" value="10" striped )
            span.caption(v-if="el.progress") {{ Math.trunc(el.progress) + '%' }}
          process-card(:currentProcess = "el" :projectName="false")
          .d-flex.justify-end(style="width:100%")
            v-btn(x-small  color="accent" test-id="process-cancel" @click.once="stopProcess(el)") {{ $t('module.process.cancel') }}
        
        .divider.mb-4(v-if="returnProcessList.withAllProject.length && returnProcessList.withThisProject.length")
        
        .mb-4(style="width:100%" v-for="el in returnProcessList.withAllProject" :key="el.uuid" )
          .p-text(test-id="active-processes") {{ formatAlias(el) }}
          v-progress-linear.mt-1.progress-unclick( v-model="el.progress" color="light-blue" height="10" value="10" striped )
            span.caption(v-if="el.progress") {{ Math.trunc(el.progress) + '%' }}
          process-card.nextLine(:currentProcess = "el" :projectName="true")
          .d-flex.justify-end(style="width:100%")
            v-btn(x-small  color="accent" test-id="process-cancel" @click.once="stopProcess(el)") {{ $t('module.process.cancel') }}

</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { NotificationService } from '../../../../assets/model/notifications/NotificationService'
import ProcessCard from "./ProcessCard.vue"

import { processSubscribeTopic, processUnsubscribeTopic, sendProcess } from "/src/api/socket/notificationSocket.js"


export default {
  name: 'ProjectProcessList',
  
  components: {
    ProcessCard
  },

  data () {
    return {
      forThisProject: false,
    }
  },

  async beforeMount() {
    await processSubscribeTopic()
    await sendProcess()
  },

  async beforeDestroy() {
    await processUnsubscribeTopic()
    this.resetProcessLists()
  },

  computed: {
    ...mapState('process', ['processes', 'uploads', 'processList', 'onlyProjectProcessList']),
    ...mapState('dashboard', ['projects']),
    ...mapGetters('project',['projectUuid']),

    checkProcessProject() {
      return !!this.uploads.filter(el => {
        return el.projectUuid == this.projectUuid
      }).length
    },

    returnProcessList() {
      
      let processArr = {
        'withThisProject': [],
        'withAllProject': []
      }

      let withThisProjectArrUuids = []
      this.processList.map(el => {
        if(el.projectUuid == this.projectUuid) {
          processArr.withThisProject.push(el)
          withThisProjectArrUuids.push(el.uuid)
        }
        else processArr.withAllProject.push(el)
      })

      this.onlyProjectProcessList.map(el => {
        if (!withThisProjectArrUuids.includes(el.uuid)) processArr.withThisProject.push(el)
      })
  
      return processArr
    }
  },


  methods: {
    ...mapActions('process', ['stopActiveProcess']),
    ...mapActions('patches', ['switchActivationPatch']),
    ...mapMutations('process', ['resetProcessLists']),

    isAlias(processName) {
      return processName.includes('{') || processName.includes('}')
    },

    formatAlias(process) {
      if(this.isAlias(process.name)) {
        return NotificationService.NotificationWithValue(process.name)
      }
      return process.name
    },

    stopProcess(process) {
      this.stopActiveProcess(process)
    },

    processForProject(p) {
      let project = null
      project = this.projects?.find(project => project.uuid == p.projectUuid)
      let forProject = this.$t('module.process.forProject') + " "
      return project ? forProject + project?.title : ""
    },
  }
}
</script>

<style lang="scss" scoped>
.p-text {
  font: normal 11px/16px $roboto;
  color: #505254;
}

.project-panel {
  height: calc(100%) !important;
}

.t-scroll-body {
  height: 100%;
}

.checkbox { 
  margin-top: 7px;
  display: flex;
  color: var(--v-accent-base) !important;
  font-size: 12px;
}

.block-processing {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap:nowrap;
  justify-content: center;
  align-items: center;
}
.process_loading {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}
.divider {
  width: 100%;
  height: 1px;
  background: #4d4d4d;
}
.wid {
  max-width: 256px;
}
.nextLine{
  word-break: break-all;
}

.progress-unclick {
  pointer-events: none
}
</style>