import { defineStore } from 'pinia'
import { IFCGridsService } from '@/plugins/IFCGrids/IFCGridsService'
import vuexStore from '@/store'

export const useGridStore = defineStore('viewer-grid', {
  getters: {
    isGridsAvailable: () => true, // TODO: Заменить на актуальный флаг доступности сеток. Булат
    isGridsEnabled: () => vuexStore.state.IFCGrids.gridsActivity,
  },

  actions: {
    // Контекст нужен, чтобы IFCGridsService мог показать сообщение об ошибке в случае необходимости (костыль)
    toggleDisplayOfGrids (context) {
      IFCGridsService.handleGridsDisplay(context)
    }
  }
})