<template lang="pug">
  div
    div.bar-rule-text(v-if="!editMode" @click="switchEditMode" :class="{'pointer': !disabled, 'gray-filter':disabled}")
      div(v-html="logicGroupTitle")
    v-card(v-show="editMode && !disabled" light)
      v-card-text
        logic-group-calc.mx-n2(:logicGroup="editLogicGroup" ref="logicGroup" :axisFilter="['ATTRIBUTE','USERPROP','CONST', 'MATERIALS', 'SYSTEMS', 'GEOMETRY']" :lockable="lockable" :default-class="false" @copy='copyExpression')
      //- v-card-actions
      v-spacer
      .d-flex.align-center.pa-2
        v-btn.text-none.dialog-confirm-action.ml-auto.mr-2(fab small depressed light @click="cancel()") 
          v-icon mdi-close
        v-btn.text-none.dialog-confirm-action(fab color="accent" small depressed light test-id="check-logic-group-btn" @click="checkLogicGroup()")
          v-icon mdi-check
</template>

<script>
import LogicGroupCalc from '../../../components/logicGroup/LogicGroupCalc'
import { mapState } from 'vuex'

import RuleConditionValid from '@/assets/model/condition/RuleConditionValid'
import ElementRuleConditionValid from '@/assets/model/condition/ElementRuleConditionValid'

import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

export default {
  components: {
    LogicGroupCalc
  },

  props: {
    value: Object,
    lockable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    ruleUuid: String
  },

  data() {
    return {
      editMode: false,
      editLogicGroup: this.value ? JSON.parse(JSON.stringify(this.value)) : {}
    }
  },

  mounted() {
    
  },

  computed: {
    ...mapState('smeta', ['checkLogicMode']),

    pickedElement() {
      return XeokitMediator.ElementsSelection.pickedElement
    },

    ruleConditionValid(){
      return RuleConditionValid.find(this.ruleUuid)
    },

    elementRuleConditionValid(){
      return ElementRuleConditionValid.find([this.ruleUuid, this.pickedElement])
    },

    newValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input',value)
        this.$emit('change',value)
      }
    },

    logicGroupTitle(){
      if (this.value && 
        (this.value && this.value.children && this.value.children.length > 0)
        || (this.value && this.value.condition && this.value.condition.length > 0)
      ) return this.makeLogicGroupText(this.value, "", "", null)

      return this.$t('section.classification.menuClassification.indicateMeasurement')
    }
  },

  watch: {
    value(v, ov){
      if ( ov == null || v == null || JSON.stringify(v) != JSON.stringify(ov)) this.editMode = false
    }
  },

  methods: {
    switchEditMode() {
      if (!this.disabled){
        this.editLogicGroup = this.value ? JSON.parse(JSON.stringify(this.value)) : {}
        this.editMode = true
      }
    },

    checkLogicGroup() {
      let lg = this.$refs.logicGroup.makeLogicGroup()
      if (lg) {
        this.newValue = lg
        this.editMode = false
      }
    },

    cancel() {
      this.editMode = !this.editMode
      this.$emit('cancel')
    },

    makeLogicGroupText(logicGroup, start, end, prevOperatorType) {
      let text = ''
      let rows = []
      logicGroup.condition.forEach(cond => rows.push(cond))
      if (logicGroup.children) {
        logicGroup.children.forEach(lg => rows.push(lg))
      }
      rows.sort((a, b) => a.sortOrder - b.sortOrder)

      
      rows.forEach((row, index) => {
        if(row.calcOperatorType !== undefined) {
          if(rows.length==1) text += this.makeLogicGroupText(row, start+"(", end+")", prevOperatorType)
          else if(index==0) text += this.makeLogicGroupText(row, start+"(", ")", logicGroup.calcOperatorType)
          else if(index<rows.length-1) text += this.makeLogicGroupText(row, "(", ")", logicGroup.calcOperatorType)
          else text += this.makeLogicGroupText(row, "(", end+")", prevOperatorType)
        } else {
          
          if(index==0){
            text += ' '+start
            start=''
          }

          let cClass = ''

          let validy = null
          if (this.checkLogicMode == 0) {
            validy = this.ruleConditionValid?.conditionsValid.find(e => e.conditionUuid == row.uuid);
          } else if (this.checkLogicMode == 1 && this.pickedElement) {
            validy = this.elementRuleConditionValid?.conditionsValid.find(e => e.conditionUuid == row.uuid);
          }

          if (validy && validy.valid) cClass = 'condition-validy-valid'
          else if (validy && !validy.valid) cClass = 'condition-validy-invalid'

          text += '<span class="'+cClass+'">'

          text += row.title

          text += '</span>'

          if(index==rows.length-1) {
              text += ' '+end
              end=''
          }

          if((index==0 || index<rows.length-1) && logicGroup.calcOperatorType!=null) text += ' <span style="color:#9b51e0;">'+logicGroup.calcOperatorType.title+'</span> '
          else if(prevOperatorType!=null && prevOperatorType.title) text +=  ' <span style="color:#9b51e0;">'+prevOperatorType.title+'</span> '
        }
      })

      return text
    },

    copyExpression(expression) {
      this.$emit('copy', expression)
    },

    pasteExpression() {
      this.$refs['logicGroup'].pasteConditions()
    },

    toggleLockCond(cond){
      cond.locked = !cond.locked
    }
  }
}
</script>

<style scoped>
  .mode {
    color:#c0c0c0;
  }
  .modeActive {
    color:#000;
    font-weight: bold;
  }
  .bar-rule-text {
    font-size:14px;
    background: #f0f0f0;
    padding:5px;
    color:#505254;
    word-break: break-all;
  }
  .gray-filter {
    filter:grayscale(50%);
  }
</style>