<template lang="pug">
task-section-expansion(v-if="hasAttachments" :title="sectionTitle" :showToggle="getAttachmentPanel" :additionalIcon="addIconButton" headerColorDark headerWrap changeHeaderHeight @additionalIconClick="attachmentPick" @openPanel="setAttachmentPanel($event)")
  template(#titleAfter)
    div.d-flex.align-center(v-if='!isTaskManager')
      app-text {{ flyCount + '/' + attachWithAnnotation.length }}
      
      v-tooltip( bottom )
        template( v-slot:activator="{ on, attrs }" )
          task-button-action.ml-1( v-on="on" size="20" :icon="isVisibleTaskAnnotations ? 'project/task/location_on.svg' : 'project/task/location_off.svg'" @click.stop="showMarkerAnnotation(!isVisibleTaskAnnotations)" )
        span {{ isVisibleTaskAnnotations ? $t('viewer.tools.switchOff') : $t('viewer.tools.turnOn') }} {{$t('module.task.taskManager.markers')}}

      v-tooltip( v-if="playFlyTo" bottom )
        template( v-slot:activator="{ on, attrs }" )
          task-button-action.ml-1( v-on="on" size="20" icon="project/task/play_circle_black.svg" @click.stop="startFlyItemAnnotation()" )
        span {{ $t('viewer.tools.flyAround') }}

      v-tooltip( v-else bottom )
        template( v-slot:activator="{ on, attrs }" )
          task-button-action.ml-1( v-on="on" size="20" icon="project/task/pause_circle.svg" @click.stop="stopFlyByAnnotation()" )
        span {{ $t('viewer.tools.stop') }}
      
      v-tooltip( bottom )
        template( v-slot:activator="{ on, attrs }" )
          task-button-action.ml-1( v-on="on" size="20" :icon=" repeat ? 'project/task/loop_on.svg' : 'project/task/loop_off.svg'" @click.stop="repeat = !repeat" )
        span {{ repeat ? $t('viewer.tools.switchOff') : $t('viewer.tools.turnOn') }} {{$t('viewer.tools.repeat')}}

  div.d-flex.flex-wrap.mx-1(style='gap: 8px;')
    task-attachment( v-for="attach in attachmentsParseCam" style='width: 250px; ' :key="attach.uuid" :attach="attach" :currentAttach="currentAttach" @location="showLocation(attach)" test-id="task-attachment" )

  task-comment-attachment-dialog( v-model='dialog.attachment' :task='selectedTask' )
</template>

<script>
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { mapMutations, mapActions, mapGetters, mapState } from 'vuex'

import TaskAttachment from '@/components/task/taskDetailsSections/TaskAttachment.vue'

import { SourcePath } from '@app/SourcePath'

import { TaskBimAnnotations } from '@/components/task/taskBimAnnotations'
import { TaskScreenshot } from '../taskScreenshot'
import TaskCommentAttachmentDialog from './taskComments/TaskCommentAttachmentDialog.vue'
import TaskSectionExpansion from '@/components/task/taskDetailsSections/TaskSectionExpansion.vue'
import TaskButtonAction from '@/components/task/taskDetailsSections/TaskButtonAction.vue'

export default {
  name: 'TaskAttachmentsSection',

  components: {
    TaskAttachment,
    TaskCommentAttachmentDialog,
    TaskSectionExpansion,
    TaskButtonAction,
  },

  props: {
    selectedTask: Object,
  },

  data() {
    return {
      selectedAttachment: null,

      playFlyTo: true,
      repeat: false,
      timersFlyTo: [],
      flyCount: 0,
      currentAttach: null,
      comment: '',
      taskId: null,
      dialog: {
        attachment: false,
      },
    }
  },

  computed: {
    ...mapState('drawings', ['drawingsPages', 'drawingsPanel']),
    ...mapState('collision/search', ['collisionSelectedGlobalIds']),
    ...mapGetters('taskDetails', ['getAttachmentPanel']),
    ...mapGetters('project', ['projectUuid']),
    ...mapGetters('task', ['filteredTasks']),
    ...mapGetters('projectPermissions', ['hasTaskCreateCommentAttach']),

    addIconButton () {
      if (this.hasTaskCreateCommentAttach) return 'add_box.png'
      return null
    },

    selectedCollisionUuid() {
      return this.collisionSelectedGlobalIds.collisionUuid
    },

    sectionTitle() {
      return this.$t('module.task.attachments') + ' (' + this.attachmentsParseCam.length + ')'
    },

    isVisibleTaskAnnotations() {
      return TaskBimAnnotations.isVisibleTaskAnnotations
    },

    hasAttachments() {
      if (this.selectedTask.attachments) {
        return this.selectedTask.attachments.length > 0
      } 
      else {
        return false
      }
    },

    attachmentsParseCam() {
      return this.hasAttachments
        ? this.selectedTask.attachments
            .map((item) => {
              if (item.sourceData) item.cameraParse = item.sourceData
              return item
            })
            .sort((a, b) => a.createDate.localeCompare(b.createDate))
        : []
    },

    attachWithAnnotation() {
      let list = this.attachmentsParseCam.filter((attach) => attach.sourceType.title === 'VIEW')

      return list.map((attach, i) => {
        attach.count = i
        let camera = attach.cameraParse
        camera.duration = (1.5 * list.length) / 3
        if (camera.duration < 1.5) camera.duration = 1.5
        else if (camera.duration > 3) camera.duration = 3

        attach.sourceData.camera.duration = camera.duration

        return attach
      })
    },

    isTaskManager() {
      return window.location.href.includes('task/manager')
    },

    selectedAnnotation() {
      return TaskBimAnnotations.selectedTaskAnnotation
    },
  },

  beforeDestroy() {
    if (!this.playFlyTo) this.stopFlyByAnnotation()
  },

  methods: {
    ...mapActions('drawings', ['loadDrawings']),
    ...mapMutations('project', ['setActiveGlobalTab']),
    ...mapActions('taskDetails', ['setAttachmentPanel']),

    attachmentPick() {
      if (this.isTaskManager) {
        this.showAttachmentDialog()
      } 
      else {
        this.takeScreenshotToTask()
      }
    },

    showLocation(attach) {
      if (
        attach.sourceType.title == 'VIEW' ||
        (attach.sourceType.title == 'DRAWINGCOMMENT' && !this.$route.path.includes('task/manager'))
      ) {
        if (attach.sourceType.title == 'VIEW') {
          if (attach.sourceData) {
            this.showCamera(attach, false)
          }
        } 
        else {
          if (!this.drawingsPages.length) {
            this.loadDrawings(this.projectUuid).then(() => {
              this.showDrawing(attach)
            })
          } 
          else {
            this.showDrawing(attach)
          }
        }
      } 
      else {
        this.showFile(attach.file, attach.contentType)
      }
    },

    flyItemAnnotation() {
      this.shownOnlyFlyAnnotations(true)
      TaskBimAnnotations.noSelectedTaskBimAnnotation()

      this.playFlyTo = false
      let attach = this.attachWithAnnotation[this.flyCount]
      if (this.flyCount < this.attachWithAnnotation.length) {
        this.showCamera(attach, true)
        this.flyCount = this.flyCount + 1
        let timer = setTimeout(() => {
          this.flyItemAnnotation()
        }, 4000)
        this.timersFlyTo.push(timer)
      } 
      else {
        if (this.repeat || this.selectedTask == this.taskId) {
          this.flyCount = 0
          this.flyItemAnnotation()
        } 
        else this.stopFlyByAnnotation()
      }
    },

    stopFlyByAnnotation() {
      this.shownOnlyFlyAnnotations(false)

      if (XeokitMediator.viewer) {
        this.timersFlyTo.forEach((timer) => clearTimeout(timer))
        this.timersFlyTo = []

        XeokitMediator.viewer.cameraFlight.stop()
        TaskScreenshot.dropScreenshot()
        TaskBimAnnotations.noSelectedTaskBimAnnotation()

        this.flyCount = 0
        this.playFlyTo = true
        this.repeat = false

        this.currentAttach = null
      }
    },

    startFlyItemAnnotation() {
      this.taskId = this.$store.state.task.selectedTask.uuid
      this.flyItemAnnotation()
    },

    shownOnlyFlyAnnotations(shown) {
      if (shown)
        this.attachWithAnnotation.forEach((attach) => {
          if (attach?.annotation) {
            const bimAnnotation = TaskBimAnnotations.getTaskBimAnnotationByUUID(attach.annotation.id)
            if (bimAnnotation) bimAnnotation.setMarkerShown(shown)
          }
        })
    },

    showCamera(attach, shownLabel = false) {
      if (
        attach.sourceData &&
        attach.sourceData !== undefined &&
        attach.uuid !== TaskBimAnnotations.selectedTaskAnnotation?.id.replace('task_annotation_', '')
      ) {
        if (attach.collision) {
          if (this.selectedCollisionUuid) {
            if (this.selectedCollisionUuid == attach.collision) {
              TaskScreenshot.dropScreenshot()
            }
            else TaskScreenshot.showTaskScreenshot(attach)
          }
          else TaskScreenshot.showTaskScreenshot(attach)
        }
        else TaskScreenshot.showTaskScreenshot(attach)
      } 
      else {
        TaskScreenshot.dropScreenshot()
      }

      if (attach.annotation && attach.annotation !== undefined) {
        TaskBimAnnotations.setSelectedTaskBimAnnotationByAttachment(attach, shownLabel)
      } 
      else TaskBimAnnotations.noSelectedTaskBimAnnotation()
    },

    async takeScreenshotToTask() {
      await TaskScreenshot.showCreateTaskScreenshotDialog({
        taskUuid: this.selectedTask.uuid,
        screenshotComment: this.comment,
      })
    },

    showDrawing(attach) {
      let camera = attach.sourceData
      this.setActiveGlobalTab('drawings')
      if (!this.drawingsPanel) {
        this.$root.$on('pagesIsLoading', () => {
          this.drawingsPanel.scrollDrawingPageToComment(camera)
          this.$root.$off('pagesIsLoading')
        })
      } 
      else {
        this.drawingsPanel.scrollDrawingPageToComment(camera)
      }
    },

    showFile(file, contentType) {
      if (contentType === 'application/pdf') {
        window.open(SourcePath.attachment(file), '_blank')
      } 
      else {
        if (contentType.startsWith('image')) {
          let w = window.open()
          let img = new Image()
          img.src = SourcePath.attachment(file)
          img.setAttribute('style', 'text-align: center;position: absolute;inset: 0;margin: auto;')
          w.document.body.appendChild(img)
        } 
        else {
          window.open(SourcePath.attachment(file), '_blank')
        }
      }
    },

    showMarkerAnnotation(showMarker) {
      TaskBimAnnotations.setTaskBimAnnotationsVisible(showMarker)
    },

    showAttachmentDialog() {
      this.dialog.attachment = true
    },
  },
}
</script>
