<template lang="pug">
  div.relative
    div.absolute.cover(v-if="coverLoading")
    v-progress-linear(v-if="loading" indeterminate)
    div.mt-2(v-if="breadcrumbs.length > 0")
      v-btn(color="accent" light @click="goBack()" icon)
        v-icon mdi-keyboard-backspace
    div(v-if="classNodeSelected")
      v-form(@submit.prevent="saveClass" ref="nodeEditFormRef")
        v-container
          v-row
            v-col(cols=6)
              app-text-field(ref="pressmark" v-model="classNodeSelected.pressmark" :label="$t('section.classification.menuClassification.code')" outlined :rules="rulesRequiredAndExist" @change="saveClass()" :disabled="!hasClassificatorCreateOrEditPrice")
            v-col(cols=6)
              app-select(v-model="classNodeSelected.nodeType" :items="noTopicTypes" item-text='message' :label="$t('section.classification.menuClassification.classType')" outlined :rules="rulesRequired" @change="saveClass()" :disabled="!hasClassificatorCreateOrEditPrice")

            app-textarea.pr-3.pl-3(v-model="classNodeSelected.title" :label="$t('section.classification.menuClassification.title')" outlined rows=2 :rules="rulesRequired" @change="saveClass()" :disabled="!hasClassificatorCreateOrEditPrice")

          v-row
            v-col(cols=6)
              app-select(v-model="classNodeSelected.unitType" :items="unitTypes" item-text='message' :label="$t('section.classification.menuClassification.unit')" outlined :rules="rulesRequired" test-id="menu-classification-unit" @change="saveClass()" :disabled="!hasClassificatorCreateOrEditPrice")
            v-col(cols=6)
              app-text-field(v-model="classNodeSelected.unitValue" :label="$t('section.classification.menuClassification.amount')" outlined :rules="rulesRequiredAndDouble" test-id="menu-classification-amount" @change="saveClass()" :disabled="!hasClassificatorCreateOrEditPrice")

        div(style="height:20px;" test-id='empty-block')
          div(v-if="savingClass")
            app-text(test-id="menu-classification-saving") {{$t('section.classification.menuClassification.saving')}}
            v-progress-linear(indeterminate)

      //- div(v-for="item in compounds.filter(e => !e.node)" :key="item.uuid") 
        app-text {{ item.uuid }} {{ item.node }}

      div.mt-2.pr-3.pl-3(v-if="isNode")
        app-section-header-tabs.mb-1( :items="compoundTypes" :value="selectedCompoundType.value" @select="onSelect" light )
        v-progress-linear(v-if="compoundLoading" indeterminate)

        v-data-table#copoundTable(
          light
          dense
          :headers="headers"
          :items="compoundsFiltred"
          item-key="uuid"
          :no-data-text="$t('section.classification.menuClassification.noBind')"
        )
          template(v-slot:item="{ item }")
            v-hover( v-slot:default="{ hover }" )
              tr.compRow(v-if="item.node")
                td(width="10%") 
                  v-btn(v-if="windowMode" text @click="goForward(item)") {{ item.node.pressmark }}
                  span(v-else) {{ item.node.pressmark }}
                td {{ item.node.title }}
                  div.small-text.gray-text(v-if="item.node.projectClassificatorUuid != projectClassificator.uuid") внешняя связь
                td.text-center(width="10%") {{ item.node.unitValue }} {{ item.node.unitType ? item.node.unitType.title : ''}}

                template(v-if="hasClassificatorCreateOrEditPrice")
                  template(v-if="item.editMode")
                    td(align="center" width="20%")
                      app-text-field(v-model="item.rate" :label="$t('section.classification.menuClassification.outgo')" hide-details outlined :rules="rulesRequiredAndDouble" @change="saveRate(item)" @blur="saveRate(item)" autofocus style="width:100px;")
                  template(v-else)
                    td.text-center.pointer(@click="editRate(item)" :title="$t('section.classification.menuClassification.changeFlow')" width="20%") {{ item.rate }}
                template(v-else)
                  td.text-center(:title="$t('section.classification.menuClassification.changeFlow')" width="20%") {{ item.rate }}

                td(align="center" justify="center" width="10%")
                  v-checkbox.mx-n1(v-model="item.showInSmeta" dense hide-details light @change="saveRate(item)" :disabled="!hasClassificatorCreateOrEditPrice")

                td(width="10%" test-id="delete-compound-column").text-center.w60
                  v-icon.unit-action( v-if="hover && hasClassificatorCreateOrEditPrice" color="#000" @click.stop="showDeleteCompound(item)" test-id="delete-compound-icon" :title="$t('section.classification.menuClassification.delete')") mdi-delete

              tr.compRow(v-else style="background: #f00;") 
                td(width="10%" colspan=3) {{ item.uuid }}
                td(colspan=2) {{$t('section.classification.menuClassification.errorBind')}}
                td(width="10%").text-center.w60
                  v-icon.unit-action( v-if="hover && hasClassificatorCreateOrEditPrice" color="#000" @click.stop="showDeleteCompound(item)" :title="$t('section.classification.menuClassification.delete')") mdi-delete

        .btn-action.mt-3.px-2(v-if="hasClassificatorCreateOrEditPrice" test-id="classification-add" @click="showAddCompound()")
          v-icon(color="#3B93AF" ) mdi-plus
          span {{$t('button.add')}}

        AddCompoundDialog(v-if="hasClassificatorCreateOrEditPrice" v-model="addCompoundDialog" :nodeType="selectedCompoundType" :compounds="compounds" @addCompound="addCompound")

        app-dialog-confirm( v-if="deleteCompound.compound" v-model="deleteCompound.dialog" @confirm="delCompound")
          app-section
            app-text {{ $t('section.classification.menuClassification.removeBinds') }} {{deleteCompound.compound.node ? deleteCompound.compound.node.title : deleteCompound.compound.uuid}}?
</template>

<script>
import AddCompoundDialog from "./AddCompoundDialog"

import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

import { api } from "@/api"

export default {
  components: {
    AddCompoundDialog
  },

  props: {
    projectClassificator: Object,
    windowMode: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading:false,
      compoundLoading:false,
      savingClass:false,

      classNodeSelected: null,

      selectedCompoundType: {value:2,name:'WORK',title: this.$t('section.classification.works')},
      compounds:[],

      addCompoundDialog: false,

      deleteCompound: {
        compound: null,
        dialog: false
      },

      selectPressmarkAfterLoad: false,
      breadcrumbs: [],
      coverLoading: false,

      allCodes: [],
      codeChecking: false,
      rules: {
        required: (value) => !!value || this.$t("error.require"),
        double: (value) => (!!value && /^\d+(.\d+)?$/.test(value)) || this.$t('section.worm.wholeOrFractionalNumber'),
        exists: (value) => {
          if (!value) return false
          if (this.editClass && this.editClass.pressmark == value) return true
          return !this.allCodes.includes(value) || 'Code exist'
        }
      }
    }
  },

  mounted() {
    this.makeEditClass(this.editClass)
  },

  computed: {
    ...mapState("smeta", ["nodeTypes", "unitTypes","editClass","units",'lastUnit','items','isTreeInPreparation']),
    ...mapState('project', ['project','projectSettings']),
    ...mapState('task', ['tasks']),
    ...mapGetters('smeta', ['getNode']),

    ...mapGetters("projectPermissions", ['hasClassificatorCreate', 'hasClassificatorEditPrice']),

    hasClassificatorCreateOrEditPrice () {
      return this.hasClassificatorCreate || this.hasClassificatorEditPrice
    },

    rulesRequired () {
      return [this.rules.required]
    },

    rulesRequiredAndExist () {
      return [this.rules.required, this.rules.exists]
    },

    rulesRequiredAndDouble () {
      return [this.rules.required, this.rules.double]
    },

    headers () {
        return [
          {text: this.$t('section.classification.menuClassification.code'), align: 'start', sortable: false},
          {text: this.$t('section.classification.menuClassification.title'),  align: 'start', sortable: false},
          {text: this.$t('section.classification.menuClassification.smallUnit'), align: 'center', sortable: false},
          {text: this.$t('section.classification.menuClassification.outgo'), align: 'center', sortable: false},
          {text: this.$t('section.classification.menuClassification.ind'), align: 'center', sortable: false},
          {text: '', align: 'center', sortable: false}
        ]
      },

    noTopicTypes(){
      return this.nodeTypes.filter(e => e.value != 1)
    },

    compoundTypes(){
      return this.nodeTypes.filter(e => (e.value != 1 && e.value != 0))
    },

    isNode(){
      return this.classNodeSelected && this.classNodeSelected.nodeType && this.classNodeSelected.nodeType.value == 0
    },

    compoundsFiltred() {
      return this.compounds.filter(e => (e.node && e.node.nodeType && e.node.nodeType.value == this.selectedCompoundType.value) || !e.node)
    }
  },

  watch: {
    editClass(val) {
      this.makeEditClass(val)
    },

    isTreeInPreparation(nv,ov){
      if (!ov && nv) this.coverLoading = true
      if (ov && !nv) this.coverLoading = false
    }
  },

  methods: {
    ...mapMutations('smeta', ['setEditClass','setLastUnit','setEditRuleItem','setExpandAfterLoadUuid']),
    ...mapActions('smeta', ['updateElement','expandTo']),
    ...mapActions('task', ['loadTasks']),

    unitName(item){
      return `${item.fullName} (${item.multiplier}${item.type.title})`
    },

    onSelect (item) {
      this.selectedCompoundType = item
    },
    
    makeEditClass(eClass){
      this.allCodes = []
      api.smeta.allCodes(this.projectClassificator.uuid).then((data) => {
        this.allCodes = data
      })
      this.loading = true
      api.smeta.loadNode(eClass.uuid, eClass.projectClassificatorUuid).then(data => {
        this.classNodeSelected = JSON.parse(JSON.stringify(data))
        this.loading = false
        this.compounds = []
        if (this.isNode) this.loadNodeCompounds()

        if (this.selectPressmarkAfterLoad){
          let sel = async () => {
            await this.$nextTick()
            this.$refs.pressmark.$el.getElementsByTagName('input')[0].select()
            this.selectPressmarkAfterLoad = false
          }
          sel()
          
        }
      })
    },

    cancelClass(){
      this.setEditClass(null)
    },

    saveClass(){
      if (this.hasClassificatorCreateOrEditPrice){

        if (this.$refs.nodeEditFormRef.validate()) {

          this.savingClass = true
          this.classNodeSelected.parent = this.editClass.parent ? {uuid:this.editClass.parent.uuid} : null
          this.classNodeSelected.projectClassificator = this.projectClassificator

          if (this.classNodeSelected.unitType == null) this.classNodeSelected.unitType = {value:0,name:'EMPTY',title:''}

          api.smeta.classcreate(this.classNodeSelected).then(data => {
            if (data.error) {
              this.$refs.pressmark.$children[0].errorBucket.push('Code exist')
              this.$refs.pressmark.$children[0].valid = false   
              if (!this.allCodes.includes(this.classNodeSelected.pressmark)) this.allCodes.push(this.classNodeSelected.pressmark)
              this.savingClass = false
            } else {
              if (!this.allCodes.includes(data.pressmark)) this.allCodes.push(data.pressmark)

              this.setLastUnit({unitType:data.unitType,unitValue:data.unitValue})
              if (this.projectClassificator.uuid == this.projectSettings.projectClassificator) this.updateElement(data)

              if (!data.children) delete data.children
              if (!this.classNodeSelected.children) this.$delete(this.classNodeSelected,'children')

              this.classNodeSelected = Object.assign(this.classNodeSelected,data)
              this.setLastUnit({unitType:data.unitType,unitValue:data.unitValue})

              this.savingClass = false
              this.handleChange()

              if (this.tasks) this.loadTasks()
            }
          })
        }
      }
    },

    handleChange(){
      if (this.hasClassificatorCreateOrEditPrice){
        this.$emit("change",this.classNodeSelected)
      }
    },

    loadNodeCompounds(){
      this.compoundLoading = true
      api.smeta.loadNodeCompounds(this.classNodeSelected.uuid, this.classNodeSelected.projectClassificatorUuid).then(data => {
        data.forEach(d => d.editMode = false)
        this.compounds = data
        this.compoundLoading = false
      })
    },

    showAddCompound() {
      if (this.hasClassificatorCreateOrEditPrice){
        this.addCompoundDialog = true
      }
    },

    addCompound(item) {
      if (this.hasClassificatorCreateOrEditPrice){
        let saveData = JSON.parse(JSON.stringify(item))
        saveData.classificatorNode = {uuid:this.classNodeSelected.uuid}
        saveData.classificatorNodeProjectClassificatorUuid = this.classNodeSelected.projectClassificatorUuid

        saveData.node = {uuid:item.node.uuid}
        saveData.nodeProjectClassificatorUuid = item.node.projectClassificatorUuid

        api.smeta.saveCompound(saveData).then(() => {
          this.loadNodeCompounds()
          this.addCompoundDialog = false
        })
      }
    },

    showDeleteCompound(item) {
      if (this.hasClassificatorCreateOrEditPrice){
        this.deleteCompound.compound = item
        this.deleteCompound.dialog = true
      }
    },

    delCompound(){
      if (this.hasClassificatorCreateOrEditPrice){
        api.smeta.delCompound(this.deleteCompound.compound.uuid).then(() => {
          this.loadNodeCompounds()
          this.deleteCompound.dialog = false
          this.deleteCompound.compound = null
        })
      }
    },

    editRate(item) {
      if (this.hasClassificatorCreateOrEditPrice){
        this.compounds.filter(e => e.editMode).forEach(e => e.editMode = false)
        item.editMode = true
      }
    },

    saveRate(item) {
      if (this.hasClassificatorCreateOrEditPrice){
        let saveData = JSON.parse(JSON.stringify(item))
        saveData.classificatorNode = {uuid:this.editClass.uuid}
        saveData.classificatorNodeProjectClassificatorUuid = this.classNodeSelected.projectClassificatorUuid

        saveData.node = {uuid:item.node.uuid}
        saveData.nodeProjectClassificatorUuid = item.node.projectClassificatorUuid
        
        api.smeta.saveCompound(saveData).then(() => {
          item.editMode = false
        })
      }
    },

    selectPressmark(){
      this.selectPressmarkAfterLoad = true
    },

    goForward(item) {
      this.breadcrumbs.push(this.editClass)
      this.goToNode(item.node)
      if (this.breadcrumbs.length > 0) {
          this.setEditClass(item.node)
        }
    },

    goBack(){
      let item = this.breadcrumbs.pop()
      this.goToNode(item)
    },

    goToNode(eClass){
      this.setEditClass(eClass)
      if (eClass.hasRules){
        this.coverLoading = true
        if (this.projectSettings.projectClassificator != eClass.projectClassificatorUuid) {
          let ps = JSON.parse(JSON.stringify(this.projectSettings))
          ps.projectClassificator = eClass.projectClassificatorUuid
          this.$store.dispatch('project/updateSettingsProjectClassificator', ps)
          this.setExpandAfterLoadUuid(eClass.uuid)
        } else if (this.items.length==0) {
          this.setExpandAfterLoadUuid(eClass.uuid)
        } else {
          this.expandTo(eClass.uuid)
          let fullNode = this.getNode(eClass.uuid)
          if (fullNode.isRule) this.setEditRuleItem(fullNode)
          else if (fullNode.children && fullNode.children.length > 0) this.setEditRuleItem(fullNode.children[0])
          this.coverLoading = false
        }
      }
    },
  }
}
</script>

<style lang="sass">
.w60
  width: 60px

#copoundTable
  .compRow
    td
      min-height: 40px !important
      height: 40px !important
      max-width: 10%
</style>

<style lang="scss" scoped>
.btn-action {
  width:100px;
  margin: 0px;
  font: normal 12px/18px $roboto;
  background-color: transparent;
  color: #3B93AF;
  text-align: center;
  border: 1px dashed #3B93AF;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}
.absolute {position: absolute;}
.relative {position: relative;}
.cover {
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  z-index: 1;
}
.small-text {font-size:12px}
.gray-text {color:#c0c0c0}
</style>