<template lang="pug">
app-panel( ident='grids' )
  app-panel-header.ml-2.mt-1( :title="$t('toolbarMenu.gridsSettings.settings')" showClose )
  app-floating-panels-section( style="height: calc(100% - 26px) !important; overflow: auto; overflow-x: hidden;" )
    app-floating-panel(:title="$t('toolbarMenu.gridsSettings.structure')" bodyMinHeight="150" :startHeight="80" overflowBody="auto" :bodyColor="'#ffffff'" :settings="floatingPanels('STRUCTURE_PROJECT_TREE')")
      ifc-tree.ml-2.mt-2( v-model="ifcTree" )    
    app-floating-panel(:title="$t('toolbarMenu.gridsSettings.displaySettings')" bodyMinHeight="150" :startHeight="80" overflowBody="auto" :bodyColor="'#ffffff'" :settings="floatingPanels('STRUCTURE_PROJECT_TREE')")
      v-checkbox.settings-options-header.ml-2.mt-2( v-model="gridsTransparency", :label="$t('toolbarMenu.gridsSettings.transparency')", dense, hide-details, light, @change="setTransparent()" )
</template>

<script>
import IfcTree from "@/components/project/common/IFCTree.vue"
import { IFCGridsService } from "@/plugins/IFCGrids/IFCGridsService"
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'grids',

  components: {
    IfcTree,
  },

  data() {
    return {
      ifcTree: null,
      // gridsTransparency: false,
    }
  },

  computed: {
    ...mapState('project', ['project']),
    ...mapState('IFCGrids', ['transparency', 'gridsActivity']),
    ...mapState('floatingPanels', ['floatingPanelsKeys']),
    ...mapGetters('floatingPanels', ['floatingPanels']),

    gridsTransparency: {
      get() {
        return this.transparency
      },
      set(value) {
        this.setGridsTransparency(value)
      }
    }
  },

  mounted() {
    let projectUuid = this.project.uuid
    this.gridsTransparency = this.transparency

    this.getIFCTree(projectUuid).then(data => {
      this.ifcTree = data.model
    })
  },

  methods: {
    ...mapActions('IFCGrids', ['getIFCTree']),
    ...mapMutations('IFCGrids', ['setGridsTransparency']),

    setTransparent: () => { IFCGridsService.redrawGrids() },
  }
}
</script>

<style>

.tree-background {
  border-radius: 5px;
  background-color: #f0f0f0;
  max-height: 400px;
}

.btn {
  outline: none !important;
}
</style>