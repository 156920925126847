import { defineStore } from 'pinia'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

export const ToolWindow = Object.freeze({
  NONE: 'none',
  SCENE_COLORS: 'colors',
  SCENE_GROUPS: 'groups',
  SCENE_TYPES: 'ifcTypes',
  PLUGIN_POINTS: 'points',
  PLUGIN_PLANES: 'planes',
  PLUGIN_CUBES: 'cubes',
  PLUGIN_RULER: 'ruler',
})

export const useActiveToolWindowStore = defineStore('viewer-activeToolWindow', {
  state: () => {
    return {
      activeWindow: ToolWindow.NONE,
    }
  },

  actions: {
    toggleActiveWindow (window) {
      window = this.activeWindow == window ? ToolWindow.NONE : window
      this.setActiveWindow(window)
    },

    setActiveWindow (window) {
      if (this.activeWindow == window) return
      
      if (this.activeWindow == ToolWindow.PLUGIN_CUBES) {
        XeokitMediator.SectionCube.setActive(false)
      }
      else if (window == ToolWindow.PLUGIN_CUBES) {
        XeokitMediator.SectionCube.setActive(true)
      }

      this.activeWindow = window
    },

    closeWindow () {
      this.setActiveWindow(ToolWindow.NONE)
    }
  }
})
