<template lang="pug">
  .drawing-container
    .drawing-header
      //- Панель управления
      div.d-flex.ml-auto.mr-2
        v-menu(bottom nudge-bottom='25' nudge-right='10' :close-on-content-click="true")
          template(v-slot:activator='{on, attrs}')
            v-btn(small icon v-bind='attrs' v-on='on')
              .drawing-scale-label {{ scaleDrawing }}
          app-panel
            app-section
              .d-flex.flex-column
                v-btn(text small v-for='measure in measures' :key='measure' color='#4D4D4D' @click='changeScaleManually(measure)')
                  .drawing-scale-label(style='color: #4D4D4D') {{ measure }}
        v-slider.ma-0.pa-0(:min='25' :max='200' :step='25' @input='changeScaleManually' :value='scaleDrawing' height='28' track-color="#4D4D4D" color='#4D4D4D' style='width: 84px;' dense hide-details)
        v-btn(icon small @click='pageFitSize()')
          img(src='/img/project/tools/drawing-fit-size.svg' width='16px' height='16px')
        v-btn.ml-1(small icon @click='setDrawingCommentMode(!drawingCommentMode)')
          v-icon(:color='drawingCommentMode? "#EB5757": "#4D4D4D"') mdi-message-plus-outline
        div.ml-1
          v-menu(v-if='selectedDrawingPage' bottom nudge-bottom='25' nudge-left='100' :min-width='340' :max-width='340' :max-height='360' :close-on-content-click="false")
            template(v-slot:activator='{on, attrs}')
              v-btn(small icon v-bind='attrs' v-on='on' :disabled='!selectedDrawingPage.attachments.length > 0' light)
                v-icon(color='#4D4D4D') mdi-text
            drawings-comments-panel

    v-progress-circular.loading-donut( v-if="drawingIsLoading" :size="40" :width="4" color="accent" indeterminate )

    .drawing-body( ref="drawingBody" :style='drawingBodyCursorStyle' )
      img#drawingImg( v-show="!drawingIsLoading" ref="drawing" :class='{"drawing-opacity": commentImportMode}' )
      .context-menu-and-comments( ref="context" :style='cursorStyle' @contextmenu="openContextMenu" @click='openContextMenu')
        .context-menu( :style="commentContextPositionStyle(contextMenu, false)" v-if="show.context" )
          button.add-comment( @click.stop="openNewCommentDialog" ) {{ $t("module.drawing.addComment.text") }}
        div(v-if='commentImportMode && !drawingIsLoading' v-for='comment in commentsToImport' :style="commentContextPositionStyle(comment, true)")
          drawing-comment( :comment="comment" :context='contextPanelCurrentSize' )
        div(v-if='!drawingIsLoading && !commentImportMode' v-for="comment in selectedDrawingPage.attachments" :style="commentContextPositionStyle(comment, true)" )
          drawing-comment( :comment="comment" :context='contextPanelCurrentSize' )
    
    //- Добавление Нового Комментария
    app-dialog(
      v-model="show.newCommentDialog" 
      :header="$t('module.drawing.addComment.header')" 
      :confirmText="$t('button.add')"
      @confirm="addNewComment"
    )
      app-section
        app-textarea.mt-3( v-model="addingComment.text" :label="$t('module.drawing.addComment.placeholder')" :height="100" outlined hide-details)
        v-checkbox( v-model="show.commentTaskBind" :label="$t('module.drawing.task.addToTask')" color="accent" light )
        app-select( v-if="show.commentTaskBind" v-model="addingComment.task" :items="tasks" :label="$t('module.drawing.task.chooseTask')"  item-text="title" )

    //- Перенос комментариев
    .import-message-block(v-if='commentImportMode')
      span {{ $t("module.drawing.importMessage") }}
      .d-flex.ml-auto.mt-3
        v-btn.mr-3(x-small text color='#757575' @click='setCommentImportMode(false)') {{ $t('button.cancel') }}
        v-btn(x-small color='accent' min-width='84') {{ $t("module.drawing.import") }}
</template>

<script>
import DrawingComment from './DrawingComment'
import DrawingsCommentsPanel from './DrawingsCommentsPanel.vue'
import { mapMutations, mapState, mapActions } from 'vuex'

export default {
  name: "DrawingPage",
  components: { DrawingComment, DrawingsCommentsPanel },

  data() {
    return {
      contextMenu: {
        x: null, 
        y: null
      },
      drawing: {
        width: null,
        height: null,
        source: null
      },
      addingComment: {},
      drawingResizeObserver: null,
      
      drawingIsLoading: true,
      drawingIsFitSize: false,
      
      show: {
        newCommentDialog: false,
        context: false,
        commentTaskBind: false
      },

      contextPanelCurrentSize: {
        width: null,
        height: null
      },
      drawingIsGrabbing: false,
      measures: [25, 50, 75, 100, 150, 200]
    }
  },

  computed: {
    ...mapState('task', ['tasks', 'selectedTask']),
    ...mapState('drawings', ['selectedDrawingPage', 'scaleDrawing', 'scroll', 'drawingCommentMode', 'commentImportMode', 'commentsToImport', 'commentGrabbing']),
    ...mapState('authUser', ['user']),

    isActualVersion() {
      return this.selectedDrawingPage.version.uuid == this.selectedDrawingPage.drawing.currentVersion.uuid
    },

    cursorStyle() {
      if(this.drawingIsGrabbing) {
        return "cursor: grabbing;"
      }
      if(this.drawingCommentMode) {
        return "cursor: url(/img/project/tools/comment-mode-cursor.svg), auto"
      }
      return "cursor: auto"
    },

    drawingBodyCursorStyle() {
      if(this.drawingCommentMode) {
        return "cursor: auto"
      }
      if(this.drawingIsGrabbing) {
        return "cursor: grabbing;"
      }
      return "cursor: auto"
    },
  },

  methods: {
    ...mapActions('task', ['loadTasks']),
    ...mapActions('drawings', ['newComment', 'getDrawingPage']),
    ...mapMutations('drawings', ['setScaleDrawing', 'setCommentImportMode', 'setDrawingCommentMode']),
    
    loadDrawing(page, comment = null){
      let drawingImg = this.$refs.drawing
      if(this.tasks.length == 0){
        this.loadTasks()
      }

      this.drawingIsLoading = true
      return this.getDrawingPage(page.uuid).then(data => {
        let initialSvg = new DOMParser().parseFromString(data, 'application/xml')
        let initialSvgWidth = page.width || initialSvg.children[0].children[1].children[1].children[0].width.animVal.value
        let initialSvgHeight = page.height || initialSvg.children[0].children[1].children[1].children[0].height.animVal.value

        data = data.replace('xmlns:xlink="http://www.w3.org/1999/xlink"', `xmlns:xlink="http://www.w3.org/1999/xlink" width="${initialSvgWidth}" height="${initialSvgHeight}"`)
        this.drawing.source = 'data:image/svg+xml;base64,' + btoa(data)
        
        this.setGrabbingListeners()
        
        drawingImg.src = this.drawing.source
        drawingImg.onload = () =>{
          this.drawingIsLoading = false
          this.drawing.width = initialSvgWidth
          this.drawing.height = initialSvgHeight
          if(comment) {
            this.scrollToComment(comment)
          }
          else if(this.selectedDrawingPage.offsets) {
            this.setScaleDrawing(this.selectedDrawingPage.offsets.scale)
            this.scrollToDrawingInitialOffsets()
          }
          else {
            this.pageFitSize()
          }
        }
        this.$emit('openPage')
      })
    },


    scrollToDrawingInitialOffsets() {
      let contextPanel = this.$refs.context
      let drawingImg = this.$refs.drawing
      let drawingBody = this.$refs.drawingBody
      let scaleOfSelectedPage = this.selectedDrawingPage.offsets.scale / 100
      let scrollOfSelectedPage = this.selectedDrawingPage.offsets.scroll

      this.clearResizeObserver()
      
      drawingImg.style = ''
      drawingImg.style.transformOrigin = 'top left'
      drawingImg.style.transform = `scale(${scaleOfSelectedPage})`

      let contextPanelStyle = `width: ${drawingImg.width * (scaleOfSelectedPage)}px; height: ${drawingImg.height * (scaleOfSelectedPage)}px; top: 8px; left: 8px`
      contextPanel.setAttribute('style', contextPanelStyle)
      this.contextPanelCurrentSize.width = contextPanel.style.width
      this.contextPanelCurrentSize.height = contextPanel.style.height

      drawingBody.scrollTo({
        left: ((drawingBody.getBoundingClientRect().width * scrollOfSelectedPage.x / 100)),
        top: ((drawingBody.getBoundingClientRect().height * scrollOfSelectedPage.y / 100)),
        behavior: "smooth"
      })
    },
    
    scrollToComment(comment) {
      let drawingBody = this.$refs.drawingBody
      let context = this.$refs.context

      this.setScaleDrawing(comment.scale)
      this.clearResizeObserver()

      this.drawingPageScale()

      drawingBody.scrollTo({
        left: ((context.getBoundingClientRect().width * (comment.x / 100))) - drawingBody.getBoundingClientRect().width / 2,
        top: ((context.getBoundingClientRect().height * (comment.y / 100))) - drawingBody.getBoundingClientRect().height / 2,
        behavior: "smooth"
      })
    },

    pageFitSize() {
      let drawingImg = this.$refs.drawing
      let contextPanel = this.$refs.context

      this.clearDrawingOffsets()
      this.setScaleDrawing(100)
      
      this.drawingResizeObserver = new ResizeObserver(()=>{
        let width = drawingImg.width
        if(width > this.drawing.width) {
          width = this.drawing.width
        }
        let height = this.drawing.height * (drawingImg.width / this.drawing.width)
        if(height > drawingImg.height){
          height = drawingImg.height
        }

        let contextPanelStyle = `width: ${width}px; height: ${height}px; top: 50%; left: 50%; transform: translate(-50%, -50%)`
        contextPanel.setAttribute('style', contextPanelStyle)
        this.contextPanelCurrentSize.width = width
        this.contextPanelCurrentSize.height = height
      })
      this.drawingResizeObserver.observe(drawingImg)

      let blockContainStyle = 'object-fit: contain; width:100%; height: 100%'
      drawingImg.setAttribute('style', blockContainStyle)
    },

    hideSidePanel() {
      this.$parent.hideSidePanel()
    },

    openContextMenu(event) {
      if(event.type == 'contextmenu' || (event.type == 'click' && this.drawingCommentMode)) {
        this.show.context = true
        this.contextMenu.x = ((event.clientX - event.target.getBoundingClientRect().left) / event.target.getBoundingClientRect().width ) * 100
        this.contextMenu.y = ((event.clientY - event.target.getBoundingClientRect().top) / event.target.getBoundingClientRect().height ) * 100
        event.preventDefault()
      } else {
        this.show.context = false
      }
    },

    openNewCommentDialog() {
      this.addingComment = {
        task: this.selectedTask
      }
      this.show.commentTaskBind = this.selectedTask ? true : false
      this.show.newCommentDialog = true
      this.show.context = false
    },

    addNewComment() {
      this.addingComment = {
        user: this.user,
        x: this.contextMenu.x,
        y: this.contextMenu.y,
        scale: this.scaleDrawing,
        page: this.selectedDrawingPage,
        src: '',
        task: this.show.commentTaskBind ? this.addingComment.task : "",
        text: this.addingComment.text
      }
      this.newComment(this.addingComment)
    },

    changeScaleManually(scale) {
      this.setScaleDrawing(scale)
      this.drawingPageScale()
    },

    drawingPageScale() {
      let drawingImg = this.$refs.drawing
      let contextPanel = this.$refs.context
      let drawingBody = this.$refs.drawingBody

      this.clearResizeObserver()
      
      this.selectedDrawingPage.offsets = {
        scale: this.scaleDrawing,
        scroll: {
          x: (drawingBody.scrollLeft / drawingBody.getBoundingClientRect().width) * 100,
          y: (drawingBody.scrollTop / drawingBody.getBoundingClientRect().height) * 100
        }
      }
      
      drawingImg.style = ''
      drawingImg.style.transformOrigin = `top left`
      drawingImg.style.transform = `scale(${this.scaleDrawing / 100})`

      contextPanel.style = ''
      let contextPanelStyle = `width: ${drawingImg.width * (this.scaleDrawing / 100)}px; height: ${drawingImg.height * (this.scaleDrawing / 100)}px; top: 8px; left: 8px`
      contextPanel.setAttribute('style', contextPanelStyle)
      this.contextPanelCurrentSize.width = drawingImg.width * (this.scaleDrawing / 100)
      this.contextPanelCurrentSize.height = drawingImg.height * (this.scaleDrawing / 100)
    },

    clearScalingListener() {
      this.$refs.drawingBody.removeEventListener('wheel', this.wheelScalingEvent)
    },

    clearDrawingOffsets() {
      this.selectedDrawingPage.offsets = null
    },

    clearResizeObserver() {
      let drawingImg = this.$refs.drawing
      if(this.drawingResizeObserver) {
        this.drawingResizeObserver.unobserve(drawingImg)
        this.drawingResizeObserver.disconnect(drawingImg)
        this.drawingResizeObserver = null
        this.drawingIsFitSize = false
      }
    },

    setGrabbingListeners() {
   
      let drawingBody = this.$refs.drawingBody

      drawingBody.onmousedown = (click) =>{
        drawingBody.onmousemove = (e) =>{
          if(!this.commentGrabbing) {
            if(!this.drawingCommentMode){
              this.drawingIsGrabbing = true

              drawingBody.scrollTop += -e.movementY
              drawingBody.scrollRight += e.movementX
              drawingBody.scrollLeft += -e.movementX

              this.selectedDrawingPage.offsets = {
                scale: this.scaleDrawing,
                scroll: {
                  x: (drawingBody.scrollLeft / drawingBody.getBoundingClientRect().width) * 100,
                  y: (drawingBody.scrollTop / drawingBody.getBoundingClientRect().height) * 100,
                }
              }
            }
            else if(click.which == 2) {
              this.drawingIsGrabbing = true

              drawingBody.scrollTop += -e.movementY
              drawingBody.scrollRight += e.movementX
              drawingBody.scrollLeft += -e.movementX

              this.selectedDrawingPage.offsets = {
                scale: this.scaleDrawing,
                scroll: {
                  x: (drawingBody.scrollLeft / drawingBody.getBoundingClientRect().width) * 100,
                  y: (drawingBody.scrollTop / drawingBody.getBoundingClientRect().height) * 100,
                }
              }
            }
          }
          return false
        }
        drawingBody.onmouseleave = () =>{ 
          drawingBody.onmousemove = null
          this.drawingIsGrabbing = false
        }
        drawingBody.onmouseup = () =>{ 
          drawingBody.onmousemove = null
          this.drawingIsGrabbing = false
        }
      }
      drawingBody.addEventListener('wheel', this.wheelScalingEvent)
      drawingBody.ondragstart = () =>{ return false }
    },

    wheelScalingEvent(e) {
      let drawingBody = this.$refs.drawingBody
      if(e.wheelDelta > 0) {
        if(this.scaleDrawing >= 100) {
          this.setScaleDrawing(this.scaleDrawing + 50)
        }
        else { 
          this.setScaleDrawing(this.scaleDrawing + 25)
        }
      } 
      else {
        if(this.scaleDrawing > 100) {
          this.setScaleDrawing(this.scaleDrawing - 50)
        }else{
          this.setScaleDrawing(this.scaleDrawing - 25)
        }
      }
      this.selectedDrawingPage.offsets = {
        scroll: {
          x: ((drawingBody.scrollLeft / drawingBody.getBoundingClientRect().width) * 100),
          y: ((drawingBody.scrollTop / drawingBody.getBoundingClientRect().height) * 100)
        },
        scale: this.scaleDrawing
      }
      this.drawingPageScale()
    },

    getPrettyDate(date) {
      let prettyDate = new Date(date)
      let month = prettyDate.getUTCMonth() + 1
      month = month < 10 ? "0" + month : month
      return prettyDate.getUTCDate() + "." + month + "." + prettyDate.getUTCFullYear()
    },
    
    commentContextPositionStyle(position, comment) {
      return {
        left: position.x + "%",
        top: comment? `calc(${position.y}% - 30px`: `calc(${position.y}% - 34px`,
        position: "absolute"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .drawing-container{
    background: #E5E5E5;
    color: #262626;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    height: 100%;
    position: relative;
  }
  .drawing-body{
    flex: 1;
    position: relative;
    overflow: hidden;
    background: white;
    padding: 8px;
    user-select: none;
    border-radius: 0px 0px 3px 3px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
  }
  .drawing-header{
    position: relative;
    display: flex;
    align-items: center;
  }
  .page-name{
    white-space: nowrap;
    font-weight: 700;
    flex: 6;
    padding: 0px 12px;
    color: #4D4D4D;
    font-size: 14px;
    line-height: 28px;
  }
  .add-comment{
    background: white;
    font-family: "Roboto";
    color: black;
    height: 20px;
    border-radius: 3px;
    border:1px black solid;
    padding: 4px;
    display: flex;
    place-items: center;
    font-size: 12px;
    white-space: nowrap;
    z-index: 1;
    border-radius: 20px 20px 20px 0px;
    height: 34px;
    width: 160px;
    display: flex;
    justify-content: center;
    border: 1px solid #EB5757;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.65);
    font-size: 12px;
    position: relative;
  }
  .add-comment::before {
    content: "";
    width: 8px;
    height: 8px;
    background-image: linear-gradient(to top right, #EB5757 50%, transparent 0);
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
  .add-comment:hover{
    background: black;
    color: white;
  }
  .context-menu{
    display: flex;
    flex-direction: column;
    visibility: visible;
  }
  .hundred{
    width: 100%;
    height: 100%;
    overflow:auto;
  }
  .context-menu-and-comments{
    position: absolute;
    top: 8px;
    left: 8px;
    // cursor: url('../../../public/img/project/tools/comment-mode-cursor.svg'), auto;
  }
  .drawing-scale-label{
    color: #4D4D4D;
    text-align: center;
  }
  .loading-donut{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 1;
  }
  .drawing-opacity{
    opacity: .3;
  }
  .import-message-block{
    position: fixed;
    left: 350px;
    top: 100px;
    width: 250px;
    height: 80px;
    background: #FFFFFF;
    opacity: .8;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.65);
    font-size: 11px;
    color: #000000;
    border-radius: 3px;
    padding: 8px 4px 0px 8px;
  }
  .version-information{
    color: #262626;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.65);
    border-radius: 3px;
    font-size: 12px;
    width: auto;
    text-align: center;
    padding: 0px 4px;
  }
</style>