<template lang="pug">
  app-dialog(v-model="$_show" width="450" :header="$t('section.equipment.maintenance.workCarriedOut')" :confirm-text="$t('button.save')" @confirm="doneTask()" :confirmDisabled='!valid || !hasMaintainceChecklistTO' withScrollPanel) 
    app-section(v-if="maintenanceTask && rules" style="height: 524px")
      .t-scroll-body
        app-text {{ $t('corp.equipment') }}: {{equipmentName}}
        .d-flex.justify-space-between
          app-text {{ $t('section.equipment.maintenance.maintenanceDate') }}: {{maintenanceTask.maintenanceDate}}
          v-btn.mr-2(v-if="hasMaintainceDateTO" x-small icon light @click="dateEdit = true")
            v-icon mdi-pencil

        app-text(v-if="maintenanceTask.status.name == 'DONE'") {{ $t('section.equipment.maintenance.actualService') }}: {{maintenanceTask.maintenanceDoneDate}}
        app-text Статус: {{maintenanceTask.status.title}}
        app-text(v-if="maintenanceTask.status.name == 'DONE'") {{ $t('section.equipment.maintenance.spent') }}: {{maintenanceTask.profileTitle}}
        
        expansion-button( :buttonHeader="$t('section.equipment.details.consumables')" )
          div.primary--text.expansion-items( v-html="getConsumables" )
        expansion-button( :buttonHeader="$t('section.equipment.details.tools')" )
          div.primary--text.expansion-items( v-html="getTools" ) 
        
        div.mt-2(v-for="rule in taskRules" :key="rule.uuid" no-gutters )
          app-text.font-weight-bold {{rule.title}}
        
          v-row.ml-2(v-for="check in rule.maintenanceChecks" :key="check.uuid" no-gutters )
            v-col
              app-text.mt-1 {{check.number}}.  {{check.title}}
            v-col(cols="auto")
              v-checkbox.mt-n1(v-model="checks" :value="check.uuid" color="accent" :disabled="!hasMaintainceChecklistTO" dense hide-details light)

        v-form(ref="saveCheckForm" v-model="valid" @submit.prevent="submit")

    maintenance-date-dialog( v-model="dateEdit" :maintenanceTask="maintenanceTask" @updateMDate="$emit('updateMDate')")
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable.vue'
import { $_validate_rules } from '@/common/ValidateRules'
import { mapGetters } from 'vuex'
import { api } from '@/api'

import MaintenanceDateDialog from './MaintenanceDateDialog.vue'
import ExpansionButton from '@/components/project/panel/left/components/ExpansionButton.vue'

export default {
  components: {
    MaintenanceDateDialog,
    ExpansionButton
  },

  mixins: [$_validate_rules, DialogToggable],

  props: {
    maintenanceTask: null,
  },

  data() {
    return {
      taskRules: [],
      checks: [],
      valid: false,
      dateEdit:false
    }
  },

  watch: {
    $_show: 'loadRules',
  },

  computed: {
    ...mapGetters('corp', ['equipments', 'isEquipmentsLoaded']),
    ...mapGetters('maintenance', ['treeFlatList']),
    ...mapGetters('projectPermissions', ['hasMaintainceChecklistTO', 'hasMaintainceDateTO']),

    equipmentName() {
      return this.treeFlatList?.find((e) => e.uuid == this.maintenanceTask.equipmentId)?.name
    },

    getConsumables() {
      let text = ""
      this.taskRules.forEach(rule => {
        if (rule.consumables) {
          text += "<p style='margin: 0px'><b>" + rule.title + "</b></p>"
          text += "<div style='padding: 4px 0 4px 12px'>"
          rule.consumables.split('\n').forEach(splitText => text += "<span>" + splitText + "<br /></span>")
          text += "</div>"
        }
      })
      return text || ""
    },

    getTools() {
      let text = ""
      this.taskRules.forEach(rule => {
        if (rule.instruments) {
          text += "<p style='margin: 0px'><b>" + rule.title + "</b></p>"
          text += "<div style='padding: 4px 0 4px 12px'>"
          rule.instruments.split('\n').forEach(splitText => text += "<span>" + splitText + "<br /></span>")
          text += "</div>"
        }
      })
      return text || ""
    },
  },

  methods: {
    submit() {
      this.$refs.saveCheckForm.validate()
    },

    loadRules() {
      if (this.maintenanceTask != null) {
        this.checks = this.maintenanceTask.maintenanceDones.map((d) => d.maintenanceCheck)
        api.maintenance.rule(this.maintenanceTask.uuid).then((data) => {
          this.taskRules = data
        })
      } else this.taskRules = []
    },

    doneTask() {
      api.maintenance.doneTaskAndCheck(this.maintenanceTask.uuid, this.checks).then((data) => {
        Object.assign(this.maintenanceTask, data)
      })
    },

    editMaintenanceDate(task) {
      this.$emit("editDate", task)
    }
  },
}
</script>

<style lang="scss" scoped>
.expansion-items {
  font: normal 12px/16px $roboto;
  margin: 0 0 0 12px;
}
</style>

<style scoped>
.h-100 {
  height: 100%;
}

.t-scroll-body {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  height: 100%;
  scrollbar-color: var(--v-primary-lighten4) var(--v-surface-lighten5);
}

.t-scroll-body::-webkit-scrollbar {
  width: 5px;
  background-color: var(--v-surface-lighten5);
}
.t-scroll-body::-webkit-scrollbar-thumb {
  background-color: var(--v-primary-lighten4);
}
</style>
