<template lang='pug'>
div
  delete-process-tree-node(v-for='node in items || []' :node='node' :level='0')
    template(v-slot:default='{ node }')
      slot(:node='node')
</template>

<script>
import DeleteProcessTreeNode from './DeleteProcessTreeNode.vue'

export default {
  name: "DeleteProcessTree",
  
  components: {
    DeleteProcessTreeNode,
  },
  
  props: {
    items: Array,
  },
}
</script>

<style>

</style>