import { defineStore } from 'pinia'
import { useSearchElementsStore } from '@/pinia/searchElements.module'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import vuexStore from '@/store'

export const useSelectionStore = defineStore('viewer-selection', {
  state: () => {
    return {
      lastPickedElement: null,
      lastSelectedCollisionsElementAB: null,
      lastCollisionSelectedElements: null,
    }
  },

  getters: {
    isSelectionFrameActive: () => XeokitMediator.SelectionFrame.isSelectionFrameActive,
    isToolDisabled: () => vuexStore.getters['project/atModelPositioning'] ? true : false,
    
    selectedCollisionsElementAB: () => vuexStore.getters['collision/table/selectedCollisionsElementAB'],
    collisionSelectedElements: () => vuexStore.state.collision.search.collisionSelectedElements,

    isShowOnlySelectedElements () {
      return XeokitMediator.ElementsSelection.isShowOnlySelectedElements
    },

    isHideSelectedElements () {
      return XeokitMediator.ElementsSelection.isHideSelectedElements
    },

    selectedElements () {
      return XeokitMediator.ElementsSelection.selectedElements
    },

    pickedElement () {
      return XeokitMediator.ElementsSelection.pickedElement
    },
  },

  actions: {
    toggleSelectionFrameActivation () {
      if (this.isSelectionFrameActive) XeokitMediator.SelectionFrame.deactivate()
      else XeokitMediator.SelectionFrame.activate()
    },

    showAllObjects () {
      useSearchElementsStore().hideElementsSelection() // Убирает галочки в таблице поиска элементов

      vuexStore.dispatch('axis/tree/deselectAll')
      vuexStore.dispatch('axis/tree/fetchElementByGlobalId', null)

      vuexStore.dispatch('collision/table/selectCollisions', [])
      vuexStore.dispatch('collision/search/deselectCollision', false)

      XeokitMediator.destroyUnnecessaryModels()

      let topUnit = vuexStore.state.project.topUnit
      if (topUnit == 'elementTree') {
        vuexStore.dispatch('axis/tree/deselectAllTreeEndpoints')
      }

      // TODO: Test
      vuexStore.commit('projectDict/setShowPanel', false)
      vuexStore.commit('worm/setShowPanelHightLightGroups', false)
      vuexStore.commit('smeta/setShowPanelHightLight', false)

      XeokitMediator.ElementsSelection.dropAll()
      
      XeokitMediator.Models.restoreModels()
    },

    toggleShowOnlySelectedObjects () {
      if (!this.isShowOnlySelectedElements) {
        let elementsSet = new Set([this.pickedElement, ...this.selectedElements, ...this.collisionSelectedElements, ...this.selectedCollisionsElementAB])
        let selectElementsUuids = Array.from(elementsSet)

        if (!new Set(this.selectedElements).has(this.pickedElement)) { // Правка работы выделения с подсвеченными элементами
          this.lastPickedElement = this.pickedElement
        }

        XeokitMediator.ElementsSelection.selectElements(selectElementsUuids)

        this.lastSelectedCollisionsElementAB = this.selectedCollisionsElementAB
        this.lastCollisionSelectedElements = this.collisionSelectedElements
      }
      else {
        let elementsSet = new Set([...this.selectedElements])
        elementsSet.delete(this.lastPickedElement)
        for (let element of this.lastSelectedCollisionsElementAB) {
          elementsSet.delete(element)
        }
        elementsSet.delete(this.lastCollisionSelectedElements[0])
        elementsSet.delete(this.lastCollisionSelectedElements[1])
        let selectElementsUuids = Array.from(elementsSet)

        XeokitMediator.ElementsSelection.selectElements(selectElementsUuids)

        this.lastPickedElement = null
        this.lastSelectedCollisionsElementAB = null
        this.lastCollisionSelectedElements = null
      }
      XeokitMediator.ElementsSelection.setShowOnlySelectedElements(!this.isShowOnlySelectedElements)
    },

    toggleHideSelectedObjects () {
      if (!this.isHideSelectedElements) {
        let elementsSet = new Set([this.pickedElement, ...this.selectedElements, ...this.collisionSelectedElements, ...this.selectedCollisionsElementAB])
        let selectElementsUuids = Array.from(elementsSet)

        if (!new Set(this.selectedElements).has(this.pickedElement)) { // Правка работы выделения с подсвеченными элементами
          this.lastPickedElement = this.pickedElement
        }

        XeokitMediator.ElementsSelection.selectElements(selectElementsUuids)

        this.lastSelectedCollisionsElementAB = this.selectedCollisionsElementAB
        this.lastCollisionSelectedElements = this.collisionSelectedElements
      }
      else {
        let elementsSet = new Set([...this.selectedElements])
        elementsSet.delete(this.lastPickedElement)
        this.lastSelectedCollisionsElementAB?.forEach(element => {
          elementsSet.delete(element)
        })
        if (this.lastCollisionSelectedElements) {
          elementsSet.delete(this.lastCollisionSelectedElements[0])
          elementsSet.delete(this.lastCollisionSelectedElements[1])
        }

        let selectElementsUuids = Array.from(elementsSet)

        XeokitMediator.ElementsSelection.selectElements(selectElementsUuids)

        this.lastPickedElement = null
        this.lastSelectedCollisionsElementAB = null
        this.lastCollisionSelectedElements = null
      }
      XeokitMediator.ElementsSelection.setHideSelectedElements(!this.isHideSelectedElements)
    },

    // showAllObjects () {
    //   this.store.showAllObjects()
    //   this.setShowPanel(false)
    //   this.setShowPanelHightLightGroups(false)
    //   this.setShowPanelHightLight(false)
    //   XeokitMediator.ElementsSelection.showAllElements()
    // },
  }
})