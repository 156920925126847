
<template lang="pug">
  app-panel.min-width-25(ident="collision" ref="collisionPanel" left)
    .d-flex.align-center.mb-1
      app-menu(v-if="hasCollisionCreateRule" :menu="collisionMenu" basic color="var(--v-primary-darken2)" )
      v-tabs.ml-2(style="width: 80%" @change="updateTab" :value="tab" height="36px" background-color="transparent" show-arrows )
        v-tabs-slider(color="var(--v-primary-darken2)")
        v-tab(test-id="searchRule-tab") {{$t('section.collision.panelMenu.searchRules')}}
        v-tab(test-id="collisions-tab") {{$t("section.collision.title")}}
        v-tab(test-id="attributeChecking-tab") {{$t('section.collision.panelMenu.attributeChecking')}}
      app-icon-button( icon="mdi-close" @click="handleClose")

    v-tabs-items(v-model="tab" light style="height: 100%")
      v-tab-item.back-color
        rules-founder(ref="rulesFounder" style="height: 100%" @loaded="ruleTabLoaded = true")


      v-tab-item.back-color
        app-floating-panels-section( style="height: calc(100% - 26px) !important; overflow: auto; overflow-x: hidden" )
          app-floating-panel( :title="$t('section.elementTree.axis.ruleTree')" bodyMinHeight="150" overflowBody="hidden" :startHeight="20" 
            :settings="floatingPanels('COLLISION_ELEMENT_TREE')")
            axis-section-rules-treeview( @edit:rule="onEditRule" :resizeTree="true" )

          app-floating-panel( :title="$t('section.collision.tree')" bodyMinHeight="150" overflowBody="hidden" :startHeight="80" 
            :settings="floatingPanels('COLLISION_COLLISION_TREE')")
            div.axis-tree-section
              axis-section( @menu="onAxisMenu" @addingaxisgroup="showAddingAxisDialog()")
              axis-section-treeview( v-if='!isAxisGroupsSelected' show-counter )

          app-floating-panel( v-if='pickedElement' :title="$t('section.element', {name: pickedElement.name})" 
            bodyMinHeight="150" overflowBody="hidden" :startHeight="25" :settings="floatingPanels('COLLISION_ELEMENT_DETAILS')" )
            element-details( :element='pickedElement' )


      v-tab-item.back-color 
        app-floating-panels-section( style="height: calc(100% - 26px) !important; overflow: auto; overflow-x: hidden" )
          app-floating-panel( :title="$t('section.elementTree.axis.ruleTree')" bodyMinHeight="150" overflowBody="hidden" :startHeight="20" 
              :settings="floatingPanels('ATTRIBUTE_RULES_TREE')")
            attribute-rules(ref="attibuteRules" @edit:rule="onEditRule" )

          app-floating-panel( v-if='selectedAttribute' :title="$t('section.element', {name: selectedAttribute.name})" 
              bodyMinHeight="150" overflowBody="hidden" :startHeight="25" :settings="floatingPanels('ATTRIBUTE_RULES_ELEMENT')" )
            element-details( :element='selectedAttribute' )

    app-menu( ref="axisMenu" :menu="axisMenu" )
    axis-grouping-dialog( v-model="dialog.editingAxisData" :group.sync="axisDataInWIP" @edited="onEditingAxisData" )
    app-dialog-confirm( v-if="axisDataInWIP" v-model="dialog.deletingAxisData" delete @confirm="onDeletingAxisData") 
      app-text {{ $t('section.elementTree.axis.deleteRule', {rule: axisDataInWIP.title}) }}

    app-dialog-confirm( v-if="dialog.calculateAll" v-model="dialog.calculateAll" @confirm="recalculateAllCol()") 
      app-text {{ $t('section.elementTree.axis.recalculateAll') }}
    
    app-dialog-loading(:show="isLoading")

    app-dialog( v-model="dialog.showStatistic" :header="$t('section.collision.panelMenu.statistic')" width="90%" @close="stat = null")
      app-section(v-if="!stat")
        v-progress-linear(color='accent' indeterminate)
      collision-statistic(v-else :stat="stat")

</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import { useAttributeCheckingTableStore } from '@/pinia'
import { mapStores } from 'pinia'
import { AxisDataType } from '@models/axis'
import { api } from "@/api"
import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator"

import AxisGroupingDialog from './components/element/Grouping'
import AxisSection from './components/axisSection/AxisSection'
import AxisSectionTreeview from './components/axisSection/AxisSectionTreeview'
import AxisSectionRules from './components/axisSection/AxisSectionRules'
import CollisionGroupsSection from './components/collisionGroups/CollisionGroupsSection'
import RulesFounder from "./components/rulesFounder/RulesFounder"
import CollisionStatistic from "./components/collision/CollisionStatistic"
import AxisSectionRulesTreeview from "./components/axisSection/AxisSectionRulesTreeview"
import ElementDetails from '@/components/project/panel/left/components/element/Details'
import AttributeRules from './components/attributeChecking/AttributeRules.vue'
import { PanelController } from '@/pages/panelController'

export default {
  components: {
    AxisSection, 
    AxisSectionTreeview, 
    AxisSectionRules,
    AxisGroupingDialog,
    AxisSectionRulesTreeview,
    CollisionGroupsSection,
    CollisionStatistic,
    RulesFounder,
    ElementDetails,
    AttributeRules
  },

  data: () => ({
    stat: null,
    ruleTabLoaded: false,
    axisDataInWIP: null,
    isLoading: false,
    dialog: {
      editingAxisData: false,
      deletingAxisData: false,
      collisionRules: false,
      showStatistic: false,
      calculateAll: false,
    },
  }),

  computed: {
    ...mapGetters('axis/tree', ['element']),
    ...mapGetters('projectPermissions', ['hasCollisionCalc', 'hasCollisionView', 'hasCollisionCreateRule', 'hasCollisionEmportImport']),
    ...mapGetters('collision/search', ['selectedAxisNodes', 'collisionSelectedElement']),
    ...mapState('collision/search', ['collisionSelectedGlobalIds', 'filter']),
    ...mapState('project', ['project', 'topUnit']),
    ...mapGetters('axis', ['selectedAxis']),
    ...mapGetters('axis/tree', ['selectedNodes']),
    ...mapGetters('layoutControler', ['panelWidth']),
    ...mapState('floatingPanels', ['floatingPanelsKeys']),
    ...mapGetters('floatingPanels', ['floatingPanels']),
    ...mapState('task', ['taskDisplay']),
    ...mapState('collision', ['tab', 'prevTab', 'width']),
    ...mapGetters("theme", ['themeName']),
    ...mapStores(useAttributeCheckingTableStore),

    
    startSize() {
      let width = `50vw !important`
      let height = `100%`
      return `width: ${width}; height: ${height};`
    },

    axisMenu () {
      return [
        { title: this.$t('button.edit'), action: this.showEditingAxisDialog },
        { title: this.$t('button.delete'), action: this.showDeletingAxisDialog },
      ]
    },

    collisionMenu(){
      if(this.tab == 0)
        return this.ruleTabLoaded ? this.$refs?.rulesFounder?.panelMenu : []
      else
        return this.panelMenu
    },

    panelMenu () {
      let list = [ 
        // { title: this.$t('section.collision.panelMenu.addGroupping'), action: this.showAddingAxisDialog }, 
        { title: this.$t('section.collision.panelMenu.statistic'), action: this.showStatistic },
      ]

      if (this.hasCollisionCalc) {
        list.push( { title: this.$t('section.collision.panelMenu.recalculate'), action: this.recalculateDialog } )
      }
      return list
    },

    isAxisGroupsSelected() {
      return this.selectedAxis.value === 5
    },

    pickedElement() {
      return this.collisionSelectedElement || this.element
    },

    selectedAttribute() {
      return this.attributeTableStore.selectedElement || this.element
    },


  },

  mounted () {
    this.$el.setAttribute('style', this.startSize)
    this.$store.dispatch('rule/init')
    this.$store.dispatch('axis/init', AxisDataType.COLLISIONS)
    this.LOAD_PLUGINS(this.project.uuid)
    this.changeWidth()
    if(this.element) this.setChoosenGlobalId(this.element.uuid)
  },

  watch: {
    tab: 'changeWidth'
  },

  beforeDestroy () {
    if(this.element) this.setChoosenGlobalId(this.element.uuid)
    this.setPrevTab(null)
    // this.setWidth({id: 'collision', width: window.innerWidth / 4 - 45})
    XeokitMediator.ElementsSelection.selectElements([])
    this.selectRule(null)
  },

  methods: {
    ...mapActions('axis', ['loadAxisDataList', 'deleteAxis']),
    ...mapActions('rule', ['recalculateAll']),
    ...mapActions('rule/details', ['selectRule']),
    ...mapActions('collision/search', ['setChoosenGlobalId']),
    ...mapActions('layoutControler', ['setWidth']),
    ...mapActions('plugins', ['LOAD_PLUGINS']),

    ...mapMutations('project', ['setActiveGlobalTab','setAttributeChecking']),
    ...mapMutations('layoutControler', ['activateDoublePanel']),
    ...mapMutations('collision', ['setTab', 'setPrevTab', 'setTreeWidth', 'setRulesWidth', 'setAttrWidth']),

    recalculateAllCol() {
      this.isLoading = true
      this.recalculateAll().then(() => {
        this.isLoading = false
      })
    },
    // TODO: Временное решение (возможно) до тех пор, пока не будет
    // проведен рефактор AppPanel
    changeWidth() {
      // this.setAttributeChecking(false)
      this.setAttributeChecking(this.tab === 2)
      let width = this.panelWidth('collision')
      let winWidth = window.innerWidth / 4

      if (width < winWidth){
        width = winWidth
      }

      this.updateWidth(this.prevTab, width, true)
      this.updateWidth(this.tab, null, false)
    },

    updateWidth(tab, width, isPrev) {
      let newWidth
      switch(tab) {
        case 0:
          if (isPrev) this.setRulesWidth(width)
          else newWidth = this.width.rules
          break
        case 1:
          if (isPrev) this.setTreeWidth(width)
          else newWidth = this.width.tree
          break
        case 2:
          if (isPrev) this.setAttrWidth(width)
          else newWidth = this.width.atributes
          break
        default:
          return
      }

      if (newWidth) this.setWidth({ id: 'collision', width: newWidth })
    },

    recalculateDialog() {
      this.dialog.calculateAll = true
    },

    handleClose(){
      this.setActiveGlobalTab('')
    },

    showStatistic () {
      this.dialog.showStatistic = true
      api.collisions.getStatistic(this.project.uuid).then( data => {
        this.stat = data
      })
    },

    showAddingAxisDialog () {
      this.axisDataInWIP = null
      this.dialog.editingAxisData = true
    },

    showEditingAxisDialog () {
      this.dialog.editingAxisData = true
    },

    showDeletingAxisDialog () {
      this.dialog.deletingAxisData = true
    },

    onAxisMenu (item, event) {
      this.axisDataInWIP = item.data
      this.$refs.axisMenu.show(event)
    },

    onEditingAxisData(uuid) {
      this.loadAxisDataList(uuid)
      this.dialog.editingAxisData = false
    },

    onDeletingAxisData() {
      this.deleteAxis(this.axisDataInWIP)
      this.dialog.deletingAxisData = false
    },

    onEditRule (rule) {
      this.updateTab(0)
      this.selectRule(rule)
    },

    updateTab(val) {
      this.setPrevTab(this.tab)
      this.setTab(val)
      setTimeout(() => PanelController.setPanelPadding(true))
    }
  },
}
</script>

<style>
.axis-tree-section {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
<style scoped>
.back-color {
  height: 100%; 
  background-color:var(--v-surface-base);
}

.min-width-25 {
  min-width: 25vw !important;
  max-width: 80vw !important;
}

.v-tab {
  opacity:0.5;
  /* color:#fff; */
  text-transform: none;
  color: var(--v-primary-darken2) !important;
} 
.v-tab--active {
  opacity:1;
}

</style>