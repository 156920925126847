// import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator";
import { ImageCropper } from './ImageCropper'
import '@/plugins/viewerTools/tools/selectionFrame/highlightFrameStyle.scss'

/*eslint-disable no-dupe-class-members*/
class DrawingsHighlightFrame {

  static #_highlightRectangle = {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0
  }
  static #_isMouseDown = false
  static #_divElement = null

  static #_mouseDownListener = () => {}
  static #_mouseMoveListener = () => {}
  static #_mouseUpListener = () => {}
  static #_scale = 1

  static activate(drawingElement, originalSize) {
    let imgHtml = drawingElement.getElementsByTagName('img')[0]
    
    ImageCropper.setImageSrc(imgHtml.src)

    let currentHeight = imgHtml.clientHeight
    this.#_scale = originalSize.height / currentHeight
    
    this.#_divElement = document.createElement('div')
    this.#_divElement.classList.add('drawing_highlight-frame') // Блокирует рамку выделения на сцене
    const divContainer = drawingElement // Вставить NewDrawingsPage контейнер
    divContainer.appendChild(this.#_divElement)

    this.#_onElementListeners(drawingElement)
  }

  static #_onElementListeners(element) {

    let cropX1 = 0 
    let cropY1 = 0 
    let cropX2 = 0
    let cropY2 = 0

    let left1 = 0
    let top1 = 0
    let left2 = 0
    let top2 = 0

    element.addEventListener("mousedown", this.#_mouseDownListener = (e) => {
      this.#_isMouseDown = true

      cropX1 = e.offsetX * this.#_scale
      cropY1 = e.offsetY * this.#_scale

      cropY2 = cropY1
      cropX2 = cropX1

      left1 = e.clientX
      top1 = e.clientY

      this.#_highlightRectangle.left = left1
      this.#_highlightRectangle.top = top1

    })

    element.addEventListener("mousemove", this.#_mouseMoveListener = (e) => {
      if (this.#_isMouseDown) {

        cropX2 = e.offsetX * this.#_scale
        cropY2 = e.offsetY * this.#_scale

        left2 = e.clientX
        top2 = e.clientY

        if (cropX1 > cropX2) {
          this.#_highlightRectangle.left = left2
          this.#_highlightRectangle.right = left1
        }
        else {
          this.#_highlightRectangle.right = left2
          this.#_highlightRectangle.left = left1
        }
        if (cropY1 > cropY2) {
          this.#_highlightRectangle.top = top2
          this.#_highlightRectangle.bottom = top1
        }
        else {
          this.#_highlightRectangle.bottom = top2
          this.#_highlightRectangle.top = top1
        }

        this.#_showHighlightRectangle(this.#_highlightRectangle)
      }
      
    })

    element.addEventListener("mouseup", this.#_mouseUpListener = () => {
      this.#_isMouseDown = false
      this.#_hideSelectionRectangle()
      let imgHtml = element.getElementsByTagName('img')[0]
      ImageCropper.cropImage(cropX1, cropY1, cropX2, cropY2, imgHtml)
    })
  }

  static #_getSelectionRectNode() {
    return document.querySelector(".drawing_highlight-frame")
  }

  static #_showHighlightRectangle(selection) {
    let rect = this.#_getSelectionRectNode()
    rect.style.left = `${selection.left}px`
    rect.style.top = `${selection.top + window.scrollY}px`
    rect.style.width = `${selection.right - selection.left}px`
    rect.style.height = `${selection.bottom - selection.top}px`
    rect.style.opacity = 0.5
  }

  static #_hideSelectionRectangle() {
    this._endCanvasPos = null
    this._startCanvasPos = null
    this._endCanvasPosOrigin = null
    this._startCanvasPosOrigin = null

    this._selectionRectangle = {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }
    this.#_showHighlightRectangle(this._selectionRectangle)

    let rect = this.#_getSelectionRectNode()
    rect.style.opacity = 0
  }

  // TODO УБРАТЬ СЛУШАТЕЛИ ПРИ ВЫХОДЕ ИЗ ПРОЕКТА, желательно вообще все возможные про проекту
  static #_removeMouseListeners(element) {
    element.removeEventListener("mousedown", this.#_mouseDownListener)
    element.removeEventListener("mousemove", this.#_mouseMoveListener)
    element.removeEventListener("mouseup", this.#_mouseUpListener)
  }

  static deactivate(element) {
    this.#_removeMouseListeners(element)
    this.#_divElement?.remove()
  }
}

export { DrawingsHighlightFrame }

