<template lang="pug">
app-dialog.group-dialog( v-model="$_show" width="900" complex header="Группировки" ) 
  v-row( no-gutters )
    v-col(cols=8)
        app-section-external-header {{ $t('section.elementTree.selectionConditions') }}
    v-col(cols=4)
        app-section-external-header {{ $t('section.elementTree.groupingOrder') }}
  
  v-row(no-gutters)
    v-col(cols=8)
      logic-group(:logicGroup="form.logicGroup" ref="logicGroupComp" :groupUuid="form.uuid") 

    v-col(cols=4 style="padding-left: 1px;")
        app-section.h-100.base-scroll-body( :rounded="false" )
          v-form(ref="addGroupForm" v-model="valid" @submit.prevent="submit")
            app-text-field( v-model="form.title" :label="$t('section.elementTree.filterName')" :rules="[rules.required]" hide-details test-id="filter-name-input" )

            v-checkbox.my-2.ml-2( v-model="form.showchild" dense hide-details light ) 
              template(v-slot:label)
                app-text.mx-n1 {{ $t('section.elementTree.showchild')}}

            //- v-checkbox.my-2.ml-2( v-model="form.colorize" dense hide-details light test-id="colorize-element-check") 
            //-   template(v-slot:label)
            //-     app-text.mx-n1 {{ $t('section.elementTree.colorize')}}

            //- color-picker.mb-3(v-if="form.colorize == true" v-model="form.color" )

            draggable(v-model="form.axis")
              v-row(no-gutters v-for="(a, index) in form.axis" :key="index") 
                v-col.position-relative
                  v-hover( v-slot:default="{ hover }" close-delay="150" )
                    .content.mb-2.px-2.py-1.rounded {{a.axis.title}} 
                      span(v-if="a.title") {{a.title}}
                      v-icon.unit-action( v-if="hover" color="grey" @click.stop="removeAxis(index)" ) clear

                v-col.text-right.ml-2.pt-1(cols="auto")
                  v-icon.unit-drag( color="grey" ) list
          v-row.ma-0
            app-icon-button( v-if="isAxisEnumLoaded" icon="$add-box-outlined" light @click="showAddAxis" )

        app-dialog( v-model="dialog.addAxis" :header="$t('section.elementTree.addCriterion')" :items="axisItems" ) 

  v-row(no-gutters)
    v-col.btn.pl-2(cols=12)
      app-button.mt-3.mb-2.mr-2( action @click="createGroup" :disabled="!valid" test-id="grouping-save" ) {{ $t('button.save') }}

  select-attribute(v-model="dialog.selectAttribute" @selected="afterSelectAttr") 
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable'
import { mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import { $_validate_rules } from '@/common/ValidateRules'
import LogicGroup from '../logicGroup/LogicGroup.vue'
import SelectAttribute from './SelectAttribute.vue'
import ColorPicker from './ColorPicker.vue'

export default {
  name: 'Grouping',
  mixins: [DialogToggable, $_validate_rules],
  components: { draggable, LogicGroup, SelectAttribute, ColorPicker },
  props: {
    group: null,
  },
  data () {
    const defaultForm = Object.freeze({
      uuid: null,
      title: '', 
      color: '', 
      axis: [], 
      logicGroup: {} 
    })

    return {
      valid: false,
      dialog: {
        selectAttribute: false,
        addAxis: false,
      },
      form: Object.assign({}, defaultForm),
      defaultForm
    }
  },
  watch: {
    $_show (value) {
      if (value) {
        let baseObj = this.group || this.defaultForm
        this.form = JSON.parse(JSON.stringify(baseObj))
        if(!this.form.color || this.form.color == '' ) this.form.color = '#0000FF'
        this.addDefaultAxis()
      }
    },
  },
  computed: {
    ...mapGetters('project', ['axisEnum', 'isAxisEnumLoaded', 'myAttr', 'ismyAttrLoaded', 'logicOperator', 'isLogicOperatorLoaded']),
    groupAxisEnum() {
      return this.axisEnum.filter(a => a.group==1)
    },
    groupingName() {
      return this.group!=null ? this.$t('section.elementTree.createNewGrouping') : this.$t('section.elementTree.createNewGrouping')
    },
    axisItems() {
      let ai = []
      this.groupAxisEnum.forEach(a => {
        if(!this.form.axis.find(ex => ex.axis.name==a.name) || a.name === "ATTRIBUTE") 
          ai.push({ "title": a.title, "action": () => this.addAxis(a.name) })
      })
      this.myAttr.forEach(a => {
        ai.push({ "title": this.$t('section.elementTree.attribute', {name: a.title}), "action": () => this.afterSelectAttr(a) })
      })
      return ai
    },
  },
  methods: {
    ...mapActions('axis', ['setSelectedAxisGroupGroups']),

    removeAxis(index) {
      this.form.axis.splice(index, 1)
    },
    showAddAxis () {
      this.dialog.addAxis = true
    },
    addAxis (aName) {
      if(aName == "ATTRIBUTE")
         this.dialog.selectAttribute = true
      else
         this.form.axis.push({"axis":this.groupAxisEnum.find(a => a.name === aName)})
    },
    addDefaultAxis () {
      if(this.form.uuid == null) 
         this.form.axis.push({"axis":this.groupAxisEnum.find(a => a.name === "MODEL")}, 
                             {"axis":this.groupAxisEnum.find(a => a.name === "FLOOR")}, 
                             {"axis":this.groupAxisEnum.find(a => a.name === "CLASS")})
    },
    afterSelectAttr(attr) {
      this.dialog.selectAttribute = false
      this.form.axis.push({"axis":this.groupAxisEnum.find(a => a.name === "ATTRIBUTE"), "attr":attr.uuid, "title":attr.title})
      this.$store.dispatch('project/addMyAttr', {"title":attr.title, "uuid":attr.uuid})
    },
    submit(){
      this.$refs.addGroupForm.validate()
    },
    createGroup() {
      let newLogicGroup = this.$refs.logicGroupComp.makeLogicGroup()
      if (this.$refs.addGroupForm.validate() && newLogicGroup!=null) {
        this.form.logicGroup = newLogicGroup
        this.$store.dispatch('axis/saveAxisData', this.form).then((data) => {
          this.$emit('edited', data.uuid)
          this.setSelectedAxisGroupGroups()
        })
      } else 
        this.setSelectedAxisGroupGroups()
    },
  },
}

</script>

<style scoped>
.unit-drag {
  cursor: pointer;
}
.position-relative {
  position: relative;
}
.unit-action {
  position: absolute;
  right: 4px;
  top: 4px;
}
.h-100 {
  max-height: 500px !important;
  min-height: 350px !important;
  height: 350px;
}
.group-dialog{
  overflow: hidden !important;
}
.btn{
  display: flex;
  justify-content: flex-end;
  background-color: white;
  margin-top: 1px;
}
.content{
  background-color: #f0f0f0;
  color: var(--v-primary-base);
}
</style >
