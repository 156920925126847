<template lang="pug">
div
  app-dialog( v-model="visible" complex width="50%" :header="$t('plugin.uploadPluginTemplate')" @close="selectedPlugin = null" )
    .d-flex
      app-section.base-scroll-body.max-h400( style="width: 50%; height: 60vh;" :rounded="false" )
        .plugin-template-dialog_header-text {{ $t('plugin.pluginTemplate.list') }}:
        v-progress-linear( v-if="listLoading" indeterminate color="accent" )
        v-treeview.__project_view_tree( v-else dense light hoverable return-object item-key="name" :items="pluginsTemplate" )
          template( v-slot:label="{ item }" )
            .pointer( @click="selectPlugin(item)" ) {{ item.name }}

      app-section.max-h400( style="width: 50%; height: 60vh; margin-left: 1px;" :rounded="false" )
        .plugin-template-dialog_header-text.pb-1 {{ $t('plugin.pluginTemplate.infoHeader') }}:
        div.ml-2( v-if="selectedPlugin" )
          app-text
            div( v-html='$t("plugin.pluginTemplate.name", { val: selectedPlugin.name })' ) 
            div( v-html='$t("plugin.pluginTemplate.description", { val: selectedPlugin.description })' ) 
            div( v-html='$t("plugin.pluginTemplate.type", { val: $t("plugin.pluginType." + selectedPlugin.pluginType) })' ) 
        app-button.mt-2.ml-2( v-if="selectedPlugin" color="accent" @click="importPlugin()" outlined small ) {{ $t("plugin.pluginTemplate.importBtn") }}
        app-button.mt-2.ml-2( v-if="selectedPlugin && isAdmin" color="red" @click="deleteConfirm = true" outlined small ) {{ $t("plugin.pluginTemplate.deleteBtn") }}

  app-dialog-confirm(v-model="deleteConfirm" @confirm="deletePlugin()")
    app-text(v-if="selectedPlugin") {{ $t('plugin.pluginTemplate.delete', { val: selectedPlugin.name }) }}
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { pluginService } from '@/_services'

export default {
  data() {
    return {
      visible: false,

      deleteConfirm: false,

      selectedPlugin: null,
      listLoading: false,
    }
  }, 

  computed: {
    ...mapState('plugins', ['pluginsTemplate']),
    ...mapGetters('project', ['projectUuid']),

    isAdmin() {
      return localStorage.getItem("roles") === "ADMIN"
    },
  },

  methods: {
    ...mapActions('plugins', ['importPluginTemplate', 'getMethodsByPluginType', 'getPluginsTemplate']),
    ...mapMutations('plugins', ['setLoadedPlugin','setLoadedPluginCode']),
    ...mapActions('template', ['initPlugin']),
    ...mapActions('forms', ['loadFormForPlugin']),

    show() {
      this.visible = true
      this.loadPluginTemplateList()
    }, 

    loadPluginTemplateList() {
      this.listLoading = true
      this.getPluginsTemplate().then(() => this.listLoading = false)
    },

    selectPlugin(item) {
      this.selectedPlugin = item
    },

    reloadTemplateAndForm() {
      this.initPlugin(this.projectUuid)
      this.loadFormForPlugin(this.projectUuid)
    },

    deletePlugin() {
      pluginService.deletePluginTemplate(this.selectedPlugin.fileName).then(() => {
        this.deleteConfirm = false
        this.selectedPlugin = null
        this.loadPluginTemplateList()
      })
    },

    importPlugin() {
      this.importPluginTemplate(this.selectedPlugin.fileName).then(plugin => {
        this.selectedPlugin = null

        this.setLoadedPlugin(null)
        this.setLoadedPluginCode(null)

        this.reloadTemplateAndForm()
        pluginService.load(plugin.uuid).then(data => {
          this.setLoadedPlugin(data)
          this.getMethodsByPluginType(plugin.type.name)
          this.visible = false
          this.$emit('loadPlugin')
        })
      })
    },
  }
}
</script>

<style scoped>
.plugin-template-dialog_header-text {
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  color: #303030;
}
</style>