<template lang="pug">
  app-dialog(v-model="$_show" withScrollPanel complex width="80%" :header="$t('toolbarMenu.importStructure.importProject')" whiteSpace=true ref="dialog")
    v-row(no-gutters)
      v-col( cols=4)
        .header-column.d-flex.flex-column.pl-2.pr-2.header-column
          app-text.text-subtitle-2.mb-2.text-md-center.wrap-text {{ $t('toolbarMenu.importStructure.structureFolder') }}
          .divider
        app-section.col-section.base-scroll-body( :rounded="false" style="height: 392px;")
          v-treeview.__project_view_tree(
            ref="tree"
            v-model="selection"
            :items="dirStruct"
            item-key="nameModel" item-text="nameModel" item-children="projectStructureDTO" color="accent" 
            light dense activatable selectable open-on-click return-object
            :expand-icon="''"  
            @update:open="nodesOpen"
          )
            template(v-slot:prepend="{ item, open }")
                v-icon(v-if="!item.isFile")
                  | {{ open ? 'mdi-folder-open' : 'mdi-folder' }}

          app-button-add.mt-3.ml-1.mr-1(width="100%" :text="$t('button.add')" @click="getDirectoryStructure")                 
      v-col(cols=4)
        .header-column.d-flex.flex-column.pl-2.pr-2
          app-text.text-subtitle-2.mb-2.text-md-center.wrap-text {{ $t('toolbarMenu.importStructure.transformationRule') }}
          .divider
        app-section.col-section.base-scroll-body(:rounded="false" style="height: 392px;")
          v-row.d-flex(no-gutters v-for="(rule, index) in transformationRules")
            app-section.d-flex(style="height: 100%; width: 100%; padding: 0px; " :rounded="false")
              v-col(cols=5 style="padding: 3px;" )
                app-select(v-model="rule.title" hide-details :items="transformationRuleTypes" item-text='message' outlined @change="saveType(index, rule)")
              v-col(cols=5 style="padding: 3px;" )
                app-text-field(v-model="rule.condition" hide-details @change="saveCondition(index, rule.condition)")
              v-col.d-flex.justify-center.pr-1(cols=1)
                v-icon(tag="button" :color="checkboxColor(rule)" light @click="isSave(rule)") mdi mdi-check-circle-outline
              v-col.d-flex.justify-center(cols=1 )
                v-icon(tag="button" color='var(--v-surface-lighten1)' light @click="onRuleMenu($event, rule)") mdi mdi-dots-vertical
          app-menu(ref="ruleMenu" :menu="ruleMenu" )
          v-icon(tag="button" color="#3B93AF" light @click='addTransformationRule') mdi mdi-plus-circle-outline
      v-col(cols=4)
        .header-column.d-flex.flex-column.pl-2.pr-2.header-column
          app-text.text-subtitle-2.mb-2.text-md-center.wrap-text {{ $t('toolbarMenu.importStructure.formedStructure') }}
          .divider
        app-section.col-section.base-scroll-body.pb-2( :rounded="false" style="height: 392px;" )
          tool-tree-draggable-import-structure(:items="cloneProjectModel")
          tool-tree-draggable-import-structure(:items="previewStruct")
    v-row(no-gutters )
      v-col.btn(cols=12 style='border-top: 1px solid #0000001F;') 
        app-button.mt-3.mb-2.mr-2(cancel @click="cancleImport") {{ $t('button.cancel')}}
        app-button.mt-3.mb-2.mr-2( action @click="importStructure") {{ $t('button.save') }}
   
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable'
import ToolTreeDraggableImportStructure from "@/components/project/common/ToolTreeDraggableImportStructure.vue"

import { mapGetters, mapState, mapActions } from 'vuex'
import { api } from "@/api"

export default {
  name: 'ImportProjectStructure',
  mixins: [DialogToggable],

  components: {
    ToolTreeDraggableImportStructure
  },

  data () {
    return {
      dirStruct: [],
      dialog: {
        createProjectStruct: false
      },
      transformationRules: [],
      selection: [],
      selectionWithParents: [],
      newStruct: [],
      previewStruct: [],
      cloneProjectModel: [],
      currentRule: null

    }
  },

  computed: {
    ...mapState('project', ['project']),
    ...mapGetters('project', ['projectModel']),

    ruleMenu () {
      return [
        { title: this.$t('button.delete'), action: this.deleteRule},
      ]
    },

    transformationRuleTypes () {
      return [
        this.$t('importStructure.transformationRule.type.separatorCharacter'),
        this.$t('importStructure.transformationRule.type.length')
      ]
    },

  },

  watch: {
    $_show(val) {
      const deepCopy = (tree)=> {
        const array = []
        for (const node of tree) {
          let clone = {
            title: node.title,
            model: [],
            opened: false
          }
          if (node.model && node.model.length > 0) clone.model = deepCopy(node.model)
          array.push(clone) 
        }

        return array
      }

      if (val) this.cloneProjectModel= deepCopy(this.projectModel)

    },

    selection() {
      let _selectedNodes = []
      let _treenodes = this.$refs['tree'].nodes

      for (const key in _treenodes) {
        if (Object.hasOwnProperty.call(_treenodes, key)) {
          const node = _treenodes[key]
          if (node.isSelected || node.isIndeterminate) {
            node.item.selected = true
            _selectedNodes.push(node.item)
          } 
        }
      }
  
      this.selectionWithParents = _selectedNodes;
      this.newStruct = this.createNewStruct(this.dirStruct)
      this.requestPreviewProjectStructure()


    },
  },

  methods: {
    ...mapActions('project', ['loadProject']),

    requestPreviewProjectStructure () {
      let projectStructurePreviewRequestDTO = {
        previewProjectStructureDTO: this.newStruct,
        projectStructureRulesDTO: this.transformationRules
      }

      api.projects.projectStructurePreviewRequestDTO(projectStructurePreviewRequestDTO, this.project.uuid).then(data =>{
        this.previewStruct = data.previewProjectStructureDTO
      }) 
    },

    nodesOpen (nodes) {
      nodes.forEach(node => {
        node.opened = true
      });

    },

    createNewStruct (tree) {
      const array = []
      for (const node of tree) {
        if (node.selected) {
          let one = { ...node }
          array.push(one) 
          if (one.projectStructureDTO && one.projectStructureDTO.length > 0) one.projectStructureDTO = this.createNewStruct(one.projectStructureDTO)
          node.selected = false
        }
      }

      return array
    },

    checkboxColor(rule) {
      return rule.isSave? '#81B635': '#B8B8B8'
    },

    isSave (rule) {
      rule.isSave= rule.isSave? false: true
      this.requestPreviewProjectStructure()
    },

    onRuleMenu (event, rule) {
      this.currentRule = rule
      this.$refs.ruleMenu.show(event)
    },

    deleteRule () {
      this.transformationRules.splice(this.currentRule.index, 1)
      this.requestPreviewProjectStructure()
    },

    saveCondition(index, condition) {
      this.transformationRules[index].condition = condition
      this.requestPreviewProjectStructure()

    },

    saveType(index,rule) {
      this.transformationRules[index].ruleType = rule.ruleType == 'SYMBOL'? 'LENGTH': 'SYMBOL'
      this.requestPreviewProjectStructure()
    },

    addTransformationRule(){
      let rule = {
        title: this.$t('importStructure.transformationRule.type.separatorCharacter'),
        ruleType: 'SYMBOL',
        isSave: false,
        condition: '',
        projectUuid: this.project.uuid,
        index: this.transformationRules.length
      }

      this.transformationRules.push(rule)
    },
    
    async getDirectoryStructure() {
        const dirHandle = await window.showDirectoryPicker();
        const structure = await this.readDirectory(dirHandle);
        this.updateProjectStructure(structure);
    },

    async readDirectory(dirHandle, level = 0) {
      const dir = [];
      for await (const entry of dirHandle.values()) {
        const commonProps = {
          nameModel: entry.name,
          projectStructureDTO: [],
          ifcFile: null,
          getIfcFile: null,
          selected: false,
          opened: false,
          isFile: false,
        };
        if (entry.kind === 'directory') {
          commonProps.projectStructureDTO = await this.readDirectory(entry, level + 1),
          dir.push({...commonProps})
        } else if (entry.kind === 'file') {
          commonProps.ifcFile = await entry.getFile(),
          commonProps.isFile = true,
          dir.push({...commonProps})

        }
      }

      return dir;
    },

    updateProjectStructure(structure) {
      this.dirStruct = structure
    },

    importStructure() {
      this.request(this.previewStruct, this.newStruct)
      api.projects.importProjectStructureRule(this.transformationRules, this.project.uuid).then(() => {
        this.loadProject(this.project.uuid)
      })
      this.$_show = false
      
    },

    request(previewStruct, newStruct, parentUuid = null) {
      return previewStruct.reduce((promiseChain, previewEntry, i) => {
        return promiseChain.then(() => {
          const newEntry = newStruct[i];
          let formData = new FormData();
          formData.append("nameModel", previewEntry.nameModel);
          formData.append("parentUuid", parentUuid);
          formData.append("file", newEntry.ifcFile);
          return api.projects.projectStructureLoadRequestDTO(formData, this.project.uuid)
            .then(data => {
              if (previewEntry.projectStructureDTO && previewEntry.projectStructureDTO.length > 0) {
                return this.request(previewEntry.projectStructureDTO, newEntry.projectStructureDTO, data.ParentUuid);
              }
            });
        });
      }, Promise.resolve())
      .catch(error => {
        console.error("Ошибка при загрузке структуры проекта:", error);
      });
},

    cancleImport() {
      this.$_show = false
    },
  }
}
</script>

<style lang="scss" scoped>
.btn{
  display: flex;
  justify-content: flex-end;
  background-color: white;
}

.col-section{
  border-right: 1px solid #0000001F;
  height: 100%;
  width: 100%;
}

.divider {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: 1px;
  background: #4d4d4d;
}

.wrap-text {
  white-space: nowrap; 
  text-overflow: ellipsis; 
  overflow: hidden;
}

.header-column {
  background-color: white; 
  height: 36px; 
  padding-top: 11px;
}
</style>

<style lang="sass" >
.__project_view_tree.v-treeview
  .v-treeview-node__toggle
    width: 8px
  .v-treeview-node__level
    width: 8px
  .v-icon
    font-size: 16px
  .v-treeview-node__content
    padding-right: 20px
  .v-treeview-node__checkbox 
    position: absolute
    right: 2px
</style>
