<template lang="pug">
div.mx-1
  v-hover.attachment-container( #default="{ hover }" )
    .t-attach.app-cursor--pointer.mt-2.ml-1.relative( 
      :class="{ 'elevation-4': hover, 'isactive' : isCurrentAttach }"
      @click="selectAttachment"
    )

      v-img.attachment-img( v-if="isAttachHaveScreenShoot(attach)" :src="filePath(attach)" :width="247" :height="126" )
        task-button-action.drawing-attachment-header-action(icon="project/tools/3d.svg" size=24 )
      
      v-img.attachment-img( v-else-if="isAttachHaveImage(attach)" :src="filePath(attach)" :width="247" :height="126" ).custom-attach-brd
        div.icon-box.gray-bg.d-flex.justify-center.align-center.drawing-attachment-header-action
          task-button-action.drawing-attachment-header-action(icon="project/tools/attach_file_boxed.svg" size=24 )
      
      .scroll-hidden(v-else-if='isAttachHaveDrawing(attach)' ref='drawingAttachmentWindow').custom-attach-brd
        img.attachment-img(ref='drawingAttachmentPreview' :width="247" :height="126")
        task-button-action.drawing-attachment-header-action(icon="project/tools/2d.svg" size=24)
      
      div( v-else )
        div( v-if='widthComment' )
          v-img.attachment-img(:src="`/img/project/tools/custom_file_bg_`+randomImage+`.svg`" :width="247" :height="126" ).custom-attach-bg.custom-attach-brd
          app-text.pl-8.file-text.pt-2.drawing-attachment-header-action.custom-attach-bg.custom-attach-brd-panel {{ attach.name || $t('module.task.noName') }}
          div.icon-box.d-flex.justify-center.align-center.drawing-attachment-header-action
            task-button-action.drawing-attachment-header-action(icon="project/tools/attach_file_gray.svg" size=24 )

        div.attach-meta.d-flex.custom-attach-bg.custom-attach-brd( v-else )
          div.icon-box-img.d-flex.justify-center.align-center
            v-img(:src="`/img/project/tools/attach_file_gray.svg`" size=24  )
          app-text.pl-2.file-text.mt-2 {{ attach.name || $t('module.task.noName') }}

      v-tooltip( bottom )
        template( v-slot:activator="{ on, attrs }" )
          div.attachment-menu-body( v-on="on" :class="{ 'blackout-img': hover }" )
            .d-flex.img-buttons-flex( v-if="$app.device.isPortable ? true : hover" )

              v-tooltip( v-if="showLocationBtn && !isTaskManager" bottom )
                template( v-slot:activator="{ on, attrs }" )
                  task-button-action.img-button.ma-1( v-on="on" icon="project/tools/my_location.svg" @click.stop="$emit('location')")
                span {{ $t('module.task.goCoords') }} 
                span( v-if="is3DAttach" ) (3D)

              v-tooltip( bottom )
                template( v-slot:activator="{ on, attrs }" )
                    task-button-action.img-button.ma-1( v-on="on" icon="project/tools/cloud_download.svg" test-id="attachment-download" @click.stop="attachmentDownload()")
                span {{ $t('button.save') }}

              v-tooltip(v-if="hasTaskDeleteCommentAttach" bottom )
                template( v-slot:activator="{ on, attrs }" )
                    task-button-action.img-button.ma-1( v-on="on" icon="project/tools/delete_black.svg" test-id="attachment-delete" @click.stop="confirmDeleteAttach()" )
                span {{ $t('module.task.delAttachment') }}
              v-tooltip( v-if="attach.contentType != 'application/pdf'" bottom )
                template( v-slot:activator="{ on, attrs }" )
                  task-button-action.img-button.ma-1( v-on="on" icon="project/tools/eye.svg" @click.stop="showImage()")
                span {{ $t('module.task.openAttachment') }} 
        div.attachment-tooltip
          span(v-html="attachmentInfo()")
  image-dialog( style="position:relative" v-model="showFullImage"  withScrollPanel width="auto")
    v-img(:src="imagePath")
        
  //- Подтвержденеие на удаление вложения
  app-dialog-confirm(
    v-model="dialog.confirmDelAttach" 
    @confirm="deleteSelectedAttachment"
  ) 
    app-text {{ $t('module.task.delAttachment') }}?
      
</template>

<script>
import { SourcePath } from '@app/SourcePath'
import AppTaskSimpleAttachment from './TaskSimpleAttachment'
import ImageDialog from '@/components/task/taskDetailsSections/ImageDialog'
import { mapState, mapActions, mapGetters } from 'vuex'
import { TaskBimAnnotations } from '@/components/task/taskBimAnnotations'
import TaskButtonAction from '@/components/task/taskDetailsSections/TaskButtonAction.vue'

export default {
  name: "TaskAttachment",

  filters: {
    prettyDate: (fulldate) => {
      let [date, time] = fulldate.split(' ')
      let [hour, minutes] = time.split(':')
      let [year, month, day] = date.split('-')
      return `${day}.${month}.${year} ${hour}:${minutes}`
    },
  },

  components: {
    AppTaskSimpleAttachment,
    ImageDialog,
    TaskButtonAction
  },

  props: {
    attach: null
  },

  data () {
    return {
      dialog: {
        confirmDelAttach: false
      },
      drawingAttachment: {
        src: null
      },
      customBg: ['/img/project/tools/custom_file_bg_1.svg', '/img/project/tools/custom_file_bg_2.svg'],
      showFullImage: false,
      drawingApplication: null,
      isCollisionAttachmentActive: false
    }
  },

  computed: {
    ...mapState('layoutControler', ['detailsWidth']),
    ...mapState('collision/search', ['collisionSelectedGlobalIds']),
    ...mapGetters('authUser', ['toLocalTimeZone']),
    ...mapGetters('projectPermissions', ['hasTaskDeleteCommentAttach']),

    showLocationBtn () {
      if(this.attach.sourceType.title=="VIEW" || this.attach.sourceType.title=="DRAWINGCOMMENT") return true
      else return false
    },

    is3DAttach () {
      if(this.attach.sourceType.title=="VIEW") return this.attach.sourceData?.camera
      else return false
    },

    selectedAnnotation() {
      return !this.$route.path.includes('task/manager') ? TaskBimAnnotations.selectedTaskAnnotation : false
    },

    selectedCollisionUuid() {
      return this.collisionSelectedGlobalIds.collisionUuid
    },

    isCurrentAttach () {
      if (this.selectedAnnotation) {
        return this.attach.uuid === this.selectedAnnotation.id.replace("task_annotation_", "")
      }
      else if (this.attach.collision && !this.isCollisionAttachmentActive) {
        return this.selectedCollisionUuid == this.attach.collision
      }
      return false
    },

    widthComment () {
      return this.detailsWidth > 544
    },

    randomImage () {
        return Math.floor(Math.random() * 2)
    },

    isTaskManager() {
      return window.location.href.includes('task/manager')
    },

    imagePath() {
      if ( this.attach.contentType == "application/octet-stream") return this.drawingApplication
      return SourcePath.attachment(this.attach.file)
    }
  },

  mounted () {
    if(this.isAttachHaveDrawing(this.attach)) {
      let drawingAttachmentPreview = this.$refs.drawingAttachmentPreview
      
      this.getDrawingPagePreview(this.attach.cameraParse.page).then((url) => {
        this.drawingApplication = URL.createObjectURL(url)
        url = URL.createObjectURL(url)
        drawingAttachmentPreview.src = url
        drawingAttachmentPreview.onload = () => {
          drawingAttachmentPreview.setAttribute('style', `transform-origin: top left; transform: scale(${this.attach.cameraParse.scale / 100});`)
          drawingAttachmentPreview.style.transformOrigin = 'top left'
          drawingAttachmentPreview.style.transform = `scale(${this.attach.cameraParse.scale / 100})`
          
          let drawingPreviewRect = drawingAttachmentPreview.getBoundingClientRect()
          this.$refs.drawingAttachmentWindow.scrollTo(
            (this.attach.cameraParse.x / 100 * drawingPreviewRect.width) - drawingPreviewRect.width / 4, 
            (this.attach.cameraParse.y / 100 * drawingPreviewRect.height) - drawingPreviewRect.height / 4
          )
        }
      })
    }
  },

  methods: {
    ...mapActions('task', ['deleteAttachment']),
    ...mapActions('drawings', ['deleteComment', 'getDrawingPagePreview', 'getDrawingPage']),

    selectAttachment() {
      // if (this.attach.collision) {
        
      // }
      // else this.isCollisionAttachmentActive = false

      this.$emit('location')
    },

    attachmentInfo () {
      if ( this.attach.sourceType.title == "VIEW" ) {
        return ( this.attach.createDate ? this.toLocalTimeZone(this.attach.createDate.replace(" ", "T")) : "" ) + "<br/>" + this.attach.cameraParse.comment
      }
      else {
        if ( this.attach.sourceType.title == "FILE" || this.attach.sourceType.title == "IMAGE"){
          return this.attach.name + "<br/>" + ( this.attach.createDate ? this.toLocalTimeZone(this.attach.createDate.replace(" ", "T")) : "" )
        } else {
          return this.attach.name + "<br/>" + this.attach.sourceData.text
        }
      }
    },

    attachmentDownload () {
      if (this.attach.sourceType.title === "DRAWINGCOMMENT") this.downloadDrawing(this.attach)
      else this.showFile(this.attach.file,this.attach.contentType)
    },

    filePath () {
      return SourcePath.attachment(this.attach.file)
    },

    showFile (file,contentType) {
      if(contentType==='application/pdf') window.open(SourcePath.attachment(file), '_blank');
  
      else if (contentType.startsWith('image')) {
        let w = window.open()
        let img = new Image()
        img.src = SourcePath.attachment(file)
        img.setAttribute('style', 'text-align: center; position: absolute; inset: 0; margin: auto;')
        w.document.body.appendChild(img)
      }

      else {
        const url = SourcePath.attachment(file)
        const link = document.createElement('a')
        
        link.href = url
        link.download = file
        link.click()
      }
    },

    downloadDrawing(attachment) {
      this.getDrawingPage(attachment.cameraParse.page).then(svg => {
        svg = new DOMParser().parseFromString(svg, 'application/xml')
        
        let xmlSvg = new XMLSerializer().serializeToString(svg)
        let svgData = xmlSvg
        let svgBlob = new Blob([svgData], {type:"image/svg+xml;charset=utf-8"});
        let svgUrl = URL.createObjectURL(svgBlob);

        let downloadLink = document.createElement("a");
        downloadLink.href = svgUrl;
        downloadLink.download = attachment.file
        downloadLink.click();
      })
    },

    getAttachType(fileName) {
      return fileName.split('.').pop()
    },

    dateParse(fulldate) {
      let [date, time] = fulldate.split(' ')
      let [hour, minutes] = time.split(':')
      let [year, month, day] = date.split('-')

      return `${day}.${month}.${year} ${hour}:${minutes}`
    },

    isAttachHaveImage(attach) {
      if(attach.contentType.startsWith('image')){
        return true
      }
      return false
    },

    isAttachHaveScreenShoot(attach) {
      return attach?.sourceType?.title === "VIEW"
      // if(attach.sourceType.title=="VIEW"){
      //   return true
      // }
      // return false
    },

    isAttachHaveDrawing(attach) {
      return attach?.sourceType?.title === "DRAWINGCOMMENT"
      // if(attach.sourceType.title=="DRAWINGCOMMENT"){
      //   return true
      // }
      // return false
    },

    confirmDeleteAttach() {
      this.dialog.confirmDelAttach = true
    },

    deleteSelectedAttachment() {
      if (this.attach.sourceType.title == "DRAWINGCOMMENT") {
        let deletingAttachment = {
          uuid: this.attach.uuid,
          page: this.attach.cameraParse.page
        }
        this.deleteComment(deletingAttachment)
      }
      else {
        this.deleteAttachment(this.attach.uuid).then(() => {
          TaskBimAnnotations.showTaskBimAnnotations()
        })
      } 
    },

    showImage() {
      this.showFullImage = !this.showFullImage
    }


  }

}
</script>

<style>
.attachment-container{
  max-width: 180px;
  max-height: 126px;
}

.attachment-img{
  background-color: #262626; 
  display: flex;
  align-items: flex-end;
}

.attachment-without-img{
  width:100%; 
  height:100%;
}

.attachment-type-img{
  background-color: #262626; 
  width:247px; 
  height:126px;
}

.attachment-menu-body{
  position: absolute;
  top: 0px; 
  width:100%; 
  height:100%
}


.blackout-img{
  background-color: rgba(78, 78, 78,0.8);
}

.img-buttons-flex{
  align-items:center;
  justify-content:center; 
  width:100%; 
  height:100%; 
  margin: 0 -4px;
}

.img-button:hover{
  background-color: rgba(0, 0, 0, 0.25);
}

.attach-type-font{
  font-size: 50px;
  line-height: 1.5;
  white-space: nowrap;
}

.icon-box-doc {
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
}

.icon-box {
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
}

.gray-bg {
  background-color:#757575;
}
.scroll-hidden {
  overflow: hidden;
  width: 100%;
  max-width: 247px;
  height: 126px;
  
}
.drawing-attachment-header-action{
  position: absolute;
  /*bottom: 0px;
  right: 0px;*/
  top: 0px;
  left: 0px;
}
.drawing-comment{
  position: absolute;
}
.doc-box {
  width: 247px;
}
.icon-box-img {
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
}
.file-text {
  height: 32px;
  width: 100%;
  overflow: hidden; 
  text-overflow: ellipsis;
  max-width: 160px;
  white-space: nowrap;
}
.attach-meta{
  height: 32px;
  /* width: 247px; */
}

.isactive {  
  outline: 2px solid #0E9DBC;
}

.custom-attach-bg {
  background: #E5E5E5;
}
.custom-attach-brd {
  border: 1px solid #C4C4C4;
}
.custom-attach-brd-panel {
  border-top: 1px solid #C4C4C4;
  border-left: 1px solid #C4C4C4;
  border-right: 1px solid #C4C4C4;
}

.attachment-tooltip{
  white-space: pre-wrap;
  word-break: break-all;
  max-width: 350px;
}
</style>