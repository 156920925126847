<template lang="pug">
div
  expansion-button( :buttonHeader="buttonHeader" )
    app-text
      div( v-if='generatedPassport' )
        div(v-if='generatedPassport.ifcName' v-html='$t("section.structure.model.details.ifcName", { name: generatedPassport.ifcName })')
        div(v-if='generatedPassport.cadShema' v-html='$t("section.structure.model.details.cadShema", { cadShema: generatedPassport.cadShema })')
        div(v-if='generatedPassport.cadProgram' v-html='$t("section.structure.model.details.cadProgram", { cadProgram: generatedPassport.cadProgram })')
        div(v-if='generatedPassport.cadAuthor' v-html='$t("section.structure.model.details.cadAuthor", { cadAuthor: generatedPassport.cadAuthor })')
        div(v-if='generatedPassport.cadCreateDate' v-html='$t("section.structure.model.details.cadCreateDate", { cadCreateDate: generatedPassport.cadCreateDate })')
        div(v-if='generatedPassport.ifc' v-html='$t("section.structure.model.details.ifc", { ifc: generatedPassport.ifc })')
        div(v-if='generatedPassport.xkt' v-html='$t("section.structure.model.details.xkt", { xkt: generatedPassport.xkt })')
        div(v-if='generatedPassport.convex' v-html='$t("section.structure.model.details.convex", { convex: generatedPassport.convex })')
        div(v-if='generatedPassport.countAtr' v-html='$t("section.structure.model.details.countAtr", { countAtr: generatedPassport.countAtr })')
        div(v-if='generatedPassport.countElements' v-html='$t("section.structure.model.details.countElements", { countElements: generatedPassport.countElements })')
        div(v-if='generatedPassport.countTrianglesOrig' v-html='$t("section.structure.model.details.countTrianglesOrig", { countTrianglesOrig: generatedPassport.countTrianglesOrig })')
        div(v-if='generatedPassport.countTrianglesConvex' v-html='$t("section.structure.model.details.countTrianglesConvex", { countTrianglesConvex: generatedPassport.countTrianglesConvex })')
        strong(v-if='passportData.expparser') Экспериментальный парсер
</template>

<script>
import ExpansionButton from './components/ExpansionButton'
export default {
  name: 'RevisionPassport', 
  components: {
    ExpansionButton
  },
  props: {
    passportData: null, 
    buttonHeader: {
      type: String,
      default: "",
    }
  },

  data() {
    return {
      toggleDetailsModel: false
    }
  },

  computed: {
    generatedPassport() {
      let curPassport = this.passportData
      
      let passport = {
        ifcName: curPassport?.ifcFileName,
        ifc: Math.round((curPassport?.ifcFileSize + Number.EPSILON) * 100) / 100,
        xkt: Math.round((curPassport?.xktFileSize + Number.EPSILON) * 100) / 100,
        convex: Math.round((curPassport?.convexHullFileSize + Number.EPSILON) * 100) / 100,
        countAtr: curPassport?.countAttributes,
        countElements: curPassport?.countElements,
        countTrianglesOrig: curPassport?.countTrianglesOriginal,
        countTrianglesConvex: curPassport?.countTrianglesConvexHull,
        cadProgram: curPassport?.cadProgram,
        cadAuthor: curPassport?.cadAuthor,
        cadShema: curPassport?.cadShema,
        cadCreateDate: curPassport?.cadCreateDate,
      }
      if (passport.ifc || passport.xkt || passport.convex || passport.countAtr || passport.countTrianglesOrig || passport.countTrianglesConvex ||
        passport.cadProgram || passport.cadAuthor || passport.cadShema || passport.cadCreateDate || passport.countElements || passport.ifcName) {
        return passport
      }
      return null
    },
  }
}
</script>
