<template lang="pug">
app-dialog( v-model="$_show" :header="$t('section.classification.menuClassification.management')" withScrollPanel width="80%" complex @close="closeDialog")
  v-row(no-gutters)
    v-col( cols=6 style="padding-right: 1px;" )
      app-panel-header.mt-1(:title="projectClassificatorSelected ? projectClassificatorSelected.title : $t('section.classification.menuClassification.notSelected')" :menu="(hasClassificatorImportExport || hasClassificatorCreate || hasClassificatorDelete) ? classsificatorControlMenu : null")
      app-section.h500( :rounded="false" )
        app-button-add.mt-3(v-if="projectClassificators.length == 0 && canWorkWithClassificator" width="100%" :text="$t('section.classification.menuClassification.add')" @click="showAddNew")

        v-row(v-else)
          v-col( cols=6 )
            v-select.mb-5(
              v-model="projectClassificatorSelected"
              :items="projectClassificators"
              item-text="title"
              item-value="uuid"
              return-object
              :label="$t('section.classification.menuClassification.classifier')"
              outlined
              clearable
              dense
              background-color="#fff"
              color="var(--v-accent-base)"
              item-color="#4D4D4D"
              :menu-props="{light: true, maxWidth:'fit-content', contentClass: 'menu-scroll'}"
              flat
              filled
              hide-details
              light
              @change="selectProjectClassificator")
          v-col(cols=6)
            app-text-field(v-if="projectClassificatorSelected" v-model="nodeSearch" :label="$t('section.classification.menuClassification.codeOrNameSearch')" outlined dense clearable)
            

        v-progress-linear(v-if="tree.loading" indeterminate test-id="tree-loading")

        .base-scroll-body.mh400(v-if="projectClassificatorSelected" ref="treeScrollBody")
          div(v-if="!searchMode" style="position: relative;")
            app-button-add.mt-3(v-if="!tree.loading && tree.items.length == 0" width="100%" :text="$t('section.classification.menuClassification.add')" @click="addRootNode()")

            v-treeview.__project_view_tree(
              dense
              light
              hoverable
              :active.sync="tree.active"
              item-key="uuid"
              :items="tree.items"
              :open.sync="tree.opened"
              :load-children="loadChildrens"
              item-disabled="locked"
            )
              template(v-slot:label="{ item }")
                template(v-if="item.editMode")
                  v-form.py-2.my-8(ref="nodeForm" @submit.prevent="createParentNode ? saveNewParentNode(item) : saveNode(item)" :id="`item_${item.uuid}`")
                    v-row.pt-2(style="position: absolute; top: 0px; left: 0px;")
                      v-col(cols=3)
                        app-text-field.text-nopadding.bg-white( ref="pressmarkInput" hideDetails v-model="editNode.pressmark" :label="$t('section.classification.menuClassification.code')" :rules="[rules.required, rules.exists]" focus )
                      v-col(xl=7 lg=7 md=5 sm=5)
                        app-text-field.text-nopadding.bg-white( hideDetails v-model="editNode.title" :label="$t('section.classification.menuClassification.title')" outlined :rules="[rules.required]")
                      v-col(cols=1)
                        v-btn(v-if="validation" icon type="submit")
                          v-icon mdi-check
                        v-btn(icon @click.prevent="cancelEditNode(item)")
                          v-icon mdi-cancel

                template(v-else)
                  template(v-if="item.locked")
                    .nodeLabel.mr-4(:id="`item_${item.uuid}`") {{ item.pressmark }} - {{ item.title }}
                  template(v-else)
                    v-hover( v-slot:default="{ hover }" close-delay="150" :id="`item_${item.uuid}`")
                      .d-flex.py-2.p-relative(@contextmenu.prevent="showItemMenu($event,item)" @click="selectNode(item)" :title="item.title")
                        .nodeLabel.mr-4 {{ item.pressmark }} - {{ item.title }}
                        v-icon.unit-action( v-if="hover && canWorkWithClassificator" color="#000" @click.stop="showItemMenu($event,item)" ) more_vert

          div(v-if="searchMode")
            NodeSearchView(ref="nodeSearchView" :projectClassificator="projectClassificatorSelected" :searchText="nodeSearch")

      app-menu(ref="itemMenu" :menu="nodeMenu" @close="closeItemMenu")

    v-col(cols=6)
      app-panel-header.mt-1(:title="editClass ? `${$t('section.classification.menuClassification.class')} ${editClass.title}` : $t('section.classification.menuClassification.classNotSelected')")
      app-section.h500.base-scroll-body( :rounded="false" )
        NodeEditForm(v-if="editClass" :projectClassificator="projectClassificatorSelected" @change="upodateNode" ref="nodeEditForm")

    app-dialog( v-model="dialog.addnew" :header="$t('section.classification.menuClassification.newClassifier')" :confirmText="$t('button.save')" @confirm="saveNew" :confirmDisabled="!newProjectClassificator.title" )
      app-section
        v-form( ref="classificatorForm" )
          app-text-field( v-model="newProjectClassificator.title" :label="$t('section.classification.menuClassification.title')" outlined :rules="[rules.required]" )

    app-dialog( v-model="dialog.editTitle" :header="$t('section.classification.menuClassification.editClassifier')" :confirmText="$t('button.save')" @confirm="editClassificatorTitle" )
      app-section( v-if="projectClassificatorSelected" )
        v-form( ref="classificatorForm" )
          app-text-field( v-model="newClassificatorTitle" :label="$t('section.classification.menuClassification.title')" outlined :rules="[rules.required]" )

    app-dialog( v-if="projectClassificatorSelected"
                        v-model="dialog.deleteProjectClassificator"
                        :header="$t('section.classification.menuClassification.deleteClassifiers')"
                        :disabled="fulldeleteprocess.loading")
      app-section(style='max-height: 500px; overflow: auto;')
        app-text {{ $t('section.classification.menuClassification.deleteClassifier') }} "{{ projectClassificatorSelected.title }}"?

        div.mt-3.d-flex.justify-end
          app-button(cancel @click='dialog.deleteProjectClassificator = false') {{ $t('button.cancel') }}
          app-button.ml-2(action @click='deletePC()') {{ $t('button.delete') }}
        div.mt-2
        
        v-progress-linear(v-if="fulldeleteprocess.loading" color="#ff0000" indeterminate)
        div(v-if="fulldeleteprocess.errors.length > 0")
          div(v-for="(er,index) in fulldeleteprocess.errors" :key="index")
            v-alert( type="error") {{ $t('section.classification.classificationControll.deleteErrors.'+er.info) }}
            delete-process-tree(:items='[er]')
              template(v-slot:default='{ node }')
                app-text.flex.text-truncate(:title='node.name') 
                  <b>{{ (node.type && node.type + ':') || ($t('section.classification.classificationControll.deleteErrors.' + node.info)) }}</b> {{ node.name && $t(node.name.replace(/[{}$]/g, '')) }}
            //- div.d-flex.align-center(v-for='node in er.flatlist' style='height: 24px;')
              div(:style='{ "margin-left": node.level * 8 + "px" }')
              v-icon.mr-1(v-if='node.node.children && node.node.children.length' color='accent' small @click) mdi-chevron-right
              div.mr-5(v-else)
              app-text.flex.text-truncate() <b>{{ node.node.type }}:</b> {{ node.node.name || node.node.info }}
            //- div(v-for="inf in er.entityInfo")
            //-   app-text {{ JSON.stringify(inf) }}


    app-dialog-confirm( v-if="deleteNode.node"
                v-model="deleteNode.dialog"
                :header="(deleteNode.node.nodeType && deleteNode.node.nodeType.value == 1) ? $t('section.classification.menuClassification.deleteSection') : $t('section.classification.menuClassification.deleteClass')"
                @confirm="delNode"
                :disabled="deleteNode.deleting" )
      app-section
        div.my-3(v-if="deleteNode.node.nodeType && deleteNode.node.nodeType.value == 1")
          .d-flex.flex-column.align-center
            app-text {{$t('section.classification.menuClassification.deleteSection')}} "{{ deleteNode.node.title }}"?
            v-checkbox.mx-n1(v-model="deleteNode.delSubNodes" dense hide-details light)
              template(v-slot:label)
                app-text.mx-n1 {{$t('section.classification.menuClassification.removeSubsectionAndClasses')}}

        div.my-3(v-else) 
          app-text {{$t('section.classification.menuClassification.deleteClass')}} "{{ deleteNode.node.title }}"?
        
        div(v-if="deleteNode.deleting")
            div {{$t('section.classification.menuClassification.deleting')}}
            v-progress-linear(color="#ff0000" indeterminate)
        
        div(v-if="deleteNode.error")
          app-text {{ deleteNode.error }}


    ImportClassificatorDialog(v-model="importDialog" @update="imported")

    ExportClassificatorDialog(v-model="exportDialog")


    v-snackbar(v-model="moveNode.moveSnackBar" :multi-line="true" timeout="2000" color="green") 
      div {{ moveNode.snackBarMessage }}
      template(v-slot:action="{ attrs }")
        v-btn(color="red" icon v-bind="attrs" @click="cancelNodeMove" :title="$t('section.classification.menuClassification.undoMove')")
          v-icon mdi-close
  
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"

import { api } from "@/api"

import DialogToggable from "@/components/app/DialogToggable"

import NodeSearchView from "./NodeSearchView"
import NodeEditForm from "./NodeEditForm"

import ImportClassificatorDialog from './ImportClassificatorDialog'
import ExportClassificatorDialog from './ExportClassificatorDialog'
import DeleteProcessTree from './DeleteProcessTree.vue'

export default {
  name: "ClassificatorControll",
  mixins: [DialogToggable],
  components: {
    
    NodeSearchView,
    NodeEditForm,

    ImportClassificatorDialog,
    ExportClassificatorDialog,
    
    DeleteProcessTree,
  },

  data() {
    return {
      projectClassificatorSelected: null,
      newProjectClassificator: {
        title: "",
        project: null
      },

      newClassificatorTitle: "",

      allCodes: [],
      tree:{
        loading:false,
        items:[],
        opened:[],
        active:[]
      },

      menuItem: null,

      realEditNode: null,
      editNode: null,

      deleteNode: {
        dialog: false,
        deleting: false,
        delSubNodes: false,
        node: null,
        error: null
      },

      codeChecking: false,

      searchMode: false,
      nodeSearch: null,

      classNodeSelected: null,

      dialog: {
        addnew: false,
        editTitle: false,
        deleteProjectClassificator: false
      },

      importDialog: false,
      exportDialog: false,

      moveNode: {
        node: null,
        snackBarMessage: null,
        moveSnackBar: false
      },

      fulldeleteprocess: {
        loading:  false,
        errors: []
      },

      rules: {
        required: (value) => (!!value && !!(value.trim())) || this.$t("error.require"),
        exists: (value) => {
          if (!value) return false
          if (this.realEditNode && this.realEditNode?.pressmark == value) return true
          return !this.allCodes.includes(value) || this.$t('error.codeExist')
        }
      },
      childNode: null,
      createParentNode: false
    };
  },

  mounted() {
    if (!this.projectClassificators || this.projectClassificators.length == 0) this.listProjectClassofocators()    
  },

  computed: {
    ...mapState("project", ["project",'projectSettings']),
    ...mapState("smeta", ["nodeTypes", "unitTypes","editClass",'lastUnit']),

    ...mapState('smeta', ['projectClassificatorUuid','projectClassificators','nodeTree','openedNodes','copiedRule']),

    ...mapGetters('authUser', ['canWorkWithClassificator']),
    ...mapGetters('smeta', ['getTreeCache']),
    ...mapGetters('projectPermissions', ['hasClassificatorDelete', 'hasClassificatorImportExport', 'hasClassificatorCreate']),

    validation() {
      return this.editNode?.pressmark?.trim() && this.editNode?.title?.trim()
    },

    classsificatorControlMenu() {
      let act = []

      if(this.hasClassificatorCreate) {
        act.push({ title: this.$t('section.classification.menuClassification.addNewClassifier'), action: this.showAddNew },)
        act.push({ divider: true })
        if (this.projectClassificatorSelected && this.canWorkWithClassificator) {
          act.push({ title: this.$t('section.classification.menuClassification.editClassifier'), action: this.showEditTitleDialog })
        }
      }
      if(this.projectClassificatorSelected && this.hasClassificatorDelete) {
        act.push({ title: this.$t('section.classification.menuClassification.deleteClassifier'), action: this.showDeleteProjectClassificatorDialog })
      }

      if(this.hasClassificatorImportExport) {
        act.push({ divider: true })
        act.push({ title: this.$t('section.classification.menuClassification.importClassifier'), action: this.showImportDialog })
        act.push({ title: this.$t('section.classification.menuClassification.exportClassifier'), action: this.showExportDialog })
      }
      return act
    },

    nodeMenu(){
      if (this.menuItem && this.canWorkWithClassificator) {
        if (this.menuItem.nodeType.value == 1) {
          let  a = []
          if (this.moveNode.node && this.projectClassificatorSelected) {
            a.push({ title: this.$t('section.classification.clipboard.paste'), disabled: this.moveNode.node.projectClassificatorUuid != this.projectClassificatorSelected.uuid ? true : false, action: this.moveNodeEnd })
            a.push({ divider: true })
          }
          a.push({ title: this.$t('section.classification.menuClassification.addSubsection'), action: this.addNewNode })
          a.push({ title: this.$t('section.classification.menuClassification.addParentSection'), action: this.addNewParentNode })
          a.push({ divider: true })
          a.push({ title: this.$t('section.classification.menuClassification.editSection'), action: this.showEditNode })
          a.push({ divider: true })
          a.push({ title: this.$t('section.classification.menuClassification.moveSection'), disabled: this.moveNode.node ? true : false, action: this.moveNodeStart })
          if(this.hasClassificatorDelete) {
            a.push({ divider: true })
            a.push({ title: this.$t('section.classification.menuClassification.deleteSection'), action: this.showDeleteNode })
          }
          a.push({ divider: true })
          a.push({ title: this.$t('section.classification.menuClassification.addClass'), action: this.showAddNewNodeClass })
          return a
        } else {
          let  a = []
          a.push({ title: this.$t('section.classification.menuClassification.addParentSection'), action: this.addNewParentNode })
          a.push({ title: this.$t('section.classification.menuClassification.moveClass'), disabled: this.moveNode.node ? true : false, action: this.moveNodeStart })
          if(this.hasClassificatorDelete) a.push({ title: this.$t('section.classification.menuClassification.deleteClass'), action: this.showDeleteNode })
          return a
        }
      }
      return []
    }
  },

  watch: {
    nodeSearch() {
      if (this.nodeSearch && this.nodeSearch.trim() != "") {
        this.searchMode = true
      } else {
        this.searchMode = false
      }
      this.setEditClass(null)
    },

    projectClassificatorSelected(){
      this.nodeSearch = null
      this.setEditClass(null)
    },

    editClass(val){
      this.tree.active = []
      if (val) this.tree.active.push(val.uuid)
    },

    'moveNode.snackBarMessage'(val){
      this.moveNode.moveSnackBar = false
      if (val) this.moveNode.moveSnackBar = true
    },

    $_show(val){
      if (!val) {
        if (this.moveNode.node) this.cancelNodeMove()
        this.moveNode = {moveSnackBar:false, snackBarMessage:null, node:null}
      }
    }
  },

  methods: {
    ...mapMutations("smeta", ["setEditClass",'setLastUnit']),
    ...mapActions("smeta", ['addChildElement', 'addParentElement', 'updateElement','removeElementByUuid','removeElementByUuidDeep','moveElement','commitSmetaTree','LOAD_PROJECT_CLASSIFICATORS']),

    selectProjectClassificator(val){
      if (val) this.loadClassificatorNodes()
      else this.clearTree()
      this.moveNode = {moveSnackBar:false, snackBarMessage:null, node:null}
    },

    closeDialog(){
      if(this.editNode){
        let node = this.findNode(this.editNode.uuid)
        if(node){
          this.cancelEditNode(node)
        }
      }
    },

    show(){
      this.$_show = true
    },

    showAdd(){
      this.$_show = true
      setTimeout(()=>this.showAddNew(),200)
    },

    showEditItem(item){
      this.setEditClass(null)
      this.cancelEditNode()

      this.$_show = true
      setTimeout(()=>{
        let openTo = []
        let it = item
        while (it != null) {
          openTo.unshift(it)
          it = it.parent
        }

        let openFunc = (elem) => {
          openTo.shift()

          if (openTo.length == 0) {
            setTimeout(() => {
              document.getElementById("item_"+elem.uuid).scrollIntoView()
            },300)
            if (elem.nodeType.name == 'TOPIC') {
              let node = this.findNode(elem.uuid)
              this.editNode = Object.assign({},node)
              node.editMode = true
            } else {
              this.setEditClass(elem)
            }
          } else {
            if (!this.tree.opened.includes(elem.uuid) || elem.children.length == 0) {
              api.smeta.childrennodesfast(elem.uuid, elem.projectClassificatorUuid).then((data) => {
                this.bindNodesList(elem, data)
                elem.children = data
                if (!this.tree.opened.includes(elem.uuid)) this.tree.opened.push(elem.uuid)
                let e = elem.children.find(el => el.uuid == openTo[0].uuid)
                openFunc(e)
              })
            } else {
              let e = elem.children.find(el => el.uuid == openTo[0].uuid)
              openFunc(e)  
            }
          }
        }

        if (!this.projectClassificatorSelected || this.projectClassificatorSelected.uuid != item.projectClassificatorUuid || this.tree.items.length == 0) {
          let pc = this.projectClassificators.find(e => e.uuid == item.projectClassificatorUuid)
          this.projectClassificatorSelected = pc
          this.tree.loading = true
          this.tree.items = []
          api.smeta.topnodes(this.projectClassificatorSelected.uuid).then((data) => {
            this.bindNodesList(null, data)
            this.tree.items = data
            this.tree.loading = false
            let e = this.tree.items.find(el => el.uuid == openTo[0].uuid)
            if (!this.tree.opened.includes(e.uuid)) this.tree.opened.push(e.uuid)
            openFunc(e)
          })
        } else {
          let e = this.tree.items.find(el => el.uuid == openTo[0].uuid)
          if (!this.tree.opened.includes(e.uuid)) this.tree.opened.push(e.uuid)
          openFunc(e)
        }
      },200)
    },

    showImport(){
      this.$_show = true
      setTimeout(()=>this.showImportDialog(),200)
    },

    showItemMenu(event, item) {
      this.menuItem = item
      if (this.nodeMenu.length > 0) this.$refs.itemMenu.show(event)
    },

    closeItemMenu() {
      this.menuItem = null
    },

    clearTree() {
      this.tree = {
        loading:false,
        items:[],
        opened:[],
        active:[]
      }
    },

    listProjectClassofocators() {
      this.LOAD_PROJECT_CLASSIFICATORS(this.project.uuid)
    },

    showAddNew() {
      this.newProjectClassificator.title = ""
      this.newProjectClassificator.project = {uuid: this.project.uuid}
      this.dialog.addnew = true
    },

    showEditTitleDialog() {
      if (this.canWorkWithClassificator) {
        this.newClassificatorTitle = this.projectClassificatorSelected.title
        this.dialog.editTitle = true
      }
    },

    showDeleteProjectClassificatorDialog(){
      this.fulldeleteprocess.loading = false
      this.fulldeleteprocess.errors = []
      this.dialog.deleteProjectClassificator = true
    },

    deletePC(){
      this.fulldeleteprocess.loading = true
      this.fulldeleteprocess.errors = []
      api.smeta.deleteProjectClassificator(this.projectClassificatorSelected.uuid).then(data => {
        if (data.status == 'OK') {
          this.listProjectClassofocators()
          this.tree.items = []
          
          this.dialog.deleteProjectClassificator = false

          if (this.projectClassificatorSelected.uuid == this.projectSettings.projectClassificator) {
            let ps = JSON.parse(JSON.stringify(this.projectSettings))
            ps.projectClassificator = null
            this.$store.dispatch('project/updateSettingsProjectClassificator', ps)
          }

          this.projectClassificatorSelected = null
        } else {
          data.errors.forEach(root => {
            const cache = {}
            root.children = []
            root.entityInfo.forEach(entityInfo => {
              if (!cache[entityInfo.uuid]) {
                root.children.push(entityInfo)
              }
              let children = cache[entityInfo.uuid] ||= entityInfo.children = []
              while (entityInfo) {
                entityInfo = entityInfo.entityInfo
                if (entityInfo) {
                  cache[entityInfo.uuid] || children.push(entityInfo)
                  entityInfo.entityInfo && (children = cache[entityInfo.uuid] ||= entityInfo.children = [])
                }
              }
            })
          })
          this.fulldeleteprocess.errors.push(...data.errors)  
        }

        this.fulldeleteprocess.loading = false
        
      }).catch(e => {
        this.fulldeleteprocess.errors.push(e)
        this.fulldeleteprocess.loading = false
      })
    },

    saveNew() {
      if (this.$refs.classificatorForm.validate()) {
        api.smeta.projclasscreate(this.newProjectClassificator).then((data) => {
          this.projectClassificatorSelected = data
          this.listProjectClassofocators()
          this.loadClassificatorNodes()
          this.dialog.addnew = false
        })
      }
    },

    editClassificatorTitle() {
      if (this.canWorkWithClassificator) {
        if (this.$refs.classificatorForm.validate()) {
          api.smeta.projclassEditTitle(this.projectClassificatorSelected.uuid, this.newClassificatorTitle).then((data) => {
            this.projectClassificatorSelected = data;
            this.listProjectClassofocators()
            this.loadClassificatorNodes()
            this.dialog.editTitle = false
          })
        }
      }
    },

    showAddNewClass(node) {
      this.classNodeSelected = {
        title: null,
        pressmark: null,
        nodeType: null,
        unitType: null,
        parent: (node && node.nodeType.value) == 1 ? node : null,
        projectClassificator: this.projectClassificatorSelected,
      }
    },

    cancelClass() {
      this.classNodeSelected = null
      this.activeNode = []
    },

    loadClassificatorNodes() {
      this.allCodes = []
      api.smeta.allCodes(this.projectClassificatorSelected.uuid).then((data) => {
        this.allCodes = data
      })
      this.tree.loading = true
      this.tree.items = []
      api.smeta.topnodes(this.projectClassificatorSelected.uuid).then((data) => {
        if(this.tree.items.length == 0){
          this.bindNodesList(null, data)
          this.tree.items = data
        }
        this.tree.loading = false
      })
    },

    loadChildrens(item, callback) {
      api.smeta.childrennodesfast(item.uuid, item.projectClassificatorUuid).then((data) => {
        this.bindNodesList(item, data)
        item.children = data
        if (callback) callback(item)
        if (!this.tree.opened.includes(item.uuid)) this.tree.opened.push(item.uuid)
        
      })
    },

    bindNodesList(parent, data) {
      let lock = parent ? parent.locked : false
      data.forEach(item => {
        this.$set(item, "editMode", false)
        this.$set(item, "locked", lock)
        if (!item.hasChilds && !item.children) this.$delete(item, "children")
        else if (item.hasChilds && !item.children) this.$set(item, "children", [])
      })
    },

    selectNode(item){
      if (item.nodeType && item.nodeType.value == 1) {
        let i = this.tree.opened.findIndex(e => e == item.uuid)
        if (i>=0) this.tree.opened.splice(i,1)
        else this.tree.opened.push(item.uuid)
      } else {
        if (this.editClass && this.editClass.uuid == item.uuid) this.setEditClass(null)
        else this.setEditClass(item)
      }
    },

    addRootNode(){
      if (this.canWorkWithClassificator) {
        this.editNode = this.createNew()
        this.editNode.title = this.$t('section.classification.menuClassification.newSection')
        this.editNode.nodeType = { value: 1, name: "TOPIC", title: this.$t('section.equipment.details.chapter') }
        this.editNode.editMode = true
        this.editNode.isRoot = true
        this.tree.items.push(this.editNode)
      }
    },

    addNewNode() {
      if (this.canWorkWithClassificator) {
        this.cancelEditNode()

        let realnode = this.findNode(this.menuItem.uuid)

        let callback = () => {
          let list = this.tree.items
          if (realnode && realnode.children) list = realnode.children

          this.editNode = this.createNew()
          this.editNode.pressmark = null
          this.editNode.title = this.$t('section.classification.menuClassification.newSection')
          this.editNode.parent = realnode
          this.editNode.nodeType = { value: 1, name: "TOPIC", title: this.$t('section.equipment.details.chapter') }
          this.editNode.editMode = true
          this.editNode.projectClassificatorUuid = this.projectClassificatorSelected.uuid

          let sel = async () => {
            await this.$nextTick()
            this.$refs.pressmarkInput.$el.getElementsByTagName('input')[0].select()
          }
          sel()

          list.unshift(this.editNode)
        }

        if (!realnode.hasChilds) {
          realnode.hasChilds = true
          this.$set(realnode, "children", [])
        }

        if (!this.tree.opened.includes(realnode.uuid)) this.loadChildrens(realnode,callback)
        else callback()
      }
    },

    addNewParentNode(){
      if (this.canWorkWithClassificator) {
        this.cancelEditNode()
        let realnode = this.menuItem.parent ? this.findNode(this.menuItem.parent.uuid) : this.findNode(this.menuItem.uuid)
        this.childNode = this.menuItem

        let callback = () => {
          let list = this.tree.items
          if (realnode && this.childNode.parent) list = realnode.children

          this.editNode = this.createNew()
          this.editNode.pressmark = null
          this.editNode.title = this.$t('section.classification.menuClassification.newSection')
          this.editNode.parent = this.findNode(this.childNode.uuid).parent
          this.editNode.nodeType = { value: 1, name: "TOPIC", title: this.$t('section.equipment.details.chapter') }
          this.editNode.editMode = true

          let sel = async () => {
            await this.$nextTick()
            this.$refs.pressmarkInput.$el.getElementsByTagName('input')[0].select()
          }
          sel()

          list.unshift(this.editNode)
        }
        
        if (!realnode.hasChilds) {
          realnode.hasChilds = true
          this.$set(realnode, "children", [])
        }
        if (!this.tree.opened.includes(realnode.uuid)) this.loadChildrens(realnode,callback)
        else callback()
      }
      this.createParentNode = true
    },

    saveNewParentNode(){
      api.smeta.createParentFor(this.childNode.uuid, this.editNode).then(data => {
        if (this.projectClassificatorSelected.uuid == this.projectSettings.projectClassificator) {
          this.addParentElement({element:data,parentForUuid:this.childNode.uuid})
        }
        let parentNode = this.findNode(this.editNode.parent?.uuid)
        if(parentNode){
          this.closeChildrenNode(parentNode)
        }
        if (data.parent) {
          let p = this.findNode(data.parent.uuid)
          this.loadChildrens(p)
        } else {
          this.loadClassificatorNodes()
        }
        this.createParentNode = false
        this.$emit('update',{projectClassificator: this.projectClassificatorSelected, node: data.parent})
      })
    },
    
    createNew(){
      return {
        uuid: null,
        title: this.$t('section.classification.menuClassification.newSection'),
        pressmark: null,
        nodeType: null,
        unitType: null,
        parent: null,
        projectClassificator: this.projectClassificatorSelected,
        hasChilds: false,
        editMode: false
      }
    },

    showEditNewParentNode(newNode){
      this.$nextTick(() => {
        if (this.canWorkWithClassificator) {
        this.cancelEditNode()

        let node = this.findNode(newNode.uuid)
        this.realEditNode = node

        this.editNode = Object.assign({},node)
        node.editMode = true
      }
      })
    },

    showEditNode(){
      if(this.editNode){
        let node = this.findNode(this.editNode.uuid)
        if(node){
          this.cancelEditNode(node)
        }
      }
      if (this.canWorkWithClassificator) {
        this.cancelEditNode()

        let node = this.findNode(this.menuItem.uuid)
        this.realEditNode = node

        this.editNode = Object.assign({},node)
        node.editMode = true
      }
    },

    cancelEditNode(item){
      if (this.editNode) {

        let realNode = this.findNode(this.editNode.uuid)
        if (realNode && !realNode.uuid) {
          let par
          let list = this.tree.items
          if (realNode.parent) {
            par = this.findNode(realNode.parent.uuid)
            if (par && par.children) list = par.children
          }
          let index = list.findIndex(e => e.uuid == null)
          if (index >= 0) list.splice(index,1)

          if (list.length == 0 && par) {
            par.hasChilds = false
            this.$delete(par, 'children');
            let i = this.tree.opened.findIndex(e => e == par.uuid)
            if (i>=0) this.tree.opened.splice(i,1)
          }
          realNode.editMode = false
        }
        if(item) item.editMode = false
        this.createParentNode = false
        this.editNode = null
        this.realEditNode = null
        
      }
    },

    saveNode(){
      if (this.canWorkWithClassificator) {
       
        let isAdd = !this.editNode.uuid ? true : false

    
        if (this.$refs.nodeForm.validate()) {
          if (this.editNode.parent) this.editNode.parent = {uuid:this.editNode.parent.uuid}
          this.editNode.projectClassificator = this.projectClassificatorSelected
          api.smeta.classcreate(this.editNode).then(data => {
            if (data.error) {
              this.$refs.pressmarkInput.$children[0].errorBucket.push(this.$t('error.codeExist'))
              this.$refs.pressmarkInput.$children[0].valid = false
              if (!this.allCodes.includes(this.editNode.pressmark)) this.allCodes.push(this.editNode.pressmark)
            } else {
              if (!data.children) delete data.children

              if (!this.allCodes.includes(data.pressmark)) this.allCodes.push(data.pressmark)

              if (this.projectClassificatorSelected.uuid == this.projectSettings.projectClassificator) {
                if (isAdd) {
                  if (this.editNode.parent == null) {
                    this.getTreeCache.addRootElement({element:data})
                  } else {
                    this.addChildElement({ parentUuid: this.editNode.parent?.uuid, element: data, elemType:data.nodeType.name })
                  }
                } else {
                  this.updateElement(data)
                }
              }

              let realNode = this.findNode(this.editNode.uuid)

              this.$set(data, "editMode", realNode.editMode)
              this.$set(data, "hasChilds", realNode.hasChilds)
              if (realNode.children) this.$set(data, "children", realNode.children)
              data.editMode = false

              
              let list = this.tree.items
              if (data.parent) {
                let par = this.findNode(data.parent.uuid)
                this.closeChildrenNode(par)
                this.loadChildrens(par)
                if (par) list = par.children
              }

              let ind = list.findIndex(e => (e.uuid == this.editNode.uuid && e.editMode))
              this.$set(list,ind,data)

              list.sort((a, b) => {
                if (a.pressmark < b.pressmark) return -1
                else return 1
              })

              this.editNode = null

              this.$emit('update',{projectClassificator: this.projectClassificatorSelected, node: data})
            }
          })
        }
      }
    },

    closeChildrenNode(parent){
      if(parent?.children?.length > 0){
        parent.children.map(el => {
          let i = this.tree.opened.findIndex(e => e == el.uuid)
          if (i>=0) this.tree.opened.splice(i,1)
        })
      }
      let i = this.tree.opened.findIndex(e => e == parent.uuid)
      if (i>=0) this.tree.opened.splice(i,1)
    },

    showDeleteNode() {
      if (this.canWorkWithClassificator) {
        this.deleteNode.error = null
        this.deleteNode.deleting = false
        this.deleteNode.node = this.findNode(this.menuItem.uuid)
        this.deleteNode.dialog = true
      }
    },

    delNode() {
      if (this.canWorkWithClassificator) {
        this.deleteNode.deleting = true
        var par
        var list = this.tree.items
        if (this.deleteNode.node.parent) {
          par = this.findNode(this.deleteNode.node.parent.uuid)
          if (par && par.children) list = par.children
        }

        if (this.deleteNode.delSubNodes) {
          api.smeta.delNode(this.deleteNode.node.uuid).then(() => {
            if (this.projectClassificatorSelected.uuid == this.projectSettings.projectClassificator) this.removeElementByUuidDeep(this.deleteNode.node.uuid)

            let index = list.findIndex(e => e.uuid == this.deleteNode.node.uuid)
            if (index >= 0) list.splice(index,1)

            if (list.length == 0 && par) {
              par.hasChilds = false
              this.$delete(par, 'children');
              let i = this.tree.opened.findIndex(e => e == par.uuid)
              if (i>=0) this.tree.opened.splice(i,1)
            }

            this.deleteNode.dialog = false
            this.setEditClass(null)

            this.$emit('update',{projectClassificator: this.projectClassificatorSelected, node: par})

            this.deleteNode.deleting = false

            this.deleteNode.delSubNodes = false
          }).catch(e => {
            this.deleteNode.error = e
          })

        } else {
          api.smeta.delNodeOnly(this.deleteNode.node.uuid).then(() => {
            if (this.projectClassificatorSelected.uuid == this.projectSettings.projectClassificator) this.removeElementByUuid(this.deleteNode.node.uuid)

            let index = list.findIndex(e => e.uuid == this.deleteNode.node.uuid)

            if (index >= 0) list.splice(index,1)

            if (par) {
              let i = this.tree.opened.findIndex(e => e == par.uuid)
              if (i>=0) this.tree.opened.splice(i,1)
              this.loadChildrens(par)
            } else {
              this.loadClassificatorNodes()
            }

            if(this.moveNode.node && this.deleteNode.node.uuid == this.moveNode.node.parent?.uuid){
              this.moveNode.node.parent = par
            }

            this.deleteNode.dialog = false
            this.setEditClass(null)
            this.$emit('update',{projectClassificator: this.projectClassificatorSelected, node: par})
            
            this.deleteNode.deleting = false
          }).catch(e => {
            this.deleteNode.error = e
          })
        }
      }
    },


    showAddNewNodeClass(){
      if (this.canWorkWithClassificator) {
        this.cancelEditNode()

        let realnode = this.findNode(this.menuItem.uuid)

        let callback = () => {
          this.editNode = this.createNew()
          this.editNode.pressmark = null
          this.editNode.title = this.$t('section.classification.menuClassification.newClass')
          this.editNode.parent = realnode
          this.editNode.nodeType = { value: 0, name: 'NODE', title: this.$t('section.classification.menuClassification.element') }
          this.editNode.unitType = this.lastUnit.unitType ? this.lastUnit.unitType : { value: 1, name: 'ITEM', title: '' }
          this.editNode.unitValue = this.lastUnit.unitValue
          this.editNode.editMode = false

          this.editNode.parent = {uuid:this.editNode.parent.uuid}
          api.smeta.classcreate(this.editNode).then(data => {
            if (!data.children) delete data.children

            if (this.projectClassificatorSelected.uuid == this.projectSettings.projectClassificator) {
              this.addChildElement({ parentUuid: this.editNode.parent.uuid, element: data, elemType:data.nodeType.name })
            }
            this.loadChildrens(realnode)
            let list = this.tree.items
            if (realnode && realnode.children) list = realnode.children
            list.push(data)

            this.tree.opened.push(realnode.uuid)
            this.setEditClass(this.findNode(data.uuid))

            let sel = async () => {
              await this.$nextTick()
              this.$refs.nodeEditForm.selectPressmark()
            }
            sel()

            this.editNode = null

            this.$emit('update',{projectClassificator: this.projectClassificatorSelected, node: realnode})
          })
        }

        if (!realnode.hasChilds) {
          realnode.hasChilds = true
          this.$set(realnode, "children", [])
        }
        this.closeChildrenNode(realnode)
        this.loadChildrens(realnode,callback)
      }
    },

    findNode(uuid,list){
      if (!list) list = this.tree.items
      for (let item of list){
        if (item.uuid == uuid) return item
        else {
          if (item.children && item.children.length > 0) {
            let cd = this.findNode(uuid,item.children)
            if (cd) return cd
          }
        }
      }
    },

    moveNodeStart(){
      if (this.canWorkWithClassificator) {
        this.moveNode.node = this.findNode(this.menuItem.uuid)
        this.moveNode.snackBarMessage = `${this.$t('section.classification.menuClassification.moving')} ${this.moveNode.node.title}`
        this.toggleNodeLock(this.moveNode.node, true)
      }
    },

    moveNodeEnd(){
      if (this.canWorkWithClassificator) {
        let toNode = this.findNode(this.menuItem.uuid)
        api.smeta.moveNodeTo(this.moveNode.node.uuid, toNode.uuid).then(() => {
          
          if (this.projectClassificatorSelected.uuid == this.projectSettings.projectClassificator) {
            this.moveElement({moveUuid:this.moveNode.node.uuid, toUuid:toNode.uuid})
          }

          if (!toNode.children) this.$set(toNode,'children',[])
          this.closeChildrenNode(toNode)
          this.loadChildrens(toNode)
          this.$emit('update',{projectClassificator: this.projectClassificatorSelected, node: toNode})

          if (this.moveNode.node.parent) {
            let par = this.findNode(this.moveNode.node.parent.uuid)
            if (par) {
              let ind = par.children.findIndex(e => e.uuid == this.moveNode.node.uuid)
              if (ind > -1) par.children.splice(ind,1)
            }
            if (this.moveNode.node.nodeType.name == 'TOPIC') {
              this.moveNode.node.parent.projectClassificatorUuid = this.moveNode.node.projectClassificatorUuid
              if(this.moveNode.node.parent){
                let par = this.findNode(this.moveNode.node.parent.uuid)
                this.closeChildrenNode(par)
                this.loadChildrens(this.moveNode.node.parent)
              }
              
            }
            this.$emit('update',{projectClassificator: this.projectClassificatorSelected, node: par})
          } else {
            let ind = this.tree.items.findIndex(e => e.uuid == this.moveNode.node.uuid)
            if (ind > -1) this.tree.items.splice(ind,1)
          }

          this.toggleNodeLock(this.moveNode.node, false)
          this.moveNode = {moveSnackBar:false, snackBarMessage:null, node:null}
        })
      }
    },

    cancelNodeMove(){
      this.toggleNodeLock(this.moveNode.node, false)
      this.moveNode = {moveSnackBar:false, snackBarMessage:null, node:null}
    },

    toggleNodeLock(node, lock){
      node.locked = lock
      if (node.children){
        node.children.forEach(n => this.toggleNodeLock(n, lock))
      }
    },

    upodateNode(item){
      if (this.canWorkWithClassificator) {
        if (this.searchMode) this.$refs.nodeSearchView.updateNode(item)
        else {
          let node = this.findNode(item.uuid, this.tree.items)
          if (node) node = Object.assign(node,item)
        }
        this.$emit('update',{projectClassificator: this.projectClassificatorSelected, node: item})
      }
    },

    showImportDialog() {
      this.importDialog = true
    },

    showExportDialog() {
      this.exportDialog = true
    },

    imported(data){
      this.listProjectClassofocators()
      this.projectClassificatorSelected = data
      this.loadClassificatorNodes()
      this.$emit('update',{projectClassificator: this.projectClassificatorSelected, node: null})
    }
  }
};
</script>

<style lang="scss" scoped>
.h500 {
  height: 500px;
}

.p-relative {
  position: relative;
}

.nodeLabel {
  text-overflow: ellipsis;
  overflow: hidden;
}

.t-scroll-body {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: var(--v-primary-lighten4) var(--v-surface-lighten5);
}

.mh400 {max-height: 400px;}
.mh500 {max-height: 500px;}

.t-scroll-body::-webkit-scrollbar {
    width: 5px;
    background-color: var(--v-surface-lighten5);
}

.t-scroll-body::-webkit-scrollbar-thumb  {
   background-color: var(--v-primary-lighten4);
}

::v-deep.text-nopadding {
  .v-text-field__details {
    padding: 0px;
    white-space: normal;
  }
}

.bg-white {
  background-color: white;
}
  
</style>