<template lang="pug">
app-toolbar( horizontal )
  app-toolbar-button( :title="$t('section.elementTree.title')" :active="topUnit=='elementTree'" icon="$new-project-icon" icon-size="24" @click="setUnit(topPanelEnum.elementTree)" ) 
  app-toolbar-button( :title="$t('projectToolbarMenu.tasks')" :active="topUnit=='task'" icon="$format-list-icon" icon-size="32" test-id="layer-top-task-btn" @click="setUnit(topPanelEnum.task)" )
  app-toolbar-button( :title="$t('projectToolbarMenu.notifications')" :active="topUnit=='notification'" :badge="hasNotification" icon="$notification-icon" icon-size="32" test-id="layer-top-notif-btn" @click="setUnit(topPanelEnum.notification)" )
  app-toolbar-button( :title="$t('projectToolbarMenu.activeProcess')" :active="topUnit=='process'" :badge="hasActiveProcess" icon="$forward-icon" icon-size="32" test-id="layer-top-process-btn" @click="setUnit(topPanelEnum.process)" )
  app-toolbar-button( :title="$t('projectToolbarMenu.user')" :active="topUnit=='profileSettings'" icon="$admin-icon" icon-size="32" @click="setUnit(topPanelEnum.profileSettings)" )
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { topPanelEnum } from '@/store/sections/LayoutRepository'

export default {
  computed: {
    ...mapState('project', ['topUnit']), 
    ...mapGetters('notification', ['hasNotification']),
    ...mapGetters('process', ['hasActiveProcess']),

    topPanelEnum() {
      return topPanelEnum
    }
  },

  mounted() {
    // TODO: проверить - это было в оригинале ProjectLayerTop, но хз нужно ли это вообще
    this.setTopUnit(this.topUnit)
    this.setFilterPanel(false)
    this.setSelectedTask(null)
  },

  methods: {
    ...mapMutations('project', ['setTopUnit']), 
    ...mapMutations('task', ['setFilterPanel', 'setSelectedTask']),
    ...mapActions('project', ['postPanelsStatus']),

    setUnit (unit = '') {
      if (this.topUnit !== unit) { 
        this.setTopUnit(unit)
        this.setSelectedTask(null)
      } else { 
        this.setSelectedTask(null)
        this.setTopUnit(topPanelEnum.DEFAULT)
      }
      this.postPanelsStatus()
    },
  }
}
</script>