<template lang="pug">
  app-panel( width="256" shadowPanel ident="notification")
    app-panel-header( :title="$t('module.notification.title')" :menu="menu" hideClose)
      template(#menu)
        app-icon-button.ml-1(icon="$task-menu-icon" iconSize="16"  @click.native="showTypeMenu") {{ $t('button.filter')}}

    div.t-scroll-body#virtual.maxWi(:class="{'block-notification' : !notificationsList.length }")
      div( v-if="!notificationsList.length && !notificationLoaded") 
        .text-center {{ $t('module.notification.empty') }}
      
      .notification-loading.d-flex.justify-center.align-center(v-if="notificationLoaded")
        //- v-progress-circular(size="25" indeterminate)
        v-progress-linear(color="accent" indeterminate)
      
      div.fill-height.base-scroll-body(v-else)
        div(v-for="item in newNotifications")
          v-hover(#default="{ hover }")  
            .wrapper.my-2( :class="{'new-notification': !item.readStatus.value}")
              v-btn.mt-n3.mr-n3( v-if="hover" x-small dense icon light absolute top right @click="hideNotification(item)" )
                v-icon(color="#C4C4C4") delete
              .pa-2
                .d-flex.align-center
                  app-avatar.d-inline-flex.mr-2(v-if="item.author" :url="avatarUrl(item.author)" :text="item.author.name" :colorObj="getBgColor(item.author.uuid)" tooltipLeft random)
                  
                  v-tooltip(bottom open-delay=300)
                    template(v-slot:activator='{ on, attrs }')
                      app-text.d-inline-flex.mt-1.notification-text(v-bind='attrs' v-on='on' v-if="item.info" v-html="$t('info_' + item.uuid)")
                    .tooltip-text( v-html="$t('info_' + item.uuid)")

                .title.notification-info(v-if="item.sourceType == 'Task'" v-html="$t('sourceTitle_' + item.uuid)")
                .title.notification-info(v-if="(!item.sourceType || item.sourceType == 'LongActionResult') && item.notificationType.name !== 'PLUGIN_SCRIPT_ACTION'") {{item.projectName.replace('->', '')}}
                
                .pt-2(v-if="item.sourceType != 'Task'")
                  span(style="color:#757575" v-if="!forThisProject") {{item.projectName}}

                  //- span( v-if="item.sourceTitle" v-html="$t('sourceTitle_' + item.uuid)")

                  v-tooltip(bottom :disabled="checkCollisionRulesLength(item)")
                    template(v-slot:activator='{ on, attrs }')
                      span.d-inline-flex( :class="{'notification-text' : !checkCollisionRulesLength(item) || isReport(item)}" v-bind="attrs" v-on="on" v-if="item.sourceTitle" v-html="$t('sourceTitle_' + item.uuid)")
                    .attachment-tooltip
                      .tooltip-text(v-html="$t('sourceTitle_' + item.uuid)")  

              .project-info.px-2(v-if="item.sourceType == 'Task' || item.sourceType == 'WorkSpace'" :class="{'px-2' : !forThisProject }")
                span.caption(v-if="!forThisProject") {{item.projectName}}
                span.caption.word(v-if="item.workSpace" v-html="$t('workSpace' + item.uuid )" :class="{'' : forThisProject }")

              .time-and-button.pa-2
                .caption.mt-1 {{ toLocalTimeZone(item.sendDate.replace(" ", "T")) }}
                
                .d-flex.justify-end(v-if="item.sourceType === 'Task' && item.source && item.source.project && projectUuid === item.source.project && !item.taskArchived")
                  v-btn(color="blue" @click="openTask(item)" text x-small test-id = 'notification-open-task') {{ $t('module.task.openTask') }}
                .d-flex.justify-end(v-if="item.sourceType === 'Task' && item.source && item.source.project && projectUuid !== item.source.project && !item.taskArchived")
                  v-btn(color="blue" @click="openWindowTask(item.source)" text x-small test-id = 'notification-open-task') {{ $t('module.task.openTask') }}
                .d-flex.justify-end(v-if="checkReloadButton(item)")
                  v-btn(color="blue" @click="reloadCommand(item)" text x-small) {{ $t('module.mnemoschemas.viewerHeaderAction.refresh') }}
                
                .d-flex.justify-end(v-if="item.sourceType === 'LongActionResult' && item.source && item.source.type.name === 'FILE' && item.source.result != ''")
                  v-btn(color="blue" @click="downloadLongActionResultFile(item.source)" text x-small) {{ $t('download') }}

                .d-flex.justify-end(v-if="item.sourceType === 'ReportInfo' && item.source && item.source.reportName")
                  v-btn(color="blue" @click="downloadReport(item.source)" text x-small) {{ $t('download') }}
        .notifications_loading(v-if="!loadStateNotification")
          //- v-progress-circular(size="25" indeterminate color="grey")
          v-progress-linear(color="accent" indeterminate)
      .d-flex.justify-center
        app-button-add.newNotification-alert.mt-3.mr-1( v-if="newNotificationStatus"  width="100%" :text="$t('module.notification.newNotification')" @click="newNotificationDialog") 
    
    app-dialog-confirm(v-model="dialog.delete" @confirm="deleteAll") 
      app-text {{ $t('module.notification.deleteAllNotifications') }}? 

    app-dialog-confirm(v-model="dialog.deleteNotification" @confirm="deleteSelected")
      app-text {{ $t('module.notification.deleteNotification') }}?

    notification-filter-menu(ref="typeMenu" :menu="menu" @selectFilter="selectFilter")
</template>

<script>
import {NotificationService} from "@models/notifications/NotificationService"
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { SourcePath } from '@/assets/app/SourcePath'

import { config, $_app_server } from '@/_helpers'
import { authUserService } from '@/_services'
import { send, sendProject} from "/src/api/socket/notificationSocket.js"
import uniqolor from 'uniqolor';
import socketActions from "/src/api/socket/notificationSocketActions.js"
import NotificationFilterMenu from '@/components/project/common/NotificationFilterMenu.vue'
// import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

function openRequestedPopup(url) {
  window.open(url, '_blank');
}

export default {
  name: 'ProjectNotificationList',

  data () {
    return {
      dialog: {
        delete: false,
        deleteNotification: false,
      },
      page: 0,
      selectedNotification: null,
      isTypeMenuVisible: false,
      forThisProject: false,
      selectedFilters: [],
      unread: false,
      notificationLoaded: false
    }
  },

  components: { NotificationFilterMenu },

  beforeMount() {
    if(this.topUnit) {
      this.forThisProject = true
    }
    
    this.notificationLoaded = true

    this.getNotifications().then(() => {
      this.notificationLoaded = false
    })
  },

  mounted() {
    this.readingNotificationList()
    document.querySelector("#virtual > div").onscroll = this.notifiactionScrollListener
  },

  beforeDestroy() {
    this.setNewNotificationStatus(false)
    clearTimeout(this.notificationReadingTime)
  },

  computed: {
    ...mapState('project', ['topUnit', 'projectSettings','selectedModel']),
    ...mapState('notification', ['notificationsList', 'notificationPageInfo', 'loadStateNotification', 'newNotificationStatus']),
    ...mapGetters('project',['projectUuid', 'flatlist']),
    ...mapGetters('authUser',['toLocalTimeZone']),
    ...mapGetters('notification', ['hasNotification']),
    ...mapGetters('authUser', ['myUUID']),

    checkReadStatus() {
      
      let unreadedNotList = []
      
      this.newNotifications.map(el => {
        if(!el.readStatus.value) {
          unreadedNotList.push(el.uuid)
        }
      })
      return unreadedNotList
    },

    newNotifications() {
      return this.notificationsList.map( item => { 
        let keyInfo = "info_" + item.uuid
        if (this.$t(keyInfo) === keyInfo)
          NotificationService.NotificationRebuild(item.info, keyInfo);

        let keySource = "sourceTitle_" + item.uuid
        if (this.$t(keySource) === keySource) {
          let sourceTitle = this.parseSourceTitle(item)
          NotificationService.NotificationRebuild(sourceTitle, keySource)
        }

        
        let keyProject = "workSpace" + item.uuid
        if ( item.workSpace && this.$t(keyProject) === keyProject)
          NotificationService.NotificationRebuild(this.getWorkSpaceTranslate(item.workSpace), keyProject)
        
        return item
      }) 
    },

    menu () {
      return [
        { name: "TASK", title: this.$t('module.notification.task'), checked: false },
        { name: "MODELS", title: this.$t('module.notification.revision'), checked: false },
        { name: "COLLISION", title: this.$t('module.notification.collision'), checked: false },
        { name: "FORTHISPROJECT", title: this.$t('module.notification.forThisProject'), checked: !!this.projectUuid },
        { name: "UNREAD", title: this.$t('module.notification.unread'), checked: false },
        { name: "READALL", title: this.$t('module.notification.readAll') },
        { name: "DELETEALL", title: this.$t('module.notification.deleteAll') },
      ]
    },
  },

  methods: {
    ...mapActions('notification', ['getNotificationsList', 'deleteNotification', 'deleteAllNotificationWithProject', 'deleteAllNotifications', 'readAllNotificationWithProject', 'readAllNotification']),
    ...mapMutations('notification', ['setNoficationInfo', 'setNewNotificationStatus']),
    ...mapMutations('project', ['setSelectedModel']),
    ...mapMutations('task', ['setSelectedTask', 'setTaskIsLoad', 'setTasks']),
    ...mapActions('task', ['loadTasks']),
    ...mapMutations('project', ['setTopUnit']),
    ...mapActions('project', ['loadProject', 'updateSettings', 'makeRevisionActive']),
    ...mapActions('patches', ['loadPatchList']),
    ...mapActions('workspace', ['changeWorkSpace']),

    isAlias(sourceTitle) {
      if (sourceTitle) return sourceTitle.includes('@:') && sourceTitle[sourceTitle.length-1] !=')'
    },

    parseSourceTitle(item) {
      if(this.isAlias(item.sourceTitle)) {
        let index = item.sourceTitle.indexOf(')')
        let translateString = NotificationService.NotificationTranslateString(item.sourceTitle.slice(index+1))
        
        return item.sourceTitle.slice(0, index+1) + translateString
      }

      return item.sourceTitle
    },

    checkCollisionRulesLength(el) {
      if(el.sourceType == "LongActionTask" && el.sourceTitle && el.sourceTitle.includes(',')) {
        return el.sourceTitle.split(',').length < 4
      }
      return true
    },

    isReport(el){
      return el.sourceType == "ReportInfo"
    },

    checkReloadButton(item) {
      return (item.notificationType.name === 'REVISION_READY' 
      || item.notificationType.name === 'COLLISIONS_READY' 
      || item.notificationType.name === 'PATCH_READY' 
      || item.notificationType.name === 'APPLY_REVISION_DELETED') 
      && (item?.author?.uuid != this.myUUID) && this.projectUuid
    },

    reloadCommand(el) {
      let bodyRevision
      
      switch (el.notificationType.name) {      
        case 'REVISION_READY':
          
          this.loadProject(this.projectUuid).then(() => {
            this.flatlist.map(q => {
              if(q.uuid == el.source.modelUuid) {
                bodyRevision = q.revision
              }
            })

            this.makeRevisionActive({ modelUuid: el.source.modelUuid, rev: bodyRevision })
          })          
          break

        case 'PATCH_READY':
          this.loadPatchList()
          break
      }
      this.loadProject(this.projectUuid)
    },

    readingNotificationList() {
      this.notificationReadingTime = setTimeout(() => {
        if(this.checkReadStatus.length) {
          this.readAllNotifications()
        } 
      }, 2000);
    },

    changeNotificationStatus() {
      this.newNotifications.forEach(obj => {
        if(!obj.readStatus.value) obj.readStatus.value = 1
      });
    },

    newNotificationDialog() {
      this.page = 0
      document.getElementById("virtual").scrollTo(0,0)
      this.getNotifications()
      this.readingNotificationList()
      this.setNewNotificationStatus(false)
    },

    selectFilter(selectedFilter) {
      
      if (selectedFilter == 'READALL') {
        return this.readAllNotifications()
      }

      if(selectedFilter === 'DELETEALL') {
        return this.dialog.delete = true  
      }

      if (selectedFilter == 'TASK' || selectedFilter == 'COLLISION' || selectedFilter == 'MODELS') {
        if(!this.selectedFilters.includes(selectedFilter)) {
          this.selectedFilters.push(selectedFilter)
        }
  
        else {
          let idx = this.selectedFilters.indexOf(selectedFilter)
          this.selectedFilters.splice(idx, 1)
        }
      }

      if(selectedFilter === 'FORTHISPROJECT') {
        this.forThisProject = !this.forThisProject 
      }
      
      if (selectedFilter === 'UNREAD') {
        this.unread = !this.unread
      }

      this.getNotifications()
      this.readingNotificationList()
    },

    getNotifications(page) {
      let notificationFilterInfo = {
        filterByType: this.selectedFilters,
        notRead: this.unread,
        forThisProject: this.forThisProject,
        projectUuid: this.projectUuid
      }

      this.setNoficationInfo(notificationFilterInfo)

      return this.getNotificationsList(page)
    },

    showTypeMenu (event) { 
      if (this.isTypeMenuVisible) {
        this.$refs.typeMenu
      } else {
        this.$refs.typeMenu.show(event)
      }
      this.isTypeMenuVisible = !this.isTypeMenuVisible
    },
    hideTypeMenu() {
      this.$refs.typeMenu.hide()
    },

    readAllNotifications() {
      if(this.topUnit=='notification' && this.projectUuid && this.forThisProject) {
        this.readAllNotificationWithProject(this.projectUuid).then(() => {
          this.changeNotificationStatus()
          sendProject()
          
        })
      }
      else {
        this.readAllNotification().then(() => {
          this.changeNotificationStatus()
          send()
        })
      } 

    },

    deleteSelected () {
      
      this.dialog.deleteNotification = false
      
      this.deleteNotification(this.selectedNotification.uuid)

      if(this.topUnit) {
        sendProject()
      }
      else {
        send()
      }

      this.selectedNotification = null
    },

    deleteAll () {
      if (this.topUnit && this.projectUuid && this.topUnit !== 'DEFAULT') {
        this.deleteAllNotificationWithProject(this.projectUuid)
        sendProject()
      }
      if (this.topUnit == 'DEFAULT') {
        this.deleteAllNotifications()
        send()
      } 

    },

    hideNotification(n){
      this.dialog.deleteNotification = true
      this.selectedNotification = n
    },  

    openTask (task) {
      this.changeWorkSpace(task.source.workSpace).then(() => {
        task.short = true
        this.setSelectedTask(task.source)
        this.setTopUnit('task')

        this.$nextTick(() => {
          if (task.info == "@:(notify.task.name.commentToTask)" || task.info == "@:(notify.task.name.attachmentToTask)") {
            let infoTask = document.querySelector('.taskInfo')
            let comment = document.getElementById(`${task.sourceData}`)
            document.getElementById(`${task.sourceData}`).classList.add('selectedComment')
            infoTask.scrollTo(0, comment.offsetTop)
          }
        })
      })
    },

    openWindowTask (task) {
      openRequestedPopup('/project/' + task.project + '?selectedTask=' + task.uuid + '&workSpace=' + (task.workSpace ?? ''));
    },

    avatarUrl (author) {
      return SourcePath.avatar(author.avatar)
    },

    getBgColor(uuid) {
      return uniqolor(uuid, { format: 'rgb' })
    },

    downloadLongActionResultFile(source){
      let send = () => {
        $_app_server({
          url: `${config.apiUrl}longactionresult/${source.uuid}/file`,
          method: 'GET',
          responseType: 'blob'
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${source.result}`);
          document.body.appendChild(link);
          link.click();
          link.remove()
        }).catch( (error) => {
          console.log(error)
          if (error.response !== undefined) {
            console.log(error.response.status)
            if (error.response.status === 401){
              authUserService.refreshToken().then(() => {
                send()
              }).catch( (error) => {
                console.log(error)
              })
            }
          }
        })
      }

      send()
    },

    downloadReport(source) {
      let send = () => {
        socketActions.getGenerateReport(source).catch( (error) => {
          console.log(error)
          if (error.response !== undefined) {
            console.log(error.response.status)
            if (error.response.status === 401){
              authUserService.refreshToken().then(() => {
                send()
              }).catch( (error) => {
                console.log(error)
              })
            }
          }
        })
      }

      send()
    },

    notifiactionScrollListener(e) {
      let scrollTop = e.target.scrollTop
      if (
        e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
        this.notificationPageInfo.totalPages >= this.page && !this.notificationPageInfo.last
      ) {
        this.page++
        this.getNotifications(this.page)
        this.readingNotificationList()
      }

      this.$nextTick(() => {
        e.target.scrollTop = scrollTop 
      })
    },

    getWorkSpaceTranslate(title) {
      if (!title) return ""

      if (title.includes("{"))
        return this.$t(title.replace("{", "").replace("}", ""))

      return title
    },
  }
}
</script>

<style>
.selectedComment{
  box-shadow: 0px 0px 2px 2px #4d4d4d !important;
}
</style>

<style lang="scss" scoped>
.notifications_loading{
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}
.wrapper {
  position: relative;
  border: 1px solid #C4C4C4;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0 0 5px #282828;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 2px !important;
}
.dict-button {
  display: block;
  font: normal 12px/24px $roboto;
  text-transform: none;
}
.t-scroll-body {
  height: calc(100%);
}
.notification-info {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 0 0 0;
}
.checkbox {
  margin-top: 4px;
  display: flex;
  color: var(--v-accent-base) !important;
  font-size: 12px;
}
.block-notification {
  display: flex;
  flex-wrap:nowrap;
  justify-content: center;
  align-items: center;
}

.new-notification {
  background: #dff6fd;
}

.time-and-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-virtual-scroll::-webkit-scrollbar {
  display: none !important;
}

// .v-scroll-block {
//  margin: -8px 0px -5px -5px;
// }

.newNotification-alert {
  position: absolute;
  top: 40px;
  width: 88%;
  background: #2196f3;
  color: #ffffff;
}
.notification-text {
  max-width: calc(100% - 50px);
}

::v-deep.notification-text span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::v-deep.tooltip-text .notification-title {
  color: #fff !important;
  // padding: 0 20px;
}
.notification-loading {
  width: 100%;
}
// .collision-notification {
//   }
// ::v-deep.collision-notification span{
//   max-width: calc(100% - 10px);
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
// }

.attachment-tooltip{
  white-space: pre-wrap;
  word-break: break-all;
  max-width: 350px;
}
.word{
  word-wrap: break-word; 
}
// .maxWi {
//   max-width: 256px;
// }
</style>