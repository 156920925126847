<template lang="pug">
  div
    app-dialog(v-model="dialog.repositoryPlugins" :header="$t('plugin.info.publicRepositoryPlugin')" width="40%" @close="pluginInfo = null")
      div.d-flex
        app-section.plugin__section_height.base-scroll-body(:style="sectionWidth")
          v-treeview.__project_view_tree(dense light hoverable  return-object  item-key="uuid" :items="repPluginTree")
            template(v-slot:label="{ item }")
              template(v-if="item.children") {{ item.name }}
              template(v-else)
                .d-flex.align-center.pb-2
                  v-avatar.mr-4(v-if="item.avatar" color="grey" size="20")
                    v-img(:src="getPluginAvatar(item.uuid)" contain)
                  .pointer(@click="setActivePluginInfo(item)") {{ item.name }}
          v-divider.mt-3.mb-3(light)
          v-treeview.__project_view_tree(dense light hoverable return-object item-key="uuid" :items="myRepPluginTree")
            template(v-slot:label="{ item }")
              template(v-if="item.children") {{ item.name }}
              template(v-else)
                .d-flex.align-center.pb-2
                  v-avatar.mr-4(v-if="item.avatar" color="grey" size="20")
                    v-img(:src="getPluginAvatar(item.uuid)" contain)
                  .pointer(@click="setActivePluginInfo(item)") {{ item.name }}
        app-section.ml-3.base-scroll-body.plugin__section_height.overflow-x-hidden(style="width: 50%" v-if="pluginInfo")
          v-row.align-center(no-gutters)
            v-col(cols=1)
              v-avatar.mr-4(v-if="pluginInfo.avatar" color="grey" size="30")
                v-img(:src="getPluginAvatar(pluginInfo.uuid)" contain)
            v-col
              app-text.mb-3.pt-3(style="text-align: center; font-size: 16px") {{ $t('plugin.info.infoHeader', { val: this.pluginInfo.name }) }}
            v-col(cols=1)
              v-btn( v-if="menuIsExist" icon color="#757575" @click.stop="showActionRepPlugin($event)")
                v-icon mdi-dots-vertical
                
          div(v-if="pluginInfo")
            app-text {{ $t("plugin.info.createdDate", { val: pluginInfo.createdDate }) }}
            app-text {{ $t("plugin.info.modifiedDate", { val: pluginInfo.modifiedDate }) }}
            app-text {{ $t("plugin.info.rating", { val: pluginInfo.rating }) }}
            app-text {{ $t("plugin.info.version", { val: pluginInfo.version }) }}
            app-text.mt-1 {{ $t("plugin.info.description") }}
            md-textarea(:text="pluginInfo.description" height="100%")
            app-text.mt-1(v-if="pluginInfo.validateType.name === 'DENIED'") {{ $t("plugin.info.comment") }}:
            span(v-if="pluginInfo.validateType.name === 'DENIED'" style="color: black; overflow-wrap: break-word; white-space: pre-wrap;") {{ this.pluginInfo.rejectionReason }}
        app-menu(ref="actionRepPlugin" :menu="actionRepPlugin")


    app-dialog(v-model="dialog.publishPlugin" :header="$t('plugin.info.publication')" @confirm="publishPlugin" :confirmText="$t('plugin.info.publish')" :confirmDisabled="confirmDisabled")
      app-section
        app-text-field(v-model="repPgInfo.name" :label="$t('plugin.info.name')")
        app-file-input(v-model="repPgInfo.avatar" :rules="avatarRules" formats=".png, .jpeg, .jpg" :label="$t('plugin.avatar.pluginAvatar')" prepend-icon="mdi-camera" small-chips dense light show-size)
        app-textarea(v-if="!repPgInfo.showMd" v-model="repPgInfo.description" :label="$t('plugin.info.pluginDescription')" no-resize height="150")
        md-textarea.mb-3(v-show="repPgInfo.showMd" :text="repPgInfo.description" ref="mdTextarea" height="400px")
        div.d-flex.justify-space-between
          v-btn(color="accent" @click="repPgInfo.showMd = !repPgInfo.showMd" x-small outlined) {{ repPgInfo.showMd ? $t('plugin.info.edit') : $t('plugin.info.preview')}}
          v-btn(color="accent" @click="showInfo" x-small icon :title="$t('plugin.info.formattingHelp')")
            v-icon help
</template>

<script>
  import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
  import MdTextarea from '@/components/project/panel/left/plugin/MdTextarea.vue'
  import { AlertService } from '@app/AlertService'
  import { pluginService } from '@/_services'
  import { config } from '@/_helpers'

  export default {
    name: 'PluginRepositoryComponents',
    components: {
      MdTextarea,
    },

    props: {
      pluginActiveInfo: Object,
    },

    data() {
      return {
        dialog: {
          deletePlugin: false,
          methods: false,
          publishPlugin: false,
          repositoryPlugins: false,

          method: {
            createCondition: false,
          },
        },
        repPgInfo: {
          showMd: false,
          name: null,
          description: null,
          avatar: null,
        },
      }
    },

    computed: {
      ...mapState('plugins', ['types', 'repositoryPlugins', 'loadedPlugin', 'validatePlugin', 'deniedPlugin']),
      ...mapGetters('authUser', ['myUUID']),

      pluginInfo: {
        get() {
          return this.pluginActiveInfo
        },
        set(value) {
          this.$emit('update:pluginActiveInfo', value)
        },
      },

      menuIsExist() {
        return !!this.actionRepPlugin && this.actionRepPlugin?.length > 0
      },

      avatarRules(){
        return [value => !value || value.size < (1<<20) * 10 || this.$t('plugin.avatar.fileSize'),
          value => !value || value.type === 'image/png' ||  value.type === 'image/jpeg' || this.$t('plugin.avatar.invalidFile')
        ]
      },

      confirmDisabled(){
        if(!this.repPgInfo.avatar)
          return false
        else return !((this.repPgInfo.avatar.type === 'image/png' || this.repPgInfo.avatar.type === 'image/jpeg') && this.repPgInfo.avatar.size < (1<<20) * 10)
      },

      sectionWidth() {
        return this.pluginInfo ? 'width: 50%' : 'width: 100%'
      },

      repPluginTree() {
        let tree = this.types.map((t) => {
          let it = {
            name: t.title,
            uuid: t.value,
            children: this.repositoryPlugins.filter((e) => e.type.value == t.value),
          }
          return it
        })
        return tree
      },

      myRepPluginTree() {
        let tree = []

        tree.push({name: this.$t('plugin.info.underReview'), uuid: '1', children: this.validatePlugin})
        tree.push({name: this.$t('plugin.info.rejected'), uuid: '2', children: this.deniedPlugin})

        return tree
      },

      actionRepPlugin() {
        let menu = []
        if (this.pluginInfo?.validateType.name === 'PUBLISHED')
          menu.push({ title: this.$t('plugin.info.copyPlugin'), action: () => this.$emit('loadRepPlugin') })
        if (this.myUUID === this.pluginInfo?.profileUuid)
          menu.push({ title: this.$t('plugin.info.editPlugin'), action: () => this.$emit('editRepPlugin') })
        if (this.myUUID === this.pluginInfo?.profileUuid)
          menu.push({ title: this.$t('plugin.info.deletePlugin'), action: () => this.$emit('deletePlugin') })

        return menu
      },
    },

    methods: {
      ...mapActions('plugins', ['addRepositoryPlugin', 'getValidatePlugins', 'getDeniedPlugins']),
      ...mapMutations('plugins', ['setPlugins', 'setLoadedPlugin', 'setLoadedPluginCode']),

      showRepPluginList() {
        this.dialog.repositoryPlugins = true
      },

      hideRepPluginList() {
        this.dialog.repositoryPlugins = false
      },

      showPublishDialog() {
        this.repPgInfo.name = null
        this.repPgInfo.avatar = null
        this.repPgInfo.description = null
        this.repPgInfo.showMd = false
        this.dialog.publishPlugin = true
      },

      showInfo() {
        this.$refs.mdTextarea.showInfo()
      },

      publishPlugin() {
        let repPlugin = Object.assign({}, this.loadedPlugin)
        repPlugin.name = this.repPgInfo.name
        repPlugin.description = this.repPgInfo.description

        this.addRepositoryPlugin(repPlugin).then((data) => {
          if (this.repPgInfo.avatar) {
            let obj = {
              uuid: data.uuid,
              file: this.repPgInfo.avatar,
            }
            pluginService.setRepositoryPluginAvatar(obj)
          }
          AlertService.info({ info: this.$t('plugin.alert.pluginSubmitReview') })
        })
      },

      setActivePluginInfo(item) {
        this.pluginInfo = item
      },

      showActionRepPlugin($event) {
        this.$refs['actionRepPlugin'].show($event)
      },

      getPluginAvatar(pluginScriptUuid) {
        return config.pluginsUrl + `plugins/repository/${pluginScriptUuid}/avatar`
      },
    },
  }
</script>

<style scoped>
.plugin__section_height {
  height: 75vh !important;
}
</style>
