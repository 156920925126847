<template lang="pug">
app-panel(ident="maintenancepanel" left v-bind="$attrs" :class="{'w70' : fullScreenFlag }" )

    .d-flex
      v-tabs(v-model="tab" height="36px" background-color="transparent" )
        v-tabs-slider(color="var(--v-primary-darken2)")
        v-tab {{ $t('section.journal.subsystems') }}
        v-tab {{ $t('section.journal.chart') }}
        v-tab {{ $t('section.journal.mnemoschemas') }}
        v-tab {{ $t('section.journal.eventHistory') }}
        v-tab {{ $t('section.journal.channels') }}
        

      app-icon-button( icon="mdi-close" @click="handleClose" )
      
    v-tabs-items(v-model="tab" light  style="height: 100%")
      v-tab-item(eager style="height: 100%" key="0")
        subsystems(ref="subsystems")

      v-tab-item(eager style="height: 100%" key="1")
        schedule(ref="schedule" @goToEquipment="goToEquipment")
      
      v-tab-item(eager style="height: 100%" key="2")
        mnemoschemas(ref="mnemoschemas")

      v-tab-item(eager style="height: 100%" key="3")
        eventlog(ref="eventlog" @goToEquipment="goToEquipment")

      v-tab-item(eager style="height: 100%" key="4")
        //- channelPanel(ref="channel")
        channels(ref='channels')

</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import Subsystems from './Subsystems.vue'
import Mnemoschemas from './Mnemoschemas.vue'
import Eventlog from './Eventlog.vue'
import Schedule from './Schedule.vue'
import Channels from './Channels.vue'
import ChannelPanel from '../mnemoschemas/components/channels/ChannelPanel.vue'
import { Telemetry } from '@/utils'
import { AlertService } from '@/assets/app/AlertService'

export default {
  name: 'maintenance-panel',

  components: {
    Subsystems,
    Mnemoschemas,
    Eventlog,
    Schedule,
    ChannelPanel,
    Channels,
  },

  data() {
    return {
      tab: 0,
      fullScreenFlag: false,
      socketReopenIdx: 0,
    }
  },

  watch: {
    tab: 'loadByTab',
  },

  mounted() {
    this.$store.dispatch('maintenance/INIT').then(() => {
      if(this.wsFlag) {
        Telemetry.startTelemetry(this.projectUuid)
        Telemetry.on('close', (event) => {
          if (event.code !== 1000 && this.socketReopenIdx !== 5) {
            this.socketReopenIdx ++
            Telemetry.startTelemetry(this.projectUuid)
          }
          else if (this.socketReopenIdx >= 5){
            AlertService.error({
              data: {error_description: this.$t('section.journal.connectionDevices')}
            })
          }
        })
      }
    })
  },

  beforeDestroy() {
    Telemetry.clear()
  },

  computed: {
    ...mapState('maintenance', ['wsFlag']),
    ...mapGetters('project', ['projectUuid']),
    ...mapGetters('theme', ['themeName']),
  },

  methods: {
    ...mapMutations('project', ['setActiveGlobalTab']),

    loadByTab(tab) {
      if (tab == 0) this.$refs.subsystems.loadData()
      else if (tab == 1) this.$refs.schedule.loadData()
      else if (tab == 3) this.$refs.eventlog.loadData()
    },

    handleClose() {
      this.setActiveGlobalTab('')
    },

    handleFullWidth() {
      this.fullScreenFlag = !this.fullScreenFlag
    },

    goToEquipment(item) {
      this.$refs.subsystems.openEquipment(item)
      this.tab = 0
    },
  },
}
</script>

<style scoped>
.v-tab {
  /* opacity:0.5; */
  /* color:#fff; */
  text-transform: none;
  color: var(--v-primary-darken2) !important;
}
/* .v-tab--active{
  opacity:1;
} */

</style>

<style lang="scss">
#maintenancepanel {
  max-height: 100%;
  height: 100%;
  overflow-y: hidden;
}

.w100 {
  width: 100vw !important;
}

.w70 {
  width: 70vw !important;
}
</style>
