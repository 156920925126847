import { defineStore } from 'pinia'
import { isModeOrthogonal, setPerspectiveMode, setOrthogonalMode } from '@/services/viewer/projection.service'

export const useProjectionStore = defineStore('viewer-projection', {
  getters: {
    isModeOrthogonal,
  },

  actions: {
    toggleProjection () {
      this.isModeOrthogonal ? setPerspectiveMode() : setOrthogonalMode()
    },
  }
})