<template lang="pug">
app-dialog( v-model="visible" width="40%" :header="$t('plugin.uploadPlugin')" complex )
  .d-flex
    app-section.base-scroll-body.max-h400( style="width: 50%;height: 75vh;" :rounded="false" )
      app-text {{ $t('plugin.projectPlugins') }}
      v-treeview.__project_view_tree( dense light hoverable return-object item-key="uuid" :items="localPluginsTree" )
        template( v-slot:label="{ item }" )
          template( v-if="item.children" ) {{ item.name }}
          template( v-else ) 
            .pointer( @click="loadPlugin(item)" ) {{ item.name }}

    app-section.base-scroll-body.max-h400( style="width: 50%;height: 75vh; margin-left: 1px;" :rounded="false" )
      app-text {{ $t('plugin.companyPlugins') }}
      v-treeview.__project_view_tree( dense light hoverable return-object item-key="uuid" :items="orgPluginsTree" )
        template( v-slot:label="{ item }" )
          template( v-if="item.children" ) {{ item.name }}
          template( v-else ) 
            .pointer( @click="loadPlugin(item)" ) {{ item.name }}
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { pluginService } from '@/_services'

export default {
  data() {
    return {
      visible: false
    }
  }, 

  computed: {
    ...mapState('plugins', ['types','plugins', 'orgPlugins', 'loadedPlugin']),

    localPluginsTree() {
      let tree = this.types.map(t => {
        let it = {
          name: t.title,
          uuid: t.value,
          children: this.plugins.filter(e => e.type.value == t.value)
        }
        return it
      })
      return tree
    },

    orgPluginsTree() {
      let tree = this.types.map(t => {
        let it = {
          name: t.title,
          uuid: t.value,
          children: this.orgPlugins.filter(e => e.type.value == t.value)
        }
        return it
      })
      return tree
    },
  },

  methods: {
    ...mapActions('plugins', ['getMethodsByPluginType']),
    ...mapMutations('plugins', ['setLoadedPlugin','setLoadedPluginCode']),


    show() {
      this.visible = !this.visible
    }, 

    loadPlugin(item){
      this.setLoadedPlugin(null)
      this.setLoadedPluginCode(null)
      pluginService.load(item.uuid).then(data => {
        this.setLoadedPlugin(data)
        // this.getHintByPluginType(item.type.name)
        this.getMethodsByPluginType(item.type.name)
        this.visible = false
        this.$emit('loadPlugin')
      })
    },
  }
}
</script>