<template lang="pug">
  v-hover( #default="{hover}" )
    .d-flex.flex-column.px-2
      .group-name
        span {{ drawing.name }}

        //- Управление чертежём
        transition(name='contol-button')
          div.ml-auto
            v-btn(color="#C4C4C4" @click='showDrawingControlMenu' small icon)
              v-icon mdi-dots-vertical
            app-menu(ref="drawingControl" :menu='drawingSettings')
                  
      .group-version.d-flex.align-center
        .actual-version {{ $t('module.drawing.actualVersionRow', {number: drawing.currentVersion.versionNum, date: prettyDate(drawing.currentVersion.createDate)}) }}
        v-menu(:close-on-content-click="true")
          template(v-slot:activator='{on, attrs}')
            v-btn(color='#757575' v-bind='attrs' v-on='on' x-small icon)
              v-icon mdi-menu-down
          app-section
            .d-flex.flex-column.black--text( v-if='drawing.versions.length > 0' )
              .drawing-version-row(v-for='version in drawing.versions')
                span(v-if='isActualVersion(version)' :style='versionNameStyle(version)') {{ $t('module.drawing.actualVersionRow', { number: version.versionNum, date: prettyDate(version.createDate)}) }}
                span(v-else :style='versionNameStyle(version)') {{ $t('module.drawing.versionRow', { number: version.versionNum, date: prettyDate(version.createDate)}) }}
                v-btn(small icon @click='downloadSelectedVersion(version)')
                  img(src='/img/project/tools/arrow-down-download.svg')
      
      //- Редактирование чертежа
      app-dialog(v-model='editDrawingDialog.show' :header='$t("module.drawing.editDrawing")')
        app-section.py-4.d-flex.flex-column
          v-menu(nudge-bottom='28' bottom)
            template(v-slot:activator='{on, attrs}')
              div.relative(v-bind='attrs' v-on='on')
                input.edit-drawing-input.pointer(:placeholder='$t("module.drawing.indicateSource")' readonly :value='translateName(editDrawingDialog.drawing.model.name)')
                v-btn.select-button(x-small icon)
                  v-icon(color='#757575') mdi-menu-down
            .d-flex.flex-column
              .model-select-row(v-for='model in preparedModels' @click='editDrawingDialog.drawing.model = model') {{ translateName(model.name) }}
          input.edit-drawing-input(v-model='editDrawingDialog.drawing.name' :placeholder='$t("module.drawing.name")')
          v-btn.ml-auto.mt-1(color='accent' small @click='editDrawing' :disabled='!editDrawingFieldsAreValid' light) {{ $t("button.save") }}

      //- Удаление чертежа
      app-dialog-confirm(v-model='deleteDrawingDialog.show' @confirm="deleteDrawing" )
        app-text {{ $t('module.drawing.deleteConfirm', {name: deleteDrawingDialog.drawing.name}) }}


      //- Вызов проводника для выбора версии
      input(v-show='false' type='file' ref='fileInput' accept='.pdf' @change='addNewVersionForDrawing')
</template>

<script>
import { Drawing } from '@/assets/model/drawing/Drawing'
import { NotificationService } from '@/assets/model/notifications/NotificationService'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "DrawingRow",
  
  props: {
    drawing: {
      type: Drawing,
      required: true
    }
  },

  data() {
    return {
      editDrawingDialog: {
        show: false,
        drawing: {}
      },
      deleteDrawingDialog: {
        show: false,
        drawing: {}
      }
    }
  },
  
  computed: {
    ...mapGetters('project', ['flatlist', 'projectUuid']),
    ...mapGetters('drawings', ['drawingsPanel']),

    drawingSettings () {
      return [
        { title: this.$t("module.drawing.pageSettings"), action: this.editPageDisplaySettings },
        { title: this.$t("module.drawing.addVersion"), action: this.showFileExplorerForNewVersion },
        { title: this.$t("button.edit"), action: this.showEditDrawingDialog },
        { title: this.$t("module.drawing.delete"), action: this.showDeleteDrawingDialog },
      ]
    },
    
    preparedModels () {
      let filteringUUIDs = []
      return this.flatlist.map(original => {
        let m = { ...original }
        if (filteringUUIDs.includes(m.uuid)) {
          m.disabled = true
          filteringUUIDs.push(...m.model.map(i => i.uuid))
        }

        m.title = this.$t( NotificationService.NotificationReplace(m.name) )
        let fulltext = []
        for (let i = 0; i < m.level; i++) fulltext.push('| - ')
        fulltext.push(m.title)
        m.text = fulltext.join('')
        return m;
      })
    },
    editDrawingFieldsAreValid() {
      return this.editDrawingDialog.drawing.name && this.editDrawingDialog.drawing.name.length > 0
    }
  },
  
  methods: {
    ...mapActions('drawings', ['changeDrawing', 'archiveDrawing', 'downloadVersion']),
    ...mapActions('process', ['runDrawingVersionUpload']),

    editDrawing() {
      this.editDrawingDialog.show = false
      this.changeDrawing(this.editDrawingDialog.drawing)
    },

    addNewVersionForDrawing(event) {
      let file = event.target.files[0]

      let newVersionForm = new FormData()
      newVersionForm.append('name', file.name)
      newVersionForm.append('file', file)
      newVersionForm.append('drawingUuid', this.drawing.uuid)
      newVersionForm.append('modelUuid', this.drawing.model.uuid)

      this.runDrawingVersionUpload(newVersionForm)
    },

    deleteDrawing() {
      this.deleteDrawingDialog.show = false
      this.archiveDrawing(this.deleteDrawingDialog.drawing)
    },

    downloadSelectedVersion(version) {
      this.downloadVersion(version).then(file => {
        const url = window.URL.createObjectURL(new Blob([file]));
        const link = document.createElement("a");
        link.href = url;
        
        let downloadName = version.name + ".pdf"
        link.setAttribute("download", downloadName);

        document.body.appendChild(link);
        link.click();
      })
    },

    showEditDrawingDialog() {
      this.editDrawingDialog.drawing = new Drawing(this.drawing)
      this.editDrawingDialog.show = true
    },
    
    showDeleteDrawingDialog() {
      this.deleteDrawingDialog.drawing = new Drawing(this.drawing)
      this.deleteDrawingDialog.show = true
    },

    showFileExplorerForNewVersion() {
      this.$refs.fileInput.click()
    },

    showDrawingControlMenu(event) {
      this.$refs.drawingControl.show(event)
    },

    editPageDisplaySettings() {
      this.$emit('editPageDisplaySettings')
    },

    isActualVersion(version) {
      return version.uuid === this.drawing.currentVersion.uuid
    },

    versionNameStyle(version) {
      return {
        color: version.uuid === this.drawing.currentVersion.uuid ? "#000000" : '#757575'
      }
    },

    prettyDate(date) {
      return date.slice(8, 10) + '.' + date.slice(5, 7) + '.' + date.slice(0, 4);
    },

    translateName (name) {
      return this.$t(NotificationService.NotificationReplace(name))
    }
  }
}
</script>

<style lang="scss" scoped>
.group-name{
  font-size: 18px;
  display: flex;
  line-height: 32px;
}
.group-version{
  font-size: 12px;
}
.drawing-version-row{
  font-size: 14px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.relative{
  position: relative;
}
.edit-drawing-input{
  background: #E5E5E5;
  border: none;
  outline: none;
  height: 32px;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 2px;
  color: #4D4D4D;
  font-size: 14px;
  width: 100%;
}
.select-button{
  position: absolute;
  top: 6px;
  right: 6px;
}
.model-select-row{
  background: white;
  color: #4D4D4D;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
}
.drawing-version-row:hover{
  background: #F8F8F8;
}
.contol-button-enter-active, .contol-button-leave-active {
  transition: opacity .2s;
}
.contol-button-enter, .contol-button-leave-to{
  opacity: 0;
}
.v-icon:hover{
  color: #4D4D4D
}
</style>