<template lang="pug">
v-dialog( v-model="$_show" content-class="app-sud-dialog"
          :persistent="true"
          :hide-overlay="false"
          :internal-activator="true" )
  div( v-if="$_show" )
    .app-dialog.surface
      .drawing_cropping_dialog_header
        v-layout( align-center )
          v-flex
            app-section-header( :title="'Вырезать фрагмент чертежа'" )
          v-flex( shrink )
            app-icon-button( icon="mdi-close" @click="handleClose" test-id="close-app-panel" )
    .d-flex.justify-center.img_body( ref='drawing-page-image-cropping' )
      img.img_size( :src="imgSrc" :draggable="false" )

    .drawing_cropping_dialog__footer.surface( :class="{ 'drawing_cropping_dialog__footer_complex' : false }" )
      .d-flex.flex-row.justify-center
        span Выделите область на чертеже - она встанет на модель
        //- .ml-auto
        //-   app-button( cancel @click="handleClose" ) {{ $t('module.process.cancel') }}
        //-   app-button.ml-2.justify-end( action @click="handleConfirm" test-id="dialog-add-button" ) {{ $t("Добавить") }}
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable'
import { DrawingsHighlightFrame } from './drawingsHighlightFrame'
import { PickImagePlane } from '@/plugins/xeokit/imagePlanes/pickImagePlane'

export default {
  mixins: [DialogToggable],

  data() {
    return {
      title: ""
    }
  },

  props: {
    imgSrc: String,
    originalPageSize: Object
  },

  watch: {
    $_show(value) {
      if (value) {
        this.$nextTick(() => this.activate())
      }
    }
  },

  methods: {
    activate() {
      this.$nextTick(() => DrawingsHighlightFrame.activate(this.$refs['drawing-page-image-cropping'], this.originalPageSize))
    },

    handleClose() {
      DrawingsHighlightFrame.deactivate(this.$refs['drawing-page-image-cropping'])
      this.$_show = false
    },

    handleConfirm() {
      DrawingsHighlightFrame.deactivate(this.$refs['drawing-page-image-cropping'])
      PickImagePlane.waitImagePlanePick()
      this.$_show = false
    },
  },
}
</script>

<style lang="scss" scoped>
.img_body {
  background-color: white;
  // pointer-events: none;
}

.img_size {
  height: max-content; 
  max-height: 79vh;
  max-width: 100%;
  background-color: white;
}

.drawing_cropping_dialog_header {
  padding: 8px 4px 8px 8px;
  font: normal 14px/12px $slab;
}

.drawing_cropping_dialog__footer_complex {
  background-color: white;
}

.drawing_cropping_dialog__footer {
  text-align: right;
  padding: 10px;
}

.drawing_cropping_dialog_confirm-action {
  margin: 0px;
  font: normal 14px/18px $roboto;
  color: #FFFFFF;
}

.textField {
  width: 40%;
}
</style>