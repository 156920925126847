import { defineStore } from 'pinia'
import { fetchMouseNavigation, setMouseNavigation } from '@/services/viewer/navigation.service'
import { MouseNavigationMode as Mode } from '@/constants/viewer.constants'

export const useNavigationStore = defineStore('viewer-navigation', {
  getters: {
    isCurrentModePro: () => fetchMouseNavigation() === Mode.pro,
  },

  actions: {
    toggleNavigation () {
      let nextMode = this.isCurrentModePro ? Mode.custom : Mode.pro
      setMouseNavigation(nextMode)
    },
  }
})