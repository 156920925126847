<template lang="pug">
app-panel.pl-2( padding="0px" )
  app-floating-panels-section( style="height: calc(100% - 26px) !important" )
    app-floating-panel( title="Связи" bodyMinHeight="150" overflowBody="hidden" 
                       :settings="floatingPanels('CLASSIFICATION_ANALYZE_LINKS')")
      app-section.connections-section.base-scroll-body( id="appSection" )
        SmetaConnectionsSharedCard( v-if="!sharedInfo.isEmpty" :sharedInfo="sharedInfo" )
        SmetaConnectionsLinkCard( v-for="link in smetaLinks" :linkInfo="link" @openLink="openLink" )

</template>

<script>
import { mapGetters } from 'vuex';
import SmetaConnectionsSharedCard from '../components/connections/SmetaConnectionsSharedCard.vue'
import SmetaConnectionsLinkCard from './connections/SmetaConnectionsLinkCard.vue';
import { useSmetaConnectionsStore } from '@/pinia/smeta/smetaConnections.store.js';

const smetaConnectionsStore = useSmetaConnectionsStore()

export default {
  components: {
    SmetaConnectionsSharedCard,
    SmetaConnectionsLinkCard
  },

  computed: {
    ...mapGetters('floatingPanels', ['floatingPanels']),
    ...mapGetters('project', ['projectUuid']),
    sharedInfo: () => smetaConnectionsStore.organizationInfo,
    smetaLinks: () => smetaConnectionsStore.smetaLinksByParent
  },

  methods: {
    openLink(link) {
      if (this.projectUuid != link.project.uuid) {
        localStorage.setItem("smeta_link", JSON.stringify(link))
        window.open(`${window.location.origin}/project/${link.project.uuid}`, '_blank');
      } else { 
        smetaConnectionsStore.openUnit(link)
      }
    }
  }

}
</script>

<style scoped>
.connections-section{
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>