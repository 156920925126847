<template lang="pug">
  v-hover(#default="{hover}" test-id="drawing-preview-page")
    v-img.drawing-preview-inner(:src='url' height='100%' :class='{"elevation-6": hover}' contain)
</template>

<script>  
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  name: "DrawingPreview",
  props: {
    pageUuid: String
  },
  computed: {
    ...mapState('drawings', ['previewImages'])
  },
  data() {
    return{
      url: ""
    }
  },
  methods: {
    ...mapMutations('drawings', ['addPreviewImage']),
    ...mapActions('drawings', ['getDrawingPagePreview'])
  },
  mounted(){
    let preview = this.previewImages.find(page => page.uuid == this.pageUuid)
    if(preview){
      this.url = preview.url
      return
    }
    this.getDrawingPagePreview(this.pageUuid).then(img => {
      this.url = URL.createObjectURL(img)
      if(!this.previewImages.find(page => page.uuid == this.pageUuid)){
        this.addPreviewImage({uuid: this.pageUuid, url: this.url})
      }
    })
  }
}
</script>

<style lang="scss" scoped>
  .drawing-preview-inner {
    width: 100%;
    height: 100%;
  }
</style>