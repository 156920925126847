<template lang="pug">
  .control-panel-root
    v-menu(bottom nudge-bottom='25' nudge-right='10' :close-on-content-click="true")
      template(v-slot:activator='{on, attrs}')
        v-btn(small icon v-bind='attrs' v-on='on')
          .drawing-scale-label {{ scaleDrawing }}
      app-panel
        app-section
          .d-flex.flex-column
            v-btn(text small v-for='measure in measures' :key='measure' color='#4D4D4D' @click='handleScaleChange(measure)')
              .drawing-scale-label(style='color: #4D4D4D') {{ measure }}

    v-slider.ma-0.pa-0( :value='scaleDrawing' :min='25' :max='200' :step='25' height='28' track-color="#4D4D4D" color='#4D4D4D' style='width: 84px;' dense hide-details @input='handleScaleChange($event)')
    
    v-btn(icon small @click='handleCommentMode()')
      v-icon(:color='isCommentMode ? "#EB5757": "#4D4D4D"') mdi-message-plus-outline

    v-menu(v-if='selectedDrawingPage' bottom nudge-bottom='25' nudge-left='100' :min-width='340' :max-width='340' :max-height='360' :close-on-content-click="false")
      template(v-slot:activator='{on, attrs}')
        v-btn.btn-bottom-margin(v-bind='attrs' v-on='on' :disabled='!selectedDrawingPage.attachments.length > 0' light small icon)
          v-icon(color='#4D4D4D') mdi-text
      comments-panel(@scrollto='handleScrollToComment($event)')

    v-btn.btn-bottom-margin(icon x-small @click='fitHandler()')
      svg(width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg")
        path(d="M0 14V9H2V12H5V14H0ZM0 5V0H5V2H2V5H0ZM9 14V12H12V9H14V14H9ZM12 5V2H9V0H14V5H12Z" :fill="isFitting ? '#8DAFFF' : '#4d4d4d'")

    app-tooltip( top title="Связать чертеж" )
      app-icon-button( icon="mdi-link-lock" light :iconColor="isHighlightFrameActivated ? '#8DAFFF' : '#4d4d4d'" @click="cropHandler" )
      //- v-btn.btn-bottom-margin(light small icon @click='cropHandler()')
        v-icon(:color="isHighlightFrameActivated ? '#8DAFFF' : '#4d4d4d'") mdi-text
    
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import CommentsPanel from './DrawingsCommentsPanel'

export default {
  name: "DrawingPageControlPanel",

  components: { CommentsPanel },

  computed: {
    ...mapState('drawings', ['scaleDrawing', 'measures', 'selectedDrawingPage']),
    measures: () => [25, 50, 75, 100, 150, 200]
  },

  props: {
    isHighlightFrameActivated: Boolean,
    isFitting: Boolean,
    isCommentMode: Boolean
  },

  methods: {
    ...mapMutations('drawings', ['setScaleDrawing']),

    fitHandler() {
      this.$emit('fit')
    },

    cropHandler() {
      this.$emit('crop')
    },

    handleScaleChange(value) {
      this.setScaleDrawing(value)
      this.$emit('scale')
    },

    handleCommentMode() {
      this.$emit('commentmode')
    },

    handleScrollToComment(comment) {
      this.$emit('scrollto', comment)
    }
  }
}
</script>

<style lang="scss" scoped>
.control-panel-root {
  margin-left: auto;
  display: flex;
  gap: 4px;
  align-items: center;
}

.drawing-scale-label {
  color: #4D4D4D;
  text-align: center;
}

.btn-bottom-margin {
  margin-bottom: 3px;
}
</style>