<template lang="pug">
  .d-flex.flex-column
    .d-flex.task-table-header.sizeW
      .column( v-if='header.show' v-for='header in headers' ) 
        .column-label(:class='{"text-start": header.start, "pointer": header.start}' @click='$emit("default", header.start)') 
          span( v-if="!hasTaskVolumes || (header.name!='DELETE' && header.name!='SAVE')" ) {{ header.title }}
          task-button-action( v-else-if="reportList.length != 0 && header.name=='DELETE'" icon="project/tools/delete_fill.svg" @click.stop="confirmDeleteAllReport()" )
          task-button-action.mr-3( v-else-if="reportList.length != 0 && header.name=='SAVE'" icon="project/tools/save.svg" @click.stop="saveAll()" )

    .d-flex.flex-column
      div( v-for='(report, index) in reportList' )
        task-report-table-row( :report="report" :estimation="estimation" :selectedTask="selectedTask"
          @deleteReport="deleteReport" @showEditReport="showEditReport" @saveReport="saveReport")

    app-dialog-confirm(
      v-model="dialog.confirmDelAllReport" 
      @confirm="deleteAllReport"
    ) 
      app-text {{ $t('module.task.reports.deleteAll') }}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { AlertService } from '@app/AlertService'
import TaskReportTableRow from './TaskReportTableRow'
import TaskButtonAction from '@/components/task/taskDetailsSections/TaskButtonAction.vue'
import { api } from '@/api'
import { projectService } from '@/_services'

export default {
  name: "TaskReportTable",

  components:{
    TaskReportTableRow,
    TaskButtonAction
  },

  props: {
    volumes: Array,
    headers: Array,
    estimation: null,
    selectedTask: null,
  },

  data() {
    return {
      dialog:{
        confirmDelAllReport: false,
      },

      reportList: [],

      loadVolumes: false,

      readyAddReportList: [],
    }
  },

  watch:{
    volumes () {
      if(this.loadVolumes==false){
        this.reportList=this.volumes
        this.loadVolumes=true
      }
    },
  },

  computed: {
    ...mapGetters('project', ['projectUuid']),
    ...mapGetters('projectPermissions', ['hasTaskVolumes']),
  },

  mounted() {
    // TODO БЫСТРОЕ РЕШЕНИЕ ДЛЯ ПОКАЗА ПЕРЕСМОТРЕТЬ ПОСЛЕ
    if (!window.alreadyReportTask?.[this.selectedTask.uuid]) {
      api.tasks.getSmetaVolumes(this.selectedTask.uuid).then((volumes) => {
        api.tasks.getTaskElementsHistory(this.projectUuid, this.selectedTask.uuid).then(async hist => {
          let totalVolume = 0
  
          for (let i = 0; i < hist.length; i++) {
            const el = hist[i]
            
            if (el.lastStatus?.status.name === 'READY') {
              const elInfo = await projectService.loadElementByGlobal(el.globalId, this.projectUuid)
              totalVolume += volumes.find(volume => volume.title === elInfo.name)?.quantity
            }
          }
  
          const nowPad = () => {
            const now = new Date()
            const day = now.getDate()
            const month = now.getMonth() + 1
            const year = now.getFullYear()
  
            return [day < 10 ? `0${day}` : day, month < 10 ? `0${month}` : month, year].reverse().join('-')
          }
  
          if (totalVolume) {
            const volume = {
              edit: false,
              value: totalVolume,
              reportDate: nowPad()
            }
            
            this.reportList.push(volume)
            this.saveReport(volume)

            window.alreadyReportTask ||= {}
            window.alreadyReportTask[this.selectedTask.uuid] = true
          }
        })
      })
    }
  },

  methods:{
    ...mapActions('task', ['editTaskReportVolume', 'deleteTaskReportVolume', 'createTaskReportVolume', 'editTaskReport', 'deleteAllTaskReportVolume']),

    confirmDeleteAllReport(){
      this.dialog.confirmDelAllReport=true
    },

    async deleteAllReport () { 
      await this.deleteAllTaskReportVolume(this.selectedTask).then( () => {this.$emit('update'); this.loadNewVolumes(); })
      this.clearTasks()
    },

    clearTasks(){
      this.reportList.splice(0, this.reportList.length)
    },

    loadNewVolumes(){
      this.loadVolumes=false
    },

    nowDate() {
      return new Date(Date.now() + (3600 * 1000 * 24)).toISOString().slice(0,10)
    },

    saveAll(){
      let saveList = []

      let error = false

      this.reportList.forEach(volume => {
        if(volume.edit){
          console.log(volume)
          if(volume.value.toString().replace(/[^0-9.]/g,"").length!=volume.value.toString().length){
            AlertService.error({data: {error_description:this.$t('module.task.reports.invalidData')}})
            error = true
            return
          }
          if(volume.uuid){
            saveList.push({uuid: volume.uuid, value: volume.value, reportDate: Date.parse(volume.reportDate)})
          }
          else{
            saveList.push({value: volume.value, reportDate: Date.parse(volume.reportDate)})
          }
          volume.edit=false
        }
      });

      if(error) return

      console.log(this.selectedTask.report.unitType)
      let data = {
        uuid: this.selectedTask.report.uuid,
        estimation: this.selectedTask.report.estimation,
        unitType: this.selectedTask.report.unitType.value?this.selectedTask.report.unitType:undefined,
        volumes: saveList
      }

      this.editTaskReport({selectedTask: this.selectedTask, report: data}).then( () => {
        this.$emit('update')
      })
    },
    
    deleteReport (report) {
      if(report.uuid) {
        this.reportList = this.reportList.filter((volume) => { return volume.uuid != report.uuid })

        this.deleteTaskReportVolume({selectedTask: this.selectedTask, reportVolumeUid: report.uuid})
      }
      else {
        this.reportList = this.reportList.filter((volume) => { return volume.index != report.index })
      }
    },

    showEditReport (report) {
      this.reportList = this.reportList.map((volume) => (
        volume.uuid === report.uuid
          ? { ...volume, edit: true }
          : volume
      ));
    },

    addNewReport(){
      let data = {
        reportDate: this.nowDate(),
        value: 0,
        edit: true,
        index: this.readyAddReportList.length
      }
      this.readyAddReportList.push(data)
      this.reportList.push(data)
    },

    saveReport (report) {
      if(report.value.toString().replace(/[^0-9.]/g,"").length!=report.value.toString().length){
        AlertService.error({data: {error_description:this.$t('module.task.reports.invalidData')}})
        return
      }
      if (report.uuid) {
        this.reportList = this.reportList.map((volume) => (
          volume.uuid === report.uuid
            ? { ...volume, edit: false }
            : volume
        ));

        let data = {
          uuid: report.uuid,
          reportDate: Date.parse( report.reportDate ),
          value: report.value
        }

        this.editTaskReportVolume({selectedTask: this.selectedTask, reportVolume: data})
      }
      else {
        this.reportList = this.reportList.map((volume) => (
          volume.index === report.index
            ? { ...volume, edit: false }
            : volume
        ));

        let data = {
          reportDate: Date.parse( report.reportDate ),
          value: report.value,
        }
        this.createTaskReportVolume({selectedTask: this.selectedTask, reportVolume: data}).then((newReport) => { 
          this.reportList = this.reportList.map((volume) => (
            volume.index === report.index
              ? { ...volume, uuid: newReport.uuid }
              : volume
          ));
          })
      }
    },
  }

}
</script>

<style lang="scss" scoped>
  .task-table{
    position: absolute;
    min-height: 100%;
    min-width: 100%;
  }
  .column{
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #262626;

    display: flex;
    border:1px #f8f8f8 solid;
  }
  .task-table-header{
    top: 0;
    right: 0;
    width: auto;
    height: 100%;
    font-size:12px;
  }
  .column-label{
    height: 25px;
    background: #f8f8f8;
    // width: 100%;
    padding: 0px 0px 0px 6px;
    // display: grid;
    justify-content: center;
    align-items: center;
  }
  .sizeW{
    background: #f8f8f8;
    display: grid !important;
    grid-template-columns: 90px 83px 96px 28px 120px;
  }
</style>