<template lang="pug">
ToolButton( 
  :tooltip="gridsTitle" 
  :active="store.isGridsEnabled" 
  :disabled="!store.isGridsAvailable"  
  @click="toggleDisplay" 
) $grid-icon
</template>

<script>
import { useGridStore } from '@/stores/viewerTools/grid.store'
import ToolButton from './ToolButton.vue'

export default {
  components: {
    ToolButton,
  },

  computed: {
    store: () => useGridStore(),
    gridsTitle () {
      let title = this.$t('headerToolbar.showIfcGrids')
      let availableText = this.store.isGridsAvailable ? 'Доступны' : 'Недоступны'
      return `${title} (${availableText})`
    },
  },

  methods: {
    toggleDisplay () {
      this.store.toggleDisplayOfGrids(this)
    }
  }
}
</script>