<template lang="pug">
div
  v-text-field.search-elements-field--width.mr-2(
    v-model.trim="searchText"
    :placeholder="$t('app.pageHeader.searchByNameGUIDElementType')"
    hide-details rounded outlined clearable focus color="var(--v-tertiary-base)"
    @keyup.enter="searchElements"
    height="28"
    autofocus
    @click:clear="clearSearchField" )
  v-menu( v-if="shownMenu" :value="true" :rounded="true" absolute :position-x="($el && $el.getBoundingClientRect().x) || 0" :position-y="($el && $el.getBoundingClientRect().y + 39) || 0" :close-on-content-click="false" :close-on-click="false" )
    app-section
      search-elements-menu( v-if="shownMenu" )
</template>

<script>
import { mapStores } from 'pinia'
import SearchElementsMenu from './SearchElementsMenu.vue'
import { useSearchElementsStore } from '@/pinia/searchElements.module'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { mapActions } from 'vuex'

export default {
  components: {
    SearchElementsMenu
  },

  data() {
    return {
      shownMenu: false,
      searchText: ""
    }
  },

  mounted() {
    this.searchElementsStore.setFilterCurrentProject()
    this.searchText = this.searchElementsStore.searchElementsFilter.searchText ?? ""
    if (this.searchText) this.shownMenu = true
  },

  computed: {
    ...mapStores(useSearchElementsStore)
  },

  methods: {
    ...mapActions('axis/tree', ['fetchElementByGlobalId']),
    
    searchElements() {
      this.shownMenu = true
      this.searchElementsStore.fetchElementsByFilter(this.searchText)
      // this.fetchElementByGlobalId(null)
      // this.searchElementsStore.pickedElementUuid = null
      // XeokitMediator.ElementsSelection.pickElement()
    },

    clearSearchElements() {
      // this.searchElementsStore.setSelectedElements()
      XeokitMediator.ElementsSelection.selectElements([])
      this.fetchElementByGlobalId(null)
      this.searchElementsStore.pickedElementUuid = null
      XeokitMediator.ElementsSelection.pickElement()
    }, 

    clearSearchField() {
      this.shownMenu = false 
      this.searchElementsStore.searchElementsFilter.searchText = ''
    }
  }
}
</script>

<style scoped>
.search-elements-field--width {
  width: 285px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
}

::v-deep .v-input__slot {
  min-height: 0px !important;
}

::v-deep .v-input__append-inner {
  margin-top: 0px !important;
  height: 100%;
  align-items: center;
}

</style>