<template lang="pug">
div( v-if="isComment(comment)" )
  task-comment-markdown-textarea(v-if="editable" ref="commentMarkdown" v-model="comment.text" :shortenText="shortenComment"
    style="font-size: 14px; line-height: 16px; font-weight: 400; color: #262626;" :backgroundColorSheet="'transparent'" 
    auto-grow hide-details solo flat dense :onlyViewer="(commentCreator(comment).uuid !== user.uuid) || onlyView" light
    @click="changeComment(comment)"
  )
  div.pr-1(v-else style="font-size: 14px; line-height: 16px; font-weight: 400; color: #262626; white-space: break-spaces; word-break: break-all;" ) {{ comment.text }}

div( v-else-if="comment" )
  div(v-if="comment.sourceType.title!='DRAWINGCOMMENT'")
    task-comment-markdown-textarea( v-if="editable" ref="commentMarkdown"  v-model="comment.cameraParse.comment" 
      style="font-size: 14px; line-height: 16px; font-weight: 400; color: #262626;" :backgroundColorSheet="'transparent'" light
      auto-grow hide-details solo flat dense :onlyViewer="(commentCreator(comment).uuid !== user.uuid) || onlyView"
      :shortenText="shortenComment" :disabled="editable"
      @click="changeComment(comment)"
    )
      template(v-if="comment.sourceData" v-slot:addons)
        task-simple-attachment.mt-2(:editable="editable" :attach="comment" @location="showLocation(comment)")
    div.pr-1(v-else style="font-size: 14px;line-height: 16px; font-weight: 400; color: #262626;white-space: break-spaces;word-break: break-all" ) {{ comment.cameraParse.comment }}
      task-simple-attachment.mt-2(:editable="editable" :attach="comment" @location="showLocation(comment)")
  div(v-else)
    task-comment-markdown-textarea( v-if="editable" ref="commentMarkdown" v-model="comment.cameraParse.text" :shortenText="shortenComment"
      style="font-size: 14px;line-height: 16px; font-weight: 400; color: #262626;" :backgroundColorSheet="'transparent'" light
      auto-grow hide-details solo flat dense :onlyViewer="(commentCreator(comment).uuid !== user.uuid) || onlyView"
      @click="changeComment(comment)"
    )
      template(v-if="comment.sourceData" v-slot:addons)
        task-simple-attachment.mt-2(:editable="editable" :attach="comment" @location="showLocation(comment)")
    div.pr-1(v-else style="font-size: 14px;line-height: 16px; font-weight: 400; color: #262626;" ) {{ comment.cameraParse.text }}
      task-simple-attachment.mt-2(:editable="editable" :attach="comment" @location="showLocation(comment)")
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import TaskSimpleAttachment from '@/components/task/taskDetailsSections/TaskSimpleAttachment.vue'
import { SourcePath } from '@app/SourcePath'
import { TaskBimAnnotations } from '@/components/task/taskBimAnnotations'
import { TaskScreenshot } from '../../taskScreenshot'
import TaskCommentMarkdownTextarea from '@/components/task/taskDetailsSections/taskComments/TaskCommentMarkdownTextarea.vue'

export default {
  name: 'TaskComment',

  components: {
    TaskSimpleAttachment,
    TaskCommentMarkdownTextarea,
  },

  props: {
    comment: Object,
    onlyView: Boolean,
    shortenComment: Boolean,

    editable: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapState('authUser', ['user']),
    ...mapState('drawings', ['drawingsPanel']),
    ...mapState('task', ['tasks', 'selectedTask']),
    ...mapState('collision/search', ['collisionSelectedGlobalIds']),

    selectedCollisionUuid() {
      return this.collisionSelectedGlobalIds.collisionUuid
    }
  },

  methods: {
    ...mapActions('task', ['editComment', 'editCommentAttachment', 'editCommentAttachmentWithCam']),
    ...mapActions('drawings', ['editDrawingComment']),
    ...mapMutations('project', ['setActiveGlobalTab']),

    isComment(comment) {
      if (comment == null) {
        return false
      }
      if (comment != null) {
        return !comment.sourceData
      }
    },

    commentCreator(comment) {
      return comment.author || comment.creator || ''
    },

    showLocation(attach) {
      if (
        attach.sourceType.title === 'VIEW' ||
        (attach.sourceType.title === 'DRAWINGCOMMENT' && !this.$route.path.includes('task/manager'))
      ) {
        if (attach.sourceType.title === 'VIEW') {
          if (attach.sourceData) {
            this.showCamera(attach)
          }
        } 
        else {
          this.showDrawing(attach)
        }
      } 
      else {
        this.showFile(attach.file, attach.contentType)
      }
    },

    changeComment(comment) {
      if (comment.creator.uuid === this.user.uuid) {
        if (comment.sourceType) {
          if (comment.sourceType.title === 'FILE') {
            this.editCommentAttachment({
              uuid: comment.uuid,
              text: comment.cameraParse.comment,
            })
          } 
          else if (comment.sourceType.title === 'VIEW') {
            this.editCommentAttachmentWithCam({
              uuid: comment.uuid,
              data: comment.cameraParse,
            }).then(() => {
              this.$store.dispatch('task/loadTasks').then(() => {
                if (document.querySelector('#tasks')) {
                  TaskBimAnnotations.showTaskBimAnnotations()
                }
              })
            })
          } 
          else if (comment.sourceType.title === 'DRAWINGCOMMENT') {
            comment.task = this.selectedTask
            this.editDrawingComment(comment)
          }
        } 
        else {
          this.editComment({ uuid: comment.uuid, text: comment.text })
        }
      }
    },

    showCamera(attach, shownLabel = false) {
      if (!this.$route.path.includes('task/manager')) {
        if (
          attach.sourceData &&
          attach.sourceData !== undefined &&
          attach.uuid !== TaskBimAnnotations?.selectedTaskAnnotation?.id.replace('task_annotation_', '')
        ) {
          if (attach.collision) {
            if (this.selectedCollisionUuid) {
              if (this.selectedCollisionUuid == attach.collision) {
                TaskScreenshot.dropScreenshot()
              }
              else TaskScreenshot.showTaskScreenshot(attach)
            }
            else TaskScreenshot.showTaskScreenshot(attach)
          }
          else TaskScreenshot.showTaskScreenshot(attach)
        } 
        else {
          TaskScreenshot.dropScreenshot()
        }

        if (attach.annotation && attach.annotation !== undefined) {
          TaskBimAnnotations.setSelectedTaskBimAnnotationByAttachment(attach, shownLabel)
        } 
        else TaskBimAnnotations.noSelectedTaskBimAnnotation()
      }
    },

    showDrawing(attach) {
      let camera = attach.sourceData
      this.setActiveGlobalTab('drawings')
      if (!this.drawingsPanel) {
        this.$root.$on('pagesIsLoading', () => {
          this.drawingsPanel.scrollDrawingPageToComment(camera)
          this.$root.$off('pagesIsLoading')
        })
      } 
      else {
        this.drawingsPanel.scrollDrawingPageToComment(camera)
      }
    },

    // Используется извне
    editCommentMarkdown() {
      this.$refs.commentMarkdown.handleFocusChange()
    },

    showFile(file, contentType) {
      if (contentType === 'application/pdf') {
        window.open(SourcePath.attachment(file), '_blank')
      } 
      else {
        if (contentType.startsWith('image')) {
          let w = window.open()
          let img = new Image()
          img.src = SourcePath.attachment(file)
          img.setAttribute('style', 'text-align: center;position: absolute;inset: 0;margin: auto;')
          w.document.body.appendChild(img)
        } 
        else {
          window.open(SourcePath.attachment(file), '_blank')
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
