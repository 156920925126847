<template lang="pug">
task-section-expansion( :title="$t('module.task.estimate')" :showToggle="getSmetaPanel" headerColorDark @openPanel="setSmetaPanel" )
  template( #titleAfter )
    v-sheet( v-if="dataTask && dataTask.smeta && dataTask.smeta.node && loading" color="#e5e5e5" class="px-4 ml-2 rounded-lg" )
      v-skeleton-loader( type="text" light )
    span.grey--text.text-caption.ml-2( v-else ) {{ volume.quantityAll ?  $t('module.task.smeta.volume', {quantity: volume.quantityAll}) : "" }}

  div.task-smeta-details-body.mt-2.mx-2( v-if="dataTask" )
    div( v-if="dataTask.smeta && dataTask.smeta.node" )
      div.font-smeta-details( v-if='!loading' test-id="task-smeta-body" ) {{ dataTask.smeta.node.title }} 
      
      v-skeleton-loader.mt-2.mr-9(v-if='loading' type='text' light)
      
      div.volumes.d-flex.flex-column.mb-1
        div.volume-unit.font-smeta-info-details(v-if='!loading') {{ $t("module.task.smeta.volumes.text") + " " + volume.quantityAll }}
        div.volume-unit.font-smeta-info-details(v-if='!loading') {{ $t("module.task.smeta.volumes.spent") + " " + volume.spent }}
        div.volume-unit.font-smeta-info-details(v-if='!loading') {{ $t("module.task.smeta.volumes.projected") + " " + volume.projected }}
        
        v-skeleton-loader.mb-1.mr-9.mt-2(v-if='loading' type='text' light)
        v-skeleton-loader.mb-1.mr-6(v-if='loading' type='text' light)
        v-skeleton-loader.mb-1.mr-1(v-if='loading' type='text' light)
      
      div.d-flex.mb-1( v-if='!isTaskManager' )
        v-btn.text-capitalize.font-weight-thin.mr-5.px-1(v-if="hasClassificatorCreate" style="font-size:12px" text color='#3B93AF' height='24' @click="showSmetaPanel()" test-id="task-open-smeta-panel" ) {{ $t('module.task.smeta.openQuote') }}
      
      div.d-flex
        v-tabs(background-color='white' height='24' color='#757575' slider-color='#3B93AF')
          v-tab.px-0.v-tab-smeta-fonts(style='color:#757575' :class="{'v-tab-selected': isTabSelected('WORKS')}" 
            @click='tableName="WORKS"; totalTable = 0') {{ $t('module.task.smeta.works') }}
          v-tab.mx-2.px-2.v-tab-smeta-fonts(style='color:#757575' :class="{'v-tab-selected': isTabSelected('MATERIALS')}" 
            @click='tableName="MATERIALS"; totalTable = 0') {{ $t('module.task.smeta.materials') }}
          v-tab.px-2.v-tab-smeta-fonts(style='color:#757575' :class="{'v-tab-selected': isTabSelected('MACHINES')}" 
            @click='tableName="MACHINES"; totalTable = 0') {{ $t('module.task.smeta.machines') }}
      
      div.d-flex.flex-column.mt-1( v-if='!loading' style='height:100%; overflow: hidden' )
        v-data-table.base-scroll-body( :headers='headers' :items='itemsData' :hide-default-footer="true" light fixed-header :no-data-text='$t("module.task.smeta.table.noData")' )
        
        div( v-if='dataTask.smeta.grandSmetaPosition' )
          div.total
            span {{ $t('module.task.smeta.total') }}
            span {{ decimalAdjust(totalTable) }}
          
          div.footer
            div.d-flex.justify-space-between.pr-0.v-tab-smeta-fonts
              span {{ $t('module.task.smeta.table.totalVolume', {volume: dataTask.smeta.grandSmetaPosition.quantity.result}) }}
              div.v-tab-smeta-fonts {{ totalVolume }}
            
            div.d-flex.justify-space-between.pr-0.v-tab-smeta-fonts
              span {{ $t('module.task.smeta.table.overheads', {volume: wageFund}) }}
              div.v-tab-smeta-fonts {{ overheads }}
            
            div.d-flex.justify-space-between.pr-0.v-tab-smeta-fonts
              span {{ $t('module.task.smeta.table.estimatedProfit', {volume: wageFund}) }}
              div.v-tab-smeta-fonts {{ estimatedProfit }}
            
            div.d-flex.justify-space-between.pr-0.v-tab-smeta-fonts
              span {{ $t('module.task.smeta.table.total') }}
              div.v-tab-smeta-fonts {{ total }}
        
        div.d-flex.justify-center.py-3(v-else)
          div.font-weight-thin(style="color: #00000061") {{ $t('module.task.smeta.table.noSmeta') }}
      
      v-skeleton-loader( v-if='loading' type='table-heading, list-item-two-line' light)
    
    div.px-1.py-1( v-else )
      div.font-smeta-details {{ $t('module.task.smeta.notFound') }}
  
  div.px-1.py-1.mx-2( v-else )
    div.font-smeta-details {{ $t('module.task.smeta.notFound') }}
</template>

<script>
import { Task } from '@/assets/model/task/Task'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import TaskSectionExpansion from '@/components/task/taskDetailsSections/TaskSectionExpansion.vue';

export default {
  name:"TaskSmetaSection",

  components: {
    TaskSectionExpansion
  },

  props: {
    task: {
      type: Task,
      required: true,
    }
  },

  data() {
    return { 
      dataTask: null,
      tableName: "WORKS",
      loading: true,
      
      volume: {
        quantityAll: "",
        spent: "",
        projected: ""
      },
      totalVolume: null,
      
      overheads: null,
      estimatedProfit: null,
      
      total: null,
      totalTable: null,
      
      wageFund: null,
    }
  },
  
  computed: {
    ...mapState('task', ['selectedTask']),
    ...mapState('smeta', ['items']),
    ...mapState('project', ['projectSettings']),
    ...mapGetters('taskDetails', ['getSmetaPanel']),
    ...mapGetters('smeta', ['getNode']),

    ...mapGetters("projectPermissions", ['hasClassificatorCreate']),

    headers() {
      if (this.tableName == "WORKS") {
        if (this.dataTask.smeta.grandSmetaPosition) {
          return [
            { text: '№', value: 'index', sortable: false,},
            { text: this.$t('module.task.smeta.table.worksTitle'), value: 'title', sortable: false},
            { text: this.$t('module.task.smeta.table.unit'), value: 'unitValue', sortable: false, },
            { text: this.$t('module.task.smeta.table.count'), value: 'count', sortable: false, },
            { text: this.$t('module.task.smeta.table.totalCost'), value: 'totalCost', sortable: false, },
          ]
        } 
        else {
          return [
            { text: '№', value: 'index', sortable: false,},
            { text: this.$t('module.task.smeta.table.worksTitle'), value: 'title', sortable: false,},
            { text: this.$t('module.task.smeta.table.unit'), value: 'unitValue', sortable: false,},
          ]
        }
      }
      else if (this.tableName == "MATERIALS") {
        if (this.dataTask.smeta.grandSmetaPosition) {
          return [
            { text: '№', value: 'index', sortable: false,},
            { text: this.$t('module.task.smeta.table.materialsTitle'), value: 'title', sortable: false},
            { text: this.$t('module.task.smeta.table.unit'), value: 'unitValue', sortable: false,},
            { text: this.$t('module.task.smeta.table.count'), value: 'count', sortable: false, },
            { text: this.$t('module.task.smeta.table.totalCost'), value: 'totalCost', sortable: false, },
          ]
        } 
        else {
          return [
            { text: '№', value: 'index', sortable: false,},
            { text: this.$t('module.task.smeta.table.materialsTitle'), value: 'title', sortable: false,},
            { text: this.$t('module.task.smeta.table.unit'), value: 'unitValue', sortable: false,},
          ]
        }
      }
      else if (this.tableName == "MACHINES") {
        if (this.dataTask.smeta.grandSmetaPosition) {
          return [
            { text: '№', value: 'index', sortable: false,},
            { text: this.$t('module.task.smeta.table.machinesTitle'), value: 'title', sortable: false},
            { text: this.$t('module.task.smeta.table.unit'), value: 'unitValue', sortable: false,},
            { text: this.$t('module.task.smeta.table.count'), value: 'count', sortable: false, },
            { text: this.$t('module.task.smeta.table.totalCost'), value: 'totalCost', sortable: false, },
          ]
        } 
        else {
          return [
            { text: '№', value: 'index', sortable: false,},
            { text: this.$t('module.task.smeta.table.machinesTitle'), value: 'title', sortable: false,},
            { text: this.$t('module.task.smeta.table.unit'), value: 'unitValue', sortable: false,},
          ]
        }
      }
      else {
        return []
      }
    },
    
    itemsData() {
      if (this.tableName == 'WORKS') {
        if (this.dataTask.smeta.grandSmetaPosition) {
          return this.dataTask.smeta.grandSmetaPosition.resources.filter(resource => resource.type.name === 'TZR').map((work, index) => {
            let count = this.toFloat(this.dataTask.smeta.grandSmetaPosition.quantity.result) * this.toFloat(work.quantity)
            let item = {
              index: index + 1,
              title: work.caption,
              unitValue: work.units,
              count: this.decimalAdjust(count),
              totalCost: work.priceBase ? isNaN(this.decimalAdjust(this.toFloat(work.priceBase.value) * count))? "-": this.decimalAdjust(this.toFloat(work.priceBase.value) * count) : "-"
            }
            this.totalTable += typeof item.totalCost === 'string'? 0 : item.totalCost
            return item
          })
        } 
        else {
          if (this.dataTask.smeta.works) {
            return this.dataTask.smeta.works.map((work, index) => {
              return {
                index: index + 1,
                title: work.title,
                unitValue: work.unitValue + work.unitType.title
              }
            })
          }
          else {
            return []
          }
        }
      }
      else if (this.tableName === 'MATERIALS') {
        if (this.dataTask.smeta.grandSmetaPosition) {
          return this.dataTask.smeta.grandSmetaPosition.resources.filter(resource => resource.type.name === 'MAT').map((material, index) => {
            let count = this.toFloat(this.dataTask.smeta.grandSmetaPosition.quantity.result) * this.toFloat(material.quantity)
            let item =  {
              index: index + 1,
              title: material.caption,
              unitValue: material.units,
              count: this.decimalAdjust(count),
              totalCost: isNaN(this.decimalAdjust(this.toFloat(material.priceBase?.value) * count)) ? "-": this.decimalAdjust(this.toFloat(material.priceBase?.value) * count)
            }
            this.totalTable += typeof item.totalCost === 'string'? 0 : item.totalCost
            return item
          })
        } 
        else {
          return this.dataTask.smeta.materials.map((material, index) => {
            return {
              index: index + 1,
              title: material.title,
              unitValue: material.unitValue + material.unitType.title
            }
          })
        }
      }
      else if (this.tableName === 'MACHINES') {
        if (this.dataTask.smeta.grandSmetaPosition) {
          return this.dataTask.smeta.grandSmetaPosition.resources.filter(resource => resource.type.name == 'MCH').map((machine, index) => {
            let count = this.toFloat(this.dataTask.smeta.grandSmetaPosition.quantity.result) * this.toFloat(machine.quantity)
            let item = {
              index: index + 1,
              title: machine.caption,
              unitValue: machine.units,
              count: this.decimalAdjust(count),
              totalCost: isNaN(this.decimalAdjust(this.toFloat(machine.priceBase?.value) + this.toFloat(machine.priceBase?.zm ?? "0") * count)) ? "-": this.decimalAdjust((this.toFloat(machine.priceBase?.value) + this.toFloat(machine.priceBase?.zm ?? 0)) * count)
            }
            this.totalTable += typeof item.totalCost == 'string'? 0 : item.totalCost
            return item
          })
        } 
        else {
          return this.dataTask.smeta.machines.map((machine, index) => {
            return {
              index: index + 1,
              title: machine.title,
              unitValue: machine.unitValue + machine.unitType.title
            }
          })
        }
      }
      else {
        return []
      }
    },
    
    getDateStartAndEnd() {
      let createDate = new Date(this.task.createDate)
      createDate = createDate.toISOString().split('T')[0].replace(/-/g,'.')
      createDate = createDate.split('.')
      
      let temp = createDate[0]
      createDate[0] = createDate[2]
      createDate[2] = temp.substring(2)
      createDate = createDate.join('.')
      
      let endDate = new Date(this.task.endDate)
      endDate = endDate.toISOString().split('T')[0].replace(/-/g,'.')
      endDate = endDate.split('.')
      temp = endDate[0]
      endDate[0] = endDate[2]
      endDate[2] = temp.substring(2)
      endDate = endDate.join('.')
      
      return this.$t('module.task.time.from') + createDate + this.$t('module.task.time.to') + endDate
    },

    isTaskManager() {
      return window.location.href.includes('task/manager')
    }
  },

  watch: {
    task() {
      this.loading = true
      this.dataTask = this.task
      this.smetaInfo()
    },
  },

  mounted() {
    this.smetaInfo()
  },

  beforeDestroy() {
    if(XeokitMediator?.viewer?.scene) XeokitMediator.ElementsSelection.selectElements([])
  },

  methods: {
    ...mapActions('task', ['getSmetasByTaskUuid', 'getSmetaVolumes']),
    ...mapMutations('project', ['setActiveGlobalTab']),
    ...mapActions('smeta', ['expandTo']),
    ...mapMutations('smeta', ['setEditRuleItem', 'setExpandAfterLoadUuid']),
    ...mapActions('taskDetails', ['setSmetaPanel']),
    
    smetaInfo() {
      this.volume.quantityAll = ""
      this.volume.spent = ""
      this.volume.projected = ""

      if (!this.selectedTask) return

      this.getSmetasByTaskUuid(this.selectedTask.uuid).then((data) => {
        if (!data) return

        this.dataTask = new Task(this.task, this.task.parent, this.task.level, data)
        
        this.getSmetaVolumes({ taskUuid: null, dataTask: this.dataTask }).then(volume => {
          this.volume = volume
          this.loading = false
        })
        .catch((e) => {
          console.error(e)
          this.dataTask = null
        })

        if (this.dataTask.smeta.grandSmetaPosition) {
          this.totalVolume = this.dataTask.smeta.grandSmetaPosition.priceBase ? this.decimalAdjust(this.toFloat(this.dataTask.smeta.grandSmetaPosition.priceBase.pz) * this.toFloat(this.dataTask.smeta.grandSmetaPosition.quantity.result)) : 0
          this.wageFund = this.dataTask.smeta.grandSmetaPosition.priceBase ?  this.decimalAdjust((this.toFloat(this.dataTask.smeta.grandSmetaPosition.priceBase.oz) + this.toFloat(this.dataTask.smeta.grandSmetaPosition.priceBase.zm)) * this.toFloat(this.dataTask.smeta.grandSmetaPosition.quantity.result)) : 0
          this.overheads = this.decimalAdjust(this.wageFund * 1.02)
          this.estimatedProfit = this.decimalAdjust(this.wageFund * 0.58)
          this.total = this.decimalAdjust(this.totalVolume + this.overheads + this.estimatedProfit)
        }
      })
    },

    showSmetaPanel() {
      if (!this.hasClassificatorCreate) return

      if (this.projectSettings.projectClassificator !== this.dataTask.smeta.node.projectClassificatorUuid) {
        let ps = JSON.parse(JSON.stringify(this.projectSettings))
        ps.projectClassificator = this.dataTask.smeta.node.projectClassificatorUuid
        this.$store.dispatch('project/updateSettingsProjectClassificator', ps)
        this.setExpandAfterLoadUuid(this.dataTask.smeta.node.uuid)
      } 
      else if (!this.items?.length) {
        this.setExpandAfterLoadUuid(this.dataTask.smeta.node.uuid)
      } 
      else {
        this.expandTo(this.dataTask.smeta.node.uuid)
        let fullNode = this.getNode(this.dataTask.smeta.node.uuid)
        if (fullNode.isRule) this.setEditRuleItem(fullNode)
        else if (fullNode.children && fullNode.children.length > 0) this.setEditRuleItem(fullNode.children[0])
      }

      this.$nextTick(() => this.setActiveGlobalTab('smeta'))
    },

    isTabSelected(selectedTableName) {
      return this.tableName === selectedTableName
    },

    toFloat(number) {
      if(typeof number === 'string') {
        return parseFloat(number?.replace(/[,]/g, '.'))
      }
      return number
    },

    decimalAdjust(value, exp = -2) {
      if (typeof exp === 'undefined' || +exp === 0) {
        return Math['ceil'](value);
      }
      value = +value;
      exp = +exp;
      if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
      }
      value = value.toString().split('e');
      value = Math['ceil'](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
      value = value.toString().split('e');
      return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    }
  },
}
</script>

<style lang='scss' scoped>
.font-smeta-details{
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #262626;
}
.font-smeta-info-details{
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #757575;
}
.v-tab-fonts{
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: none;
}
.v-tab-selected{
  border-radius: 3px 3px 0px 0px;
  background-color: #e5e5e5;
}
.v-slide-group__prev{
  min-width:0px;
  width:0px;
  flex:none;
}
.v-slide-group__next{
  min-width:0px;
  width:0px;
  flex:none; 
}
.v-slide-group__content{
  flex: 0 0 auto;
}
.v-tab{
  min-width: 60px;
  flex: 0 0 auto;
}
.total{
  justify-content: space-between;
  display: flex;
  color: #262626;
  font-size: 14px;
  font-weight: bold;
  padding: 12px 38px 12px 60px;
  border-top: 1px solid #E5E5E5;
}
.footer{
  background: #E5E5E5;
  display: flex;
  flex-direction: column;
  padding: 12px 24px 12px 24px;
  color: #262626;
  gap: 4px;
}
.v-tab-smeta-fonts{
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  text-transform: none;
}
::v-deep .v-data-table__wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--v-scroll-base);
}
::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: var(--v-accent-base);
}
::v-deep .v-data-table__wrapper::-webkit-scrollbar-track {
  background-color: var(--v-scroll-base);
  -webkit-box-shadow: inset 0 0 6px rgba(83, 83, 83, 0.07) !important;
}
</style>