import { useImagePlaneStore } from "@/pinia"
import { PickImagePlane } from "@/plugins/xeokit/imagePlanes/pickImagePlane"

/*eslint-disable no-dupe-class-members*/
export class ImageCropper {

  static get #_imagePlaneStore() {
    return useImagePlaneStore()
  }

  static setImageSrc(imageSrc) {
    this.#_imagePlaneStore.setImageSrc(imageSrc)
  }

  static get isDialogDrawerCropperShowed() {
    return this.#_imagePlaneStore.isDialogDrawerCropperShowed
  }

  static showDialogDrawerCropper() {
    this.#_imagePlaneStore.setDialogDrawerCropperShowed(true)
  }

  static hideDialogDrawerCropper() {
    this.#_imagePlaneStore.setDialogDrawerCropperShowed(false)
  }

  static async cropImage(x1, y1, x2, y2, img) {
    if (x1 == x2 && y1 == y2) {
      this.setImageSrc("")
    }

    let canvas = document.createElement('canvas')

    let finalImgScale = 1

    let minX = Math.min(x1, x2) * finalImgScale
    let minY = Math.min(y1, y2) * finalImgScale
    let maxX = Math.max(x1, x2) * finalImgScale
    let maxY = Math.max(y1, y2) * finalImgScale

    let width = maxX - minX
    let height = maxY - minY

    canvas.width = width
    canvas.height = height
    let context = canvas.getContext('2d')
    
    let sourceX = minX;
    let sourceY = minY;
    let sourceWidth = width;
    let sourceHeight = height;
    let destWidth = sourceWidth;
    let destHeight = sourceHeight;
    let destX = 0;
    let destY = 0;

    let decodedSrc = Buffer.from(img.src.slice(26), 'base64').toString('ascii')
    
    const parser = new DOMParser()
    let htmlObject = parser.parseFromString(decodedSrc, 'text/html')

    let svgHtml = htmlObject.getElementsByTagName('svg')[0]

    let lastWidth = parseFloat(svgHtml.getAttribute("width"))
    let lastHeight = parseFloat(svgHtml.getAttribute("height"))

    let newHeight = (lastHeight * finalImgScale).toString()
    let newWidth = (lastWidth * finalImgScale).toString()
    let newViewBox = "0 0 " + lastWidth.toString() + " " + lastHeight.toString()

    svgHtml.setAttribute("viewBox", newViewBox)
    svgHtml.setAttribute("width", newWidth)
    svgHtml.setAttribute("height", newHeight)
    
    let xml = new XMLSerializer().serializeToString(svgHtml);
    let encodedXml = Buffer.from(xml, 'ascii').toString('base64')

    let imgElement = document.createElement('img')
    imgElement.src = "data:image/svg+xml;base64," + encodedXml

    imgElement.onload = () => {
      context.drawImage(imgElement, sourceX, sourceY, sourceWidth, sourceHeight, destX, destY, destWidth, destHeight);

      const croppedPng = canvas.toDataURL('image/png');
      
      if (croppedPng) {
        this.setImageSrc(croppedPng)
      }
      else {
        this.setImageSrc(img.src)
      }

      canvas.remove();
      imgElement.remove();

      this.hideDialogDrawerCropper()
      PickImagePlane.waitImagePlanePick()
    }

  }
}