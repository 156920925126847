<template lang="pug">
  app-dialog( v-model="$_show" header='Экспорт справочника'  
              :confirmText="$t('button.export')" 
              @confirm="exportClassificator" 
              :closeOnConfirm="false" )
    app-section
      app-select.mb-5( :label="$t('section.classification.menuClassification.classifier')" 
                      v-model="selectedDictionary"
                      multiple
                      oneLine
                      :items="projectDicts"
                      item-value="uuid"
                      item-text="title" )

    div( v-if="exportLoading" )
      app-text {{$t('section.classification.menuClassification.exportMessage')}}
      v-progress-linear(indeterminate color="accent")

</template>

<script>
import { api } from "@/api"
import{ mapState } from 'vuex'
import DialogToggable from "@/components/app/DialogToggable"

export default {
  name: 'export-projectDict-dialog',
  mixins: [DialogToggable],

  data() {
    return {
      selectedDictionary: [],
      exportLoading: false,
    }
  },

  computed: {
    ...mapState('projectDict', ['projectDicts']),
  },

  methods: {
    downloadObjectAsJson(exportObj, exportName){
      let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj))
      let downloadAnchorNode = document.createElement('a')
      downloadAnchorNode.setAttribute("href",     dataStr)
      downloadAnchorNode.setAttribute("download", exportName + ".json")
      document.body.appendChild(downloadAnchorNode)
      downloadAnchorNode.click()
      downloadAnchorNode.remove()
    },

    exportClassificator() {
      this.exportLoading = true
      api.projectDict.exportDictionary(this.selectedDictionary).then((data) => {
        this.downloadObjectAsJson(data, `Справочники ${new Date().toLocaleString()}`)
        this.exportLoading = false
        this.$_show = false
        this.selectedDictionary = []
      })
    },
  }

}
</script>

<style scoped>

</style>