import { render, staticRenderFns } from "./NotificationList.vue?vue&type=template&id=29bb9b47&scoped=true&lang=pug&"
import script from "./NotificationList.vue?vue&type=script&lang=js&"
export * from "./NotificationList.vue?vue&type=script&lang=js&"
import style0 from "./NotificationList.vue?vue&type=style&index=0&id=29bb9b47&prod&lang=css&"
import style1 from "./NotificationList.vue?vue&type=style&index=1&id=29bb9b47&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29bb9b47",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VHover,VIcon,VProgressLinear,VTooltip})
