<template lang="pug">
ToolButton( :tooltip="$t(tooltipKey)" active @click="toggle" ) {{ icon }}
</template>

<script>
import ToolButton from './ToolButton.vue'
import { useProjectionStore } from '@/stores/viewerTools/projection.store'
const store = useProjectionStore()

export default {
  components: {
    ToolButton,
  },

  computed: {
    tooltipKey: () => store.isModeOrthogonal ? 'headerToolbar.orthogonalProjection' : 'headerToolbar.perspectiveProjection',
    icon: () => store.isModeOrthogonal ? '$orthogonal-projection-icon' : '$perspective-projection-icon',
  },

  methods: {
    toggle: () => store.toggleProjection(),
  }
}
</script>