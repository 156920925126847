<template lang="pug">
div
  div(v-if="models != 0") 
    div.text-left(v-for="model in models" :key="model.uuid")
      .d-flex.flex-row.justify-space-between
        div.container-fluid.unit {{ model.name }}
        div.switchSize
          v-switch.viewSwitch(v-if="model.revision != null" v-model="model.visible" color="accent" dense light @change="changeIFCGridVisibility(model.revision.uuid)")
      ifc-tree.ml-2(v-if="model.model.length > 0" v-model="model.model")
  div.no-grids.ml-2(v-else) {{ $t("toolbarMenu.noIfcGrids") }}
</template>

<script>
import IfcTree from "@/components/project/common/IFCTree.vue"
import { mapState, mapActions } from 'vuex'
import { IFCGridsService } from '@/plugins/IFCGrids/IFCGridsService'

export default {
  name: 'IfcTree',

  components: {
    IfcTree,
  },
  
  model: {
    prop: 'models',
    event: 'change'
  },

  props: {
    models: Array
  },

  computed: {
    ...mapState('project', ['project']),
  },

  methods: {
    ...mapActions('IFCGrids', ['getIFCGrids', 'saveIFCGridsVisibility']),

    changeIFCGridVisibility: (revisionUuid) => { IFCGridsService.switchGridsVisibility(revisionUuid) }
  }
}

</script>

<style scoped>
.viewSwitch {
  margin-top: 1px;
  margin-left: 10px;
  padding-top: 4px;
  margin-right: 4px;
}

.unit {
  height: 32px;
  width: 100%;
  background-color: var(--v-surface-base);
  border-radius: 5px;
  color: #F0F0F0;
  padding-left: 10px;
  padding-top: 8px;
  font-size: 12px;
}

.no-grids {
  padding-top: 8px;
  color: black; 
  font-size: 14px;
}

.switchSize {
  height: 40px;
  width: 58px;
}

.model-name {
  position: relative;
  
}
.text-left {
  text-align: left;
}
</style>