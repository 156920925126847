<template lang="pug">
  div
    div.bar-rule-text(v-if="!editMode" @click="switchEditMode" :class="{'pointer': !disabled, 'gray-filter':disabled}")
      div(v-html="logicGroupTitle")
    v-card(v-show="editMode && !disabled" light)
      v-card-text
        logic-group.mx-n2(:logicGroup="editLogicGroup" ref="logicGroup" :default-class="false" lockable @copy='copyCondition' :disabledFormula="true")
      //- v-card-actions
      v-spacer
      .d-flex.align-center.pa-2
        v-btn.text-none.dialog-confirm-action.ml-auto.mr-2(fab small depressed light @click="editMode = !editMode") 
          v-icon mdi-close
        v-btn.text-none.dialog-confirm-action(fab color="accent" small depressed light test-id="check-logic-group-btn" @click="checkLogicGroup()")
          v-icon mdi-check
</template>

<script>
import { mapState } from 'vuex'
import LogicGroup from '../../../components/logicGroup/LogicGroup'

import RuleConditionValid from '@/assets/model/condition/RuleConditionValid'
import ElementRuleConditionValid from '@/assets/model/condition/ElementRuleConditionValid'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

let lo = {
  'AND':'И',
  'OR':'ИЛИ',
  'NOT':'НЕ'
}

export default {
  components: {
    LogicGroup
  },

  props: {
    value: Object,
    disabled: {
      type: Boolean,
      default: false
    },
    ruleUuid: String
  },

  data() {
    return {
      editMode: false,
      editLogicGroup: this.value ? JSON.parse(JSON.stringify(this.value)) : {}
    }
  },

  mounted() {
    
  },

  computed: {
    ...mapState('smeta', ['checkLogicMode']),

    pickedElement() {
      return XeokitMediator.ElementsSelection.pickedElement
    },

    ruleConditionValid(){
      return RuleConditionValid.find(this.ruleUuid)
    },

    elementRuleConditionValid(){
      return ElementRuleConditionValid.find([this.ruleUuid, this.pickedElement])
    },
    

    newValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input',value)
        this.$emit('change',value)
      }
    },

    logicGroupTitle() {
      if (this.value && 
        (this.value && this.value.children && this.value.children.length > 0)
        || (this.value && this.value.condition && this.value.condition.length > 0)
      ) return this.makeLogicGroupText(this.value, "", "", null)

      return this.$t('section.classification.menuClassification.setCondition')
    }
  },

  watch: {
    value(v, ov) {
      if ( ov == null || v == null || JSON.stringify(v) != JSON.stringify(ov)) this.editMode = false
    }
  },

  methods: {
    switchEditMode() {
      if (!this.disabled){
        this.editLogicGroup = this.value ? JSON.parse(JSON.stringify(this.value)) : {}
        this.editMode = true
      }
    },

    checkLogicGroup() {
      let lg = this.$refs.logicGroup.makeLogicGroup()
      if (lg) {
        this.newValue = lg
        this.editMode = false
      }
    },

    makeLogicGroupText(logicGroup, start, end, prevOperatorType) {
      let text = ''
      let rows = []
      logicGroup.condition.forEach(cond => rows.push(cond))
      if (logicGroup.children) {
        logicGroup.children.forEach(lg => rows.push(lg))
      }
      rows.sort((a, b) => a.sortOrder - b.sortOrder)

      
      rows.forEach((row, index) => {
        if(row.logicOperatorType !== undefined) {
          if(rows.length==1) text += this.makeLogicGroupText(row, start+"(", end+")", prevOperatorType)
          else if(index==0) text += this.makeLogicGroupText(row, start+"(", ")", logicGroup.logicOperatorType)
          else if(index<rows.length-1) text += this.makeLogicGroupText(row, "(", ")", logicGroup.logicOperatorType)
          else text += this.makeLogicGroupText(row, "(", end+")", prevOperatorType)
        } else {
          
          if(index==0){
            text += ' '+start
            start=''
          }

          let cClass = ''
          
          let validy = null
          if (this.checkLogicMode == 0) {
            validy = this.ruleConditionValid?.conditionsValid.find(e => e.conditionUuid == row.uuid);
          } else if (this.checkLogicMode == 1 && this.pickedElement) {
            validy = this.elementRuleConditionValid?.conditionsValid.find(e => e.conditionUuid == row.uuid);
          }

          if (validy && validy.valid) cClass = 'condition-validy-valid'
          else if (validy && !validy.valid) cClass = 'condition-validy-invalid'

          text += '<span class="'+cClass+'">'
          

          let op = row.operator.title.toLowerCase()
          let rp = row.title.substring(row.title.indexOf(op)+op.length+1)
          let t = row.title.replace(rp,'<span style="color:#eb5757;">"'+rp+'"</span>')
          t = t.replace(row.operator.title.toLowerCase(),'<span style="color:#9b51e0;">'+this.replaceOperator(row.operator)+'</span>')
          text += t

          text += '</span>'

          if(index==rows.length-1) {
            text += end
            end=''
          }

          if((index==0 || index<rows.length-1) && logicGroup.logicOperatorType!=null) {
            if (!logicGroup.logicOperatorType.title) logicGroup.logicOperatorType.title = lo[logicGroup.logicOperatorType.value]
            text += ' <span style="color:#9b51e0;">'+logicGroup.logicOperatorType.title.toLowerCase()+'</span> '
          } else if(prevOperatorType!=null && prevOperatorType.title) text +=  ' <span style="color:#9b51e0;">'+prevOperatorType.title.toLowerCase()+'</span> '
        }
      })

      return text
    },

    replaceOperator(o) {
      switch(o.name){
        case 'EQ': return '='
        case 'NOTEQ': return '!='
        case 'GT': return '>'
        case 'LT': return '<'
        case 'GTEQ': return '>='
        case 'LTEQ': return '<='
        default: return o.title
      }
    },

    copyCondition(condition) {
      this.$emit('copy', condition)
    },

    pasteConditions() {
      this.$refs['logicGroup'].pasteConditions()
    }
  }
}
</script>

<style scoped>
  .bar-rule-text {
    font-size:14px;
    background: #f0f0f0;
    padding:5px;
    color:#505254;
  }
  .gray-filter {
    filter:grayscale(50%);
  }
</style>