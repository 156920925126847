<template lang="pug">
  app-panel.app-cursor--context.sizeW( ident="projectstructure" v-bind="$attrs" @contextmenu.native="showContextMenu" width="270")
    v-progress-linear(v-if='loadingProcesses > 0' color='accent' indeterminate)
    app-panel-header( :title="$t('section.structure.title')" :menu="structureMenu" showClose)
    app-floating-panels-section( style="height: calc(100% - 26px) !important; overflow: auto; overflow-x: hidden;" )
      app-floating-panel.base-scroll-body( :title="$t('section.structure.title')" bodyMinHeight="150" :startHeight="80" overflowBody="auto" :bodyColor="'#ffffff'" :settings="floatingPanels('STRUCTURE_PROJECT_TREE')")
        project-tool-tree-draggable.base-scroll-body.pb-2( :items="projectModel" @menu="showUnitContextMenu" test-id="tool-tree-list" :parent="null" )

      //- TODO: Если в течение полугода не понадобилось - удалить
      //- app-floating-panel.base-scroll-body( :title="'Обзор проекта'" bodyMinHeight="150" :startHeight="80" overflowBody="auto" :bodyColor="'#ffffff'" :settings="floatingPanels('STRUCTURE_PROJECT_TREE')")
      //-   template 
      //-     offset-grouping-models.base-scroll-body

      app-floating-panel( v-if="hasArch" :title="$t('headerToolbar.archive')" bodyMinHeight="150" overflowBody="hidden" :bodyColor="'#ffffff'" :settings="floatingPanels('STRUCTURE_ARCHIVE')" )
        template
          project-tool-tree-draggable.base-scroll-body.pb-2( :items="projectModel" @menu="showUnitContextMenuArchived" test-id="arch-tool-tree-list" :parent="null" :archived="true")
      
      app-floating-panel.base-scroll-body( ref="floatingPanel" v-if="showPatches" :title="$t('patch.title')" bodyMinHeight="150" :startHeight="40" overflowBody="auto" 
                                           @contextmenu.native.stop="showPatchListMenu" :settings="floatingPanels('STRUCTURE_PATCH_LIST')")
        template( v-slot:headerButton )
          app-toolbar-button( v-if="hasProjectReconstruction" header size="small" icon="$add-box-icon" :title="$t('section.structure.model.addChangeModel')" @click.stop="showAddPatchModel" )
        model-patch-list( ref="modelPatchList" )
              
    app-dialog( v-model="dialog.model" :header="$t('section.structure.model.header')" :confirm-disabled="!modelReady" @confirm="saveEditableModel" :confirmText="$t('button.save')" )
      app-section
        app-text-field( v-model="newModelTitle" :label="$t('section.structure.model.form.title')" test-id="new-model-title-input")
        app-select( v-model="choosedInSelect" :items="preparedModels" item-value="uuid" item-text="text" :label="$t('section.structure.model.header')" clearable hideDetails test-id="structure-select-model" )
        v-checkbox( v-model="inheritOffset" :label="$t('section.structure.model.inheritOffset')" color="accent" light )

    app-dialog-confirm( v-model="dialog.modelDel" @confirm="confirmDeleteModel" )
      app-section
        app-text {{ $t('section.structure.model.delete') }} "{{ $t(contextModel.title) }}"?

    app-dialog-confirm( v-model="dialog.modelArch" @confirm="confirmArchModel" )
      app-section
        app-text {{ $t('section.structure.model.addModelArchive', {name: contextModel.title}) }} 

    app-dialog-confirm( v-model="dialog.modelFromArch" @confirm="confirmFromArchModel" )
      app-section
        app-text {{ $t('section.structure.model.returnModelArchive', {name: contextModel.title}) }}
    
    app-menu( ref="addMenu" :menu="structureMenu" )
    app-menu( ref="unitMenu" :menu="unitMenu" )

    import-project-structure(v-model="dialog.createProjectStruct")
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import ImportProjectStructure from './ImportProjectStructure.vue'
import ProjectToolTreeDraggable from "@/components/project/common/ToolTreeDraggable.vue"
import OffsetGroupingModels from '@/components/project/panel/left/modelsGrouping/OffsetGroupingModels.vue'
import ProjectForm from "@/components/project/common/ProjectForm.vue"
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { NotificationService } from '@/assets/model/notifications/NotificationService'
import ModelPatchList from '@/components/project/panel/left/modelPatch/ModelPatchList.vue'
import { projectService } from '@/_services'
import { useModelOffsetGroupsStore } from '@/pinia/modelOffsetGroups/modelOffsetGroups.store'

export default {
  name: 'ProjectPanelStructure',
  components: {
    ProjectToolTreeDraggable,
    ProjectForm,
    ModelPatchList,
    OffsetGroupingModels,
    ImportProjectStructure
  },

  data () {
    return {
      buttonModelStatus: false,
      newModelTitle: '',
      inheritOffset: null,
      copyFlatlist: null,
      modelUuid: [],
      choosedInSelect: null,
      contextModel: {},
      flag: false,
      title: '',
      dialog: {
        model: false,
        modelDel: false,
        modelArch: false,
        modelFromArch: false,
        createProjectStruct: false
      },
      nodesList: [],
      
      loadingProcesses: 0,
    }
  },

  computed: {
    ...mapGetters('project', ['projectModel', 'projectUuid', 'flatlist']),
    ...mapGetters('floatingPanels', ['floatingPanels']),
    ...mapState('project', ['selectedModel', 'selectedRevision', 'project', 'modelsList', 'projectSettings']),
    ...mapState('authUser', ['theme']),
    ...mapGetters('projectPermissions', ["hasProjectReconstruction", 'hasProjectSendToArchive', "hasProjectStruct", "hasProjectAddAttribution", "hasProjectDeleteModel", "hasProjectControlArchive", "hasModelDownloadRevision" ]),

    modelOffsetGroupsStore: () => useModelOffsetGroupsStore(),

    /**
     * Предобработка дерева проекта для группировки по расположению моделей
     */
    

    hasArch () {
      return this.flatlist.some(i => i.archived)
    },

    modelReady () {
      return !!this.newModelTitle.trim()
    },

    preparedModels () {
      let filteringUUIDs = this.contextModel.uuid ? [this.contextModel.uuid] : []
      return this.flatlist.filter(i => !i.archived).map(original => {
        let m = { ...original }
        if (filteringUUIDs.includes(m.uuid)) {
          m.disabled = true
          filteringUUIDs.push(...m.model.map(i => i.uuid))
        }

        m.title = this.$t( NotificationService.NotificationReplace(m.title) )
        let fulltext = []
        for (let i = 0; i < m.level; i++) fulltext.push('| - ')
        fulltext.push(m.title)
        m.text = fulltext.join('')
        return m;
      })
    },

    structureMenu () {
      if (this.hasProjectStruct) {
        let menu = []
        menu.push({ title: this.$t('button.add'), action: this.showNewModelDialog })
        if(this.showPatches) {
          // menu.push({ title: this.$t('section.classification.menuClassification.addSection'), action: this.showAddSection })
          if(this.hasProjectReconstruction) menu.push({ title: this.$t('section.structure.model.addChangeModel'), action: this.showAddPatchModel })
        }
       
          // menu.push({ title: this.$t('section.classification.menuClassification.addSection'), action: this.showAddSection })
          //menu.push({ title: 'Настройки проекта', action: this.editProjectForm }),
        if(this.hasModelDownloadRevision) menu.push({ title: this.$t('section.structure.downloadAllRevisions'), action: () => this.downloadAllRevsiions() })
        menu.push({ title: this.$t('section.structure.importStructure'), action: this.showCreateStructDialog })

        return menu
      }
      else return null
    },
    unitMenu () {
      let menu = []

      if (this.contextModel.revision || this.contextModel.pointCloud) {
        menu.push({ title: this.$t('section.structure.model.offsetGroup.showModel'), action: this.showModel })
        menu.push({ title: this.$t('section.structure.model.offsetGroup.showModelGroup'), action: this.showModelGroup })
      }

      if (this.contextModel.archived || this.contextModel.inArchive) {
        if (this.hasProjectControlArchive) menu.push({ title: this.$t('button.fromArch'), action: this.showFromArchModel })
        if (this.hasProjectDeleteModel) menu.push({ title: this.$t('button.delete'), action: this.showDelModel })
      } 
      else {
        if ( this.flag ) {
          menu.push({ title: this.$t(`button.${this.title}`), action: this.showParentModel })
        }
        if (this.hasProjectStruct) menu.push({ title: this.$t('section.structure.addUnderModel'), action: this.showNewModelDialog })
        if (this.hasProjectStruct) menu.push({ title: this.$t('button.edit'), action: this.showEditModelDialog })
        if (this.hasProjectControlArchive || this.hasProjectSendToArchive) menu.push({ title: this.$t('button.toArch'), action: this.showArchModel })
        if (this.hasProjectDeleteModel) menu.push({ title: this.$t('button.delete'), action: this.showDelModel })
      }
      return menu
    },

    showPatches() {
      return window.settings.showPatches ?? false
    }
  },

  beforeDestroy() {
    const scene = XeokitMediator.viewer?.scene
    if (scene) {
      const objectIds = scene.objectIds
      scene.setObjectsPickable(objectIds, true)
    }
  },

  methods: {
    ...mapActions('project', ['addNewModel']),
    ...mapActions('project', ['saveModel', 'deleteModel', 'archiveModel', 'fromArchiveModel', 'getPatchInfo', 'updateWorkModels']),
    ...mapMutations('project', ['setModelsList']),

    showCreateStructDialog() {
      this.dialog.createProjectStruct = true
    },

    showContextMenu(event) {
      this.$refs.addMenu.show(event)
    },

    showUnitContextMenu(event, model) {
      this.contextModel = model
      this.contextModel.model.length > 0 ? this.flag = true : this.flag = false
      let workModel = this.projectSettings.workModel[this.contextModel.uuid]
      if (workModel) {
        workModel.switchon ? this.title = "hideParentModel" : this.title = "showParentModel"
      }
      this.$refs.unitMenu.show(event)
    },

    showUnitContextMenuArchived(event, model) {
      this.contextModel = model
      this.contextModel.inArchive = true;
      this.$refs.unitMenu.show(event)
    },

    showNewModelDialog () {
      this.newModelTitle = ''
      this.choosedInSelect = this.contextModel ? this.contextModel.uuid : ''

      this.inheritOffset = true
      this.dialog.model = true
      this.contextModel = {}
    },

    showModel() {
      XeokitMediator.CameraFlight.flyToProjectModel(this.contextModel)
    },

    showModelGroup() {
      const groupUuids = this.modelOffsetGroupsStore.groups[this.contextModel.uuid].groupUuids
      XeokitMediator.CameraFlight.flyToProjectModels(groupUuids)
    },

    showParentModel () {
      let listUuids = []
      Object.keys(this.projectSettings.workModel).forEach(uuid => {
        let model = this.projectSettings.workModel[uuid]
        if(model.switchon) listUuids.push(uuid)
      })

      if(listUuids.includes(this.contextModel.uuid)) {
        listUuids = listUuids.filter((el) => el != this.contextModel.uuid)
      } 
      else {
        listUuids.push(this.contextModel.uuid)
      }
      
      this.updateWorkModels(listUuids)
    },

    showEditModelDialog () {
      this.newModelTitle = this.$t(this.contextModel.title)

      let model = this.flatlist.find(m => m.model.map(i => i.uuid).includes(this.contextModel.uuid))
      const matchingItem = this.copyFlatlist.find(item => item.name === this.contextModel.name)

      if (this.modelUuid.includes(this.contextModel.uuid)) {
        if (matchingItem) {
          this.inheritOffset = matchingItem.inheritOffset;
        }
      } else {
        this.modelUuid.push(this.contextModel.uuid)
        this.inheritOffset = true
        this.contextModel.inheritOffset = true

        if (matchingItem) {
          matchingItem.inheritOffset = this.contextModel.inheritOffset;
        }
      }
      this.choosedInSelect = model ? model.uuid : ''
      this.dialog.model = true
    },

    showDelModel () {
      this.dialog.modelDel = true
    },

    showArchModel () {
      this.dialog.modelArch = true
    },

    showFromArchModel () {
      this.dialog.modelFromArch = true
    },

    showAddPatchModel(){
      if (!this.$refs.floatingPanel.openedPanel)
        this.$refs.floatingPanel.openBody()
      
      this.$nextTick(() => {
        this.$refs?.modelPatchList?.showAddPatchModel() 
      })
    },

    showPatchListMenu($event) {
      if(this.hasProjectReconstruction)
      this.$refs?.modelPatchList?.showPatchListMenu($event)
    },

    confirmDeleteModel () {
      if (this.contextModel.revision && this.contextModel.revision) {
        let model = XeokitMediator.viewer?.scene.models[this.contextModel.revision.uuid];
        
        if(model) model.destroy()
      }

      this.choosedInSelect = ''
      this.deleteModel(this.contextModel.uuid)
      this.contextModel = ''
      this.dialog.modelDel = false
    },

    confirmArchModel () {
      if (this.contextModel.revision) {
        let model = XeokitMediator.viewer?.scene.models[this.contextModel.revision.uuid];
        
        if(model) model.destroy()
      }

      this.choosedInSelect = ''
      this.archiveModel(this.contextModel.uuid)
      this.contextModel = ''
      this.dialog.modelArch = false
    },

    confirmFromArchModel () {
      this.fromArchiveModel(this.contextModel.uuid)
      this.dialog.modelFromArch = false
    },


    saveEditableModel() {
      let data = {
        // projectUuid: this.projectUuid,
        modelUuid: this.choosedInSelect ? this.choosedInSelect : '',
        // name: this.newModelTitle, // 
        name: this.contextModel.name, // 
        title: this.newModelTitle, 
        inheritOffset: this.inheritOffset,
      }

      const foundItem = this.copyFlatlist.find(item => item.name === this.contextModel.name)

      if (foundItem) {
        foundItem.inheritOffset = this.inheritOffset;
      }

      this.saveModel({ uuid: this.contextModel.uuid, data })
    },

    downloadAllRevsiions() {
      this.loadingProcesses++
      projectService.downloadAllRevisions(this.projectUuid).then((data) => {
        const url = URL.createObjectURL(data)
        const filename = this.projectUuid + '.zip'
        
        const link = document.createElement('a')
        
        link.href = url
        link.download = filename
        
        link.click()
        
      }).finally(() => this.loadingProcesses--)
    },
  },

  mounted() {
    this.copyFlatlist = this.flatlist
  },

  watch: {
    flatlist() {
      this.flatlist.forEach((item, index) => {
        if (item?.name !== this.copyFlatlist[index]?.name && item?.name !== undefined) {
          this.copyFlatlist.splice(index, 0, item)
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.sizeW {
  width: 320px !important;
}
.dialog-confirm-action {
  margin: 0px;
  font: normal 10px/12px $roboto;
  // color: #FFFFFF;
}

.base-scroll-body {
  overflow-x: hidden;
}

.arch-title {
  color: var(--v-surface-lighten3);
  font-size: 12px;
}
table{
  color: black;
}
.head-sizable-block{
  // background-color: ;
  width: 100%;
  height: 7px;
  cursor: row-resize;
  // pointer-events: all;
  z-index: 998;
}
.horizontal-icon-pos {
  display: table;
  position: relative;
  left: 50%;
}
</style>