<template lang="pug">
  div.base-scroll-body.pt-1( :style="resizeTree ? 'max-height: 100% !important': ''" )
    div( v-if="isRuleTreeLoading" )
      v-skeleton-loader( type="list-item-two-line" light )

    div( v-else-if="!isRulesExist")
      .d-flex.mt-2(style="height: 100%; overflow-y: hidden;")
        v-icon.ml-2( color="primary lighten-3" ) info
        app-text.ml-2.mt-1 {{ $t('section.elementTree.axis.rulesNotFound') }}

    div( v-else )
      .d-flex.justify-space-between 
        v-btn.dict-button.px-1( color="accent" dense text x-small @click="toggleMassFolding" test-id="rule-tree-collapse-expand") 
          v-icon.mr-1( color="accent" size="16" left ) unfold_more
          | {{ areAllTreeFolderOpens ? $t('section.elementTree.axis.collapseTree') : $t('section.elementTree.axis.expandTree') }}
        v-btn.dict-button.px-1( color="accent" dense text x-small @click="toggleMassSelect" test-id="rule-tree-select-all") 
          | {{ areAllTreeRulesSelected ? $t('section.elementTree.axis.clearAll') : $t('section.elementTree.axis.selectedAll') }}
          v-icon.ml-1( v-if="areAllTreeRulesSelected" color="accent" size="16" ) remove_done
          v-icon.ml-1( v-else color="accent" size="16" ) done_all
      v-treeview.__project_view_tree(
        v-model="selected"
        :items="preparedActiveRuleTree"
        item-key="uuid" item-text="title" item-children="nodes" color="accent" 
        light dense selectable open-on-click return-object 
        :open.sync="rulesOpen" v-bind="$attrs" v-on="$listeners"
        )
        template( #label="{ item }" )
          .d-flex.justify-space-between( @contextmenu.stop="event => onMenu({ item, event })" )
            .node-folder.shrink( v-if="item.isFolder" v-line-clamp="1" test-id="axis-section-rule-folder") {{ item.title }}
            .node-rule.shrink( v-if="item.isRule" v-line-clamp="1" test-id="axis-section-rule") {{ item.title }}
            .accent--text.text--darken-3.px-1(test-id="collisions-count") {{ item.countCollisions }}

    app-menu( ref="menu" :menu="menu" )

    app-dialog-confirm( v-model="dialog.confirm" @confirm="recalculateSelected" ) 
      app-text {{ $t('section.elementTree.axis.recalculateCollision', {length: selected.length}) }}
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  props:{
    resizeTree: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    dialog: {
      confirm: false
    }, 
    item: null,
  }),

  computed: {
    ...mapGetters('rule', ['preparedActiveRuleTree', 'selectedRules', 'isRuleTreeLoading', 'markedBaseRules', 'countActiveFolder']),
    ...mapGetters('projectPermissions', ['hasCollisionCalc', 'hasCollisionView']),
    ...mapState('rule', ['openedFolder']),

    selected: {
      get () { return this.selectedRules },
      set (rules) { this.selectRules(rules) }
    },

    rulesOpen:{
      get() { return this.openedFolder.rules },
      set(rules) {this.SET_OPEN_FOLDER_RULES(rules)}
    },

    menu () {
      let list = []

      if(this.hasCollisionCalc) list.push({ title: this.$t('section.elementTree.axis.recalculateSelected'), action: this.showRecalculateDialog, disabled: !this.selected.length })

      if (this.item?.isRule && this.hasCollisionView) {
        list.push({ title: this.$t('button.edit'), action: this.editRule })
      }

      return list
    },

    isRulesExist() {
      return this.markedBaseRules && this.markedBaseRules.length > 0
    },

    areAllTreeFolderOpens() {
      return (this.rulesOpen?.length === this.countActiveFolder) || (this.rulesOpen?.length >= this.countActiveFolder)
    },

    areAllTreeRulesSelected() {
      return this.selected?.length === this.markedBaseRules?.length
    },
  },

  methods: {
    ...mapActions('rule', ['selectRules', 'recalculateSelected']),
    ...mapMutations('rule', ['SET_OPEN_FOLDER_RULES']),

    onMenu ({ event, item }) {
      this.item = item
      this.$refs.menu.show(event)
    },

    showRecalculateDialog () {
      this.dialog.confirm = true
    },

    editRule () {
      this.$emit('edit:rule', this.item)
    },

    toggleMassFolding() {
      if (!this.areAllTreeFolderOpens) {
        this.rulesOpen = []
        this.expandFolders(this.preparedActiveRuleTree)
      } else this.rulesOpen = []
    },

    expandFolders(tree) {
      tree.forEach(folder => {
        this.rulesOpen.push(folder)
        if (folder.children && folder.children.length > 0)
          this.expandFolders(folder.children)
      })
    },

    toggleMassSelect() {
      this.areAllTreeRulesSelected ? this.selectRules([]) : this.selectRules(this.markedBaseRules)
    },
  }
}
</script>

<style lang="scss" scoped>
.node-folder {
  color: var(--v-primary-base);
  font: bold 12px/16px $roboto;
  cursor: context-menu;
}
.node-rule {
  color: var(--v-primary-base);
  font: normal 12px/16px $roboto;
  cursor: context-menu;
}
.node-visibility {
  background-color: var(--v-primary-lighten5);
  color: var(--v-primary-base);
  font: normal 10px/14px $roboto;
  border-radius: 4px;
}

.dict-button {
  display: block;
  font: normal 10px/16px $roboto;
  text-transform: none;
}
</style>

<style lang="sass">
.__project_view_tree.v-treeview
  .v-treeview-node__level
    width: 8px
  .v-icon
    font-size: 16px
  .v-treeview-node__content
    padding-right: 20px
  .v-treeview-node__checkbox 
    position: absolute
    right: 2px
</style>