<template lang="pug">
.app-toolkit-column
  ToolButtonSceneColors
  ToolButtonSceneGroups
  ToolButtonSceneTypes
</template>

<script>
import ToolButtonSceneColors from './buttons/ToolButtonSceneColors.vue'
import ToolButtonSceneGroups from './buttons/ToolButtonSceneGroups.vue'
import ToolButtonSceneTypes from './buttons/ToolButtonSceneTypes.vue'

export default {
  components: {
    ToolButtonSceneColors,
    ToolButtonSceneGroups,
    ToolButtonSceneTypes,
  },
}
</script>