<template lang="pug">
  .task-report-container#task-report
    .task-report-header#task-report-header.py-3.align-center

      .font-report-header-title {{ $t('module.task.reports.title') }}

      .d-flex.align-center.task-progress-bar-container( :style='`color: var(--v-primary-darken2); font-weight: 400; font-size:13px; margin-left:10px;`' )
        .d-flex.align-center
          .progress-border
            v-progress-linear( color="var(--v-primary-darken2)" height="3" background-color='#F0F0F0' :value="selectedTask.progress")
          .count
            span {{  selectedTask.progress + '%' }} 

      app-icon-button.ml-auto( icon="mdi-close" @click.stop='$emit("close")' test-id="close-task-report" )

    .task-report-body.px-2.pt-1( v-if="dataTask && dataTask.report.estimation" )
      .font-weight-regular {{ dataTask.smeta && dataTask.smeta.node ? dataTask.smeta.node.title : "" }} 

        .volume-unit.font-report-discription.mt-2( v-if="dataTask.report.estimation" ) {{ $t("module.task.reports.planValue") + " " + dataTask.report.estimation + " " + dataTask.report.unitType.title }}
      
        .font-weight-regular.task-report-empty( v-else ) {{ $t('module.task.reports.incorrectRule') }}

      task-report-table.mt-3( ref="taskReportTable" :selectedTask="selectedTask" :estimation="dataTask.report.estimation" 
        :volumes='reportList' :headers="headers" @update="sortReportList()" )

      task-button-action.add-report-btn.mb-2(v-if="hasTaskVolumes" icon="project/tools/add_box_grey.svg" @click.stop="addNewReport()" test-id="add-new-report")
      

    .task-report-body.px-2.pt-5( v-else )
      .d-flex.align-center( style="heigth: 10px" )
        .volume-unit.font-report-discription {{ $t("module.task.reports.planValue") }}
        input.report-input.report-font.ml-2( v-model="estimation" :disabled="isUnitTypePercent" maxlength="25" size="5" style='width: 37%' )
        select.report-select.report-font.ml-1( v-model="unitType" ref="selectedUnit" :style="reportSelectorWidth+'min-width:30px;'"  @input="unitTypeSelected()")
          option( v-for="(unit, index) in measureUnitType" :key="unit.value" :selected="isUnitSelected(unit)") {{ unit.title || "%" }}
        task-button-action.ml-2( v-if="hasTaskVolumes && estimationOrUnitTypeIsChange" icon="project/tools/save.svg" @click.stop="updateEstimation()" )

      task-report-table.mt-3( ref="taskReportTable" :selectedTask="selectedTask" :estimation="selectedTask.report.estimation" 
        :volumes='reportList' :headers="headers" @update="sortReportList()" ) 
        
      task-button-action.add-report-btn.mb-2(v-if="hasTaskVolumes" icon="project/tools/add_box_grey.svg" @click.stop="addNewReport()" test-id="task-report-add-new")

    app-dialog-confirm(
      v-model="dialog.updateEstimation" 
      @confirm="saveTaskReport()"
    ) 
      | {{ $t('module.task.reports.estimationUpdate') }}
</template>


<script>
import { SourcePath } from '@app/SourcePath'
import { mapState, mapActions, mapGetters } from 'vuex'
import { Task } from '@/assets/model/task/Task'
import { api } from "@/api"

import DialogToggable from '@/components/app/DialogToggable'
import TaskReportTable from '@/components/task/taskReport/TaskReportTable'
import TaskProgressBar from '@/components/task/TaskProgressBar.vue'
import TaskButtonAction from '@/components/task/taskDetailsSections/TaskButtonAction.vue'


export default {
  name: "TaskReport",

  components:{
    TaskProgressBar,
    TaskReportTable,
    TaskButtonAction
  },

  mixins: [ DialogToggable ],

  filters: {
    prettyDate: (fulldate) => {
      let [date, time] = fulldate.split(' ')
      let [hour, minutes] = time.split(':')
      let [year, month, day] = date.split('-')
      return `${day}.${month}.${year} ${hour}:${minutes}`
    },
  },

  props:{
    selectedTask: null,
  },



  data() {
    return{
      dataTask: null,
      pos1: "",
      pos2: "",
      pos3: "",
      pos4: "",
      startPos: {
        x: 0,
        y: 0
      },
      volume: {
        quantityAll: "",
        spent: "",
        projected: ""
      },
      measureUnit: 0,
      dialog: {
        addReport: false,
        updateEstimation: false
      },

      headers: [
        { title: this.$t('module.task.reports.date'), start: true, minWidth: "90px", name: "DATE", show: true, required: true, position: 0 },
        { title: this.$t('section.elementTree.geometry.volume'), minWidth: "70px", flex: true, icon: true, name: "VALUE", show: true, required: false, position: 1 },
        { title: "%", minWidth: "54px", icon: true, name: "PROCENT", show: true, required: false, position: 2 },
        { title: "", minWidth: "20px", icon: true, name: "SAVE", show: true, required: false, position: 3 },
        { title: "", minWidth: "20px", icon: true, name: "DELETE", show: true, required: false, position: 4 },
      ],

      reportList: [],

      estimation: 0,
      
      unitType: "",


    } 
  },

  mounted() {
    this.dragElement(document.getElementById("task-report"))
    this.updateReportValues()
    this.smetaInfo()
    this.sortReportList()

    this.headers[1].title = this.$t('section.elementTree.geometry.volume') + "," + this.selectedUnitTypeTitle()
  },

  watch:{
    selectedTask(val, oldVal){
      if(val.uuid==oldVal.uuid) {
        this.smetaInfo()
      }
      else{
        this.smetaInfo()
        this.updateReportList()
      }
    },
  },

  computed: {
    ...mapState('task', ['reports', 'measureUnitType', 'taskTreeCache']),
    ...mapGetters('projectPermissions', ['hasTaskVolumes']),

    allReport () {
      return this.reports || []
    },

    estimationOrUnitTypeIsChange () {
      if((!this.selectedTask.report.unitType.value || this.selectedTask.report.unitType.value==0) && this.unitType=="%") return false 
      if(!this.selectedTask.report.estimation)
        return "" !== this.estimation || 
          (this.selectedTask.report.unitType.title == "" ? "%" : this.selectedTask.report.unitType.title) !== this.unitType
      else return this.selectedTask.report.estimation.toString() !== this.estimation || 
        (this.selectedTask.report.unitType.title == "" ? "%" : this.selectedTask.report.unitType.title) !== this.unitType
    },

    reportSelectorWidth () {
      return "width: " + (this.unitType.length * 7 + 32) + "px;"
    },

    isUnitTypePercent() {
      return this.unitType=="%"
    }

  },

  methods: {
    ...mapActions('task', ['getSmetasByTaskUuid', 'getSmetaVolumes', 'createTaskReport', 'editTaskReport']),

    avatarText (profile) {
      return profile.archive ? profile.name + this.$t('section.structure.model.memberDeleted') : profile.name
    },

    avatarUrl (profile) {
      return profile.avatar ? SourcePath.avatar(profile.avatar) : ''
    },

    measureunitsList () {
      api.measureunits.list().then(data => {
        this.measureunits = data
      })
    },

    dragElement (elmnt) {
      let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      if (document.getElementById("task-report-header")) {
        document.getElementById("task-report-header").onmousedown = dragMouseDown;
      } else {
        elmnt.onmousedown = dragMouseDown;
      }

      let top = '300px'
      // if(this.startPos.y){  
      //   if(this.startPos.y < 400) top = (this.startPos.y - 54) + "px"
      //   else top = (this.startPos.y - 325) + "px"        
      // } else top =  200 + "px"
      elmnt.style.top = top

      let left = 'calc(50% - 175px)'
      // if(this.startPos.x) left = this.startPos.x - (document.documentElement.clientWidth - toNumber(this.panelDataById('tasks').width)) //такое обращение к панели нежелательно
      // else left = document.documentElement.clientWidth / 40
      elmnt.style.left = left
      
      function dragMouseDown(e) {
        e = e || window.event;
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
      }

      function closeDragElement() {
        document.onmouseup = null;
        document.onmousemove = null;
      }
    },

    closeAddReportForm () {
      this.dialog.addReport=false
    },

    smetaInfo (){
      this.dataTask=null
      if (this.selectedTask) {
        this.getSmetasByTaskUuid(this.selectedTask.uuid).then((data) => {
          if (!data) return
          this.dataTask = new Task(this.selectedTask, this.selectedTask.parent, this.selectedTask.level, data)
          this.getSmetaVolumes({ taskUuid: null, dataTask: this.dataTask }).then(volume => {
            this.volume = volume
            this.loading = false
          })
          .catch(() => {
            console.log('SMETA not found');
            this.dataTask = null
          })
          if(this.dataTask.smeta.grandSmetaPosition){
            this.totalVolume = this.dataTask.smeta.grandSmetaPosition.priceBase ? this.decimalAdjust(this.toFloat(this.dataTask.smeta.grandSmetaPosition.priceBase.pz) * this.toFloat(this.dataTask.smeta.grandSmetaPosition.quantity.result)) : 0
            this.wageFund = this.dataTask.smeta.grandSmetaPosition.priceBase ? this.decimalAdjust((this.toFloat(this.dataTask.smeta.grandSmetaPosition.priceBase.oz) + this.toFloat(this.dataTask.smeta.grandSmetaPosition.priceBase.zm)) * this.toFloat(this.dataTask.smeta.grandSmetaPosition.quantity.result)) : 0
            this.overheads = this.decimalAdjust(this.wageFund * 1.02)
            this.estimatedProfit = this.decimalAdjust(this.wageFund * 0.58)
            this.total = this.decimalAdjust(this.totalVolume + this.overheads + this.estimatedProfit)
          }
        })
      }
      
    },

    saveTaskReport (){
      if(this.selectedTask.report.uuid){
        let data = {
          uuid: this.selectedTask.report.uuid,
          estimation: this.estimation,
          unitType: this.measureUnitType[this.$refs.selectedUnit.selectedOptions[0].index],
        }
        this.editTaskReport({selectedTask: this.selectedTask, report: data}).then( () => { 
          this.$refs.taskReportTable.loadNewVolumes()
          this.sortReportList()
        })
      }
      else{
        let data = {
          estimation: this.estimation,
          unitType: this.measureUnitType[this.$refs.selectedUnit.selectedOptions[0].index],
        }
        this.createTaskReport({selectedTask: this.selectedTask, report: data}).then((response) => {
          this.dataTask.report = response
          this.updateReportList()
        })
      }
      
      this.headers[1].title = this.$t('section.elementTree.geometry.volume') + "," + this.selectedUnitTypeTitle()
      
    },

    updateReportList () {
      this.reportList=[]
      if(this.selectedTask.report.volumes){
        this.selectedTask.report.volumes.forEach(report => {
          report.edit=false
          this.reportList.push(report)
        })
      }
      this.updateReportValues()
      this.$refs.taskReportTable.loadNewVolumes()
    },

    isUnitSelected (unit) {
      return this.selectedTask.report.unitType.value === unit.value ? "selected" : ""
    },


    updateReportValues () {
      this.estimation= this.selectedTask.report.estimation ? this.selectedTask.report.estimation.toString() : ""
      if(this.selectedTask.report.unitType.value){
        if(this.selectedTask.report.unitType.value==0) this.unitType="%"
        else this.unitType = this.selectedTask.report.unitType.title
      }
      else{
        this.estimation = 100
        this.unitType="%"
      }
    },

    sortReportList () {
      this.reportList=[]
      if(this.selectedTask.report.volumes){
        this.selectedTask.report.volumes.forEach(report => {
          report.edit=false
          this.reportList.push(report)
        })
      }
      this.updateReportValues()
    },
  
    addNewReport () {
      this.$refs.taskReportTable.addNewReport()
      // let data = {
      //   reportDate: new Date(Date.now() + (3600 * 1000 * 24)).toISOString().slice(0,10),
      //   value: 0,
      //   edit: true,
      //   index: this.readyAddReportList.length
      // }
      // this.readyAddReportList.push(data)
      // this.reportList.push(data)
    },

    deleteReport (report) {
      if(report.uuid) this.reportList = this.reportList.filter((volume) => { return volume.uuid != report.uuid })
      else {
        this.reportList = this.reportList.filter((volume) => { return volume.index != report.index })
      }
    },

    updateEstimation () {
      if(this.selectedTask.report.unitType.value==0 || (this.unitType == this.selectedTask.report.unitType.title)){
        this.saveTaskReport()
      }
      else{
        this.dialog.updateEstimation=true
      }
    },

    unitTypeSelected () {
      if(this.$refs.selectedUnit.selectedOptions[0].value=="%"){
        this.estimation=100
      }
    },

    selectedUnitTypeTitle () {
      let unitTypeSpaced = this.unitType.split(" ")
      if ( unitTypeSpaced.length==1 ) {
        if(this.unitType.length>3) return this.unitType.substr(0,2)+"..."
        else return this.unitType
      }
      else {
        return unitTypeSpaced[0].substr(0, 1) + "/" + unitTypeSpaced[1].substr(0, 1)
      }
    }
  }
}
</script>

<style>
.t-report-input{
  background: #FFFFFF;
  border: 1px solid #757575;
  border-radius: 3px;
}
.darkTextareaStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #4D4D4D;
  line-height: 1100%;
}
.task-report-empty {
  margin-top: 5px ;
}

.task-report-container{
  background: var(--v-surface-base);
  top: 0px;
  left: 0px;
  padding: 0px 4px 8px 4px;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 350px;
  /* height: 450px; */
  max-height: calc(70% - 150px);
  min-height: 350px;
  border-radius: 3px;
  z-index: 10000;
  /* box-shadow: 5px 5px 5px black; */
  font-size: 14px;
}
.task-report-header{
  display: flex;
  color: #E5E5E5;
  font-weight: 300;
  padding: 0px 0px 0px 8px;
  
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.task-report-body{
  background: white;
  color: #262626;
  overflow-y: auto;
  flex: 1;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  font-weight: 300;
  gap: 4px;
  overflow-x: hidden;

  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; 
}
.task-report-body::-webkit-scrollbar {
  width: 0;
}
.font-report-header-title{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: var(--v-primary-darken2);
  overflow: hidden;
  text-overflow: ellipsis
}
.font-report-discription{
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #757575;
}
.task-progress-bar-container{
  height: 0;
}
.progress-border{
  border: 1px var(--v-primary-darken2) solid; 
  width:50px;
}
.progress-cursor{
  cursor: pointer;
}
.count{
  width: 30px;
  padding: 0px 4px;
  text-align: center;
  font-size: 12px;
}
.report-input{
  border-bottom: solid 1px;
  outline:none;
  text-align:center;
}
.report-font{
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #262626;
}
.report-select{
  -webkit-appearance: listbox !important;
  background-image: linear-gradient(to right, #f8f8f8 calc(100% - 15px), transparent 0);
}
.add-report-btn{
  min-height: 24px;
}
</style>