<template lang="pug">
  app-dialog(v-model="$_show" :header="$t('section.classification.menuClassification.smallImportClassifier')" width="1024")
    app-section
      v-tabs.mb-2(v-model="tab" light)
        v-tab(v-if="dictionary.length") Стандартные
        v-tab {{$t('section.classification.menuClassification.SCES')}}
        v-tab {{$t('section.classification.menuClassification.archicad')}}
        v-tab BIMIT
        v-tab Excel
      v-tabs-items(v-model="tab" light)
        v-tab-item(v-if="dictionary.length")
          v-row
            v-col(cols=3)
              //- v-expansion-panels
              //-   v-expansion-panel(v-for="dict in dictionary" :key="dict.uuid")
              //-     v-expansion-panel-header {{ dict.name }}
              //-     v-expansion-panel-content
              //-       div(v-for="version in dict.versions" :key="version.uuid" @click="selectVersion(version)") {{ version.name }}
              v-treeview(
                dense
                light
                open-on-click
                hoverable
                item-key="uuid"
                item-text="name"
                :items="dictionary"
                item-children="versions")
                template(v-slot:label="{ item }")
                  div(@click="selectVersion(item)") {{ item.name }}
                
            v-col(cols=1)
              v-divider(light vertical)
            v-col(v-if="selectedVersion")
              v-form(ref="exportForm" @submit.prevent="importStandart")
                div.mt-1
                  app-text-field(v-model="importRequest.title" :label="$t('section.iot.name')" outlined test-id="import-archicad-title")
                div
                  app-text.bold Состав справочника
                div
                  app-text Обязательные
                div(v-for="file in requiredFiles" :key="file.uuid")
                  v-checkbox(:label="file.originalName" v-model="importRequest.files" :value="file.uuid" hide-details dense disabled)
                v-divider.my-2(light)
                div
                  app-text Дополнительно
                div(v-for="file in notRequiredFiles" :key="file.uuid")
                
                  v-checkbox(:label="file.originalName" v-model="importRequest.files" :value="file.uuid" hide-details dense)
                .d-flex
                  v-spacer
                  v-btn(color="accent" height="32" small type="submit" @click="$_show = false") {{$t('button.import')}}
              
        v-tab-item
          div.mt-2
            app-text-field(v-model="gesnRequest.title" :label="$t('section.iot.name')" outlined test-id="import-archicad-title")
            app-text {{ $t('section.classification.menuClassification.main') }}
            v-checkbox(:label="$t('section.classification.menuClassification.SCES')" disabled v-model="gesnRequest.gesn" hide-details dense)
            v-checkbox(label="ФСБЦ_Мат_Оборуд" disabled v-model="gesnRequest.fssc" hide-details dense)
            v-checkbox(label="ФСБЦ_Маш" disabled v-model="gesnRequest.fsem" hide-details dense)
          v-divider(light)
          div.mt-2
            app-text {{ $t('section.classification.menuClassification.additionally') }}
            v-checkbox(label="ГЭСНм" v-model="gesnRequest.gesnm" hide-details dense)
            v-checkbox(label="ГЭСНмр" v-model="gesnRequest.gesnmr" hide-details dense)
            v-checkbox(label="ГЭСНп" v-model="gesnRequest.gesnp" hide-details dense)
            v-checkbox(label="ГЭСНр" v-model="gesnRequest.gesnr" hide-details dense)
            v-checkbox(label="ФССЦпг" v-model="gesnRequest.fsscpg" hide-details dense)
          v-btn.mt-2.text-none.dialog-confirm-action(v-if="!importLoading" color="accent" height="32" small depressed light test-id="import-base-classifier" @click="importGesn") {{$t('section.classification.menuClassification.importSCES')}}

        v-tab-item
          v-form.mt-2(v-on:submit.prevent="saveArhicad")
            app-text-field(v-model="arhicadTitle" :label="$t('section.iot.name')" outlined test-id="import-archicad-title")
            app-file-input(v-model="arhicadFile" formats=".txt" :label="$t('section.classification.menuClassification.fileArchicad')" test-id="import-archicad-file")
            .d-flex
              v-spacer
              v-btn(color="accent" height="32" small type="submit" :disabled="arhicadFile ? false : true" test-id="import-archicad-btn") {{$t('button.import')}}

        v-tab-item
          v-form.mt-2(v-on:submit.prevent="saveJson")
            app-text-field(v-model="jsonTitle" :label="$t('section.iot.name')" outlined hide-details test-id="import-bimit-title")
            .title-note {{ $t('section.classification.menuClassification.replaceName') }}
            app-file-input(v-model="jsonFile" formats=".json" :label="$t('section.classification.menuClassification.bimitFormat')" test-id="import-bimit-file")
            .d-flex
              v-spacer
              v-btn(color="accent" height="32" small type="submit" :disabled="jsonFile == null ? true : false || importLoading" test-id="import-bimit-btn") {{$t('button.import')}}

        v-tab-item
          v-form(v-on:submit.prevent="saveExcel" ref="excelform")
            app-select.mt-1(v-model="excelImport.format" :items="excelImportFormats" item-text="title" :label="$t('section.classification.menuClassification.fileFormat')" outlined dense :rules="[rules.required]")

            v-radio-group(v-model="excelImport.makenew" row)
              v-radio(:label="$t('section.classification.menuClassification.createNew')" :value="true")
              v-radio(:label="$t('section.classification.menuClassification.addEdit')" :value="false")
            
            app-text-field.my-4(v-if="excelImport.makenew" v-model="excelImport.title" :label="$t('section.iot.name')" outlined dense clearable hide-details :rules="[rules.required]" test-id="import-excel-title")
            app-select(v-if="!excelImport.makenew" v-model="projectClassificatorSelected" :items="projectClassificators" item-text="title" :label="$t('section.classification.menuClassification.classifier')" outlined clearable dense :rules="[rules.required]")
            
            app-file-input(v-model="excelImport.file" formats=".xls,.xlsx" label="Excel format" test-id="import-excel-file")

            .d-flex
              v-spacer
              v-btn(color="accent" height="32" small type="submit" :disabled="excelImport.file == null ? true : false || importLoading" test-id="import-excel-btn") {{$t('button.import')}}

      div(v-if="importLoading")
        app-text {{$t('section.classification.menuClassification.importMessage')}}
        v-progress-linear(indeterminate)
      app-text.mt-2(v-if="errorMessage") {{ errorMessage }}


    app-section.mt-2.base-scroll-body.section-height(v-if="importResponce")
      app-text {{$t('section.classification.menuClassification.importResult')}}
      app-text(v-if="importResponce.projectClassificators") {{$t('section.classification.menuClassification.imported')}} {{ importResponce.projectClassificators.length }}
      div.my-2
        div(v-for="cl in importResponce.projectClassificators" :key="cl.uuid")
          app-text {{ cl.title }}

      div(v-if="importResponce.errors != null || importResponce.errors.length > 0")

        v-row.mb-2
          v-col(v-if="tab == 2 || tab == 1")
            v-btn(color="accent" height="32" @click="fullDeleteClassificators") {{$t('section.classification.menuClassification.deleteImport')}}
          v-col
            v-btn(color="accent" height="32" @click="$_show = false") {{$t('button.close')}}
          div(v-if="importDeleting")
            app-text {{$t('section.classification.menuClassification.removingImport')}}
            v-progress-linear(indeterminate)

        v-alert(type="error") {{$t('section.classification.menuClassification.importError')}}
        div(style="max-heigth:200;overflow:auto;")
          div.ma-1(v-for="(e,index) in importResponce.errors" :key="index")
            app-text {{ e }}

</template>

<script>
  import { api } from "@/api"
  import { mapState, mapActions } from "vuex"
  import DialogToggable from "@/components/app/DialogToggable"

  export default {
    name: "ImportClassificatorDialog",
    mixins: [DialogToggable],

    data() {
      return {
        importLoading: false,
        errorMessage: null,
        tab: 0,

        gesnRequest:{
          title: 'Справочники ГЭСН',
          gesn: true,
          fssc: true,
          fsem: true,
          gesnm: false,
          gesnmr: false,
          gesnp: false,
          gesnr: false,
          fsscpg: false
        },

        arhicadTitle: 'Справочник Архикад',
        arhicadFile: null,

        jsonTitle: null,
        jsonFile: null,

        excelImport: {
          makenew: true,
          title: "Новый классификатор",
          format: {name:"BIMIT", value:0, title: "Бимит"},
          file: null,
        },

        excelImportFormats: [
          {value:0, title: "Бимит"},
          {value:1, title: "Главстрой СПБ"}
        ],

        importDeleting: false,

        importResponce: null,

        projectClassificatorSelected: null,

        rules: {
          required: (value) => !!value || this.$t("error.require")
        },

        selectedVersion: null,
        importRequest: {
          title: null,
          version: null,
          files: []
        }
      }
    },

    computed: {
      ...mapState("project", ["project"]),
      ...mapState('smeta', ['projectClassificators']),

      ...mapState('smetadictionary', ['dictionary']),

      requiredFiles(){
        let arr = []
        if (this.selectedVersion && this.selectedVersion.files) arr = this.selectedVersion.files.filter(f => f.required)
        return arr
      },
      notRequiredFiles(){
        let arr = []
        if (this.selectedVersion && this.selectedVersion.files)  arr = this.selectedVersion.files.filter(f => !f.required)
        return arr
      }
    },

    watch: {
      $_show(){
        this.importLoading = false
        this.errorMessage = null

        this.arhicadFile = null
        this.jsonFile = null
        
        this.importResponce = null

        this.excelImport = {
          makenew: true,
          title: "Новый классификатор",
          format: {value:0, title: "Бимит"},
          file: null,
        }

        
        if (this.$_show) this.loadSmetaDictionaries()
      },
      tab(){
        this.errorMessage = null
      }
    },

    methods:{
      ...mapActions("smeta",["LOAD_PROJECT_CLASSIFICATORS"]),

      ...mapActions("smetadictionary",["loadSmetaDictionaries"]),

      importGesn() {
        this.importLoading = true

        api.smeta.importGesn(this.project.uuid, this.gesnRequest).then(data => {
          this.$emit('update',data)

          this.importLoading = false
          this.$_show = false
        }).catch(() => {
          this.importLoading = false
          this.errorMessage = this.$t('section.classification.menuClassification.errorMessage')
        })
      },

      saveArhicad(){
        if (this.arhicadFile) {
          this.importLoading = true
          let formData = new FormData()
          formData.append('file', this.arhicadFile)
          if (this.arhicadTitle) formData.append('title', this.arhicadTitle)
          
          api.smeta.importArhicad(this.project.uuid, formData).then(data => {
            if (data.errors == null || data.errors.length == 0) {
              this.$emit('update',data.projectClassificators[0])
              this.$_show = false
              
            } else {
              this.LOAD_PROJECT_CLASSIFICATORS(this.project.uuid)
              this.importResponce = data
            }
            
            this.importLoading = false
            this.arhicadFile = null
          }).catch(() => {
            this.importLoading = false
            this.errorMessage = this.$t('section.classification.menuClassification.errorMessage')
          })
        }
      },

      saveJson(){
        if (this.jsonFile) {
          this.importLoading = true
          let formData = new FormData()
          formData.append('file', this.jsonFile);
          if (this.jsonTitle) formData.append('title', this.jsonTitle);
          
          api.smeta.importJson(this.project.uuid, formData).then(data => {

            console.log(data)

            if (data.errors == null || data.errors.length == 0) {
              this.$emit('update',data.projectClassificators[0])
              this.$_show = false
              
            } else {
              this.LOAD_PROJECT_CLASSIFICATORS(this.project.uuid)
              this.importResponce = data
            }
            this.importLoading = false
            this.jsonFile = null
          }).catch(() => {
            this.jsonFile = ''
            this.importLoading = false
            this.errorMessage = this.$t('section.classification.menuClassification.errorMessage')
          })
        }
      },

      loadErrorsFile(){

      },

      fullDeleteClassificators(){
        let formData = new FormData()
        for (let pc of this.importResponce.projectClassificators) {
          formData.append('uuid', pc.uuid);
        }

        this.importDeleting = true
        api.smeta.fullDelete(formData).then(() => {
          this.importDeleting = false
          this.importResponce = null

          this.LOAD_PROJECT_CLASSIFICATORS(this.project.uuid)
        })
      },

      saveExcel(){
        if (this.$refs.excelform.validate()) {
          this.importLoading = true
          let formData = new FormData()
          formData.append('file', this.excelImport.file);
          formData.append('makenew', this.excelImport.makenew);
          if (this.excelImport.makenew) {
            formData.append('title', this.excelImport.title);
          } else {
            formData.append('classificatoruuid', this.projectClassificatorSelected.uuid);
          }

          formData.append('format', this.excelImport.format.value);
          
          api.smeta.importExcel(this.project.uuid, formData).then(data => {
            
            console.log(data)

            if (data.errors == null || data.errors.length == 0) {
              this.$_show = false
            } else {
              this.importResponce = data
            }
            this.$emit('update',data.projectClassificators[0])

            this.importLoading = false
            
            this.excelImport = {
              makenew: true,
              title: "Новый классификатор",
              file: null
            }
          }).catch(() => {
            this.importLoading = false
            this.errorMessage = this.$t('section.classification.menuClassification.errorMessage')
          })
        }
      },

      selectVersion(item){
        if('created' in item && 'files' in item){
          if (this.selectedVersion && this.selectedVersion.uuid == item.uuid) {
            this.selectedVersion = null  
            this.importRequest = {
              title: null,
              version: null,
              files: []
            }
          } else {
            this.selectedVersion = item
            this.importRequest.title = this.selectedVersion.name
            this.importRequest.version = this.selectedVersion.uuid
            this.importRequest.files = this.selectedVersion.files.filter(e => e.required).map(e => e.uuid)
          }
        }
      },

      importStandart(){
        if (this.$refs.exportForm.validate()) {
          api.smeta.importStandart(this.importRequest).then(data => {
            console.log(data)
          })
        }
      }
    }
  }
</script>

<style scoped>
  .title-note {
    font-size: 12px;
    font-style: italic;
    color: #000;
  }
  .section-height {
    overflow-x: hidden !important;
    height: 45vh
  }
  .bold{
    font-weight: bold;
  }
</style>