<template lang="pug">
  app-dialog( v-model="$_show" :header="$t('section.classification.menuClassification.classifierExport')"
              :confirmText="$t('button.export')"
              @confirm="exportClassificator"
              :closeOnConfirm="false" 
              :confirmDisabled="format == null ? true : false || exportLoading || projectClassificatorSelected == null ? true : false" )
              
    app-section
      v-select.mb-5(
        v-model="projectClassificatorSelected"
        :items="projectClassificators"
        item-text="title"
        item-value="uuid"
        return-object
        :label="$t('section.classification.menuClassification.classifier')"
        outlined
        clearable
        dense
        background-color="#fff"
        color="var(--v-accent-base)"
        item-color="#4D4D4D"
        :menu-props="{light: true, maxWidth:'fit-content', contentClass: 'menu-scroll'}"
        flat
        filled
        hide-details
        light
        test-id="classifier-select")

      app-select.mb-5(v-model="format" :items="exportFormat" item-text="title" label="Формат" hideDetails clearable  test-id="classifier-format")

      div(v-if="exportLoading" test-id="class-export-loading")
        app-text {{$t('section.classification.menuClassification.exportMessage')}}
        v-progress-linear(indeterminate)
      app-text.mt-2(v-if="errorMessage") {{ errorMessage }}
      
</template>

<script>
  import { mapState } from "vuex"

  import { config } from '@/_helpers'
  import { authUserService } from '@/_services'
  import { $_app_server } from '@/_helpers'

  import DialogToggable from "@/components/app/DialogToggable"

  export default {
    name: "ExportClassificatorDialog",
    mixins: [DialogToggable],

    data() {
      return {
        projectClassificatorSelected: null,
        exportFormat: ['json', 'xls'],
        format: 'json',

        exportLoading: false,
        errorMessage: null,
      }
    },

    computed: {
      ...mapState('smeta', ['projectClassificators']),
      ...mapState('project', ['project']),
    },

    watch: {
      $_show(){
        this.exportLoading = false
        this.errorMessage = null
      }
    },

    methods:{
      exportClassificator(){
        if (this.projectClassificatorSelected != null) {

          let send = () => {
            this.exportLoading = true
            $_app_server({
              url: `${config.apiUrl}smeta/projclass/${this.projectClassificatorSelected.uuid}/exportjson?projectUuid=${this.project.uuid}&format=${this.format}`,
              // headers: {'Authorization': `Bearer ${localStorage.getItem('access_token')}`},
              method: 'GET',
              responseType: 'blob'
            }).then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${this.projectClassificatorSelected.title}.${this.format}`);
              document.body.appendChild(link);
              link.click();
              link.remove()
              this.$_show = false
              this.exportLoading = false
            }).catch( (error) => {
              console.log(error)
              this.errorMessage = error
              this.exportLoading = false

              if (error.response !== undefined) {
                if (error.response.status === 401){
                  authUserService.refreshToken().then(() => {
                    send()
                  }).catch( (error) => {
                    console.log(error)
                    this.errorMessage = error
                    this.exportLoading = false
                  })
                }
              }
            })
          }

          send()

        }
      }
    }
  }
</script>