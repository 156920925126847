<template lang='pug'>
v-row.h100p(no-gutters style='flex-wrap: nowrap')
  v-col(cols style='max-width: 300px; min-width: 300px;')
    app-panel(padding='0px')
      app-floating-panels-section.floating-panels-section
        app-floating-panel(:menu="receiverListMenu" ref='receiverListPanel' :title="$t('module.mnemoschemas.receivers.dataSources')" bodyMinHeight="150" overflowBody="hidden" :settings="floatingPanels('RECEIVERS_LIST')")
          //-template(v-slot:headerButton)
              v-btn.mr-n1(small icon @click.stop='openReceiverListMenu($event)')
                v-icon mdi-playlist-play

          .base-scroll-body
            div.receiver-list-item(v-for='(receiver, index) in receivers' :class='receiverListItemClassName(index, receiver)' :key='receiver.uuid' @contextmenu='openReceiverListItemMenu($event, receiver)' @click='selectReceiver(receiver)')
              span {{ receiver.name }}
              v-btn(v-if="hasMaintainceEquipment" icon small @click.stop='openReceiverListItemMenu($event, receiver)')
                v-icon mdi-dots-vertical
        
        app-floating-panel( v-if="element" :title="$t('section.element', {name: element.name})" bodyMinHeight="150" overflowBody="hidden" 
          :settings="floatingPanels('MAINTENANCE_ELEMENT_DETAILS')")
          element-details.py-0( :element="element")

    
  v-col(cols style='overflow: auto;')
    app-panel(padding='0px')
      app-floating-panels-section.floating-panels-section.pl-2
        app-floating-panel(ref='channelListPanel' :closeable="false" :title="$t('section.journal.channels')" bodyMinHeight="150" overflowBody="hidden" :settings="floatingPanels('CHANNELS_LIST')")
          template(v-slot:headerButton)
            div.search-input-container
              input.search-input( id="channelInput" v-model='channelSearchText' @click.stop)
              v-icon.magnify mdi-magnify
          
          v-progress-linear(v-if='isChannelsLoading' indeterminate color='accent')
          div(v-else)

          div.text-center.black--text(v-if="searchChannels(channelSearchText).length == 0") 
            | {{ $t("module.mnemoschemas.receivers.emptyList") }}
          v-simple-table(v-else height='100%' style='height: 100%;')
            thead
              tr
                th( v-for="[index, columnTitle, property] in channelTableColumns" :key="index") {{ $t(columnTitle) }} 
                  v-icon( size="20" v-if="index == 'title'" @click="sortColumn(index)") {{ sortDesc ? 'mdi-arrow-down' : 'mdi-arrow-up' }}
                th
            tbody
              tr(v-for='channel in sortedChannels' @contextmenu='openChannelMenu($event, channel)')
                td(v-for='[property, columnTitle, format] in channelTableColumns' :class='{"red--text": isChannelHighlighted(channel.uuid)}') {{ (format ? format(channel) : channel[property]) || '-' }}
                td(v-if="hasMaintainceEquipment")
                  v-btn(small icon @click='openChannelMenu($event, channel)')
                    v-icon mdi-dots-vertical

  app-menu(ref='receiverListMenu' :menu='receiverListMenu')
  app-menu(ref='receiverListItemMenu' :menu='receiverListItemMenu')
  app-menu(ref='channelMenu' :menu='channelMenu')

  create-receiver-dialog(v-model='showReceiverCreateDialog' :receiver='actionReceiver' @save='createReceiver($event)')
  create-channel-dialog(v-model='showChannelCreateDialog' :channel='channelToCreate')
  app-dialog-confirm(v-model='showReceiverDeleteDialog' delete @confirm='deleteReceiver(actionReceiver)')
    app-text {{ $t('module.mnemoschemas.receivers.confirmReceiverDelete', { receiverTitle: (actionReceiver && actionReceiver.name) || '' }) }}
    
  app-dialog-confirm(v-model='showChannelDeleteDialog' delete @confirm='deleteChannel(actionChannel)')
    app-text {{ $t('module.mnemoschemas.receivers.channel.confirmChannelDelete', { channelTitle: (actionChannel && actionChannel.title) || '' }) }}

  create-channel-data-dialog(v-model='showCreateChannelDataDialog' :channel='actionChannel && actionChannel.uuid')

</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import CreateChannelDialog from '../mnemoschemas/components/CreateChannelDialog.vue'
import CreateReceiverDialog from '../mnemoschemas/components/CreateReceiverDialog.vue'
import CreateChannelDataDialog from '../mnemoschemas/CreateChannelDataDialog'
import ElementDetails from '../components/element/Details'
import { telemetryService } from '@/_services/telemetry.service'
import { Telemetry } from '@/utils'
import i18n from '@/plugins/i18n'
import { api } from '@/api'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

const defaultChannel = {
  title: null,
  receiver: null
}

let socket = null


export default {
  name: "Channels",

  components: {
    CreateReceiverDialog,
    CreateChannelDialog,
    CreateChannelDataDialog,
    ElementDetails,
  },

  data: () => ({    
    colorizedChannels: {},

    actionReceiver: null,
    channelToCreate: null,
    actionChannel: null,
    
    channelSearchText: '',

    showReceiverCreateDialog: false,
    showReceiverDeleteDialog: false,
    showChannelCreateDialog: false,
    showChannelDeleteDialog: false,
    showCreateChannelDataDialog: false,
    sortBy: 'title',
    sortDesc: false,
  }),

  beforeMount() {
    this.loadReceivers()
    this.initReceiverClassList()
    this.initConverterList()
    this.initRoundTypeList()
    this.initConvertTypeList()

    Telemetry.on('update', (data) => {
      this.addChannelToChannels(data)

      let recvTime = new Date(data.tData.recvTime)
      const profile = this.profiles.find(profile => profile.warningFeatures.find(feature => feature.uuid === data.warningData?.warningFeature))
      const channel = this.getChannel(data.tData.channel)

      if (profile) {
        const equipment = this.equipmentTree.flatlist.find(i => !i.isGroup && i.uuid == channel?.equipment)
        
        if (equipment?.elements.length > 0) {
          api.projects.icfGlobalIdToGlobalId(equipment.elements, this.projectUuid).then(data => {
            this.colorizedChannels[channel.uuid] = data
            const colors = [
              [0, 1, 0],
              [1, 1, 0],
              [1, 0, 0],
              [1, 1, 1],
            ]
            XeokitMediator.ElementsSettings.setElementsColorized(data, colors[profile.level.value])
          })
        }
      }
      else if (this.colorizedChannels[channel?.uuid || ""]) {
        XeokitMediator.ElementsSettings.setElementsColorized(this.colorizedChannels[channel.uuid], null)
        delete this.colorizedChannels[channel.uuid]
      }

      this.updateChannel({ uuid: data.uuid, value: data.tData.value, recvTime })
    })
  },

  mounted() {
    this.openPanels()
  },

  destroyed() {
    this.channelsMap.clear()
    Object.values(this.colorizedChannels).forEach(elementIds => {
      XeokitMediator.ElementsSettings.setElementsColorized(elementIds, null)
    })
    socket?.close()
  },

  computed: {
    ...mapState('maintenance', {'equipmentTree': 'tree'}),
    ...mapState('equipmentWarning', ['profiles', 'events']),
    ...mapGetters('floatingPanels', ['floatingPanels']),
    ...mapGetters('receiver', ['searchValidChannelList']),
    ...mapGetters('project', ['projectUuid']),
    ...mapGetters('channel/table', ['searchChannels', 'isChannelsLoading', 'isChannelHighlighted']),
    ...mapGetters('channel/cache', ['getChannel']),
    ...mapState('channel/table', ['channels']),
    ...mapState('receiver', ['receivers', 'selectedReceiver', 'channelsSearchText', 'channelsMap', 'receiversMap']),
    ...mapGetters('axis/tree', ['element']),
    ...mapGetters('authUser', ['timeZone']),

    ...mapGetters('projectPermissions', ['hasMaintainceEquipment']),

    receiverListMenu() {
      if (this.hasMaintainceEquipment) {
        return [
          { title: this.$t('module.mnemoschemas.receivers.dataSource.create'), action: () => this.openReceiverCreateDialog(true) }
        ]
      }

      return null
    },

    sortedChannels() {
      let sortedChannels = this.channels
      if(this.channelSearchText) {
        sortedChannels = this.searchChannels(this.channelSearchText)
      }
      if (this.sortBy === 'title') {
        sortedChannels.sort((a, b) => {
          const valueA = a.title.toLowerCase()
          const valueB = b.title.toLowerCase()
          if (valueA < valueB) return this.sortDesc ? 1 : -1
          if (valueA > valueB) return this.sortDesc ? -1 : 1
          return 0;
        })
      }
      // else if (this.sortBy === 'recvTime') {
      //   sortedChannels.sort((a, b) => {
      //     const valueA = new Date(a.recvTime)
      //     const valueB = new Date(b.recvTime)
      //     if (valueA < valueB) return this.sortDesc ? 1 : -1
      //     if (valueA > valueB) return this.sortDesc ? -1 : 1
      //     return 0
      //   })
      // }
      return sortedChannels
    },

    receiverListItemMenu() {
      return [
        { title: this.$t('button.edit'), action: () => this.openReceiverCreateDialog() },
        // { title: this.$t('module.mnemoschemas.receivers.channel.create'), icon: 'mdi-plus-box-outline', action: () => this.openChannelCreateDialog() },
        { title: this.$t('button.delete'), action: () => this.openDeleteReceiverDialog() },
      ]
    },

    channelTableColumns() {
      return [
        ['title', 'module.mnemoschemas.receivers.channel.channelTitle'],
        ['value', 'module.mnemoschemas.receivers.channel.channelLastData'],
        ['recvTime', 'module.mnemoschemas.receivers.channel.channelDataReceivingDate', (channel) => channel.recvTime ? new Date(channel.recvTime).toLocaleString(i18n.locale): null],
        ['topic', 'module.mnemoschemas.receivers.channel.channelTopic'],
      ]
    },

    channelMenu() {
      return [
        { title: this.$t('button.edit'), action: () => this.openChannelCreateDialog(this.actionChannel) },
        // { title: this.$t('section.сonfiguration.newValue'), icon: 'mdi-plus-box-outline', action: () => this.openCreateTDataChannelDialog() },
        { title: this.$t('button.delete'), action: () => this.openChannelDeleteDialog() }, 
      ]
    },

    $channelsSearchText: {
      get() {
        return this.channelsSearchText
      },

      set(value) {
        this.SET_CHANNELS_SEARCH_TEXT(value)
      }
    }
  },

  methods: {
    ...mapActions('receiver', ['loadReceivers', 'saveReceiver', 'deleteReceiver', 'setSelectedReceiver', 'deleteChannel', 'createChannelData', 'initReceiverClassList', 'initConverterList', 'initRoundTypeList', 'initConvertTypeList']),
    ...mapMutations('receiver', ['SET_CHANNELS_SEARCH_TEXT']),
    ...mapActions('channel/cache', ['updateChannel']),
    ...mapActions('channel/table', ['addChannelToChannels']),

    openReceiverListMenu(event) {
      this.$refs['receiverListMenu'].show(event)
      this.actionReceiver = null
    },

    sortColumn(column) {
      if (this.sortBy === column) {
        this.sortDesc = !this.sortDesc
      } else {
        this.sortBy = column
        this.sortDesc = false
      }
    },

    openReceiverCreateDialog(isNewReceiver = false) {
      if (isNewReceiver) {
        this.actionReceiver = null
      }
      
      this.showReceiverCreateDialog = true
    },

    createReceiver(receiver) {
      this.saveReceiver(receiver)
    },

    openReceiverListItemMenu(event, receiver) {
      if (this.hasMaintainceEquipment) {
        this.$refs['receiverListItemMenu'].show(event)
        this.actionReceiver = receiver
      }
    },

    openDeleteReceiverDialog() {
      this.showReceiverDeleteDialog = true
    },

    receiverListItemClassName(index, receiver) {
      let className = ''

      if (!(index % 2)) {
        className += 'pressed'
      }

      if (receiver.uuid === this.selectedReceiver?.uuid) {
        className += ' selected'
      }

      return className
    },

    openChannelCreateDialog(channel) {
      this.channelToCreate = { ...(channel || defaultChannel), receiver: channel?.receiver || this.actionReceiver?.uuid }
      this.showChannelCreateDialog = true
    },

    openPanels() {
      const receiverListPanel = this.$refs['receiverListPanel']
      const channelListPanel = this.$refs['channelListPanel']

      !receiverListPanel?.openedPanel && receiverListPanel?.openBody()
      !channelListPanel?.openedPanel && channelListPanel?.openBody()

      return this.$nextTick()
    },

    async selectReceiver(receiver) {
      await this.setSelectedReceiver(receiver)
    },

    openChannelMenu(event, channel) {
      if (this.hasMaintainceEquipment) {
        this.$refs['channelMenu'].show(event)
        this.actionChannel = channel 
      }
    },

    openChannelDeleteDialog() {
      this.showChannelDeleteDialog = true
    },

    openCreateTDataChannelDialog() {
      this.showCreateChannelDataDialog = true
    },

    subscribeSocket() {
      const self = this
      socket = new WebSocket('ws://192.168.1.60:8099/telemetry/ws')

      socket.onopen = function(e) {
        console.log("[open] Соединение установлено", e)
      };

      socket.onmessage = function(event) {
        const data = event.data.includes('{') ? JSON.parse(event.data) : event.data // Переделать

        if (data.channel) {
          const channel = self.channelsMap.get(data.channel)
          
          if (channel) {
            channel.recvTime = data.recvTime
            channel.data = data.value

            channel.dataChanging = true
            
            setTimeout(() => {
              channel.dataChanging = false
            }, 600)
          }
        }
      };
      
      socket.onclose = function(event) {
        if (event.wasClean) {
          console.log(`[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`)
        } else {
          console.log('[close] Соединение прервано')
        }
        // TODO: Должен быть снаружи
        socket.onerror = function(error) {
          console.log(`[error] ${error.message}`)
        }

        telemetryService.refreshDevices(this.projectUuid)
      }
    },
  }
}
</script>

<style scoped lang='scss'>
  .h100p {
    height: 100%;
  }

  .floating-panels-section {
    height: calc(100% - 26px) !important; 
    overflow: auto; 
    overflow-x: hidden
  }

  .receiver-list-item {
    display: flex;
    align-items: center;
    color: black;
    font-weight: bold;
    padding: 4px 8px;
    transition: .1s;
    cursor: pointer;
    
    & span { 
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &.pressed {
      background: #F8F8F8;
    }

    &:hover {
      background: #e5e5e5;
    }

    &.selected {
      background: #e5e5e5;
    }
    
  }
  #channelInput{
    background-color: #F8F8F8; 
    border-radius: 3px;
    outline: none;
    padding: 0 5px;
  }
  .search-input {
    background-color: var(--v-surface-base);
    border: 1px solid var(--v-ternary-base);
    color: #303030;
    outline: none;
    width: 180px;
    border-radius: 3px;
    padding: 0px 32px 0px 6px;
  }
  .search-input-container{
    position: relative;
  }
  .magnify{
    position: absolute;
    right: 4px;
    color: #303030;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--v-scroll-base);
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background-color: var(--v-accent-base);
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-track {
    background-color: var(--v-scroll-base);
    -webkit-box-shadow: inset 0 0 6px rgba(83, 83, 83, 0.07) !important;
  }
</style>