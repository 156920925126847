<template lang="pug">
  app-dialog( v-model="$_show" header='Импорт справочника' :closeOnConfirm="false")
    app-section
      v-form.mt-2(v-on:submit.prevent="importClassificator")
        app-file-input( v-model="files" formats=".json")
        .d-flex
          v-spacer
          v-btn( color="accent" height="32" small type="submit" ) {{$t('button.import')}}
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import DialogToggable from "@/components/app/DialogToggable"

export default {
  name: 'import-projectDict-dialog',
  mixins: [DialogToggable],

  data() {
    return {
      files: null,
    }
  },

  methods: {
    ...mapActions('projectDict', ['importProjectDict']),
    ...mapMutations('projectDict', ['setTreeInPreparation']),

    importClassificator() {
      let formData = new FormData()
      formData.append('file', this.files)
      this.setTreeInPreparation(true)
      this.importProjectDict(formData).then(() => {
        this.$_show = false
        this.files = null
        this.setTreeInPreparation(false)
      })
    }
  }

}
</script>

<style scoped>

</style>