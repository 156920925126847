<template lang="pug">
  .tree
    draggable(:list="items" :group="'toolTreeNode'" handle=".unit__drag" fallback-on-body="true" swap-threshold="0.65")
      project-tool-tree-content(:importProjStruct="true" v-for="u in items" :key="u.uuid" style='position: relative' :u="u" )
</template>

<script>
import ProjectToolTreeContent from "@/components/project/common/ToolTreeContent"
import draggable from 'vuedraggable'


export default {
  name: 'ToolTreeDraggableImportStructure',

  components: { draggable, ProjectToolTreeContent },

  props: {
    items: Array,
  },

  data() {
    return {
    }
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
.tree {
  padding-left: 8px;
}
</style>