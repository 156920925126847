<template lang='pug'>
div
  div.d-flex.align-center(style='height: 24px;')
    div.node_level(:style='{ "margin-left": level * 8 + "px" }')
    v-icon.mr-1(v-if='node.children && node.children.length' color='accent' small @click='opened = !opened') {{ opened ? "mdi-chevron-down" : "mdi-chevron-right" }}
    div.mr-5(v-else)
    app-text.flex.text-truncate
      slot(v-bind:node='node')
  
  div(v-if='opened')
    delete-process-tree-node(v-for='childNode in node.children' :node='childNode' :level='level + 1')
      template(v-slot:default='{ node: __node }')
        slot(:node='__node')
</template>

<script>
export default {
  name: "DeleteProcessTreeNode",
  
  props: {
    node: {
      type: Object,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      opened: false,
    }
  },
}
</script>

<style>

</style>