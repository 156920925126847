<template lang="pug">
  div
    v-progress-linear(v-if="treeLoading" indeterminate)
    
    v-treeview.__project_view_tree(
      dense
      light
      hoverable
      return-object
      :active="activeItem"
      item-key="uuid"
      :items="nodeList"
    )
      template(v-slot:label="{ item }")
        v-hover( v-slot:default="{ hover }" close-delay="150" )
          .d-flex.py-2.p-relative(@contextmenu.prevent="showItemMenu($event,item)" @click="selectNode(item)")
            .nodeLabel.mr-4 {{ item.pressmark }} - {{ item.title }}
            v-icon.unit-action( v-if="hover" color="#000" @click.stop="showItemMenu($event,item)" ) more_vert

    app-menu(ref="itemMenu" :menu="menuItem ? menuItem.nodeMenu : null" @close="closeItemMenu")

    app-dialog-confirm( v-if="deleteNode.node" v-model="deleteNode.dialog" @confirm="delNode")
      app-text {{ $t('section.classification.menuClassification.deleteClass') }} "{{ deleteNode.node.title }}"?
    
</template>

<script>
import { api } from "@/api";

import { mapState, mapMutations } from "vuex";

export default {
  components: {},

  props: {
    projectClassificator: Object,
    searchText: String
  },

  data() {
    return {
      treeLoading: false,
      menuItem: null,

      nodeList: [],

      activeItem:[],

      deleteNode: {
        dialog: false,
        node: null
      }
    }
  },

  mounted() {
    this.searchClassificatorNode()
  },

  computed: {
    ...mapState("smeta", ["editClass"]),
  },

  watch: {
    searchText(){
      this.searchClassificatorNode()
    },

    editClass(val){
      this.activeItem = []
      if (val) this.activeItem.push(val)
    }
  },

  methods: {
    ...mapMutations("smeta", ["setEditClass"]),

    searchClassificatorNode() {
      this.treeLoading = true
      let query = `?uuid=${this.projectClassificator.uuid}&text=${this.searchText}`
      api.smeta.searchInClassificator(query).then((data) => {
        this.nodeList = this.bindNodesList(data)
        this.treeLoading = false
      })
    },

    bindNodesList(data) {
      for (let item of data) item = this.bindNode(item);
      return data;
    },

    bindNode(item) {
      delete item.children
      this.$set(item, "pressmarkTitle", item.pressmark + " - " + item.title);
      let nodeActions = [
          { title: this.$t('section.classification.menuClassification.deleteClass'), action: () => this.showDeleteNode(item) }
        ];
        this.$set(item, "nodeMenu", nodeActions)
      
      return item;
    },

    showItemMenu(event, item) {
      this.menuItem = item
      this.$refs.itemMenu.show(event)
    },

    closeItemMenu() {
      this.menuItem = null
    },

    showDeleteNode(item) {
      this.deleteNode.node = item
      this.deleteNode.dialog = true
    },

    delNode() {
      api.smeta.delNode(this.deleteNode.node.uuid).then(() => {
        let i = this.nodeList.findIndex(e => e.uuid == this.deleteNode.node.uuid)
        this.nodeList.splice(i,1)
        this.deleteNode.dialog = false
        this.setEditClass(null)
      })
    },

    selectNode(item){
      if (this.editClass && this.editClass.uuid == item.uuid) this.setEditClass(null)
      else this.setEditClass(item)
    },

    updateNode(item) {
      let node = this.nodeList.find(e => e.uuid == item.uuid)
      if (node) node = Object.assign(node,item)
    }
  }
}
</script>
