<template lang="pug">
div.mb-4
  .base-scroll-body( id="search-elements-table" )
    .search-elements-table-body--wrap.pa-1( ref="searchVirtualTable" )
      .first-row( :style="{height: `${scroller.firstRowHeight}px`}" )
      div( v-for="(item, index) in currentElements" :key="item.uuid" style="min-height: 24px" )
        search-elements-table-body-item( :element="item" :pickedElementUuid="pickedElementUuid" 
                                          @change="handleChange" @click="openElementDetails(item)" )
      .last-row( :style="{height: `${scroller.lastRowHeight}px`}" )
</template>
  
<script>
import { mapStores } from 'pinia'
import { mapActions } from 'vuex'
import { useSearchElementsStore } from '@/pinia/searchElements.module'
import { SearchElementsScroller } from './searchElementsScroller.js'
import SearchElementsTableBodyItem from './SearchElementsTableBodyItem.vue';
// import _ from 'lodash'

export default {
  data() {
    return {
      scroller: new SearchElementsScroller(),
      scrollPos: 0, 
    }
  },

  components: {
    SearchElementsTableBodyItem
  },

  mounted() {
    this.scroller.initScroll(this.$refs.searchVirtualTable.getBoundingClientRect().top, this.searchElementsStore.currentElements.length)
    document.getElementById('search-elements-table').onscroll = this.handleScroll
  },

  computed: {
    ...mapStores(useSearchElementsStore),

    currentElements() {
      return this.searchElementsStore.currentElements.slice(this.scroller.startIndex, this.scroller.startIndex + this.scroller.step)
    },
    pickedElementUuid() {
      return this.searchElementsStore.pickedElementUuid
    }
  },

  methods: {
    ...mapActions('axis/tree', ['fetchElementByGlobalId']),

    openElementDetails(element) {
      this.searchElementsStore.openPickedElementDetails(element?.uuid ?? null)
      this.fetchElementByGlobalId(element?.uuid ?? null)
    },

    handleChange(value) {
      this.searchElementsStore.setSelectedElement(value)
    },

    handleScroll() {
      this.scroller.handleScroll(this.$refs.searchVirtualTable.getBoundingClientRect().top, this.searchElementsStore.currentElements.length, this.scrollPos)
      this.scrollPos = this.$refs.searchVirtualTable.getBoundingClientRect().top
    }
  }
}
</script>
  
<style scoped>
.search-elements-table-body--wrap {
  display: flex;
  flex-direction: column;
}
.base-scroll-body {
  height: 300px;
  width: 410px;
}
</style>
  