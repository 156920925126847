import { AlertService } from '@/assets/app/AlertService'
import { IFCGridsDrawer } from '@/plugins/IFCGrids/IFCGridsDrawer'
import store from '@/store'

/*eslint-disable no-dupe-class-members*/
export class IFCGridsService {

  static get #_isGridsActive() { return store.state.IFCGrids.gridsActivity }
  static get #_project() { return store.state.project.project }

  static handleGridsDisplay(vueComp) {
    if(!this.#_isGridsActive) {
      store.dispatch('IFCGrids/getIFCGrids', this.#_project.uuid).then(gridData => {
        if (IFCGridsDrawer.areGridsNotExists(gridData)) {
          AlertService.info({info: vueComp.$t('toolbarMenu.noIfcGridsOrItsHidden')})
        }
        else {
          store.commit('IFCGrids/setGridsActivity', true)
          IFCGridsDrawer.redrawGrids(gridData)
        }
      })
    }
    else {
      store.commit('IFCGrids/setGridsActivity', false)
      IFCGridsDrawer.clearGrids()
    }
  }

  static redrawGrids() {
    if (this.#_isGridsActive) {
      store.dispatch('IFCGrids/getIFCGrids', this.#_project.uuid).then(data => {
        IFCGridsDrawer.redrawGrids(data)
      })
    }
  }

  static switchGridsVisibility(revisionUuid) {
    store.dispatch('IFCGrids/saveIFCGridsVisibility', revisionUuid).then(() => {
      this.redrawGrids()
    })
  }
}