<template lang="pug">
.rounded.grey.lighten-2.black--text.pa-2.text-body-2
  .mb-3
    v-chip.pl-1.pr-1.mr-2( color="var(--v-accent-base)" label outlined x-small ) SHARED
    span Доступен для внешнего использования
  .d-flex.flex-column
    .font-weight-bold Классификатор: <span class="font-weight-regular">{{ sharedInfo.classificatorTitle }}</span>
    .font-weight-bold Организация: <span class="font-weight-regular">{{ sharedInfo.title }}</span>
    .font-weight-bold Проекты:
    .d-flex.flex-column.ml-3
      router-link( v-for="project in sharedInfo.projects"
                          :to="`/project/${project.uuid}`"
                          :style="routerLinkStyle" 
                          :key="project.uuid" ) {{ project.title }}
</template>

<script>
import { SmetaShareInfo } from '@/pinia/smeta/SmetaConnections.model.js';

export default {
  props: {
    sharedInfo: SmetaShareInfo
  },

  computed: {
    routerLinkStyle() {
      return {
        "color": "var(--v-accent-base)",
        "text-decoration": "none"
      }
    }
  },

  methods: {
    toProject(uuid) {
      this.$router.push({ path: `/project/${uuid}` })
    }
  }
}
</script>