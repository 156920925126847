<template lang="pug">
app-dialog( v-model="$_show" :header="$t('section.classification.menuClassification.taskBinding')"
            @confirm="saveBindSmetaToTask" :confirm-text="$t('button.save')" )
  app-section
    app-autocomplete( v-model="smetaToTaskBind.taskUuid" :items="tasks" item-text="title" item-value="uuid" test-id="smeta-tree-bind-task" )
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'BindSmetaToTaskDialog', 
  mixins: [DialogToggable],

  props: {
    node: {
      type: Object, 
      default: null
    }
  },

  data() {
    return {
      selectedClassificationNode: null, 
      smetaToTaskBind: {
        classificatorNodeUuid: null,
        taskUuid: null
      }
    }
  },

  computed: {
    ...mapState('task', ['tasks', 'selectedTask']),
  },

  methods: {
    ...mapActions('smeta', ['updateElement']),
    ...mapActions('task', ['loadTasks', 'bindToSmeta']),

    $_beforeShow() {
      this.smetaToTaskBind.taskUuid = this.selectedTask ? this.selectedTask.uuid : null
      this.smetaToTaskBind.classificatorNodeUuid = this.node?.uuid
      this.selectedClassificationNode = this.node
    },

    saveBindSmetaToTask() {
      this.selectedClassificationNode.taskUuid = this.smetaToTaskBind.taskUuid
      this.updateElement(this.selectedClassificationNode)
      this.bindToSmeta(this.smetaToTaskBind)
      this.smetaToTaskBind = {
        classificatorNodeUuid: null,
        taskUuid: null
      }

      this.loadTasks()
    },
  }
}
</script>