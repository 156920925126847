<template lang="pug">
  div(v-if='!importProjStruct')
    template(v-if="u.archived == archived || (archived == true && hasAllChild(u))")
      project-tool-tree-unit(:model="u" @menu="handleContextMenu" :archived="archived")
      
      v-btn.hide-tree-button(v-if='hasAllChild(u)' small icon @click='hideTree(u)' test-id="tool-tree-hide")
        v-icon(color='#262626' small) {{ treeIsVisible(u) ? "mdi-menu-down" : "mdi-menu-right" }}

    project-tool-tree-draggable.list( 
      :items="treeIsVisible(u) ? u.model : []" 
      @menu="handleContextMenu" 
      :archived="archived" :parent="u.uuid"
    )    

  div(v-else)
    template()
      project-tool-tree-unit(:model="u" :importProjStruct="importProjStruct" )
      v-btn.hide-tree-button(v-if='u.projectStructureDTO && u.projectStructureDTO.length>0' small icon @click='hideTreeImportProjStruct()')
        v-icon(color='#262626' small) {{ u.opened ? "mdi-menu-down" : "mdi-menu-right" }}
      v-btn.hide-tree-button(v-if='u.model && u.model.length>0' small icon  @click='hideTreeImportProjStruct()')
        v-icon(color='#262626' small) {{ u.opened ? "mdi-menu-down" : "mdi-menu-right" }}
        

    tool-tree-draggable-import-structure.list(
      :items="treeIsVisibleImportProjStruct"
      :importProjStruct="importProjStruct"
    )


</template>

<script>
import ProjectToolTreeUnit from "@/components/project/common/ToolTreeUnit"
import { mapState, mapActions } from 'vuex'


export default {
  name: 'ProjectToolTreeContent',

  components: { 
    ProjectToolTreeUnit,
    ProjectToolTreeDraggable: () => import('@/components/project/common/ToolTreeDraggable'),
    ToolTreeDraggableImportStructure: () => import('@/components/project/common/ToolTreeDraggableImportStructure.vue')
  },

  props: {
    u: Object,
    archived: {
      default: false,
      type: Boolean
    },
    parent: null,
    importProjStruct: {
      default: false,
      type: Boolean
    },
  },

  data() {
    return {
      treeItems: []
    }
  },

  computed: {
    ...mapState('project', ['projectSettings', 'modelsList']),

    treeIsVisibleImportProjStruct(){
      if (this.u.projectStructureDTO) return this.u.opened? this.u.projectStructureDTO : []
      return this.u.opened? this.u.model : []
    },
  },

  methods: {
    ...mapActions('project', ['updateProjectStructure', 'updateArchivedProjectStructure']),

    treeIsVisible(item){
      let res = false
      if(!this.archived && this.projectSettings.structureSetting && this.projectSettings.structureSetting.length > 0 ){
        let setting = this.projectSettings.structureSetting.find(setting => setting.modelUuid == item.uuid)
        if (setting !== undefined) res = setting.opened
      } 
      else if(this.archived && this.projectSettings.archivedStructureSetting && this.projectSettings.archivedStructureSetting.length > 0 ){
        let setting = this.projectSettings.archivedStructureSetting.find(setting => setting.modelUuid == item.uuid)
        if (setting !== undefined) res = setting.opened
      }
      else {
        res = true
      }
      return res
    },

    hasAllChild(model) {
      let res = model.model.length > 0 && model.model.filter(m => m.archived == this.archived).length > 0
      if( !res && model.model.length ) {
        for(let m of model.model) {
          res = this.hasAllChild(m)
          if(res) break
        }
      }
      return res
    },
 
    hasChild(model) {
      return model.model.length > 0 && model.model.filter(m => m.archived == this.archived).length > 0
    },

    handleContextMenu (event, model) {
      this.$emit('menu', event, model)
    },

    hideTreeImportProjStruct (){
      this.u.opened = !this.u.opened

    },

    hideTree(unit) {
      this.modelsList.forEach(model => {
        if(model.uuid === unit.uuid) model.treeIsVisible = !this.treeIsVisible(unit)
        this.treeItems.push({
          modelUuid: model.uuid,
          opened: model.treeIsVisible
        })
      })
      if (this.archived) {
        this.updateArchivedProjectStructure(this.treeItems).then(() => {
          this.treeItems = []
        })
      }
      else {
        this.updateProjectStructure(this.treeItems).then(() => {
          this.treeItems = []
        })
      }
    },

  }
}
</script>

<style scoped>
.list {
  border-left: 1px dotted var(--v-surface-lighten3);
}

.hide-tree-button {
  position: absolute;
  top: 30px;
  left: -7px;

  height: 16px;
  width: 16px;
  background: var(--v-surface-lighten2);

  border: 1px solid #303030
}


</style>