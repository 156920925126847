<template lang="pug">
  tr(v-if="!item.groupTitle" @contextmenu="openRowMenu($event, item)" @click="setCollisionSelected($event, item)"
        :class="{'select-row': item.uuid == collisionSelectedGlobalIds.collisionUuid}")

    td(v-if="getVisibleCollisionTable")
      v-checkbox(v-model="item.selected" color="accent" dense hide-details :ripple="false" test-id="collision-row-check" @click.stop="addSelectionShiftCollision($event, item)")

    td(v-for="header of visibleHeaders" test-id="collision-row-cell" @click.stop="onCellClick($event, item)"
        :class="{'first-element': classForCell(item, header, 'first'), 'second-element': classForCell(item, header, 'second'), 'pointer': classForCell(item, header, 'pointer')}")
      v-skeleton-loader(v-if="!item.displayedModelA && !item.displayedModelB" loading type="text")
      span(v-else-if="header.value == 'displayedModelA'" id="elementA") {{item[header.value]}}
      span(v-else-if="header.value == 'displayedModelB'" id="elementB") {{item[header.value]}}
      span.pointer(v-else-if="header.value == 'displayedStatus'" id="status") {{item[header.value]}}
      v-select.mt-0(
        v-else-if="header.value == 'type'" 
        v-model='item.type' 
        :items='collisionTypes' 
        item-color="litegray" 
        item-value='title' 
        return-object dense flat 
        item-text="title" 
        @change="patchDataWithDebounce($event, item.uuid, type, setCollisionTypes)"
      )
      v-checkbox.mr-0.ml-0(
        v-else-if="header.value == 'isCollision'" 
        v-model="item.isCollision" 
        color="accent" 
        dense hide-details 
        :ripple="false" 
        id="isCollision" 
        @change="patchDataWithDebounce($event, item.uuid, status, setTrueOrFalse)"
      )
      v-combobox.mt-0(
        v-else-if="header.value == 'allowedDistance'" 
        v-model="item.allowedDistance" 
        auto-select-first dense flat 
        :items="distanceInNumber" 
        item-color="litegray" 
        @change="patchDataWithDebounce($event, item.uuid, allowedDistance, updateAllowedDistances)" 
        @click="getDistanceList(item.uuid)"
      )
      div( v-else-if="header.value == 'displayedTask'" )
        .collision-tasks( v-if="isDisplayedTaskAvailable" )
          v-progress-circular.mr-1( v-if='taskLoading.isLoading' color="white" size="15" width="2" indeterminate )
          v-menu( v-model='taskLoading.taskMenuShow' v-if='item.displayedTask.length > 1' offset-y close-on-content-click close-on-click )
            template( #activator="{ on, attrs }" )
              .d-flex( v-bind='attrs' v-on='on' )
                .no-overflow {{ $t('section.collision.table.attachedTasks') }}
                v-icon.ml-1(color='black' x-small) mdi-menu-down
            .collision-menu(test-id="task-in")
              .collision-menu-title.pointer( v-for='task in item.displayedTask' :title="$t('section.collision.table.goToTask', {title: task.title})" @click.stop='showTaskDetails(task), taskLoading.taskMenuShow = false' ) {{ task.title }}
          .no-overflow.pointer( v-else :title="$t('section.collision.table.goToTask', {title: item.displayedTask[0].title})" @click.stop='showTaskDetails(item.displayedTask[0])' ) {{ item.displayedTask[0].title }}
        span(v-else)
      //- div( v-else-if="header.value == 'displayedDistance'")
      //-   v-menu( v-if="checkDistance(item[header.value])" v-model='distance.menu' offset-y close-on-content-click close-on-click)
      //-     template( #activator="{ on, attrs }" )
      //-       .d-flex( v-bind='attrs' v-on='on')
      //-         .no-overflow(@click="getDistanceList(item.uuid)") {{ item[header.value] }}
      //-         v-icon.ml-1(color='black' x-small @click="getDistanceList(item.uuid)") mdi-menu-down
      //-     .collision-menu(v-if="distance.list.length > 0 && distance.menu")
      //-       .collision-menu-title.pointer( v-for='value in distance.list' @click.stop='changeDistance(item.uuid, value), distance.menu = false' ) {{ value }}
      //-   .no-overflow(v-else) {{ item[header.value] }}
      //- v-tooltip(v-else-if="header.value == 'displayedDescription'" top max-width="50vw")
      //-   template(v-slot:activator="{ on, attrs }")
      //-     span(v-bind="attrs" v-on="on") {{item[header.value]}}
      //-   span {{item[header.value]}}
      span.pointer(v-else) {{item[header.value]}}
      
    app-dialog(v-model="dialog.openTask" :header="$t('module.task.info')" :confirmText="$t('button.confirm')" @confirm="changeWorkspace()")
      app-section 
        span.primary--text {{$t("collision.task.swapWorkspaceInfo")}} 
    
  tr(v-else @contextmenu="item.groupUuid ? openGroupMenu($event, item) : ''")
    td.group
      v-checkbox(v-model="item.selected" :indeterminate="indeterminateGroup" :class="{'checkboxColor' : indeterminateGroup}" color="accent" dense hide-details  :ripple="false" test-id="collision-group-check" @click.stop="selectGroup(item)" ) 

    td.pointer.group(:colspan="visibleHeaders.length" style="padding-left:8px" @click="toggleGroup(item)" test-id="collision-row-group") 
      v-icon {{ item.isOpen ? 'mdi-menu-down' : 'mdi-menu-up' }} 
      span {{item.groupTitle}}
</template>

<script>
  import { panelNames } from '@/store/sections/LayoutRepository'
  import CollisionHighlightService from '@/components/project/panel/left/components/collision/CollisionHighlightService.js'

  import _ from 'lodash'

  import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
  import { AlertService } from '@/assets/app/AlertService'
  import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
  import { api } from '@/api'
  import toNumber from 'lodash/toNumber'
  import store from '@/store'

  export default {
    props: {
      item: Object,
    },

    data() {
      return {
        taskLoading: {
          isLoading: false,
          taskUuid: null,
          taskMenuShow: false,
        },

        distance: {
          menu: false,
          rowId: null,
          list: [],
        },

        type: {
          data: {},
          timeout: null,
        },

        status: {
          data: {},
          timeout: null,
        },

        allowedDistance: {
          data: {},
          timeout: null,
        },
        dialog: {
          openTask: false,
        },
        selectedTask: {},
      }
    },

    computed: {
      ...mapState('task', ['tasks', 'taskDisplay']),
      ...mapState('collision/search', ['collisionTypes', 'collisionSelectedGlobalIds']),
      ...mapState('collision/table', ['tableItems', 'lastCheckedItem', 'selectedGroup']),
      ...mapState('workspace', ['workspaces']),
      ...mapState("project", ["projectSettings"]),

      ...mapGetters('collision/table', ['visibleHeaders', 'selectedCollisions', 'getVisibleCollisionTable']),
      ...mapGetters('layoutControler', ['panelWidth']),
      ...mapGetters('workspace', ['workspaces']),

      isDisplayedTaskAvailable() {
        return this.item.displayedTask && this.item.displayedTask.length > 0 
          && this.item.displayedTask[0].title 
      },

      groupItems() {
        return this.tableItems.filter(item => item.groupName === this.item.groupTitle)
      },

      indeterminateGroup() {
        if (!this.item.groupTitle) return
        let groupSelectedItems = this.selectedCollisions.filter(item => item.groupName === this.item.groupTitle)

        return !!(groupSelectedItems.length && !(groupSelectedItems.length === this.groupItems.length))
      },

      distanceInNumber() {
        return this.distance.list.map(dist => toNumber(dist.substring(0, dist.length - 2)))
      }
    },

    methods: {
      ...mapActions('task', ['loadTasks']),
      ...mapActions('collision/table', ['selectCollisions', 'selectShiftCollision', 'changeIsCollisionValue', 'changeActiveDistance', 'setTrueOrFalse', 'setCollisionTypes', 'updateAllowedDistances']),
      ...mapActions('collision/search', ['setCollisionUuid']),

      ...mapMutations('task', ['setTaskDisplay', 'setSelectedTask', 'setPrevTaskPanel']),
      ...mapMutations('project', ['setTopUnit']),
      ...mapMutations('layoutControler', ['setFromCollision', 'setWidthData']),
      ...mapMutations('collision/table', ['SET_LAST_CHECKED_ITEM', 'SET_SELECTED_GROUP']),
      ...mapActions('workspace', ['changeWorkSpace']),

      classForCell(item, header, type) {
        switch (type) {
          case 'first':
            if (item.globalIdB === null) {
              return item.uuid == this.collisionSelectedGlobalIds.collisionUuid &&
                (header.value == 'displayedModelA' && item.globalIdA == this.collisionSelectedGlobalIds.choosen)
            }
            else {
              return (item.uuid == this.collisionSelectedGlobalIds.collisionUuid &&
                ((header.value == 'displayedModelA' && item.globalIdA == this.collisionSelectedGlobalIds.choosen) ||
                  (header.value == 'displayedModelB' && item.globalIdB == this.collisionSelectedGlobalIds.choosen)))
            }
          case 'second':
            if (item.globalIdB === null) {
              return false
            }
            return (item.uuid == this.collisionSelectedGlobalIds.collisionUuid &&
              ((header.value == 'displayedModelA' && item.globalIdB == this.collisionSelectedGlobalIds.choosen) ||
                (header.value == 'displayedModelB' && item.globalIdA == this.collisionSelectedGlobalIds.choosen)))
          case 'pointer':
            return (
              header.value == 'displayedModelA' ||
              header.value == 'displayedModelB' ||
              header.value == 'displayedStatus'
            )
        }
        return false
      },

      checkInGroups(item) {
        let groupMainElement = this.tableItems.find(el => el.groupTitle === item.groupName)
        let list = this.tableItems.filter(item => item.groupName == groupMainElement.groupTitle)

        let group = []
        if(list?.length == list.filter(item => item.selected)?.length) {
          group = [...this.selectedGroup, groupMainElement]
          this.SET_SELECTED_GROUP(group)
        }
      },

      onCellClick(event, item) {
        let element = event.target?.firstChild?.id
        if (!element) element = event.target?.id
        switch (element) {
          case 'elementA':
            this.handleSelectModel(item, 'a')
            break
          case 'elementB':
            if (item.globalIdB) 
              this.handleSelectModel(item, 'b')
            break
          case 'status':
            this.$emit('statusMenu', event, item)
            break
          default:
            this.setCollisionSelected(event, item)
            break
        }
      },

      setCollisionSelected(event, row) {
        if (!event?.srcElement?.className?.includes('v-select__selection') && event?.srcElement?.type != 'checkbox') {
          CollisionHighlightService.setCollisionSelected(row)
        }
      },

      addSelectionShiftCollision(event, item) {
        store.dispatch('axis/tree/fetchElementByGlobalId', null)
        
        let list
        if (event.shiftKey) {
          if (this.lastCheckedItem) {
            let start = this.lastCheckedItem.index
            let end = item.index
            if (start < end) {
              list = this.tableItems.slice(start, end + 1)
            } 
            else {
              list = this.tableItems.slice(end, start + 1)
            }
          } 
          else {
            list = this.tableItems.slice(0, item.index)
          }
          list = list.filter((el) => !el.groupTitle)
        } 
        else {
          list = [item]
        }

        if (item.selected) {
          list = _.uniq([...this.selectedCollisions, ...list])
        } 
        else {
          list = _.difference(this.selectedCollisions, list)
        }

        this.SET_LAST_CHECKED_ITEM(item)
        this.selectCollisions(list)
        if (item.groupName) this.checkInGroups(item)
      },

      selectGroup(item) {
        let list = []
        for (let i = item.index + 1; i < this.tableItems.length; i++) {
          if (this.tableItems[i].globalIdA || this.tableItems[i].globalIdB) {
            list.push(this.tableItems[i])
          } else break
        }
        let group

        if (item.selected) {
          list = [...list, ...this.selectedCollisions]
          group = [...this.selectedGroup, item]
        } else {
          list = _.difference(this.selectedCollisions, list)
          group = _.difference(this.selectedGroup, [item])
        }

        this.SET_SELECTED_GROUP(group)
        list = _.uniq(list)
        this.selectCollisions(list)
      },

      handleSelectModel(item, element) {
        if (item.globalIdA) {
          CollisionHighlightService.handleSelectModel(item, element)
        } 
        else {
          XeokitMediator.ElementsSelection.pickElement(item.elementA.uuid)
          this.setCollisionUuid(item.uuid)
          AlertService.info({ info: "Элемент не имеет геометрии"})
        }
      },

      toggleGroup(item) {
        this.$emit('group', item)
      },

      openRowMenu(event, item) {
        this.$emit('rowMenu', event, item)
      },

      // TODO: Пользователь может "заспамить" сервак
      changeIsCollision(row) {
        row.isCollision = !row.isCollision
        this.changeIsCollisionValue(row.uuid)
      },

      
      changeWorkspace() {
        let ws = this.workspaces.find(ws => ws.uuid == this.selectedTask.workSpace)
        this.changeWorkSpace(ws.uuid).then(() => {
          this.showTaskDetails(this.selectedTask)
        })
        this.dialog.openTask = false
      },

      showTaskDetails(task) {
        if(this.projectSettings.workSpace !== task.workSpace) {
          this.selectedTask = task
          this.dialog.openTask = true
        }
        else {
          CollisionHighlightService.setCollisionSelected(this.item)
          const selectedTask = this.tasks.find(t => t.uuid === task.uuid)
  
          let panelName = this.taskDisplay
          let panelWidth = this.panelWidth(panelNames.TASKS)
          panelWidth = panelWidth ?? window.innerWidth / 6
  
          if (selectedTask) {
            this.setPrevTaskPanel({ panelName: panelName, panelWidth: panelWidth })
            this.setTopUnit('task')
            this.setTaskDisplay('tree')
            this.setSelectedTask(selectedTask)
  
            this.setWidthData({ id: panelNames.TASKS, width: window.innerWidth / 8 })
          } 
          else {
            this.taskLoading.isLoading = true
            this.taskLoading.taskUuid = task.uuid
            this.loadTasks().then(() => {
              const selectedTask = this.tasks.find(t => t.uuid === task.uuid)
              this.setPrevTaskPanel({ panelName: panelName, panelWidth: panelWidth })
              this.setTopUnit('task')
              this.setTaskDisplay('tree')
              this.setSelectedTask(selectedTask)
  
              this.setWidthData({ id: panelNames.TASKS, width: window.innerWidth / 8 })
  
              this.taskLoading.isLoading = false
              this.taskLoading.taskUuid = null
            })
          }
  
          this.setFromCollision(true)
        }
      },

      openGroupMenu(event, item) {
        this.$emit('groupMenu', event, item)
      },

      changeDistance(id, value) {
        this.changeActiveDistance({ collisionUuid: id, value: value })
      },

      getDistanceList(uuid) {
        if (this.distance.rowId && this.distance.rowId === uuid && this.distance.menu) {
          this.distance.menu = false
          this.distance.list = []
        } 
        else {
          this.distance.rowId = uuid
          api.collisions.getDistanceList(uuid).then(data => {
            if (data != null && data.length > 0) {
              this.distance.list = data
              this.distance.menu = true
            }
            else {
              AlertService.info({ info: "У коллизии нет списка расстояний"})
            }
          })
        }
      },

      checkDistance(val) {
        let num = Number.parseFloat(val)
        return (!!num || num === 0) && (!val.includes("%"))
      },

      patchDataWithDebounce(event, collisionUuid, debounced, saveMethod) {
        clearTimeout(debounced.timeout)
        debounced.data[collisionUuid] = event
        debounced.timeout = setTimeout(() => {
          saveMethod(debounced.data)
        }, 2000)
      },
    },
  }
</script>

<style scoped lang='scss'>
  .first-element {
    border: 1px solid lime !important;
  }

  .second-element {
    border: 1px solid yellow !important;
  }

  .select-row {
    background: var(--v-surface-lighten2) !important;
  }

  .group {
    background-color: #fff;
    border: none
  }

  .v-progress-circular {
    min-width: 15px;
  }
  .collision-menu-title {
    padding: 0px 4px;
    transition-duration: .2s;
    // border-radius: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    font-size: 14px;
    line-height: 20px;
    min-height: 20px;

    &:hover {
      background: #e3e3e3;
    }
  }
  .v-menu__content {
    background: #4d4d4d;
    border-radius: 3px;
    padding: 4px;
  }
  .collision-menu {
    background: #ffffff;
    color: #4d4d4d;
    border-radius: 5px;
    padding: 4px 0px;  

    display: flex;
    flex-direction: column;

    overflow-y: auto;
    max-height: 100px;
    max-width: 100%;
    min-width: 150px;
  }
  .collision-tasks {
    width: 100%;
  }
  .no-overflow {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ::v-deep .v-text-field__details{
    min-height: 0;
  }

  ::v-deep .checkboxColor .v-icon--dense.material-icons{
    color: var(--v-accent-base) !important;
  }
</style>
