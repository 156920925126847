<template lang="pug">
  .selected-page-root
    
    .selected-page-header
      .selected-page-title(:title='selectedDrawingPage.name') {{ selectedDrawingPage.name }}
      control-panel( :isFitting="pageIsFittingSize" :isHighlightFrameActivated="highlightFrameActivated" :isCommentMode="commentMode" @fit='fitSelectedPage()' @crop='activateHighlightFrame()' @scale='scaleSelectedPage()' @commentmode='toggleCommentMode()' @scrollto='scrollPageToComment($event)')

    .selected-page-body( ref='selected-page-body' :style='grabbingCursor')
      img( ref='drawing-page-image' id="drawing-img" )

      .pseudo-page-panel( v-show='!pageIsFittingSize' ref='pseudo-page-panel' :style='panelCommentModeStyle' @click='commentMode ? showContextMenu($event) : null' @contextmenu='showContextMenu($event)')
        .context-menu( v-if='show.contextMenu' :style='contextMenuPosition' @contextmenu.stop='hideContextMenu($event)' )
          button.context-menu-button(@click.stop='showCommentCreateDialog()') {{ $t("module.drawing.addComment.text") }}

        div( v-if='!pageIsLoading && !commentImportMode' v-for='comment in selectedDrawingPage.attachments' :style='pageCommentPosition(comment)')
          page-comment( :comment='comment' :context='currentPseudoPanelSizes' :key='comment.uuid')

        div( v-if='!pageIsLoading && !commentImportMode' v-for='comment in commentsToImport' :style='pageCommentPosition(comment)')
          page-comment( :comment='comment' :context='currentPseudoPanelSizes' :key='comment.uuid')

      .pseudo-page-panel-fitting( v-show='pageIsFittingSize' test-id="new-drawing-page" ref='pseudo-page-panel-fitting' :style='panelCommentModeStyle' @click='commentMode ? showContextMenu($event) : null' @contextmenu='showContextMenu($event)')
        .context-menu( v-if='show.contextMenu' :style='contextMenuPosition' @contextmenu.stop='hideContextMenu($event)' )
          button.context-menu-button(@click.stop='showCommentCreateDialog()' test-id="new-drawing-page-add-button") {{ $t("module.drawing.addComment.text") }}

        div( v-if='!pageIsLoading && !commentImportMode' v-for='comment in selectedDrawingPage.attachments' :style='pageCommentPosition(comment)')
          page-comment( :comment='comment' :context='currentPseudoPanelSizes' :key='comment.uuid')

        div( v-if='!pageIsLoading && commentImportMode' v-for='comment in loadCommentsInPage' :style='pageCommentPosition(comment)')
          page-comment( :comment='comment' :context='currentPseudoPanelSizes' :key='comment.uuid')
      
      .comment-import-mode-panel( v-if='commentImportMode' )
        .comment-import-mode-hint
          span {{ $t("module.drawing.importMessage") }}
          .d-flex.ml-auto.mt-3
            v-btn.mr-3(x-small text color='#757575' @click='setCommentImportMode(false)') {{ $t('button.cancel') }}
            v-btn(x-small color='accent' min-width='84' @click='importCommentNewPage') {{ $t("module.drawing.import") }}
            
      .page-loading-panel( v-if='pageIsLoading' )
        v-progress-circular(color='accent' indeterminate)
    
    comment-create-dialog( v-if='contextMenuPosition' ref='comment-create-dialog' :coords='contextMenuPosition.originalCoords' )

    drawing-cropping-dialog( v-model="isDrawingCroppingDialogOpen" :imgSrc="preparedSrc" :originalPageSize="originalPageSize")
    
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import PageComment from './DrawingComment.vue'
import CommentCreateDialog from './DrawingPageCommentCreateDialog.vue'
import ControlPanel from './DrawingPageControlPanel.vue'
import DrawingPage from './DrawingPage.vue'
import DrawingCroppingDialog from './DrawingCroppingDialog.vue'
import { ImageCropper } from './ImageCropper'

export default {
  name: "NewDrawingPage",

  components: { PageComment, CommentCreateDialog, ControlPanel, DrawingPage, DrawingCroppingDialog },
  
  data() {
    return {
      originalPageSize: {
        width: null,
        height: null
      },
      currentPseudoPanelSizes: {
        width: null,
        height: null
      },

      pageIsLoading: false,
      pageIsFittingSize: false,

      contextMenuPosition: null,

      show: {
        contextMenu: false
      },

      commentMode: false,
      drawingIsGrabbing: false,
      isCtrlKeyDown: false,

      highlightFrameActivated: false,

      preparedSrc: null,
    }
  },

  computed: {
    ...mapState('drawings', ['selectedDrawingPage', 'scaleDrawing', 'commentImportMode', 'commentsToImport', 'commentGrabbing', 'drawingsPages', 'flagCommentToImport', 'commentsImpotToNewPage', 'loadCommentsInPage']),

    panelCommentModeStyle() {
      if (this.commentMode) {
        if (this.drawingIsGrabbing) {
          return {
            cursor: "grabbing"
          }
        }
        else {
          return {
            cursor: "url(/img/project/tools/comment-mode-cursor.svg) 4 18, auto"
          }
        }
      }
      return ""
    },

    grabbingCursor() {
      if (this.drawingIsGrabbing) {
        return {
          cursor: "grabbing"
        }
      }
      return ""
    },

    isDrawingCroppingDialogOpen: {
      get() {
        return ImageCropper.isDialogDrawerCropperShowed
      },
      set(dialogDrawerCropperShowed) {
        if (dialogDrawerCropperShowed) ImageCropper.showDialogDrawerCropper()
        else ImageCropper.hideDialogDrawerCropper()
      },   
    }
  },

  methods: {
    ...mapActions('drawings', ['loadPage', 'importCommentToNewPage', 'getDrawingPage']),
    ...mapMutations('drawings', ['setScaleDrawing', 'setCommentImportMode', 'setCommentsToImport', 'setPseudoPagePanelWidth', 'setPseudoPagePanelHeight']),

    dialogConfirm() {

    },

    importCommentNewPage(){
      let commentsToImport = this.commentsToImport.map(comment => {return comment})
      if(this.flagCommentToImport)
        this.importCommentToNewPage({newPage: this.selectedDrawingPage , comments: commentsToImport })
      else
        this.importCommentToNewPage({newPage: this.selectedDrawingPage, comments: commentsToImport})
    }, 

    startPageLoading(page) {
      let drawingPageImage = this.$refs['drawing-page-image']
      let pseudoPagePanel = this.$refs['pseudo-page-panel']

      if (this.pageIsLoading) {
        return 
      }

      this.pageIsLoading = true
      this.commentMode = false

      return new Promise(resolve => {
        this.loadPage(page.uuid).then(pageSrc => {
          const rawSvg = new DOMParser().parseFromString(pageSrc, 'application/xml')
          const rawSvgWidth = page.width || rawSvg.children[0].children[1].children[1].children[0].width.animVal.value
          const rawSvgHeight = page.height || rawSvg.children[0].children[1].children[1].children[0].height.animVal.value
          const preparedSvg = pageSrc.replace('xmlns:xlink="http://www.w3.org/1999/xlink"', `xmlns:xlink="http://www.w3.org/1999/xlink" width="${rawSvgWidth}" height="${rawSvgHeight}"`)
          const preparedSrc = 'data:image/svg+xml;base64,' + btoa(preparedSvg)
          
          this.preparedSrc = preparedSrc

          drawingPageImage.src = preparedSrc

          drawingPageImage.onload = () => {
            // let { width, height } = drawingPageImage
            this.originalPageSize.width = rawSvgWidth
            this.originalPageSize.height = rawSvgHeight

            pseudoPagePanel.style.width = rawSvgWidth + "px"
            pseudoPagePanel.style.height = rawSvgHeight + "px"

            this.currentPseudoPanelSizes.width = rawSvgWidth
            this.currentPseudoPanelSizes.height = rawSvgHeight

            this.setPageListeners()

            if (this.selectedDrawingPage.offsets) {
              this.scrollToInitialOffsets()
            }
            else {
              this.pageIsFittingSize = false
              this.fitSelectedPage()
            }
            this.pageIsLoading = false
            resolve()
          }
        })
      })
    },

    setPageListeners() {
      const selectedPageBody = this.$refs['selected-page-body']
      
      selectedPageBody.onmousedown = (clickStartEvent) => {
  
        selectedPageBody.onmousemove = (moveEvent) => {
          if (!this.commentGrabbing) {
            if (!this.commentMode) {
              this.drawingIsGrabbing = true

              selectedPageBody.scrollTop -= moveEvent.movementY
              selectedPageBody.scrollRight += moveEvent.movementX
              selectedPageBody.scrollLeft -= moveEvent.movementX
            }
            else if (clickStartEvent.which === 2) {
              this.drawingIsGrabbing = true

              selectedPageBody.scrollTop -= moveEvent.movementY
              selectedPageBody.scrollRight += moveEvent.movementX
              selectedPageBody.scrollLeft -= moveEvent.movementX
            }
            this.selectedDrawingPage.offsets = {
              scale: this.scaleDrawing,
              scroll: {
                x: selectedPageBody.scrollLeft,
                y: selectedPageBody.scrollTop
              }
            }
          }
        }
        selectedPageBody.onmouseleave = () =>{ 
          selectedPageBody.onmousemove = null
          this.drawingIsGrabbing = false
        }
        selectedPageBody.onmouseup = () =>{ 
          selectedPageBody.onmousemove = null
          this.drawingIsGrabbing = false
        }
        
      }

      selectedPageBody.ondragstart = () => false
      selectedPageBody.addEventListener('wheel', this.wheelEventHandler)
    },

    wheelEventHandler(event) {
      if (this.pageIsFittingSize) {
        this.stopFittingPage()
        return
      }
      
      if (event.wheelDelta > 0) {
        if (this.scaleDrawing >= 100) {
          this.setScaleDrawing(this.scaleDrawing + 50) 
        }
        else {
          this.setScaleDrawing(this.scaleDrawing + 25)
        }
      }
      else {
        if (this.scaleDrawing > 100) {
          this.setScaleDrawing(this.scaleDrawing - 50) 
        }
        else {
          this.setScaleDrawing(this.scaleDrawing - 25) 
        }
      }
      this.scaleSelectedPage()
    },

    fitSelectedPage() {
      const drawingPageImage = this.$refs['drawing-page-image']
      const pseudoPagePanelFitting = this.$refs['pseudo-page-panel-fitting']

      this.selectedDrawingPage.offsets = null

      if (this.scaleDrawing !== 100) {
        this.setScaleDrawing(100)
        this.scaleSelectedPage()
      }
      
      drawingPageImage.style.transform = ''
      drawingPageImage.style.transformOrigin = ''

      if (!this.pageIsFittingSize) {
        this.pageIsFittingSize = true

        this.pageResizeObserver = new ResizeObserver(() => {
          pseudoPagePanelFitting.style.height = drawingPageImage.height + "px"
          setTimeout(() => {
            this.currentPseudoPanelSizes.height = parseInt(pseudoPagePanelFitting.style.height)
            this.currentPseudoPanelSizes.width = pseudoPagePanelFitting.getBoundingClientRect().width
          })
        })

        this.pageResizeObserver.observe(drawingPageImage)

        drawingPageImage.classList.add('page-fit')
        pseudoPagePanelFitting.classList.add('page-fit')

        drawingPageImage.style.maxWidth = this.originalPageSize.width + 'px'
        pseudoPagePanelFitting.style.maxWidth = this.originalPageSize.width + 'px'

        setTimeout(() => {
          this.currentPseudoPanelSizes.width = pseudoPagePanelFitting.getBoundingClientRect().width
          this.currentPseudoPanelSizes.height = pseudoPagePanelFitting.getBoundingClientRect().width
        }) 
      }
      else {
        this.stopFittingPage()
      }
    },

    activateHighlightFrame() {
      ImageCropper.showDialogDrawerCropper()
    },

    stopFittingPage() {
      this.pageResizeObserver.disconnect()

      const drawingPageImage = this.$refs['drawing-page-image']
      const pseudoPagePanelFitting = this.$refs['pseudo-page-panel-fitting']
      const pseudoPagePanel = this.$refs['pseudo-page-panel']
      
      this.pageIsFittingSize = false 

      drawingPageImage.classList.remove('page-fit')
      pseudoPagePanelFitting.classList.remove('page-fit')

      drawingPageImage.style.maxWidth = ''
      pseudoPagePanelFitting.style.maxWidth = ''

      setTimeout(() => {
        pseudoPagePanel.style.width = drawingPageImage.getBoundingClientRect().width + "px"
        pseudoPagePanel.style.height = drawingPageImage.getBoundingClientRect().height + "px"

        this.currentPseudoPanelSizes.width = parseInt(pseudoPagePanel.getBoundingClientRect().width)
        this.currentPseudoPanelSizes.height = parseInt(pseudoPagePanel.getBoundingClientRect().height)
      })
    },

    showContextMenu(event) {
      event.preventDefault()
      const targetBoundingClientRect = event.target.getBoundingClientRect()

      const left = ((event.clientX - targetBoundingClientRect.left) / targetBoundingClientRect.width ) * 100
      const top = ((event.clientY - targetBoundingClientRect.top) / targetBoundingClientRect.height ) * 100

      this.contextMenuPosition = {
        top: `calc(${top}% - 34px)`,
        left: left + "%",
        position: "absolute",

        originalCoords: {
          xCoord: left,
          yCoord: top
        }
      }

      this.show.contextMenu = true

      if (!this.commentMode) {
        document.body.addEventListener('click', this.hideContextMenu)
      }
    },

    hideContextMenu(event = null) {
      event?.preventDefault()
      document.body.removeEventListener('click', this.hideContextMenu)

      this.show.contextMenu = false
    },

    scaleSelectedPage(returnIfFitting = true) {
      const drawingPageImage = this.$refs['drawing-page-image']
      let pseudoPagePanel = this.$refs['pseudo-page-panel']

      if (this.pageIsFittingSize) {
        this.stopFittingPage()
        if (returnIfFitting) {
          return 
        }
      }

      drawingPageImage.style.transformOrigin = 'top left' 
      drawingPageImage.style.transform = `scale(${this.scaleDrawing / 100})`

      setTimeout(() => {
        pseudoPagePanel.style.width = drawingPageImage.getBoundingClientRect().width + 'px' 
        pseudoPagePanel.style.height = drawingPageImage.getBoundingClientRect().height + 'px'

        this.currentPseudoPanelSizes.width = parseInt(pseudoPagePanel.style.width)
        this.currentPseudoPanelSizes.height = parseInt(pseudoPagePanel.style.height)
      })

      this.selectedDrawingPage.offsets = {
        scale: this.scaleDrawing,
        scroll: {
          x: this.selectedDrawingPage.offsets?.x ? this.selectedDrawingPage.offsets?.x : 0,
          y: this.selectedDrawingPage.offsets?.y ? this.selectedDrawingPage.offsets?.y : 0, 
        }
      }
    },

    showCommentCreateDialog() {
      this.hideContextMenu()
      this.$refs['comment-create-dialog'].show()
    },

    toggleCommentMode() {
      this.commentMode = !this.commentMode
      this.hideContextMenu()
    },

    scrollPageToComment(comment) {
      const selectedPageBody = this.$refs['selected-page-body']
      const pseudoPagePanel = this.$refs['pseudo-page-panel']
      const pageBodyClientRect = selectedPageBody.getBoundingClientRect()

      this.setScaleDrawing(comment.scale)
      this.scaleSelectedPage(false)

      setTimeout(() => {
        let xCoord = ((pseudoPagePanel.getBoundingClientRect().width * (comment.x / 100))) - pageBodyClientRect.width / 2
        let yCoord = ((pseudoPagePanel.getBoundingClientRect().height * (comment.y / 100))) - pageBodyClientRect.height / 2

        selectedPageBody.scrollTo(xCoord, yCoord)

        this.selectedDrawingPage.offsets = {
          scale: this.scaleDrawing,
          scroll: {
            x: xCoord,
            y: yCoord
          }
        }
      })
    },

    scrollToInitialOffsets() {
      const selectedPageBody = this.$refs['selected-page-body']
      const { x, y } = this.selectedDrawingPage.offsets.scroll

      this.setScaleDrawing(this.selectedDrawingPage.offsets.scale)
      this.scaleSelectedPage(false)

      selectedPageBody.scrollTo(x, y)

      this.selectedDrawingPage.offsets = {
        scale: this.scaleDrawing,
        scroll: { x, y }
      }
    },

    pageCommentPosition(comment) {
      return {
        left: comment.x + "%",
        top: `calc(${comment.y}% - 30px`,
        position: "absolute"
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .selected-page-root {
    background: #FFFFFF;

    min-height: 100%;
    height: 1px;
    width: 100%;
    min-width: 300px;

    border-radius: 3px;
  }

  .selected-page-header {
    background: #E5E5E5;
    min-height: 32px;
    width: 100%;

    line-height: 32px;
    font-weight: bold;
    
    display: flex;
    padding: 0px 12px;
    align-items: center;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    & .control-panel {
      margin-left: auto;
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }

  .selected-page-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .selected-page-body {
    background: inherit;
    
    width: 100%;
    height: calc(100% - 32px);
    position: relative;
    overflow: hidden;

    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  .pseudo-page-panel {
    position: absolute;
    top: 0px; left: 0px;
  }

  .pseudo-page-panel-fitting {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
  }

  .page-comments {
    width: 100%;
    height: 100%;
  }

  .page-fit {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-height: 100%;
  }

  .button-margin-3 {
    margin-bottom: 3px;
  }
  
  .context-menu-button{
    background: white;
    color: black;
    border: 1px solid #EB5757;
    border-radius: 20px 20px 20px 0px;

    line-height: 34px;
    font-size: 12px;

    padding: 0px 4px;  
    z-index: 1;
    width: 160px;
    
    display: flex;
    justify-content: center;
    position: relative;

    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.65);
  }

  .context-menu-button::before {
    content: "";
    
    width: 8px;
    height: 8px;
    
    background-image: linear-gradient(to top right, #EB5757 50%, transparent 0);
    
    position: absolute;
    bottom: 0px;
    left: 0px;
  }

  .context-menu-button:hover {
    background: black;
    color: white;
  }

  .comment-import-mode-panel {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0px;
    left: 0px;

    display: flex;
    justify-content: center;
  }

  .comment-import-mode-hint {
    width: 250px;
    height: 80px;

    margin-top: 8px;
    padding: 8px 4px 0 8px;
    border-radius: 3px;

    box-shadow: 0 0 4px rgba(0,0,0,.65);
    background: rgb(255, 255, 255, .9);

    font-size: 11px;

    display: flex;
    flex-direction: column;
  }

  .page-loading-panel {
    width: 100%;
    height: 100%;

    position: sticky;
    top: 0px; right: 0px; bottom: 0px; left: 0px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: white;
    opacity: .8;
  }
</style>